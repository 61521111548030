%at-error-page-logo-skin-default{
  width: 12rem;
  height: 12rem;
  display: block;
  background-repeat: no-repeat;
}

%at-error-page-logo-skin-session{
  background-image: url("/assets/images/expired_session.png");
}

@mixin error-page-logo($selector, $skin: default) {
  #{$selector} {
    @extend %at-error-page-logo-skin-#{$skin} !optional;
    background-position: center;
  }
}


// Include component here
@include error-page-logo('.error-page__logo');
@include error-page-logo('.error-page__logo--session', 'session');
