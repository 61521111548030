// Declare skins here

%at-simulation__group-skin-default {
  @include clearfix();
  font-family: $font-regular;
  color: color-palette(tone-primary-dark);
  font-size: 1.6rem;
  line-height: 2;
  text-align: center;
  margin: 0 auto 3rem;
  list-style-type: none;
  padding: 0;

  @include gridle_state(mobile) {
    margin-bottom: 2.5rem;
  }

  @include gridle_state(mobile) {
    line-height: 1.5;
  }
}

%at-simulation__group-skin-medium {
  font-size: $font-size-normal;
  line-height: 1.4;
}

%at-simulation__group-skin-primary {
  color: color-palette(tone-primary);
}

%at-simulation__group-skin-grey {
  font-size: inherit;
  color: color-palette(tone-primary-dark);
  line-height: 1.4;
}

%at-simulation__group-skin-recap {

  // Pret/Sitauation tables.
  .pret, .situation {
    & > div:nth-child(2) {
      margin: 0 2rem;
    }

    & > div:nth-child(2) > div {
      padding-left: 7rem;
    }
  }

  .pret > div > div:first-child {
    border-right: .1rem solid color-palette(tone-primary-light);
  }

  .situation > div > div:last-child {
    border-left: .1rem solid color-palette(tone-primary-light);
  }

  // Sports.
  .situation .sports {
    display: table-row;

    span {
      display: table-cell;
      vertical-align: top;
    }

    ul {
      display: inline-block;
      list-style-type: none;
    }
  }

}

%at-simulation__group-skin-qs-summary {
  text-align: left;
  counter-reset: digit-counter;
  //font-size: 2.4rem;
  //line-height: 4.5rem;

  &.qs-summary-wrapper{
    margin-top:4rem;
  }

  // Here we use regular font un bold, cause the Medium font transform the '-v' string into an arrow
  .section-title {
    font-family: $font-regular;
    font-weight: bold;
    font-size: inherit;
    margin-bottom: 3rem;

    &__title {
      display: inline-block;
      margin-bottom: 2rem;

      @include gridle_state(mobile) {
        margin-bottom: 0;
      }
    }

    &:before {
      position: absolute;
      left: 0; top: 0;
    }
  }
}

%at-simulation__group-skin-qs %at-simulation__group-skin-qs-question {
  border-bottom: .1rem solid color-palette(tone-primary-light);
  padding-bottom: 5rem;
  margin-bottom: 4rem;
}
%at-simulation__group-skin-qs{
  .button--save{
    display: none;
  }
}

%at-simulation__group-skin-qs-question {
  & > .height-weight-question {
    display: table;
    margin: 0 auto;

    & > label {
      display: table-row;

      & > span {
        display: table-cell;
        text-align: right;

        &:last-child {
          text-align: left;
          padding: 4.5rem 0 0 1rem;
        }
      }
    }
  }

  .not-height-weight-question .question-title {
    padding: 0;
  }

  .not-height-weight-question .yes-no-buttons {
    @include clearfix();
    padding: 3.5rem 0 0;
  }

}

%at-simulation__group-skin-bank {
  margin-bottom: 1rem;
  text-align: left;

  label {
    padding-right: 1rem;
  }

  @include gridle_state(mobile) {
    margin-bottom: 1.5rem;
  }
}

%at-simulation__group-skin-payment-day-bank {
  margin-top: 6rem;
}

%at-simulation__group-skin-separator {
  padding: 2rem 0;
  border-bottom: .1rem solid rgba(color-palette(tone-primary-dark), .5);
  margin-bottom: 0;

  &:last-child {
    margin-bottom: 4rem;
  }
}

@mixin simulation__group($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__group-skin-#{$skin} !optional;

    .select, .textfield { // TODO: modifiers
      font-size: inherit;
    }

    p { // TODO : refactor with &Paragraph
      margin: 0;
      margin-bottom: 2rem;
      line-height: 2.5rem;
    }

    @if skin==default {
      @include gridle_state(mobile) {
        //font-size: 2rem;
        line-height: 3rem;

        .select-box {
          top: -0.5rem;
        }

        .select, .textfield { // TODO: modifiers
          //font-size: 2rem;
        }
      }
    }

  }
}

// Include component here
@include simulation__group('.simulation__group');
@include simulation__group('.simulation__group--medium', 'medium');
@include simulation__group('.simulation__group--primary', 'primary');
@include simulation__group('.simulation__group--grey', 'grey');
@include simulation__group('.simulation__group--qs', 'qs');
@include simulation__group('.simulation__group--qs-question', 'qs-question');
@include simulation__group('.simulation__group--recap', 'recap');
@include simulation__group('.simulation__group--bank', 'bank');
@include simulation__group('.simulation__group--payment-day-bank', 'payment-day-bank');
@include simulation__group('.simulation__group--qs-summary', 'qs-summary');
@include simulation__group('.simulation__group--separator', 'separator');
