// Declare skins here

%at-proposition__guarantees-skin-default {
  margin: 0 0 4rem 0;
  list-style-type: none;
  color: color-palette(tone-primary-dark);
  font-size: $font-size-normal;
  padding: 4rem 6rem;
  border-color: color-palette(tone-primary-light);
  border-style: solid;
  border-width: 0 0.1rem 0.1rem 0.1rem;
  position: relative;

  &:after {
    content: '';
    display: block;
    border-left: 1px solid color-palette(tone-primary-light);
    height: 100%;
    position: absolute;
    top: 0; right: 39%;
  }
}

@mixin proposition__guarantees($selector, $skin: default) {
  #{$selector} {
    @extend %at-proposition__guarantees-skin-#{$skin} !optional;

    &Row {
      & + & {
        margin-top: 2rem;
      }
    }

    &Cell {
      &:last-child {
        text-align: center;
      }
    }
  }
}

// Include component here
@include proposition__guarantees('.proposition__guarantees');
