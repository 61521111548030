// Declare skins here

%at-dashboard-recap-skin-default {
  position: relative;
  text-align: center;
  font-size: $font-size-normal;

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    height: 100%; top: 0; right: -.4rem;
    border-right: .1rem solid color-palette(tone-primary-dark);

    // Equal height tricks
    padding-bottom: 900rem;
    margin-bottom: 900rem;
  }

  @include gridle_state(mobile) {
    &:after {
      display: none;
    }
  }
}

@mixin dashboard-recap($selector, $skin: default) {
  #{$selector} {
    @extend %at-dashboard-recap-skin-#{$skin} !optional;

    .slides{
      display: inline-block;
    }

    &__title {
      color: color-palette(tone-primary);
      font-size: 2rem;
      line-height: 1.3;
      text-transform: uppercase;
      margin-bottom: 3.5rem;
      padding: 0 3rem;
      text-align: center;
    }

    &__list {
      margin: 0; padding: 0;
      list-style-type: none;
    }

    &__item {
      color: color-palette(tone-primary-dark);
      line-height: 1;
      padding: 0 3rem;

      & + & {
        margin-top: 1.4rem;
      }

      span {
        font-family: $font-medium;
        color: color-palette(tone-secondary);
      }
    }

    &__action {
      margin-top: 2rem;
      opacity: 0;
      transition: opacity 0.2s ease-out 0.5s;
      &--fadein {
        opacity: 1;
      }

      &.button--flat-primary-reversed {
        max-width: 20rem;
        padding: 2rem;
      }
    }

    &--left {
      text-align: left;
    }
  }
}

// Include component here
@include dashboard-recap('.dashboard-recap');
