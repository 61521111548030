// Declare skins here

%at-slider-arrow-skin-default {
  display: block;
  font-size: 5rem;
  color: color-palette(tone-primary);
  background: none;
  outline: none;
  border: none;
  position: absolute;
  top: 50%;
  z-index: 10;
  @include icon(select-track-left);
}

%at-slider-arrow-skin-right {
  @include icon(select-track-right);
  right: 1.5rem;
  transform: translate3d(50%, -50%, 0);
}

%at-slider-arrow-skin-left {
  @include icon(select-track-left);
  left: 1.5rem;
  transform: translate3d(-50%, -50%, 0);
}

@mixin slider-arrow($selector, $skin: default) {
  #{$selector} {
    @extend %at-slider-arrow-skin-#{$skin} !optional;
  }
}

// Include component here
@include slider-arrow('.slider__arrow');
@include slider-arrow('.slider__arrow--right', 'right');
@include slider-arrow('.slider__arrow--left', 'left');
