%at-sinister__send-skin-default {
  .button {
    border: none;

    &:hover,
    &:focus {
      background-color: color-palette(neutral);
      color: color-palette(tone-primary);
    }
  }
}

@mixin sinister__send($selector, $skin: default) {
  #{$selector} {
    @extend %at-sinister__send-skin-#{$skin} !optional;

    &-subtitle {
      color: color-palette(tone-primary);
      font-family: $font-medium;
    }
  }
}

// Include component here
@include sinister__send('.sinister__send');
