// Declare skins here

%at-login-page-skin-default {
    width: 62.2rem;
    min-height: 41.9rem;
    border-radius: 0.6rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    background: color-palette(neutral);

    .page-title {
        margin-left: 3.4rem;
        margin-right: 0;
        @include gridle_state(mobile) {
            margin-left: 2.2rem;
        }
    }

    @include gridle_state(mobile) {
        width: 100%;
        min-height: initial;
        border-radius: 0;
    }
    .hide-mobile {
        @include gridle_state(mobile) {
            display: none !important;
        }
    }
    .hide-desktop {
        display: none;
        @include gridle_state(mobile) {
            display: block !important;
        }
    }
}

%at-login-page-skin-lg {
    width: 104.2rem;
    min-height: 52.5rem;
    border-radius: 0.6rem;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
    background: color-palette(neutral);

    .page-title {
        margin-left: 3.4rem;
        @include gridle_state(mobile) {
            margin-left: 2.2rem;
        }
    }
    @include gridle_state(tablet, mobile) {
        width: 100%;
        min-height: initial;
        border-radius: 0;
    }
}

@mixin login-page($selector, $skin: default) {
    #{$selector} {
        @extend %at-login-page-skin-#{$skin} !optional;

        &__content {
            box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
            padding: 2rem 0rem;

            & > form {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            @include gridle_state(mobile) {
                padding: 0rem 2rem;
            }
        }
        &__subtitle {
            padding: 2rem 4rem;
            color: white;
            background: color-palette(neutral-grey-darker);
            @include gridle_state(mobile) {
                padding: 1rem 3rem;
                font-size: 1.1rem;
            }
        }

        &__input {
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding-left: 0 !important;
            padding-right: 0 !important;
            display: block;
            vertical-align: middle;
            @include gridle_state(mobile, tablet) {
                flex-direction: column;
            }

            & > label {
                text-transform: uppercase;
                text-align: right;
                font-size: 1.4rem;
                padding: 0;
                padding-right: 2rem;
                margin-top: 1.5rem;
                @include gridle_state(mobile, tablet) {
                    text-align: left;
                    font-size: 1.2rem;
                    margin: 0.5rem 0;
                }
            }
            & input {
                background: color-palette(neutral-grey-light);
                border: 0.1rem solid lightgrey;
                border-radius: 0.4rem;
                box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.4);
                padding: 1.3rem 1rem;
                line-height: 0.2rem;
            }
            & .login-page__input-autocomplete {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
            & span.icon {
                font-size: 2rem;
                display: block;
                padding-top: 1rem;
            }
        }

        &__button {
            margin-top: 3rem;
        }
        &__bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            @include gridle_state(mobile) {
                position: initial;
            }
        }

        &__inputdesc {
            font-size: 1.2rem;
        }
        &__caisse {
            text-align: center;
            padding: 0rem 1.5rem;
            &Title {
                color: color-palette(tone-secondary-black);
                font-size: 1.4rem;
            }
        }

        &__tooltip {
            margin-left: 1rem;
        }
        .angucomplete-holder {
            width: 100%;
        }
        .page-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__resetusername {
            text-align: center;
            img {
                margin-bottom: 3rem;
            }
            button {
                margin-top: 4rem;
                margin-bottom: 2rem;
            }
        }
    }
}

.otp-connexion .enter-code {
    @include gridle_state(mobile) {
        margin-left: 0% !important;
    }
}

.otp-connexion .enter-code input {
    @include gridle_state(mobile) {
        width: 100% !important;
    }
}

.otp-connexion .enter-code-error {
    @include gridle_state(mobile) {
        margin-left: 0% !important;
        width: 100% !important;
    }
}

// Include component here
@include login-page('.login-page');
@include login-page('.login-page--lg', 'lg');
