%at-edito2-fq-skin-default {
  // Default skin here

  background-color: color-palette(tile-neutral);
  padding: 5rem 0;
  text-align: center;
}

@mixin edito2-fq($selector, $skin: default) {
  #{$selector} {
    @extend %at-edito2-fq-skin-#{$skin} !optional;

    &__header {
      color: color-palette(tone-primary);
      width: 65rem;
      line-height: 1.4;
      display: inline-block;
      font-size: 2.4rem;
      margin-bottom: 3rem;
    }

    &__form {
      color: color-palette(tone-primary-dark);
      font-size: 2.4rem;
    }
  }
}

// Include component here
@include edito2-fq('.edito2-fq');
