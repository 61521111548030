%at-sinister__header-skin-default {
  color: color-palette(tone-primary);
  text-align: center;
  font-size: $font-size-normal;
}

@mixin sinister__header($selector, $skin: default) {
  #{$selector} {
    @extend %at-sinister__header-skin-#{$skin} !optional;

    &_title {
      padding-bottom: 2rem;
      font-family: $font-medium;
      font-size: 2.6rem;
    }
  }
}

// Include component here
@include sinister__header('.sinister__header');
