// Declare skins here

%at-dashboard__header-skin-default {
  color: color-palette(tone-grey-black-dark);
  text-align: center;
  font-size: $font-size-normal;
}

@mixin dashboard__header($selector, $skin: default) {
  #{$selector} {
    @extend %at-dashboard__header-skin-#{$skin} !optional;

    &_title {
      font-family: $font-medium;
      font-size: (2.6 / 1.9) * 100%;
      color: color-palette(tone-primary);
    }

    &_subTitle {
      text-transform: uppercase;
      font-size: (1.7 / 1.9) * 100%;
      margin: 3rem 0 2.5rem;
    }
  }
}

// Include component here
@include dashboard__header('.dashboard__header');
