// Declare skins here

%at-client-skin-default {
  margin-top: 7.5rem;

  @include gridle_state(mobile) {
    margin-top: 2.5rem;
  }
}

@mixin client($selector, $skin: default) {
  #{$selector} {
    @extend %at-client-skin-#{$skin} !optional;

    &-panel {
      padding-left: 7rem;
      margin-top: -7.5rem;
      padding-top: 7.5rem;
      background-color: color-palette(tone-primary-lightest);
      min-height: 100vh;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    }
    &-sinister_message {
      a{
        font-size: $font-size-normal;
        text-decoration: none;
        color: color-palette(tone-primary);
        cursor: pointer;

        &:hover {
          color: color-palette(tone-primary);
        }
      }

    }

    &-mobile-navigation {
      margin: 3rem auto;
      text-align: center;

      .select {
        border-width: .3rem;
        font-size: 2.3rem;
        font-family: $font-medium;
        padding-left: 1rem;
      }
    }

    @include gridle_state(mobile) {
      .page-title {
        margin-left: 1rem;
      }
    }
  }
}

// Include component here
@include client('.client');
