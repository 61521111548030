// Declare skins here

%at-simulation__quotiteList-skin-default {
  margin-bottom: 4.5rem;
}

@mixin simulation__quotiteList($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__quotiteList-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__quotiteList('.simulation__quotiteList');
