// Declare skins here

%at-offer-skin-default {
  background: color-palette(neutral);
  display: table-cell;
  vertical-align: top;

  @include gridle_state(mobile) {
    display: block;
  }
}

@mixin offer($selector, $skin: default) {
  #{$selector} {
    @extend %at-offer-skin-#{$skin} !optional;
  }
}

// Include component here
@include offer('.offer');
