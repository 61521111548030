// Declare skins here

%at-testimony-skin-default {
}

%at-testimony-skin-home {

  // Default skin here
  background: url('/assets/images/home-bg2.png') no-repeat;
  background-size: 40%;
  background-position: bottom left;

  @include gridle_state(tablet){
    background: none;
  }

}

%at-testimony-skin-edito {
  background-color: color-palette(tone-secondary);
  display: table-cell;
  @include icon(testimony);
  width: 35rem;
  padding:0 1.6rem 7rem 1.6rem;
  position: relative;
  min-height: 30rem;

  &__breadcrumb {
    padding: 0 5rem;

    @include gridle_state(mobile) {
      padding: 0 5%;
      display: inline-block;
      margin-top: 4rem;
      width: auto;
    }
  }

  &:before {
    position: absolute;
    top: 2rem; right: 3rem;
    font-size: 3rem;
    color: color-palette(neutral);
  }
}

%at-testimony-skin-edito1 {
  @extend %at-testimony-skin-edito;

  // Default skin here
  background-color: color-palette(tone-secondary);
  display: table-cell;
  text-align: center;
}

%at-testimony-skin-edito2 {
  @extend %at-testimony-skin-edito;

  // Default skin here
  background-color: color-palette(tone-secondary);
  text-align: left;
  display: block;
}

%at-testimony-skin-edito2-grey {
  @extend %at-testimony-skin-edito2;
  background-color: color-palette(neutral-grey);
  color: color-palette(tone-primary-dark);
}


@mixin testimony($selector, $skin: default) {
  #{$selector} {
    @extend %at-testimony-skin-#{$skin} !optional;

    @if($skin == default){
      .js_slides{
        padding: 0;
      }

      .slider{
        max-width: 60rem;
        float: right;
      }

      .js_frame{
        border-radius: 0.6rem;
        box-shadow: 0 0 0.3rem rgba(1, 1, 1, 0.25);
        background-color: color-palette(neutral);
      }
    }
  }
}

// Include component here
@include testimony('.testimony');
@include testimony('.testimony--home', 'home');
@include testimony('.testimony--edito1', 'edito1');
@include testimony('.testimony--edito2', 'edito2');
@include testimony('.testimony--edito2--grey', 'edito2-grey');

