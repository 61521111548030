// Declare skins here

%at-testimony-author-skin-default {
  max-width: 10.6rem;
  float: left;
  margin-right: 1.6rem;
}

@mixin testimony-author($selector, $skin: default) {
  #{$selector} {
    @extend %at-testimony-author-skin-#{$skin} !optional;
  }
}

// Include component here
@include testimony-author('.testimony-author');
