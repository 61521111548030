%at-recovery-form-skin-default {

}

%at-recovery-form-skin-bank-selection {
  .angucomplete-holder {
    display: block;
    width: 100%;
  }

  .angucomplete-dropdown {
    height: 9rem;
  }
}

@mixin recovery-form($selector, $skin: default) {
  #{$selector} {
    @extend %at-recovery-form-skin-#{$skin} !optional;

    &__fields {
      list-style: none;
      padding-left: 0;
      width: 60%;
      margin: 3rem auto;

      @include gridle_state(mobile) {
        width: 80%;
      }

      &__item, &__input {
        width: 100%;
      }

      &__item {
        margin-bottom: 2rem;
      }

      &__item:last-child {
        margin-bottom: 0;
      }

      &__input, .select-box__track {
        line-height: 2;
      }

      .select-box {
        width: 100%;
        margin-right: 0;
      }
    }

    &__checks {
      list-style: none;
      padding-left: 0;
      margin: 3rem 0;

      &__item + &__item {
        margin-top: 2rem;
      }

      &__item--margin {
        margin-bottom: 5rem;
      }
    }

    &__condition {
      font-size: 1.3rem;
      text-align: center;
      color: color-palette(tone-primary-dark);
    }

    &__footer {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 2rem;

      .button{
        @include gridle_state(mobile) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

// Include component here
@include recovery-form('.recovery-form');
@include recovery-form('.bank-selection-form', 'bank-selection');
