// Declare skins here

%at-filefield-skin-default {
  /* IE8 and FF freak out if this rule is within another selector */
  &::-webkit-file-upload-button
  {
    padding:0;
    border:0;
    background:none;
    visibility: hidden;
    width: 0; height: 0;
  }

  border: none;
  text-align: center;
  background-color: color-palette(neutral-light);
  padding: 1rem 5rem;
  color: color-palette(tone-primary);
  font: 2rem $font-regular;
  outline:none;
  cursor: pointer;
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

%at-filefield-skin-empty {
  @include icon(add);
  background-color: color-palette(neutral);
  border: .2rem dashed color-palette(tone-secondary-dark);
  color: color-palette(tone-secondary-dark);
  transition: all .5s ease-out;

  &:hover {
    color: color-palette(tone-primary);
    border-color: color-palette(tone-primary);
  }
}

%at-filefield-skin-valid {
  color: color-palette(neutral-grey-darken);
  border-color: color-palette(neutral-grey-darken);
  cursor: auto;

  &:hover {
    color: color-palette(neutral-grey-darken);
    border-color: color-palette(neutral-grey-darken);
  }
}

%at-filefield-skin-error,
%at-filefield-skin-invalid {
  border:0.2rem dashed color-palette(tone-error);
  color: color-palette(tone-error);
}

@mixin filefield($selector, $skin: default) {
  #{$selector} {
    @extend %at-filefield-skin-#{$skin} !optional;
  }
}

// Include component here
@include filefield('.filefield');
@include filefield('.filefield--empty', 'empty');
@include filefield('.filefield--valid', 'valid');
@include filefield('.filefield--invalid', 'invalid');
@include filefield('.filefield--error', 'error');
