// Declare skins here

%at-glossary-results-skin-default {
  margin-bottom: 3rem;
}

@mixin glossary-results ($selector, $skin: default) {
  #{$selector} {
    @extend %at-glossary-results-skin-#{$skin} !optional;
  }
}

// Include component here
@include glossary-results('.glossary-results');
