// Declare skins here

%at-login-link-skin-default {
  // Default skin here
  font-family: $font-regular;
  font-size: 1.4rem;
  color: color-palette(tone-primary-dark);
  display: block;
  text-decoration: underline;
  cursor: pointer;
}

@mixin login-link($selector, $skin: default) {
  #{$selector} {
    @extend %at-login-link-skin-#{$skin} !optional;
  }
}

// Include component here
@include login-link('.login-box__link');
