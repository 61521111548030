// Declare skins here

%at-header-logo__subtitle-skin-default {
  color: color-palette(tone-secondary);
  font-size: 1.5rem;
  margin: 0;
  font-family: $font-bold;
}

%at-header-logo__subtitle-skin-small {
  font-size: 1.3rem;
}

@mixin header-logo__subtitle($selector, $skin: default) {
  #{$selector} {
    @extend %at-header-logo__subtitle-skin-#{$skin} !optional;
  }
}

// Include component here
@include header-logo__subtitle('.header-logo__subtitle');
@include header-logo__subtitle('.header-logo__subtitle--small', 'small');
