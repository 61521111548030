@import "../5_templates/_appointment";

%at-appointment-confirmation-skin-page {
  %at-button-skin-download {
    background-color: white;
    border: 1px solid #fc0081;
    border-radius: 3px;
    color: #fc0081;
    height: 4.7rem;
    width: 27rem;

    @include gridle_state(mobile) {
      max-width: 29rem;
      min-width: 24rem;
      width: 100%;
    }
  }
  @include gridle_state(mobile) {
    padding-bottom: 0;
    .appointment {
      padding-bottom: 5rem;
    }
  }
}

@mixin appointment-confirmation($selector, $skin: default) {
  #{$selector} {
    @extend %at-appointment-confirmation-skin-#{$skin} !optional;
    .download-block--button {
      display: flex;
      justify-content: center;
    }
    &__header {
      &-checked {
        img {
          width: 6rem;
        }
        @include gridle_state(mobile) {
          margin-top: 2rem;
        }
        text-align: center;
      }
      &--desktop-padding {
        padding-top: 3rem;
        padding-bottom: 2rem;
        padding-left: 2rem;
        @include gridle_state(mobile) {
          padding-top: 0;
          .download-block--button {
            padding-top: 1rem;
            padding-bottom: 2rem;
          }
        }
      }
      &-dateInput {
        background-image: url("/assets/images/calendar-confirm.svg");
        background-repeat: no-repeat;
        background-size: 74%;
        background-position: -4% 61%;
        padding: 1.5rem 3rem 0.7rem 1px;
        width: 18rem !important;
      }
      &-diaryText {
        font-family: $font-black;
        font-size: 12px;
        font-weight: 600;
        @include gridle_state(mobile) {
          font-size: 14px;
        }
        @include gridle_state(mobile-xs) {
          font-size: 13px;
        }
      }
      &-container {
        &-title {
          font-size: 27px;
          font-weight: 400;
          line-height: 30px;
          font-family: "montserrat", sans-serif;

          @include gridle_state(mobile) {
            font-size: 1.7rem;
            line-height: 2rem;
            margin: 0 auto;
            max-width: 30rem;
            padding-top: 1rem;
            text-align: center;
          }
          @include gridle_state(mobile-xs) {
            font-size: 2rem;
            line-height: 3rem;
            max-width: 25rem;
          }
        }
        &-datetitle {
          font-family: "montserrat", sans-serif;
          font-size: 29px;
          font-weight: 600;
          line-height: 42px;
          padding-bottom: 0rem;

          @include gridle_state(mobile) {
            font-size: 1.7rem;
            line-height: 3rem;
            margin: 0 auto;
            max-width: 30rem;
            text-align: center;
          }
          @include gridle_state(mobile-xs) {
            font-size: 2rem;
            line-height: 3rem;
            max-width: 25rem;
          }
        }
      }
    }
    &__box {
      background-color: color-palette(neutral-light);
      box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.2);
      margin: 0;
      padding: 4rem 4.1rem;
      padding-top: 1rem;
      @include gridle_state(mobile) {
        padding: 0rem 2rem;
        margin: 0 -1rem;
      }
      &-body {
        font-family: $font-black;
        font-size: 20px;
        font-weight: 600;
        line-height: 22px;
        padding-top: 3rem;
        padding-bottom: 1rem;
        @include gridle_state(mobile) {
          color: #000000;
          font-size: 17px;
          padding-top: 3rem;
          padding-bottom: 1rem;
        }
        &__download-button {
          width: 70%;
          margin-top: 2rem;
          margin-bottom: 1rem;
          @include gridle_state(mobile) {
            width: 100%;
          }
        }
        &--documents {
          &__document {
            margin-bottom: 0.9rem;
            font-weight: bolder;
          }
          font-size: 14px;
          font-weight: 300;
          padding-bottom: 1rem;
          @include gridle_state(mobile) {
            line-height: 2.2rem;
            margin-bottom: 1rem;
            padding-bottom: 0rem;
          }
        }
        &--documentsOrder {
          font-size: 14px;
          font-weight: 300;
          line-height: 160%;
          padding-bottom: 0.3rem;
          @include gridle_state(mobile) {
            line-height: 2.2rem;
            margin-bottom: 1rem;
            padding-bottom: 0rem;
          }
        }
        &--result {
          &__icon {
            content: url(/assets/images/warning.svg);
            width: 2rem;
            height: 2rem;
          }
          display: flex;
          align-items: center;
          color: #074624;
          font-size: 14px;
          font-style: italic;
          font-weight: 600;
          line-height: 23px;
          padding-top: 0.5rem;
          padding-bottom: 1rem;
          @include gridle_state(mobile) {
            font-size: 14px;
            line-height: 18px;
          }
        }
        &--primary {
          text-transform: uppercase;
          color: #fc0081;
        }
        &--secondary {
          color: #074624;
        }
      }
      &-mapSide {
        &.grid-6 {
          padding-left: 10rem;
          padding-right: 0;
          @include gridle_state(mobile) {
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
        &--address {
          text-transform: uppercase;
          padding: 0;
          &-details {
            color: #666666;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            padding: 0;
            margin-bottom: 2rem;
            @include gridle_state(mobile) {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
        & cand-map [id*="map_"] {
          height: 264px;
        }
      }
    }
    &__footer {
      &--cancelRdv {
        cursor: pointer;
        color: #fc0081;
        margin-left: 1rem;
        background-color: #fee5f2;
        border-color: #fee5f2;
        &:hover,
        &:focus {
          color: #fc0081;
          background-color: #ffd5eb;
          border: 2px solid #ffd5eb;
        }
        @include gridle_state(mobile) {
          margin: 0;
          margin-top: 1rem;
          padding: 1rem 0;
        }
      }

      &--buttons {
        @include gridle_state(mobile) {
          display: flex;
          flex-direction: column-reverse;
          padding: 2rem 1rem;
        }
        & &-style {
          display: flex;
          padding-right: 3rem;
          @include gridle_state(mobile) {
            padding-right: 0;
            flex-direction: column;
            margin-top: 1rem;
            .button.button--grey {
              border-width: 1px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

@include appointment-confirmation(".appointment-confirmation");
@include appointment-confirmation(".appointment-confirmation-page", "page");
