// Declare skins here

%at-simulation-skin-default {
  position: relative;
  margin-top: 7.5rem;
  
  .simulation__panel--right {
    margin-top: 12.6rem;
  }
}

@mixin simulation($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation-skin-#{$skin} !optional;

    &__step {
      padding: 0;
    }

    &__title {
      @include gridle_state(mobile) {
        margin-bottom: 3rem;
      }
    }

    &__actions {
      position: fixed;
      top: 22rem;
      right: 0;

      &Item {
        display: block;
        cursor: pointer;
        padding: 1.5rem;
        color: color-palette(tone-primary);
        background-color: color-palette(neutral-grey-dark);

        & + & {
          margin-top: 1rem;
        }
      }
    }
    &__form{
      position: relative;
    }
  }
}

// Include component here
@include simulation('.simulation');
