// Declare skins here

%at-simulation-table__cellTitle-skin-default {
  @include gridle_state(mobile) {
    display: block;
  }
}

@mixin simulation-table__cellTitle($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation-table__cellTitle-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation-table__cellTitle('.simulation-table__cellTitle');
