// Declare skins here

%at-contactformelement-skin-default {
  // Default skin here
  margin-top: 6rem;
}

@mixin contactformelement($selector, $skin: default) {
  #{$selector} {
    @extend %at-contactformelement-skin-#{$skin} !optional;
  }
}

// Include component here
@include contactformelement('.contact-form__element');
