%at-modal-content-skin-default {
  color: color-palette(tone-primary-dark);
  font-size: $font-size-normal;
  margin: 2rem 0;

  @include gridle_state(mobile) {
    font-size: 1.5rem;
  }
}

@mixin modal-content($selector, $skin: default) {
  #{$selector} {
    @extend %at-modal-content-skin-#{$skin} !optional;
  }
}

.errorCodeMessage {
  font-size: 1.2rem;
  padding-top: 0.8rem;
}

// Include component here
@include modal-content('.cand-modal__content');
