// Declare skins here

@keyframes openList {
  0% { transform: translate3d(0, -1.5rem, 0); opacity: 0 }
  100% { transform: none; opacity: 1; }
}

%at-custom-select-skin-default {
  position: relative;
  display: inline-block;
  line-height: 1;
  padding-bottom: 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 1;
    //background-color: white;
    opacity: 0; // Hack to trigger IE "hasLayout" and prevent pointer-events
  }

  .select {
    pointer-events: none;

    @include gridle_state(tablet) {
      pointer-events: auto;
    }
  }

  .select-box__track {
    transition: transform .3s ease-in-out;
  }

  &.js-opened {
    .custom-select__list {
      display: block;
      animation: openList .3s ease-out;

      @include gridle_state(tablet) {
        display: none;
      }
    }

    .select-box__track {
      transform: rotateZ(-180deg);
    }
  }

  @include gridle_state(tablet) {
    &:before {
      display: none;
    }
  }
}

@mixin custom-select($selector, $skin: default) {
  #{$selector} {
    @extend %at-custom-select-skin-#{$skin} !optional;

    &__list {
      background-color: color-palette(neutral-grey);
      position: absolute;
      top: 100%;
      left: 0; right: 0;
      margin: 0; padding: 0;
      list-style-type: none;
      display: none;
      z-index: 2;
      text-align: left;
      overflow-x: hidden;
      overflow-y: scroll;
      max-height: 23rem;
      box-shadow: 0 0.3rem 0.5rem 0 rgba(0,0,0,.3);
    }

    &__item {
      padding: .8rem;
      cursor: pointer;
      line-height: 1.5;
      color: color-palette(tone-secondary-dark);

      &--disabled {
        display: none;
      }

      &:hover, &--selected {
        color: color-palette(tone-secondary);
        background-color: rgba(color-palette(neutral), 0.9);
      }
    }
  }
}

// Include component here
@include custom-select('.custom-select');
