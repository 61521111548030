%at-box-header-skin-default {
  // Default skin here

  color: inherit;
  font-size: 2.1rem;
  margin-bottom: 3rem;
  line-height: 1.3;
}

@mixin box-header($selector, $skin: default) {
  #{$selector} {
    @extend %at-box-header-skin-#{$skin} !optional;
  }
}

// Include component here
@include box-header('.box__header');
