// Declare skins here

%at-header-burger-skin-default {
  color: color-palette(tone-primary);
  font-size: 2.5rem;
  float: left;
  line-height: 6rem;
  border:0;
  outline: 0;
  background: color-palette(neutral);
  padding: 0 .5rem;
  @include icon(burger);

  @include gridle_state(header-mobile mobile){
    display: block;

  }
}

@mixin header-burger($selector, $skin: default) {
  #{$selector} {
    @extend %at-header-burger-skin-#{$skin} !optional;
  }
}

// Include component here
@include header-burger('.header-burger');
