// Declare skins here
%at-panel__content-skin-default {
  padding : 1.5rem;
  width:100%;
  background-color : color-palette(neutral);

}


@mixin panel-content($selector, $skin: default) {
  #{$selector} {
    @extend %at-panel__content-skin-#{$skin} !optional;

  }
}

// Include component here
@include panel-content('.panel__content');
