// Declare skins here

%at-footer-button-skin-default {
  // Default skin here
  background: none;
  outline: none;
  border: solid 0.1rem color-palette(neutral);
  padding: 0.7rem 0.5rem 0.1rem 0.5rem;
  font-size: 2.5rem;
  margin: 0 1.3rem;
  color: rgba(color-palette(neutral), 1);
  text-decoration: none;

  &:hover {
    color: rgba(color-palette(neutral), 0.6);
    border: solid 0.1rem rgba(color-palette(neutral), 0.6);
  }
}

@mixin footer-button($selector, $skin: default) {
  #{$selector} {
    @extend %at-footer-button-skin-#{$skin} !optional;
  }
}

// Include component here
@include footer-button('.footer__button');

