%at-notification-block-skin-default {
  display: flex;
  width: 100%;
  min-height: 7rem;
  margin: 2rem 0;
}

%at-notification-block-skin-green {
  border: .2rem solid color-palette(tone-secondary);
  .notification-block--icon {
    background-color: color-palette(tone-secondary);
  }
}

@mixin notification-block($selector, $skin: default) {
  #{$selector} {
    @extend %at-notification-block-skin-#{$skin} !optional;

    &--text {
      align-items: center;
      display: flex;
      justify-content: left;
      flex: 1;
      padding: 2rem;
      color: color-palette(neutral-grey-darker);
      background-color: color-palette(neutral);
    }

    &--icon {
      width: 5rem;
      color: color-palette(neutral);
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 3rem;
    }

  }
}

// Include component here
@include notification-block('.notification-block');
@include notification-block('.notification-block--green', 'green');

