@import "../default/2_atoms/button";

%at-button-skin-default {
  padding: 1.1rem 2.2rem;
  border: none;
  border-radius: 0.2rem;
  box-shadow: none;
  font-size: $font-size-medium;
  text-transform: uppercase;

  @include gridle_state(tablet) {
    padding: 1rem 2.2rem;
    min-width: 20rem;
  }

  @include gridle_state(mobile) {
    padding: 0.7rem 0 0.8rem 0;
    min-width: 13.2rem;
    font-size: 1.3rem;
  }
}

%at-button-skin-primary {
  border: 2px solid color-palette(tone-primary);

  &:hover,
  &:focus {
    background-color: color-palette(tone-primary-dark);
    border: 2px solid color-palette(tone-primary-dark);
  }
}

%at-button-skin-save {
  color: color-palette(tone-primary);
  background: none;
  border: 2px solid color-palette(tone-primary);
  border-radius: 2px;

  &:hover {
    color: color-palette(neutral);
    background: color-palette(tone-primary);
    border: 2px solid color-palette(tone-primary);
  }
}

%at-button-skin-previous {
  color: color-palette(neutral-grey-black);
  border: 2px solid color-palette(neutral-grey-black);
  border-radius: 2px;

  &:hover,
  &:focus {
    color: color-palette(neutral);
    background: color-palette(neutral-grey-black);
    border: 2px solid color-palette(neutral-grey-black);
  }
}

%at-button-skin-download {
  border: 2px solid color-palette(tone-primary);
  background-color: color-palette(neutral);
  color: color-palette(tone-primary);
  width: 29rem;
  text-decoration: none;

  .button-text {
    margin-left: 0.5rem;
  }
  @include gridle_state(mobile) {
    width: 100%;
    max-width: 29rem;
    min-width: 24rem;
  }

  &:hover,
  &:focus {
    background-color: color-palette(tone-primary);
    border: 2px solid color-palette(tone-primary);
    color: color-palette(neutral);
  }
}

%at-button-skin-contact {
  width: 34rem;
  border: 2px solid color-palette(tone-primary);
  background-color: transparent;
  padding: 0.8rem 2rem;
  color: color-palette(neutral-grey-black);
  font: 1.9rem $font-medium;
  outline: none;
  display: inline-block;
  cursor: default;
  border-radius: 2.5rem;
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: 0;
  position: relative;
  text-align: center;

  vertical-align: middle;
  text-decoration: none;

  @include gridle_state(mobile) {
    width: 100%;
    max-width: 30rem;
    font-size: 1.5rem;
  }
}

%at-button-skin-noBackground {
  background: none;
}
.button {
  &--validate {
    color: #fc0081;
    background-color: #fee5f2;
    border-color: #fee5f2;
    padding: 0.6rem 2rem;
    border-radius: 2px;
  }
  &--deny {
    color: #666666;
    background-color: #ececec;
    border-radius: 2px;
    padding: 0.6rem 2rem;
  }
}

@include button(".button--previous", "previous");
@include button(".button--save", "save");
@include button(".button--download", "download");
@include button(".button--contact", "contact");
@include button(".button--noBackground", "noBackground");
