%at-iconblock-skin-default{
	font-size : 4.8rem;
	background-image : url('/assets/images/info-rectangle.svg');
	color : color-palette(neutral);

  .iconblock--centered & {
    margin-right: 2rem;
  }
}

%at-iconblock-skin-red{
  filter: hue-rotate(271deg) saturate(6);
}
%at-iconblock-skin-waiting{
  background : url('/assets/images/waiting.svg') center no-repeat;
  font-size : 4.8rem;
  color : color-palette(neutral);
}
%at-iconblock-skin-valid{
  background-image : url('/assets/images/valid.svg');
}

%at-iconblock-skin-unknown{
  background-image : url('/assets/images/unknown.svg');
}

%at-iconblock-skin-refused{
  background-image : url('/assets/images/refused.svg');
}

%at-iconblock-skin-sms {
  background-image : url('/assets/images/icon-sms.svg');
}

@mixin iconblock($selector, $skin: default) {
  #{$selector} {
    @extend %at-iconblock-skin-#{$skin} !optional;

      background-position :center;
      background-repeat: no-repeat;
      width: 4.8rem;
      height: 8.4rem;
      vertical-align : middle;
      text-align : center;
      margin : 0;

    @if ($skin == default) {
      &--centered {
        display: flex;
        align-items: center;
      }
    }
  }
}

@include iconblock('.iconblock');
@include iconblock('.iconblock--red','red');
@include iconblock('.iconblock--waiting','waiting');
@include iconblock('.iconblock--valid','valid');
@include iconblock('.iconblock--unknown','unknown');
@include iconblock('.iconblock--refused','refused');
@include iconblock('.iconblock--sms','sms');
