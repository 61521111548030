// Declare skins here

%at-pills-skin-default {
 & .pill{
   margin : 1rem 0;
   background-color : color-palette(neutral);
   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
 }
}

%at-pills-skin-with-content{
  & .pill{
    background-color: transparent;
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.2);
    margin: 0;
    border-bottom: 1px solid #d4d4d4;

  }


}
%at-pills-skin-link{
  & .pill {
    position: relative;
    cursor:pointer;
  }
}

@mixin pills($selector, $skin: default) {
  #{$selector} {
    @extend %at-pills-skin-#{$skin} !optional;

    @if($skin == default) {
      display: block;
      padding: 0;
      margin:0;
      & .pill{
        display: block;
        list-style-type: none;
        min-height: 4.1rem;
        width:100%;
      }

    }


  }
}

// Include component here
@include pills('.pills');
@include pills('.pills--link', 'link');
@include pills('.pills--with-content', 'with-content');