// Declare skins here

%at-tooltiphtml-skin-default {
    display: none;
    position:fixed;
    pointer-events: none;
    text-align: center;
    padding: 1.5rem 2rem;
    font-size: 1.2rem;
    line-height: 1;
    max-width: 50rem;
    min-width: 25rem;
    font-weight: 400;
    text-transform: none;
    color: #333;
    width: 4.56rem;
    min-height: 9.4rem;
    background-color: white;
    border: 0.1rem solid color-palette(neutral-grey-darker);
    border-radius: 0.4rem;
    z-index: 4;
}


@mixin tooltiphtml($selector, $skin: default) {
  #{$selector} {
    @extend %at-tooltiphtml-skin-#{$skin} !optional;
    &Container{
      display: flex;
    }
    &Elt{
      cursor:pointer;
      margin: auto;
    }
    &:before{
      display: block;
      border: 1rem solid;
      z-index: 9999;
      content: '';
      width: 0;
      height: 0;
      position: absolute;
    }
    &:after{
      display: block;
      border: 1.2rem solid;
      content: '';
      width: 0;
      height: 0;
      position: absolute;
    }

    &--down:after{
      left: 50%;
      transform: translateX(-50%);
      top: -2.4rem;
      border-color: transparent transparent color-palette(neutral-grey-darker) transparent;
    }
    &--down:before{
      left: 50%;
      transform: translateX(-50%);
      top: -2rem;
      border-color: transparent transparent white transparent;
    }

    &--up:after{
      left: 50%;
      transform: translateX(-50%);
      bottom: -2.4rem;
      border-color:color-palette(neutral-grey-darker)  transparent  transparent transparent;

    }
    &--up:before{
      left: 50%;
      transform: translateX(-50%);
      bottom: -2rem;
      border-color:white  transparent  transparent transparent;
    }

    &--left:after{
      top: 50%;
      transform: translateY(-50%);
      right: -2.4rem;
      border-color:transparent transparent transparent color-palette(neutral-grey-darker) ;

    }
    &--left:before{
      top: 50%;
      transform: translateY(-50%);
      right: -2rem;
      border-color:transparent  transparent transparent white ;
    }

    &--right:after{
      top: 50%;
      transform: translateY(-50%);
      left: -2.4rem;
      border-color:transparent  color-palette(neutral-grey-darker) transparent transparent ;

    }
    &--right:before{
      top: 50%;
      transform: translateY(-50%);
      left: -2rem;
      border-color:transparent  white  transparent transparent ;
    }

    &--show{
        display: inherit;
    }
  }
}

// Include component here
@include tooltiphtml('.tooltiphtml');
