// Declare skins here

%at-simulation__tarifCell-skin-default {
  text-align: center;
  height: 9.5rem;
  display: inline-block;
}

%at-simulation__tarifCell-skin-separator {
  border-right: solid 0.1rem color-palette(tone-primary);
  @include gridle_state(mobile){
    border: none;
  }
}

@mixin simulation__tarifCell($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__tarifCell-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__tarifCell('.simulation__tarifCell');
@include simulation__tarifCell('.simulation__tarifCell--separator', 'separator');
