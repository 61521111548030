// Declare skins here

%at-simulation-offreSendmail-skin-default {
  margin-top: 3rem;
  border: .1rem solid color-palette(tone-primary-light);
  padding: 4rem 3rem;

  @include gridle_state(mobile){
    margin-top: 0;
  }
}

@mixin simulation-offreSendmail($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation-offreSendmail-skin-#{$skin} !optional;

    &Title {
      margin-bottom: 3rem;
    }

    &Text {
      line-height: 2.9rem;
      margin-bottom: 3rem;
    }
  }
}

// Include component here
@include simulation-offreSendmail('.simulation__offreSendmail');
