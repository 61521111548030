%at-recovery-text-skin-default {
  text-align: center;
}

@mixin recovery-text($selector, $skin: default) {
  #{$selector} {
    @extend %at-recovery-text-skin-#{$skin} !optional;
  }
}

// Include component here
@include recovery-text('.recovery__text');
