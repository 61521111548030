%at-modal-title-skin-default {
  text-align: center;
  color: color-palette(tone-primary);
  font-size: 2.8rem;

  @include gridle_state(mobile) {
    max-width: 75%;
    margin: auto;
  }
}

@mixin modal-title($selector, $skin: default) {
  #{$selector} {
    @extend %at-modal-title-skin-#{$skin} !optional;
  }
}

// Include component here
@include modal-title('.cand-modal__title');
