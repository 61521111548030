@import '../default/4_organisms/footer';

%at-footer-skin-default {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5rem;
  background: color-palette(neutral-grey-black-dark);
  opacity: .8;
  padding: 1rem;
}
