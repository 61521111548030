%at-contractual-selection-skin-default {

}

%at-contractual-selection-skin-text {
margin-bottom: 2rem;
}


@mixin contractual-selection($selector, $skin: default) {
  #{$selector} {
    @extend %at-contractual-selection-skin-#{$skin} !optional;

    &--figuresTitle{
      color: color-palette(tone-secondary);
      font-family: $font-regular;
      display: block;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 700;
      margin-bottom: 1rem;
      @include gridle_state(mobile) {
        text-align: left;
      }
    }

    &--rdvText{
      padding-top: 4rem;
      padding-bottom: 4.8rem;
      text-align: left;
      width: 70%;

      @include gridle_state(tablet) {
        width: 90%;
        padding-top: 0;
        padding-bottom: 2rem;
      }
      @include gridle_state(mobile) {
        width: 100%;
      }
    }

    &--rdvContact{
      width: 70%;
      margin: 0 auto;
      float: none;
      display: inline-block;
      vertical-align: middle;
      @include gridle_state(tablet) {
        width: 90%;
      }
      @include gridle_state(mobile) {
        width: 100%;
      }
    }

    &--rdvButton{
      display: inline-block;
      vertical-align: middle;
      margin-left: 2rem;
      @include gridle_state(mobile) {
        display: block;
        text-align: center;
      }
    }

    &--textInline{
      display: inline-block;
      vertical-align: middle;
    }

    &--rdvButton-text{
      width: 23rem;
      height: 1.7rem;
      color: color-palette(neutral);
      font-size: $font-size-normal;
      font-weight: 300;
      background-color: color-palette(tone-primary);
      margin: 0 auto;
      text-align: center;
      display: block;
      line-height: 1;
    }

    &--rdvButton-notice{
      margin-top: 1rem;
      font-size: 1.1rem;
      text-align: center;
      display: block;
      @include gridle_state(mobile) {
        text-align: left;
        margin-left: -2rem;
      }
    }

    &--rdvOnline{
      padding: 5rem 0;

      @include gridle_state(mobile) {
        padding: 3rem 0;
      }
      .button{
        @include gridle_state(mobile) {
          margin-top: 2rem;
        }
      }
    }

    &--rdvButton-padding{
      padding-top: 2rem;
    }

    .simulation__buttons--light{
      border-top: none;
    }

    .button--download{
      min-width: 15rem;
    }

    .waiting-page__modal-content{
      margin-top: 3rem;
      border: .2rem solid color-palette(tone-warning);
      width: 100%;
    }

    .waiting-page__message{
      padding: 0 1rem;
      vertical-align: middle;
      display: table-cell;

      p{
        display: block;
      }
    }
  }
}

// Include component here
@include contractual-selection('.contractual-selection');
