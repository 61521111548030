// Declare skins here

%at-simulation__panelContent-skin-default {
  background-color: color-palette(neutral-grey);
  padding: 2rem;
  margin-left: -2rem;
}

@mixin simulation__panelContent($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__panelContent-skin-#{$skin} !optional;

    &_title {
      color: color-palette(tone-primary);
      font-size: $font-size-normal;
      font-family: $font-regular;
    }

    &_text {
      margin-bottom: 0;
      color: color-palette(tone-primary-dark);
    }

    &_form {
      display: table;
      border-collapse: collapse;
      margin-top: 1rem;

      fieldset & {
        padding: 0;
      }
    }

    &_input {
      min-width: 0;
      width: 80%;
    }

    &_button {
      min-width: 0;
      padding: 0;
      width: 16%;
      height: 100%;
      margin-left: 4%;
    }

    &_text, &_input, &_button {
      font-size: 1.4rem;
    }
  }
}

// Include component here
@include simulation__panelContent('.simulation__panelContent');
