// Declare skins here

%at-contactsubtitle-skin-default {
  // Default skin here
  font-family: $font-medium;
  font-size: 2.3rem;
  margin-top: 0;
}

@mixin contactsubtitle($selector, $skin: default) {
  #{$selector} {
    @extend %at-contactsubtitle-skin-#{$skin} !optional;
  }
}

// Include component here
@include contactsubtitle('.contact-subtitle');
