// Declare skins here

%at-tab__content-skin-default {
  background-color : color-palette(neutral);
  margin-top: 0;
  padding: 3rem 0;
}

@mixin tab-content($selector, $skin: default) {
  #{$selector} {
    @extend %at-tab__content-skin-#{$skin} !optional;
    

  }
}

// Include component here
@include tab-content('.tab__content');
