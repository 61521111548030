@import '../../default/2_atoms/choice/choiceboxlabel';

%at-choice-box__label-skin-buttonChecked {
  border: 0;
  background-color: color-palette(tone-primary);
}

%at-choice-box__label-skin-button {
  @extend %at-button-skin-default;

  padding: 0;
  border: 0.2rem solid color-palette(neutral-grey-darker);
  border-radius: 2px;
  color: color-palette(neutral-grey-darker);
  font-size: 1.2rem;
  font-weight: 700;

  &:not([disabled]):hover {
    border-color: color-palette(neutral-grey-darker);
    background: color-palette(neutral-grey-darker);
    color: color-palette(neutral);
  }
}

%at-choice-box__label-skin-buttonChecked {
  background-color: color-palette(neutral-grey-darker);
}

%at-choice-box__label-skin-buttonFocus {
  box-shadow: none;
}

%at-choice-box__label-skin-invalid {
  a {
    color: color-palette(tone-error);
  }
}
