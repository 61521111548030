.qss-to-qs{
	.cand-modal__content p.qss-to-qs__iconblock {
		color : white;
	}
	.qss-to-qs__message{
		padding : 2rem;
	}
	.qss-to-qs__modal-content{
		display : table;

		p {
			display: table-cell;
		}
	}

	.qss-to-qs__next-button{
		float : right;
	}

}
