%at-content-header-skin-default {
  // Default skin here

  font-size: $font-size-big;
  color: color-palette(tone-primary);
  margin-bottom: 5rem;
}

@mixin content-header($selector, $skin: default) {
  #{$selector} {
    @extend %at-content-header-skin-#{$skin} !optional;
  }
}

// Include component here
@include content-header('.content-header');
