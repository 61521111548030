%at-box-skin-box {
  padding: 2rem;
}

%at-box-skin-with-button {
  position: relative;
  padding-bottom: 7rem;
}

%at-box-skin-neutral {
  // Default skin here
  background-color: color-palette(neutral);
  color: color-palette(tone-primary-dark);
}

%at-box-skin-secondary {
  background-color: color-palette(tone-secondary);
  color: color-palette(neutral);
}

@mixin box($selector, $skins: neutral) {
  #{$selector} {
    @each $skin in $skins {
      @extend %at-box-skin-#{$skin} !optional;
    }
  }
}

// Include component here
@include box('.box-with-button', ('box', 'with-button', 'neutral'));
@include box('.box-with-button--secondary', ('box', 'with-button', 'secondary'));
