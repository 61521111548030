%at-good-to-know-skin-default {
  position: relative;
  margin-left: 2.4rem;

  @include gridle_state (mobile) {
    margin-top: 2rem;
    margin-left: 0;
  }
}

@mixin good-to-know($selector, $skin: default) {
  #{$selector} {
    @extend %at-good-to-know-skin-#{$skin} !optional;

    &__title {
      position: absolute;
      left: 2.8rem;
      top: -1.8rem;
      display: inline-block;
      padding: .8rem 1.8rem;
      background-color: color-palette(tone-secondary);
      color: color-palette(neutral);
      border-radius: .8rem;
      font-size: $font-size-normal;

      @include gridle_state (mobile) {
        font-size: 1.6rem;
      }
    }

    &__content {
      padding: 2.5rem 1.8rem 1.5rem 1.8rem;
      border: .2rem solid color-palette(tone-secondary);
      border-radius: 1rem;
      font-size: $font-size-small;

      @include gridle_state (mobile) {
        padding: 1.5rem 1.5rem 0 1.5rem;
      }
    }
  }
}

// Include component here
@include good-to-know('.good-to-know');
