// Declare skins here

%at-simulation__headercontent-skin-default {
  color: #000a8b;
  font-size: $font-size-normal;
  text-align: center;
  max-width: 80rem;
  margin: 0 auto;
}

%at-simulation__headercontent-skin-title {
    line-height: 4.3rem;
    font-size: $font-size-big;
}

%at-simulation__headercontent-skin-text {
  font-size: $font-size-normal;
  line-height: 1.5;
}


@mixin simulation__headercontent($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__headercontent-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__headercontent('.simulation__headercontent');
@include simulation__headercontent('.simulation__headercontent--title','title');
@include simulation__headercontent('.simulation__headercontent--text','text');

