// Declare skins here
%at-offer__subTitle-skin-default {
  @include clearfix();
  font-family: $font-regular;
  color: color-palette(tone-primary-dark);
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2.2rem;
  line-height: 2.5rem;
  list-style-type: none;
  padding: 0;

  @include gridle_state(mobile) {
    margin-bottom: 2.5rem;
  }
}

%at-offer__subTitle-skin-strong {
  font-family: $font-medium;
}

%at-offer__subTitle-skin-info {
  font-size: 1.7rem;
  line-height: 2rem;
  padding-bottom: 2rem;
  display: block;
}


@mixin offer__subTitle($selector, $skin: default) {
  #{$selector} {
    @extend %at-offer__subTitle-skin-#{$skin} !optional;
  }
}

// Include component here
@include offer__subTitle('.offer__subTitle');
@include offer__subTitle('.offer__subTitle--strong','strong');
@include offer__subTitle('.offer__subTitle--info','info');
