// Declare skins here

%at-simulation__errors-skin-default {
  text-align: center;
  color: color-palette(tone-error);
  font-size: 1.4rem;
  margin: 0 0 5rem;
}

@mixin simulation__errors($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__errors-skin-#{$skin} !optional;
    ul{
      text-align: left;
      list-style: none;
    }
  }
}

// Include component here
@include simulation__errors('.simulation__errors');
