%at-document-selection-skin-default {

}

%at-document-selection-skin-text {
margin-bottom: 2rem;
}


@mixin document-selection($selector, $skin: default) {
  #{$selector} {
    @extend %at-document-selection-skin-#{$skin} !optional;



    .tab__header{
      width: 49.5%;
      vertical-align: bottom;
      padding: 2rem 1%;
      text-align: center;
      align-items: center;
      display: flex;


      @include gridle_state(mobile) {
        &:not(.tab__header--active){
          width: 11%;
          min-width: 3rem;
        }

        &.tab__header--active{
          width: 88%;
        }
      }

    }

    .tabs li:not(:last-child){
      margin-right: 1%;
    }

    .tab__header__title{
      vertical-align: middle;
      line-height: 1.5;
      width: 100%;
      position: relative;
      @include gridle_state(mobile) {
        line-height: 1.2;
      }
    }
    .tab__header__title--text {
      display: inline;
      margin-left: 1%;
      vertical-align: middle;
      @include gridle_state(mobile) {
        display: none;
      }
    }

    .tab__header__title--digit{
      vertical-align: middle;
      &:after{
        content: '.';
      }
    }

    .tab__header--active{
      .tab__header__title--text {
        @include gridle_state(mobile) {
          display: inline;
        }
      }
    }


    .simulation__group--padding{
      padding: 4rem;
      @include gridle_state(mobile) {
        padding: 1rem;
      }
    }

    .download-block{
      width: 100%;
      padding: 2.5rem 3rem;
      background-color: color-palette(neutral-grey-light);
      color: color-palette(neutral-grey-darker);
      margin-top: 4.4rem;
      @include gridle_state(mobile) {
        padding: 1.5rem 1.5rem;
      }
      a {
        text-decoration: none !important;
      }
    }

    .download-block--text{
      display: table-cell;
      vertical-align: middle;
      padding-left: 7rem;
    }

    .download-block--info{
      background: transparent url("/assets/images/warning.svg") 0 50% no-repeat;
      display: table;
      min-height: 5.5rem;
      margin-bottom: 1rem;
      @include gridle_state(mobile) {
        margin-bottom: 2rem;
      }
    }

    .center{
      margin: 0 auto;
      float: none;
      text-align: center;
    }

    .document-selection--text__centered {
      text-align: center;
    }

    .simulation__buttons--light{
      margin: 0 -5rem;
      margin-top: 4rem;
      @include gridle_state(mobile) {
        padding-right: 3rem;
        padding-left: 3rem;
      }
    }

    .tooltip--paddingleft{
      .icon--info{
        color: color-palette(tone-secondary);
      }
    }

    &--tooltip{
      .document-selection--text{
        padding-left: 1rem;
      }
      margin-bottom: 2rem;
    }

  }
}

// Include component here
@include document-selection('.document-selection');
@include document-selection('.document-selection--text','text');
