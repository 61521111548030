// Declare skins here

%at-header-nav-skin-default {
  text-align: right;
}

@mixin header-nav($selector, $skin: default) {
  #{$selector} {
    @extend %at-header-nav-skin-#{$skin} !optional;

    &List {
      padding: 0;
      list-style-type: none;
      white-space: nowrap;
      margin: 0;
    }

    &Item {
      display: inline-block;
      vertical-align: middle;
      transition: opacity 0.3s ease-out, visibility 0.3s ease-out;

      &--hidden{
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

// Include component here
@include header-nav('.header-nav');
