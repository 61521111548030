%at-statut-da-history-skin-default {
  display: flex;
  justify-content: space-between;
}


@mixin statut-da-history($selector, $skin: default) {
  #{$selector} {
    @extend %at-statut-da-history-skin-#{$skin} !optional;
    
    &Content{
      background: color-palette(neutral-light);
      text-transform: uppercase;
      padding-top: 0 !important;
      @include gridle_state(tablet,mobile) {
        padding:0rem 2rem 2rem 2rem !important; 
      }      
    }
    
    &Current{
      border: .1rem solid color-palette(neutral-grey-light);
      display: flex; 
      justify-content: space-between;
      align-items: center; 
      background: white;
      padding-right:3rem;
      font-size: 1.6rem;
       @include gridle_state(tablet,mobile) {
         display: block;
       }
      &Title{
        margin-left: 2rem;
        display: inline-block;          
        @include gridle_state(tablet,mobile) {
          font-size: 1.4rem;
          display: table-cell;
          vertical-align: middle;
          padding-left: 2rem;
        }
        .statutdahistoryCurrent--waiting &{
          color:color-palette(tone-warning);
        }
        .statutdahistoryCurrent--valid &{
          color:color-palette(tone-valid);
        }
        .statutdahistoryCurrent--error &{
          color:color-palette(tone-error);
        }
      }
      &Icon{
          display: inline-block;  
          @include gridle_state(tablet,mobile) {      
            display: table-cell;
          }     
      }
      &Date{
          color:color-palette(neutral-grey-darker);
          @include gridle_state(tablet,mobile) {      
            font-size: 1.2rem;
          }   
      }
    }

    &Title{
      font-size: 1.6rem;
      padding: 2rem 0rem;
      color: color-palette(neutral-grey-black);
      @include gridle_state(tablet,mobile) {
        font-size: 1.2rem;
      }  
    }
    &List{
      background: white;
      border: .1rem solid color-palette(neutral-grey-light); 
      padding: 0rem 2rem;
      margin: 0;
      > li{
        display: flex; 
        justify-content: space-between;
        list-style: none;  
        padding: 3rem 1rem; 
        font-size: 1.6rem;
        color: color-palette(neutral-grey-darker);
        @include gridle_state(tablet,mobile) {
          font-size: 1.2rem;
          flex-direction: column;
          padding: 2rem 1rem;          
        }
      }
      > li:not(:last-child){
        border-bottom: .1rem solid color-palette(neutral-grey-light); 
      }
    }
  }
}

// Include component here
@include statut-da-history('.statutdahistory');
