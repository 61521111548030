// Declare skins here

%at-simulation__stepperItem_digit-skin-default {
  &:after {
    content: '.';
    display: inline;
  }
}

@mixin simulation__stepperItem_digit($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__stepperItem_digit-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__stepperItem_digit('.simulation__stepperItem_digit');
