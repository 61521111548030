%at-password-meter-item-skin-default {
  // Default skin here

  display: inline-block;
  width: 3rem;
  height: .4rem;
  background-color: color-palette(neutral-grey-dark);

  & + & {
    margin-left: .5rem;
  }
}

%at-password-meter-item-skin-low {
  background-color: color-palette(tone-error);
}

%at-password-meter-item-skin-circle {
  width: .5rem;
  height: .5rem;
  border-radius: 50%;
}

%at-password-meter-item-skin-medium {
  background-color: color-palette(tone-primary);
}

%at-password-meter-item-skin-strong {
  background-color: color-palette(tone-secondary);
}

@mixin password-meter-item($selector, $skin: default) {
  #{$selector} {
    @extend %at-password-meter-item-skin-#{$skin} !optional;
  }
}

// Include component here
@include password-meter-item('.password-meter__item');
@include password-meter-item('.password-meter__item--low', 'low');
@include password-meter-item('.password-meter__item--medium', 'medium');
@include password-meter-item('.password-meter__item--strong', 'strong');
@include password-meter-item('.password-meter__item--circle', 'circle');
