// Declare skins here

%at-glossary-results-itemLink-skin-default {

    font-size: $font-size-normal;
    font-family: $font-medium;
    text-decoration: none;
    color: color-palette(tone-primary-dark);
    padding-bottom: 0.2rem;

    &:hover {
      border-bottom: 0.2rem solid color-palette(tone-secondary-dark);
    }
}

@mixin glossary-results-item ($selector, $skin: default) {
  #{$selector} {
    @extend %at-glossary-results-itemLink-skin-#{$skin} !optional;
  }
}

// Include component here
@include glossary-results-item('.glossary-results__itemLink');
