%at-signature-steps-skin-default {
  width: 100%;
  margin-top: 2rem;
}

%at-signature-steps-skin-item {
  width: 100%;
  display: table;
}

%at-signature-steps-skin-element {
  display: table-cell;
  vertical-align: top;
}

%at-signature-steps-skin-image {
  display: table-cell;
  position: absolute;
  top: 1.1rem;
  left: 1.2rem;
}

%at-signature-steps-skin-elementImage {
  width: 7rem;
  padding-top: 1.5rem;
  @include gridle_state(mobile) {
    padding-top: 1rem;
  }
}



%at-signature-steps-skin-circle {
  width: 5.3rem;
  height: 5.3rem;
  position: relative;
}

%at-signature-steps-skin-text {
  display: block;
  margin-left: 3rem;
  .signature-steps--textContact{
    display: block;
    margin-top: 1rem;
    width: 24rem;
    border: 1px solid color-palette(tone-secondary);
    padding: 1rem;

    @include gridle_state(mobile) {
      max-width: 24rem;
      width: 100%;
      font-size: 1.4rem;
    }
  }
}

%at-signature-steps-skin-content {
  position: relative;
  &:before{
    content: attr(data-number);
    position: absolute;
    color: color-palette(tone-secondary);
    font-size: 2.2rem;
    font-family: $font-regular;
    font-weight: 500;
    line-height: 0.9;
    border-right: 2px solid;
    width: 2rem;

  }
}

@mixin signature-steps($selector, $skin: default) {
  #{$selector} {
    @extend %at-signature-steps-skin-#{$skin} !optional;

    &--link {
      color: color-palette(tone-secondary);
      text-decoration: underline;
      cursor: pointer;
    }
    @if $skin == 'element' {
      &-underline {
        border-bottom: 1px solid color-palette(neutral-grey-light);
      }


      &-padding {
        padding-top: 4rem;
        padding-bottom: 4rem;
        padding-left: 2rem;
        @include gridle_state(mobile) {
          padding-top: 3rem;
          padding-bottom: 3rem;
        }
      }
    }

  }
}

// Include component here
@include signature-steps('.signature-steps');
@include signature-steps('.signature-steps--item','item');
@include signature-steps('.signature-steps--element','element');
@include signature-steps('.signature-steps--elementImage','elementImage');
@include signature-steps('.signature-steps--image','image');
@include signature-steps('.signature-steps--number','number');
@include signature-steps('.signature-steps--circle','circle');
@include signature-steps('.signature-steps--text','text');
@include signature-steps('.signature-steps--content','content');
