.ddi-faq {
  .cand-modal__overlay {
    max-height: fit-content;
  }
  .cand-modal__title.cand-modal__title--faq {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0;
    font-weight: 700;
    text-transform: initial;
    font-size: 3rem;

    @include gridle_state(mobile) {
      max-width: none;
      text-align: center;
      font-weight: 600;
    }
    span {
      &.cand-modal__small-text {
        color: #4c4c4c;
        font-size: 1.7rem;
        @media only screen and (max-height: 360px ) and (orientation: landscape){
          font-size: 1.4rem;
        }
      }
      text-transform: initial;
    }
  }
  &__questions-container {
    max-height: 44rem;
    overflow: auto;
    padding-right: 2rem;
    padding-top: 1px;
    padding-left: 2px;
    @include gridle_state(mobile) {
      padding: 2px;
      max-height: 35rem;
    }
    @include gridle_state(mobile-xs) {
      max-height: 23rem;
    }
    &__question {
      .pegase-accordion {
        &__header {
          text-transform: initial;
          font-size: 15.5px;
          span {
            max-width: 95%;
            @include gridle_state(mobile) {
              max-width: 90%;
            }
          }
        }
        &__body {
          &.active {
            max-height: 80rem;
          }
        }
      }
      &__answer {
        padding: 1rem;
        font-size: 1.35rem;
      }
    }
  }

  @media only screen and (max-height: 767px ) and (orientation: landscape){

    header{
      height:30vh;
      position: -webkit-sticky;
      position: sticky;
      padding-top: 1.25em;
    }

    .cand-modal__title.cand-modal__title--faq {
      -webkit-align-content: center;
      text-align: center;
      font-size: 2em;
    }

    section {
      height: calc(60vh);
      overflow-y: auto;
      transition: all 0.5s;
    }

    footer {
      height:10vh;
      position: -webkit-sticky;
      position: sticky;
      margin-bottom: 1.25em;
    }
  }

  @media only screen and (max-height: 360px ) and (orientation: landscape){
    .cand-modal__title.cand-modal__title--faq {
      font-size: 2.5rem;
    }
  }

}
