// Declare skins here

%at-list-digit-skin-abstract {
  &:before {
    display: inline-block;
  }
}

%at-list-digit-skin-default {
  counter-increment: digit-counter;

  .list-digit__target, &.list-digit__target {
    position: relative;
    padding-left: 8rem;
  }

  .list-digit__target:before, &.list-digit__target:before {
    @extend %at-list-digit-skin-abstract;
    content: counter(digit-counter);
    color: color-palette(tone-secondary);
    font-family: $font-hw-bold;
    font-size: 4.5rem;
    border-right: .1rem solid color-palette(tone-primary-light);
    width: 5.5rem;
    line-height: 3rem;
    position: absolute;
    top: 0;
    left: 0;
  }
}

%at-list-digit-skin-alpha {
  counter-increment: digit-alpha-counter;

  .list-digit__target, &.list-digit__target {
    position: relative;
    padding-left: 8rem;
  }

  .list-digit__target:before, &.list-digit__target:before {
    @extend %at-list-digit-skin-abstract;
    content: counter(digit-alpha-counter, lower-alpha) '.';
    color: color-palette(tone-secondary);
    font-family: $font-hw-bold;
    font-size: 3.3rem;
    text-align: right;
    width: 8.5rem;
    position: absolute;
    top: 0;
    left: -2rem;
  }
}

@mixin list-digit($selector, $skin: default) {
  #{$selector} {
    @extend %at-list-digit-skin-#{$skin} !optional;
  }
}

// Include component here
@include list-digit('.list-digit');
@include list-digit('.list-digit--alpha', 'alpha');
