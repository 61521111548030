// Declare skins here

%at-fastquote-summary__content-skin-default {
  display: inline-block;
  line-height: 5rem;
  margin: 1rem 0;
}

@mixin fastquote-summary__content($selector, $skin: default) {
  #{$selector} {
    @extend %at-fastquote-summary__content-skin-#{$skin} !optional;

    &Big {
      font-size: 3.65rem;
      display: inline-block;
      vertical-align: bottom;

      @include gridle_state(mobile) {
        font-size: 3rem;
      }

      @include gridle_state(desktop-small) {
        font-size: 3.3rem;
      }

      &--secondary {
        color: color-palette(tone-secondary);
      }


    }

    &Medium {
      font-size: 1.5rem;
      display: inline-block;
      line-height: 1;
      //margin: 0 1rem;
      vertical-align: baseline;
      max-width: 20rem;
      white-space: pre-line;

      @include gridle_state(mobile) {
        font-size: 1.2rem;
        display: block;
        max-width: none;
        margin: 0 0 1rem 0;
      }
    }
  }
}

// Include component here
@include fastquote-summary__content('.fastquote-summary__content');
