%at-testimony-image-container-skin-edito1 {
  // Default skin here
}

@mixin testimony-image-container($selector, $skin: default) {
  #{$selector} {
    @extend %at-testimony-image-container-skin-#{$skin} !optional;

    position: relative;
    padding-top: 33% !important;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}

// Include component here
@include testimony-image-container('.testimony__image-container--edito1', 'edito1');
