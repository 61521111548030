// Declare skins here

%at-simulation__stepperItem-skin-default {
  display: inline-block;
  font-size: 1.7rem;
  line-height: $font-size-normal;
  color: color-palette(neutral-grey-dark);
  cursor: pointer;
  position: relative;
  padding-bottom: 2rem;
  transition: all .2s ease-out;

  &:after {
    content: '';
    display: block;
    height: .4rem;
    width: 100%;
    background-color: color-palette(neutral-grey-dark);
    position: absolute;
    bottom: 0; left: 0;

    transition: all .2s ease-out;
  }

  & + & {
    margin-left: 1.5rem;
  }

  @include gridle_state(mobile) {
    min-width: 10%;
    font-size: $font-size-normal;
    line-height: 1.9rem;
    padding-bottom: 1rem;

    & + & {
      margin-left: 2rem;
    }

    &:after {
      height: .3rem;
    }

    .simulation__stepperItem_text {
      display: none;
    }
  }
}

%at-simulation__stepperItem-skin-completed {
  color: color-palette(tone-primary-dark);

  &:before {
    @include icon('select-track');
  }

  &:after {
    background-color: color-palette(tone-primary-dark);
  }
}

%at-simulation__stepperItem-skin-selected {
  color: color-palette(tone-primary-dark);
  font-size: $font-size-normal;
  line-height: 2rem;

  &:after {
    background-color: color-palette(tone-secondary);
    height: .7rem;
  }

  @include gridle_state(mobile) {
    width: 50%;
    color: color-palette(tone-primary);
    font-size: $font-size-normal;
    line-height: 1.9rem;

    &:after {
      height: .3rem;
    }

    .simulation__stepperItem_text {
      display: inline;
    }
  }
}

%at-simulation__stepperItem-skin-warning {
  color: color-palette(tone-warning);
  &:after {
    background-color: color-palette(tone-warning);
  }

  .warn-field-changed__handler {
    @extend %at-warn-field-changed__handler-active;
  }

  opacity: .6;
  &.simulation__stepperItem--selected {
    opacity: 1;
  }
}

%at-simulation__stepperItem-skin-nocursor {
  cursor: text;
}

@mixin simulation__stepperItem($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__stepperItem-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__stepperItem('.simulation__stepperItem');
@include simulation__stepperItem('.simulation__stepperItem--completed', 'completed');
@include simulation__stepperItem('.simulation__stepperItem--selected', 'selected');
@include simulation__stepperItem('.simulation__stepperItem--warning', 'warning');
@include simulation__stepperItem('.simulation__stepperItem--nocursor', 'nocursor');
