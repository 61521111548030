// Declare skins here

%at-select-skin-default {
  border: 0;
  background: none;
  color: color-palette(neutral-grey);
  border-bottom: 0.1rem solid color-palette(dolphin);
  border-radius: 0;
  font-size: inherit;
  padding-bottom: .5rem;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &.js-valid, &.ng-valid {
    color: color-palette(tone-valid);
  }

  &.js-invalid, &.ng-invalid {
    color: color-palette(tone-error);
    border-color: color-palette(tone-error);

    & ~ .select-box__track {
      color: color-palette(tone-error);
    }
  }

  &.js-invalid.js-dirty,
  &.ng-invalid.ng-dirty {
    background-color: color-palette(tone-error-mistyrose);
  }

  &.js-pristine, &.ng-pristine.ng-invalid-required {
    color: color-palette(input-placeholder);
    border-color: color-palette(neutral-grey-dark);
    background-color: color-palette(neutral-grey);

    & ~.select-box__track {
      color: color-palette(tone-primary-dark);
    }
  }
}


%at-select-skin-secondary {
  border-bottom: .1rem solid color-palette(tone-secondary);
  line-height: 3rem;

  &.js-valid, &.ng-valid {
    color: color-palette(tone-primary);
  }

  &.js-pristine, &.ng-pristine.ng-invalid-required {
    //background-color: color-palette(neutral-grey-darken);
  }
}

%at-select-skin-max-width {
  max-width: 50rem;

  @include gridle_state(tablet) {
    max-width: 70rem;
  }

  @include gridle_state(mobile) {
    max-width: 32rem;
  }
}

%at-select-skin-tiny {
  max-width: 27rem;
}

%at-select-skin-capitalize {
  text-transform: capitalize;
}

%at-select-skin-disabled {
  border-bottom-width: 0;
  color: color-palette(tone-primary-dark);

  &.js-valid, &.ng-valid {
    color: color-palette(tone-primary-dark);
  }
}


%at-select-skin-neutral-grey-darkest {
  &.js-pristine,
  &.ng-pristine.ng-invalid-required,
  &.js-valid,
  &.ng-valid {
    color: color-palette(neutral-grey-darkest);
    border-bottom: solid .2rem color-palette(neutral-grey-darkest);
    background-color: color-palette(neutral);
  }
}

@mixin select($selector, $skin: default) {
  #{$selector} {
    @extend %at-select-skin-#{$skin} !optional;

    @if $skin == default {
      appearance: none;
      border-radius: 0;
    }
  }
}

// Include component here
@include select('.select');
@include select('.select--secondary', 'secondary');
@include select('.select--max-width', 'max-width');
@include select('.select--tiny', 'tiny');
@include select('.select--capitalize', 'capitalize');
@include select('.select--neutral-grey-darkest', 'neutral-grey-darkest');
@include select('.select--disabled', 'disabled');
