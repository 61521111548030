%at-status-header-skin-default{
  display:flex;
  justify-content: space-between;
  margin: 1.7rem 0;
  @include gridle_state(tablet,mobile) {
    flex-direction: column;
  }
  .dashboard-status-header__subtitle{
    color : color-palette(tone-warning);
    @include gridle_state(tablet,mobile) {
      font-size: 1.4rem;
    }
  }
  .dashboard-status-header__title{
    @include gridle_state(tablet,mobile) {
      font-size: 1.5rem;
    }
  }
}

%at-status-header-skin-valid{
  .dashboard-status-header__subtitle{
    color : color-palette(tone-secondary);
  }
}

%at-status-header-skin-refused{
  .dashboard-status-header__subtitle{
    color : color-palette(tone-error);
  }
}

%at-status-header-skin-padding{
  padding-right: 3rem;
  @include gridle_state(tablet,mobile) {
    padding-right: 0rem;
  }
}

%at-status-header-skin-center{
  align-self: center;
}

@mixin status-header($selector, $skin: default) {
  #{$selector} {
    @extend %at-status-header-skin-#{$skin} !optional;
    @if($skin==default){
      &__heading, &__icon{
        display: table-cell;

      }
      &__heading{
        vertical-align: bottom;
        padding-left: 3rem;
        text-transform: uppercase;

        @include gridle_state(mobile){
          padding-left: 2rem;
        }
      }


      &__link{
        text-align: center;
      }
    }
  }
}

// Include component here
@include status-header('.dashboard-status-header');
@include status-header('.dashboard-status-header--valid', 'valid');
@include status-header('.dashboard-status-header--refused', 'refused');
@include status-header('.dashboard-status-header--padding', 'padding');
@include status-header('.dashboard-status-header--center', 'center');
