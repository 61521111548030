%at-modal-footer-skin-default {
  display:  inline-block;
  border-top : 1px solid color-palette(neutral-grey-light);
  padding : 2rem;
  width:100%;
  @include gridle_state(mobile) {
   padding : 0.7rem;
   border-top:none;
  }
}

@mixin modal-footer($selector, $skin: default) {
  #{$selector} {
    @extend %at-modal-footer-skin-#{$skin} !optional;
  }
}

// Include component here
@include modal-footer('.cand-modal__footer');
