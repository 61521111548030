/* Defaults vars
================

Variables with default values

*/

$logoWidth : 26rem;
$logoHeight : 6.3rem;

$bp-mobile-xs-max: 320px;
$bp-mobile-max: 768px;
$bp-tablet-max: 1024px;
$bp-desktop-small: 1200px;
$bp-desktop-medium: 1400px;
$bp-header-mobile-max: 1130px;

$viewport-max-width: 1280px;

// Font size
$font-size-small: 1.4rem;
$font-size-medium: 1.6rem;
$font-size-normal: 1.8rem;
$font-size-big: 2.6rem;
