// Declare skins here

%at-simulation__listItem-skin-default {
}

%at-simulation__listItem-skin-recap {
  text-align: left;

  & > span:last-child {
    font-family: $font-medium;
    text-transform: capitalize;
  }

}

%at-simulation__listItem-skin-palier-recap {
  display: table-row;

  & > div {
    display: table-cell;
    line-height: 7rem;
    border: 0.1rem solid color-palette(tone-primary);
  }

  & > div:first-child {
    background-color: color-palette(tone-primary);
    color: color-palette(neutral);
    text-transform: uppercase;
  }

  &:not(:last-child) > div:first-child {
    border-bottom: .1rem solid color-palette(tone-primary-light);
  }

  & > div > span:last-child {
    display: inline-block;
    font-family: $font-medium;
    min-width: 12rem;
    text-align: left;
  }

}

%at-simulation__listItem-skin-garantie-recap {
  @include icon(checkmark);
  min-height: 6.5rem;
  line-height: 4rem;

  @include clearfix();

  // Quotite
  &:first-child {
    padding-bottom: 5rem;
  }

  & span {
    display: inline-block;
  }

  & > span:first-child {
    padding-left: 0.7rem;
  }

  & > span:last-child {
    float: right;
    font-family: $font-medium;
  }

  // TODO: Reduire la specificité du selecteur à l'aide d'une classe BEM
  &:first-child > span:last-child,
  &:not(:first-child) > span:last-child > span {
    width: 12rem;
    text-align: right;
  }

  // TODO: Reduire la specificité du selecteur à l'aide d'une classe BEM
  &:not(:first-child) > span:last-child > span > span:last-child {
    display: block;
    font-size: 1.4rem;
    line-height: 1.4rem;
  }

}

%at-simulation__listItem-skin-qs-summary {
  margin-bottom: 4rem;

  div {
    margin: 0;
    padding: 0;
  }



  .question-title--small{
    font-size: $font-size-normal;
    line-height: 2rem;
  }

  .sub-questions {
    display: block;
    width: 100%;
    font-size: 1.5rem;
    font-family: $font-medium;
    line-height: 2.6rem;


    @include gridle_state (mobile) {
      display: block;
      width: auto;
      font-size: $font-size-normal;
      line-height: 3rem;
    }

    &__tab-header{
      text-align: left;
    }

    &__add-button {
      padding-left: 8rem;
    }

  }

  .complex-question{
    width: 100%;
    background-color: color-palette(neutral-grey);
    box-shadow: inset 1px 1px 3px rgba(color-palette(neutral-grey-dark), 0.5), inset -1px -1px 3px rgba(color-palette(neutral-grey-dark), 0.5);
    padding : 4rem 3rem 3rem;
    margin : 1.6rem 0;

    .complex-question__intro{
      font-size: $font-size-small;
      margin-bottom: 2rem;
      text-align : left;
      &:after{
        content: ' *';
      }
      @include gridle_state(mobile){
        text-align: right;
      }
    }

    .complex-question__content{
      &:not(:last-child){
        border-bottom: 1px solid rgba(color-palette(neutral-grey-dark), 0.2);
        margin-bottom:2rem;
      }
      &:not(:first-child){
        padding-top:2rem;
      }
    }

    .sub-question-icon {
      font-size: 1.4rem;
      color: color-palette(tone-error);
      padding: 5.2rem 0;
      float : right;

      @include gridle_state(mobile){
        padding: 0;
        float:none;
      }
    }

    @include gridle_state(mobile){
      padding:2rem;
    }

  }

  .sub-question-icon {
    cursor: pointer;
    font-size: 2.0rem;
    color: color-palette(tone-primary-dark);

    &:not(:last-child) {
      margin-right: .5rem;
    }
  }

  %button {
    text-align: center;
    height: 6rem;
    line-height: 6rem;
    display: inline-block;
  }

  .choice-button {
    @extend %button;
    width: 12rem;
    color: color-palette(tone-primary-light);
    border: .1rem solid color-palette(tone-primary-light);
    @include gridle_state (mobile) {
      color: color-palette(tone-primary-dark);
      border-color: color-palette(tone-primary-dark);
    }
  }

  .choice-button--active {
    background: color-palette(tone-secondary);
    color: color-palette(neutral);
    border-color: color-palette(tone-secondary);
  }

  .modify-button {
    @extend %button;
    font-family: $font-medium;
    font-size: 2rem;
    color: color-palette(tone-primary-dark);
    text-decoration: underline;
  }

  @include gridle_state (mobile) {

    .buttons {

      @include gridle_state(mobile) {
        .yesNo-button {
          width: 40%;
          max-width: 15rem;

          &--filter {
            width: 100%;
          }
        }
      }

      & > :not(:last-child) {
        @include gridle_state(mobile) {
          margin-right: 1rem;
        }
      }
    }

    &.not-height-weight-question .buttons {
      margin-top: 1.5rem;
    }

    &.height-weight-question .question-title {
      & > span:last-child {
        display: block;
      }

      .height, .weight, .message-2, .message-4 {
        color: color-palette(tone-secondary);
      }
    }

    .sub-questions {
      padding-top: 0;
    }

    .sub-question {
      border: 0;
      display: block;
      margin-top: 1.5rem;
      line-height: 2.5rem;
      padding-left: 0;

      &__add-button {
        padding-left: 8rem;
      }
    }

    .sub-question:last-child {
      margin-bottom: 1rem;
    }

  }

}

%at-simulation__listItem-skin-document {
  padding-top: 2rem;

  &.document-1 {
    padding-top: 0;
  }

  &.document-2,
  &.document-3,
  &.document-4 {
    padding-top: 0;

    @include gridle_state (mobile) {
      padding-top: 2rem;
    }
  }

  a {
    display: block;
    text-decoration: none;
  }


  &:first-child {
    @include gridle_state (mobile) {
      padding-top: 0;
    }
  }

  &.downloadable {
    cursor: pointer;
  }

  .document-picture {
    display: inline-block;
  }

  .document-title {
    color: color-palette(tone-primary);
    font-size: $font-size-normal;
    line-height: 2.6rem;
  }

  .document-upload-date {
    color: color-palette(tone-primary-dark);
    font-size: 1.4rem;
    line-height: 2rem;
  }

}

@mixin simulation__listItem($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__listItem-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__listItem('.simulation__listItem');
@include simulation__listItem('.simulation__listItem--qs-summary','qs-summary');
@include simulation__listItem('.simulation__listItem--document','document');
