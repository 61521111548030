// Declare skins here

%at-content-block-skin-default {
  // Default skin here
  margin: 0 auto;
  padding: 5rem 1rem;
  //overflow: hidden;

  @include gridle_state(mobile){
    height: auto;
    padding: 1rem;
  }
}

%at-content-block-skin-no-cssvhunit {
  min-height: 80rem;
}

%at-content-block-skin-edito1 {
  // Default skin here
  margin: 0 auto;
  padding-top: 5rem;
  overflow: hidden;

  @include gridle_state(mobile){
    height: auto;
  }
}

%at-content-block-skin-grid {
  @extend .grid-container;
}

%at-content-block-skin-noHead {
  padding-top: 3rem;
  @include gridle_state (header-mobile) {
    margin-top: 3rem;
  }
}

%at-content-block-skin-text {
  color: color-palette(grey-text);
  font-size: 1.6rem;

  .text__underscore{
    text-decoration: underline;
  }

  .text__secondary{
    color: color-palette(tone-secondary);
  }
}


@mixin content-block($selector, $skin: default) {
  #{$selector} {
    @extend %at-content-block-skin-#{$skin} !optional;

    &--text__primary{
      color: color-palette(tone-primary);
      font-size: 1.8rem;
    }

    &--breadcrumb{
      padding: 1rem;
    }

    &--edito__element{
      padding-top: 4rem;
      width: 100%;
      display: block;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      li {
        padding: 1rem 0 1rem 2rem
      }

      li::before {
        content: "• ";
        color: color-palette(tone-secondary); /* or whatever color you prefer */
        margin-right: .5rem;
      }

      .custom-select__list {
        li::before {
          content: "";
          margin: 0;
        }
      }
    }

    &--edito__arian{
      color: color-palette(grey-arian);
      font-size: 1.6rem;
      margin-top: 2rem;

      a{
        text-decoration: none;
        color: color-palette(grey-arian);
        &:hover{
          color: color-palette(tone-primary);
        }
        &:visited{
          color: color-palette(grey-arian);
        }
      }
    }

    @include gridle_state (  mobile ) {
      min-height: 0;
    }
  }
}

// Include component here
@include content-block('.content-block');
@include content-block('.no-cssvhunit .content-block', 'no-cssvhunit');
@include content-block('.content-block--grid', 'grid');
@include content-block('.content-block--noHead', 'noHead');
@include content-block('.content-block--edito1', 'edito1');
@include content-block('.content-block--text', 'text');
