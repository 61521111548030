// Declare skins here

%at-page-title-skin-default {
  color: color-palette(tone-secondary);
  font-family: $font-medium;

  &:after{
    content:"";
    display:block;
    width:8rem;
    border-bottom: solid 4px color-palette(tone-primary);
    margin-top:1rem;
  }
}

%at-page-title-skin-hw {
  font-family: $font-hw-bold;
  color: color-palette(neutral);
  font-size: 4.4rem;
  text-shadow: 0 0.3rem 0.4rem rgba(1, 1, 1, 0.16);
}

%at-page-title-skin-logo {
  color: color-palette(neutral);
  font-size: 3rem;
  text-shadow: 0 0.2rem 0.3rem rgba(1, 1, 1, 0.25);
  margin:0;

  &:after {
    display: none;
  }

  @include gridle_state(tablet) {
    font-size: 2.5rem;
  }

  @include gridle_state(mobile) {
    text-align: center !important;
  }

  .page-title--hw {
    color: color-palette(neutral);
    font-size: 2.8rem;
    @include gridle_state(tablet) {
      font-size: 2.4rem;
    }
  }

  .page-title--time{
    color: color-palette(tone-secondary);
  }
}

%at-page-title-skin-primary {
  color: color-palette(tone-primary);

  .page-title--hw {
    color: color-palette(tone-secondary);
  }
}

%at-page-title-skin-icon {
  cursor: pointer;
  position: relative;
  &:before{
    position: absolute;
    margin-left: 26rem;
    font-size: .5rem;
    top: 1rem;
  }
}

%at-page-title-skin-multiline {
  position: relative;

  &:after {
    position: absolute;
    top: 3rem;
  }
}

%at-page-title-skin-time {
  display: inline-block;
  width: 2.5rem;
  height: 2.4rem;
  background: url('/assets/images/timer.svg') 0 0 no-repeat;

  @include gridle_state(mobile) {
    width: 2.5rem;
    height: 3rem;
    vertical-align: middle;
  }
}

@mixin page-title($selector, $skin: default) {
  #{$selector} {
    @extend %at-page-title-skin-#{$skin} !optional;

    &--second{
      margin-top: 2rem;
      @include gridle_state(mobile) {
        margin-top: 0;
      }
    }

    &--subtext{
      font-size: 1.5rem;
      margin-top: 3rem;
      font-family: $font-regular;
    }

    &--medium{
      font-size: 2.6rem;
    }
  }
}

// Include component here
@include page-title('.page-title');
@include page-title('.page-title--hw', 'hw');
@include page-title('.page-title--logo', 'logo');
@include page-title('.page-title--primary', 'primary');
@include page-title('.page-title--icon', 'icon');
@include page-title('.page-title--multiline', 'multiline');
@include page-title('.page-title--time', 'time');
