// Declare skins here

%at-header-navLink-skin-default {
  text-decoration: none;
  padding: 1rem 0;
  margin: 0 1rem;
  text-transform: uppercase;
  color: color-palette(tone-primary);
  font-size: 1.4rem;
  font-family: $font-medium;
  cursor: pointer;

  .button {
    font-size: inherit;
  }
}

%at-header-navLink-skin-noButton {
  &:hover {
    border-bottom: .3rem solid color-palette(tone-primary);
    @include gridle_state(header-mobile mobile){
      border: none;
      padding: 0;
      margin: 0;
    }
  }
}

%at-header-navLink-skin-Device {
 @include gridle_state(header-mobile,mobile){
    min-width: 22rem;
    text-align: center;
    border: 1px solid color-palette(tone-secondary);
    padding: 1.5rem 2.2rem;
    background-color: color-palette(tone-secondary);
    color: color-palette(neutral);
    transition: opacity .3s ease-out, background-color .1s ease-out, color .1s ease-out;
    cursor: pointer;
    box-shadow: 0 0.1rem 0.2rem rgba(1, 1, 1, 0.42);
    border-radius: 2.5rem;
    margin:auto !important;
    &:hover, &:focus {
      color: color-palette(neutral);
      border-color: color-palette(tone-secondary);
      background-color: lighten(color-palette(tone-secondary), 2%);
    }
  }
}

@mixin header-navLink($selector, $skin: default) {
  #{$selector} {
    @extend %at-header-navLink-skin-#{$skin} !optional;
  }
}

// Include component here
@include header-navLink('.header-navLink');
@include header-navLink('.header-navLink--noButton', 'noButton');
@include header-navLink('.header-navLink--Device', 'Device');
