@import '../../default/2_atoms/simulation/simulationparagraph';

%at-simulation__paragraph-skin-default {
  color: color-palette(neutral-grey-black);
  text-align: left;
}

%at-simulation__paragraph-skin-question-preambule {
  padding-right: 6.6rem;
  padding-left : 6.6rem;
  color: color-palette(tone-secondary);

  @include gridle_state(mobile) {
    padding: 0 2rem 2rem;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 700;
  }
}
