// Declare skins here

%at-info-block__large-skin-default {
  text-align: center;
  box-shadow: 0 0 0.3rem rgba(1, 1, 1, 0.25);
  padding: 1.7rem;
  height: 53.3rem;
  @include gridle_state(mobile) {
    box-shadow: none;
    padding : 0;
    height: initial;
  }
}

@mixin info-block__large($selector, $skin: default) {
  #{$selector} {
    @extend %at-info-block__large-skin-#{$skin} !optional;

    @if($skin == default){
      .info-block__visual{
        width: 100%;
      }

      .info-block__visual, .info-block__title, .info-block__text{
        margin: 0 0 3.5rem 0;
        display: block;
      }

      .info-block__title, .info-block__text {
        padding: 0 5rem;
        @include gridle_state(mobile) {
          padding: 0;
        }
      }

      .info-block__text{
        font-size: 1.6rem;
      }
    }
  }
}

// Include component here
@include info-block__large('.info-block__large');

