// Declare skins here

%at-proposition__recap-skin-default {
  @include gridle_state(mobile) {
    padding: 0rem;
    margin-bottom: 2rem;
  }
}

@mixin proposition__recap($selector, $skin: default) {
  #{$selector} {
    @extend %at-proposition__recap-skin-#{$skin} !optional;

    &Title {
      color: color-palette(tone-secondary);
      font-size: 1.8rem;
      font-family: $font-medium;
      text-align: center;
      margin: 2rem 0rem;     
    }
    &MonthlyCost{
      display: flex;
      flex-direction: column;
      align-items: center;
      &Title{
        color: color-palette(tone-primary);
        font-size: 1.8rem;
        text-align: center;
      }
      &Price{
        color: color-palette(tone-secondary);
        font-family: $font-hw-bold;
        font-size: 4.1rem;
        &--uyesu{
          position: relative;
          text-align: center;
          padding: 0rem 2rem;
          &:after{
            content: url(/assets/images/uyesu.svg);
            display: block;
            position: absolute;
            transform:scaleX(-1) translate(-50%, -50%);
            top:50%;
            right: -5rem;
          }
          &:before{
            content: url(/assets/images/uyesu.svg);
            display: block;
            position: absolute;
            transform:translate(-50%, -50%);
            top:50%;          
            left: -5rem;
          }
        }
      }
    }
    &Table{
      border: .1rem solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.15);
      display: flex;
      text-align: center;
      width: 100%;
      padding: 1rem 0rem;
      margin: 2rem 0rem;
      justify-content: space-between;
      & > span{
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &Title {
        color: color-palette(tone-primary);        
      }
      &Border{
        width: 0.1rem;
        background:  rgba(0, 0, 0, 0.2);
        @include gridle_state(tablet,mobile) {
          display: none;
        }        
      }
      @include gridle_state(tablet) {
        flex-wrap: wrap;
        & > span:nth-child(-n+2){
          margin-bottom: 2rem;          
        }
        & > span:nth-child(4n-2),& > span:nth-child(4n-3){
          border-right: .2rem solid rgba(0, 0, 0, 0.15);          
        }
      }
      @include gridle_state(mobile) {
        display: inherit;
        width: 90%;
        padding: 1rem 0 0 0;
        margin: 2rem auto;
        & > span:nth-child(-n+2){
          margin-bottom: 0rem;          
        }
        & > span:nth-child(4n-2),& > span:nth-child(4n-3){
          border-right: none;          
        }
      }
      .icon--info2{
        color: color-palette(neutral-grey-darkest);
      }
    }
    &Button{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding-bottom: 3rem;
      & > button{
        margin-top: 1rem;
      }
      & > button + button{
        margin-left: 2rem;
      }
      @include gridle_state(mobile) {
        justify-content:space-around;        
        & > button + button{
          margin-left: 0rem;
        }
      }      
    }
    &Details{
      border: .15rem solid color-palette(tone-secondary);
      border-radius: 1rem;
      padding: 2rem 3rem;
      display: flex;
      flex-wrap: wrap;
      & > div{
        width: 50%;
        padding: 0rem 1rem;
      }
      & > div:nth-child(3){
        width: 100%;
      }
      & .offer__guaranteesList{
        color: color-palette(tone-primary);        
      }
      @include gridle_state(mobile) {
        padding: 1;
        & > div{
          width: 100%;
          padding: 0rem;
        }
        & > div::first-child{
          order:1;
        }
        & > div:nth-child(2){
          order:3;
        }
        & > div:nth-child(3){
          order:2;
        }
      }      
    }
    &Palier{
      display: none;
      border: .15rem solid rgba(0, 0, 0, 0.3);
      text-align: center;
      color: color-palette(dolphin);
      &--active{
        display: flex;
      }
      & > span{
        padding: 0.7rem;
        margin: auto;
      }
      & > span:nth-child(odd){
        flex:1;
      }
      & > span:nth-child(even){
        flex:2;
      }
      & > span:first-child{
        color: color-palette(tone-primary);              
      }
      & > span+span{
        border-left: .15rem solid rgba(0, 0, 0, 0.3);
      }
      &:not(:last-child){
        border-bottom: none;
      }
      @include gridle_state(mobile) {
        & > span:nth-child(even){
          flex:1;
        }
      }      
    }
  }
}

// Include component here
@include proposition__recap('.proposition__recap');
