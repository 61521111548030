// Declare skins here

%at-glossary-results-list-skin-default {
  padding: 0;
}

@mixin glossary-results-list ($selector, $skin: default) {
  #{$selector} {
    @extend %at-glossary-results-list-skin-#{$skin} !optional;
  }
}

// Include component here
@include glossary-results-list('.glossary-results__list');
