// Declare skins here

%at-simulation__panel-skin-default {
  padding-bottom: 3rem;

  @include gridle_state(mobile) {
    padding-bottom: 0;
  }

  .simulation__panelTitle {
    margin-bottom: 8rem;
    line-height: 4.6rem;
    font-family: $font-medium;
    font-size: 3.8rem;
    &--margin{
      @include gridle_state(desktop-small) {
        margin-right: 4rem;
      }
    }
  }
}

%at-simulation__panel-skin-right {
  .simulation__panelTitle, .simulation__panelContent {
    padding-left: 2rem;
    margin-left: 0;
  }
}

%at-simulation__panel-skin-floatable {
  float: left;
  width: 0;
  transform: translate3d(1rem, 0, 0);
}

%at-simulation__panel-skin-modification {
  margin-bottom: 0;
}

%at-simulation__panel-skin-relative {
  position: relative;
}

@mixin simulation__panel($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__panel-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__panel('.simulation__panel');
@include simulation__panel('.simulation__panel--right', 'right');
@include simulation__panel('.simulation__panel--floatable', 'floatable');
@include simulation__panel('.simulation__panel--modification', 'modification');
@include simulation__panel('.simulation__panel--relative', 'relative');
