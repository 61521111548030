@import '../default/3_molecules/headernav';

.header-navItem {
  .header-navItem__text-mobile {
    position: absolute;
    bottom: .3rem;
    width: 100%;
    line-height: 1.4rem;
    text-align: center;
    left:0;
  }
  &:first-child{
    margin-right: -.5rem;
  }
}
