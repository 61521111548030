// Declare skins here

%at-footer__ca-logo-skin-default {
  display:block;
}

@mixin footer__ca-logo($selector, $skin: default) {
  #{$selector} {
    @extend %at-footer__ca-logo-skin-#{$skin} !optional;
  }
}

// Include component here
@include footer__ca-logo('.footer__ca-logo');
