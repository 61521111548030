// Declare skins here

%at-contacttitle-skin-default {
  // Default skin here
  display: block;
  font-family: $font-medium;
  font-size: 3.8rem;
}

@mixin contacttitle($selector, $skin: default) {
  #{$selector} {
    @extend %at-contacttitle-skin-#{$skin} !optional;
  }
}

// Include component here
@include contacttitle('.contact-title');
