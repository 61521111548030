// Declare skins here

%at-simulation-table-skin-default {
  color: color-palette(tone-primary-dark);
  font-size: $font-size-normal;
  padding: 0;
  width: 100%;
  margin-top: 2.5rem;
}

@mixin simulation-table($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation-table-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation-table('.simulation-table');
