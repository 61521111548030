%at-personal-skin-default {
  // Default skin here

  .button{
    margin-top: 3rem;
  }

  .simulation__group{
    padding-bottom: 5rem;
    &:first-child {
      border-bottom: solid 0.1rem color-palette(tone-primary-light);
    }
  }

  .message{
    font-family: $font-regular;
    font-size: $font-size-normal;
    color: color-palette(tone-primary-dark);

    &--info {
      color: color-palette(tone-secondary);
    }

  }
}

%at-personal-skin-capaddress {
  display: inline-block;
}

@mixin client-personal($selector, $skin: default) {
  #{$selector} {
    @extend %at-personal-skin-#{$skin} !optional;

    @if $skin == default {

      &__password-wrapper{
        display: block;
        overflow: hidden;
      }

    }

    @if $skin == capaddress {
      .capaddress--codePostal{
        max-width: 50rem;
        display: inline-block;
      }
      .capaddress--streetName{
        max-width: 50rem;
        display: inline-block;
      }
      .capaddress--streetNumber{
        max-width: 20rem;
        display: inline-block;
      }
    }



  }
}

// Include component here
@include client-personal('.client-personal');
@include client-personal('.client-personal--capaddress','capaddress');
