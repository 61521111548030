// Declare skins here

%at-faq-question-skin-default {
  // Default skin here
  font-family: $font-regular;
  font-size: $font-size-normal;
  color: color-palette(tone-primary-dark);
  list-style: none;
  margin-bottom: 3.3rem;
  opacity: 0.6;
  display: block;
  position: relative;

  a {
    color: color-palette(tone-primary-dark);
    text-decoration: none;

    & > span {
      padding-bottom: 1rem;

      @include gridle_state(mobile) {
        padding-bottom: 0;
      }
    }
  }
}

%at-faq-question-skin-selected {
  font-family: $font-medium;
  opacity: 1;

  a > span{
    border-bottom:solid 0.1rem color-palette(tone-secondary);
  }
}

@mixin faq-question($selector, $skin: default) {
  #{$selector} {
    @extend %at-faq-question-skin-#{$skin} !optional;
  }
}

// Include component here
@include faq-question('.faq__question');
@include faq-question('.faq__question--selected', 'selected');
