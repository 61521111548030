// Declare skins here

%at-footer__menu-skin-default {
  margin: 0;
  padding: 0 2rem 0 0;
  font-family: $font-regular;
  font-size: $font-size-normal;
  list-style-type: none;
  line-height: 3.3rem;

  @include gridle_state(mobile){
    padding: 0;
    font-size: 1.4rem;
  }
}

%at-footer__menu-skin-subsection {
  margin: 2rem 0 0 0;
}

@mixin footer__menu($selector, $skin: default) {
  #{$selector} {
    @extend %at-footer__menu-skin-#{$skin} !optional;
  }
}

// Include component here
@include footer__menu('.footer__menu');
@include footer__menu('.footer__menu--subsection', 'subsection');
