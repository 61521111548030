// Declare skins here

%at-big-price-skin-default {
  font-size: 4rem;
}

%at-big-price-skin-decimal {
  font-size: 65%;
  margin-right: 1.3rem;
}

%at-big-price-skin-unit,
%at-big-price-skin-unit2 {
  font-size: 70%;
}

%at-big-price-skin-hw {
  font-family: $font-hw-bold;

  .big-price--unit {
    font-size: inherit;
  }
}

@mixin big-price($selector, $skin: default) {
  #{$selector} {
    @extend %at-big-price-skin-#{$skin} !optional;
  }
}

// Include component here
@include big-price('.big-price');
@include big-price('.big-price--decimal', 'decimal');
@include big-price('.big-price--unit', 'unit');
@include big-price('.big-price--unit2', 'unit2');
@include big-price('.big-price--hw', 'hw');
