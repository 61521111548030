// Declare skins here

%at-form-message-skin-default {
  font-size: 2rem;
  font-family: $font-medium;
  padding: 1rem 0;
  display: none;
}

%at-form-message-skin-tiny {
  font-size: 1.4rem;
  margin: 0 0 5rem;
}

%at-form-message-skin-success {
  color: color-palette(tone-secondary);
}

%at-form-message-skin-warning {
  color: color-palette(tone-warning);
}

%at-form-message-skin-error {
  color: color-palette(tone-error);
}

%at-form-message-skin-no-margin {
  margin: 0rem;
}

@keyframes showFormMessage {
  from { opacity: 0; transform: translate3d(0, -2rem, 0); }
  to { opacity: 1; transform: none; }
}

@mixin form-message($selector, $skin: default) {
  #{$selector} {
    @extend %at-form-message-skin-#{$skin} !optional;

    @if $skin == default {
      &.js-show-form-message {
        display: block;
        animation: showFormMessage .3s ease-in-out;
        animation-fill-mode: forwards;
      }
    }
  }
}

// Include component here
@include form-message('.form-message');
@include form-message('.form-message--success', 'success');
@include form-message('.form-message--warning', 'warning');
@include form-message('.form-message--error', 'error');
@include form-message('.form-message--tiny', 'tiny');
@include form-message('.form-message--required', 'error');
@include form-message('.form-message--no-margin', 'no-margin');
