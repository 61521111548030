// Declare skins here

%at-textfield-rich-skin-default {
  position: relative;
  //border-bottom: .1rem solid color-palette(tone-primary);
  padding-bottom: .5rem;
  margin-right: .5rem;
}

%at-textfield-rich-post-skin-default {
  font-size: 2rem;
}

%at-textfield-rich-skin-list {
  & + & {
    margin-left: .5rem;
  }
}

@mixin textfield-rich($selector, $skin: default) {
  #{$selector} {
    @extend %at-textfield-rich-skin-#{$skin} !optional;

    .textfield {
      //border-width: 0;
      padding-right: 2.5rem;
      margin-right: -2rem;
    }

    &__post {
      @extend %at-textfield-rich-post-skin-#{$skin} !optional;
    }
  }
}

// Include component here
@include textfield-rich('.textfield-rich');
@include textfield-rich('.textfield-rich--list', 'list');
