// Declare skins here

%at-tile-wrapper-skin-default {
  // Default skin here
  padding: 3rem;
  display: inline-block;
  float: none !important;
  vertical-align: middle;
}

@mixin tile-wrapper($selector, $skin: default) {
  #{$selector} {
    @extend %at-tile-wrapper-skin-#{$skin} !optional;
  }
}

// Include component here
@include tile-wrapper('.tile-wrapper');
