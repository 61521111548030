/* Image Quark
==============

This is a practical example of a quark component for the <img> tag

*/

img {
  max-width: 100%;
  height: auto;
}