// Declare skins here

%at-projects-item-skin-default {
  text-align: center;
}

@mixin projects-item ($selector, $skin: default) {
  #{$selector} {
    @extend %at-projects-item-skin-#{$skin} !optional;

    @if($skin == default){

      @include gridle_state(mobile) {
        &:first-child {
          padding-bottom: 2rem;
        }
      }

      &__visual{
        min-height: 12rem;
        line-height: 7;
        margin-bottom: 2rem;

        $sizeMap:(tarif:10.5rem, doctor:8.6rem, sports:8.7rem, protection:8.8rem, cadenas:9.6rem);
        @each $key, $value in $sizeMap{
          &--#{$key} {
            width: $value;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      &__title{
        color: color-palette(tone-primary);
        font-size: 1.6rem;
        font-family: $font-bold;

        &--hw{
          color: color-palette(tone-primary);
          font-family: $font-hw-bold;
          font-size: 2.4rem;
        }
      }

      &__description{
        padding: 0 2rem;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }

    @if ($skin == landing) {
      margin-bottom: 0;

      .projects-item__visual {
        margin-bottom: 0;
        min-height: 11rem;

        $sizeMap: (tarif:8.2rem, doctor:6.3rem, protection:9rem);
        @each $key, $value in $sizeMap {
          &--#{$key} {
            width: $value;
          }
        }
      }

      .projects-item__subtext{
        color: color-palette(tone-primary-dark);
      }
    }

    @if ($skin == landing) {
      margin-bottom: 2.5rem;

      .projects-item__visual {
        margin-bottom: 0;
        min-height: 11rem;

        $sizeMap: (tarif:8.2rem, doctor:6.3rem, protection:9rem);
        @each $key, $value in $sizeMap {
          &--#{$key} {
            width: $value;
          }
        }
      }
    }
  }
}

// Include component here
@include projects-item('.projects-item');
@include projects-item('.projects-item--landing', 'landing');
