%at-sub-answer-group-skin-default {

  flex: 1 1 auto;
  min-width:6rem;
  max-width: 50%;
  position: relative;

}
%at-sub-answer-group-skin-small{
  flex-grow: 0;
}
%at-sub-answer-group-skin-wide{
 flex-grow: 2;

}

  @mixin sub-answer-group($selector, $skin: default) {
    #{$selector} {
      @extend %at-sub-answer-group-skin-#{$skin} !optional;
      @include gridle_state(mobile){
        width:100%;
        max-width:100%;
      }

    }
  }

// Include component here
@include sub-answer-group('.sub-answer__group');
@include sub-answer-group('.sub-answer__group--wide', 'wide');
@include sub-answer-group('.sub-answer__group--medium', 'medium');
@include sub-answer-group('.sub-answer__group--small', 'small');