%at-model-status-skin-default {
  font-size: 1.6rem;
  font-family: $font-medium;
  line-height: 2.5;
}

%at-model-status-skin-margin {
  @extend %at-model-status-skin-default;
  margin-bottom: 2rem;
}

%at-model-status-skin-valid {
  color: color-palette(tone-secondary);

  &::before {
    content: " ✓ ";
  }
}

%at-model-status-skin-invalid {
  color: color-palette(tone-error);

  &::before {
    content: " ✗ ";
  }
}

%at-model-status-skin-verylow {
  color: color-palette(tone-primary);
}

%at-model-status-skin-low {
  color: color-palette(tone-primary);
}

%at-model-status-skin-medium {
  color: color-palette(tone-primary);
}

%at-model-status-skin-clear {
  clear: both;
}

%at-model-status-skin-nopadding {
  padding:0 !important;
}

%at-model-status-skin-ml {
  margin-left:1rem;
    @include gridle_state(tablet,mobile) {
     margin-left:0rem;
    }
}

@mixin model-status($selector, $skin: default) {
  #{$selector} {
    @extend %at-model-status-skin-#{$skin} !optional;

    &--inline{
      display: inline-block;
    }

    &--float{
      display: inline-block;
      margin-left: 1rem;
      line-height: 1rem;
    }

    &--middle{
      vertical-align: middle;
    }

    &--margin__float{
      display: inline-block;
      margin-left: 1rem;
      line-height: 1rem;
      @include gridle_state(mobile) {
        float: right;
        margin-top: 1rem;
      }
    }
  }
}

// Include component here
@include model-status('.model-status');
@include model-status('.model-status--margin', 'margin');
@include model-status('.model-status--valid', 'valid');
@include model-status('.model-status--invalid', 'invalid');
@include model-status('.model-status--verylow', 'verylow');
@include model-status('.model-status--low', 'low');
@include model-status('.model-status--medium', 'medium');
@include model-status('.model-status--clear', 'clear');
@include model-status('.model-status--nopadding', 'nopadding');
@include model-status('.model-status--ml', 'ml');
