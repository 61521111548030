%at-signature-skin-default {
  // Default skin here
}

@mixin signature($selector, $skin: default) {
  #{$selector} {
    @extend %at-signature-skin-#{$skin} !optional;

    &__message{
      margin-bottom:4rem;
    }
    &__icon--info {
      margin-right: 1.5rem;
      width: 2.5rem;
      height: 2.5rem;
      background-image: url(/assets/images/info.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    &__message-container {
      display: flex;
      align-items: center;
    }
    &__message--success{
      span.icon--checkmark{
        color: color-palette(tone-secondary);
        margin-right : 1.6rem;
        font-size:2.2rem;
      }
    }
    &__error{
      text-align: center;
      color: inherit;
      width: 50%;

      @include gridle_state(mobile){
        width: auto;
      }
      p{
        margin: inherit;
      }
      &-subtitle{
        font-weight: 700;
      }
      &-icon {
        width: 100%;
        height: 11.4rem;
        background: url('/assets/images/signature-error.svg') no-repeat center;
        margin-bottom: 6.1rem;
      }
    }
    &__iframe {
      height: 100rem;
      -webkit-overflow-scrolling: touch;

      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
    }

    &__iframeLoading {
      text-align: center;
      span {
      font-size: 2rem;
        display: block;

      &:before {
        display: inline-block;
      }
    }
  }
  }
}

// Include component here
@include signature('.signature');