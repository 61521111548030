// Declare skins here

%at-projects-list-skin-default {
  // Default skin here
  margin-top: 5.9rem;

  @include gridle_state(mobile) {
    margin-top: 0;
  }
}

@mixin projects-list($selector, $skin: default) {
  #{$selector} {
    @extend %at-projects-list-skin-#{$skin} !optional;
  }
}

// Include component here
@include projects-list('.projects-list');
