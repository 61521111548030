.pegase-accordion {
  margin-bottom: 1rem;
  &.active {
    outline: 1px solid #84d000;
  }
  &__header {
    @extend %important-text;
    font-family: "Monstserrat", sans-serif;
    border: 1px solid #ececec;
    box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
    text-transform: capitalize;
    display: flex;
    padding: 1rem 1.6rem;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &--icon {
      user-select: none;
      &.active {
        height: 3px;
        width: 20px;
        background-color: #84d000;
      }
    }
  }
  &__body {
    transition: max-height 0.2s ease-out;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
    &.default {
      max-height: 0px;
    }
    &.active {
      height:auto !important;
      border: 1px solid #ececec;
    }
  }
}
