%at-consents-skin-default {
  .consents__introduction {
    padding-left: 2.4rem;
    padding-bottom: .7rem;
    font-size: $font-size-medium;
    color: color-palette(tone-primary);
    text-transform: uppercase;

    @include gridle_state(mobile) {
      padding-left: 0;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }

  .consents__information {
    text-transform: uppercase;
  }

  .consents__optin {
    margin: 0;
  }

  .choice-box__label, .choice-box__container {
    padding: 2.4rem 2rem 2.1rem 2rem;
    border: 1px solid color-palette(tone-secondary);
    color: color-palette(neutral-grey-black);
    text-align: left;

    @include gridle_state(mobile) {
      padding: .9rem 3rem .9rem 2rem;
    }
  
    a {
      color: color-palette(tone-secondary);
    }
  }

  .choice-box__label {
    &:before {
      border-color: color-palette(tone-secondary);

      @include gridle_state(mobile) {
        position: absolute;
        transform: translate3d(0, -50%, 0);
        top: 50%;
      }
    }
  }

  .choice-box__label--sup--pegase {
    padding: 0;
    border: none;
    width: auto;

    &:before {
      border-color: color-palette(tone-secondary);
      position: relative;
      top: .2rem;

      @include gridle_state(mobile) {
        position: absolute;
        transform: translate3d(0, -50%, 0);
        top: 50%;
      }
    }
  }

  .choice-box__label--pegase {
    width: auto;
    position: absolute;
    left: 2rem;
    opacity: 0;
    padding: 0;

    @include gridle_state(mobile) {
      top: 50%;
    }
  }

  .choice-box--inline-left .choice-box__label > span {
    @include gridle_state(mobile) {
      display: block;
      padding-left: 4rem;
      line-height: 1.8rem;
      font-size: 1.2rem;
      text-align: left;
    }
  }

  .consents__action {
    padding: 0;
    border-radius: 2px;
  }

  .consents__label {
    display: block;
    padding: 1.1rem 2.2rem;
    cursor: pointer;

    @include gridle_state(mobile) {
      padding: .7rem 0 .8rem 0;
    }
  }

  .consents__figure:last-child {
    @include gridle_state(mobile) {
      margin-bottom: 2rem;
    }
  }
}

@mixin consents($selector, $skin: default) {
  #{$selector} {
    @extend %at-consents-skin-#{$skin} !optional;

    &__download_error {
      color: color-palette(tone-error);
      font-size: 1.2rem;
      margin-top: 2rem;
    }

    &__warning{
      margin-top: 2rem;
      .icon--info{
        font-size: 3rem;
        color: color-palette(tone-secondary);
        margin-right: 1rem;
      }

      &--iconPadding{
        margin-right: 3rem !important;
      }

    }

    &__warningText{
      display: flex;
      align-items: center;
    }

    &__other--link {
      margin-left: 3rem;
      padding-left: 0.5rem;
    }

  }
}

// Include component here
@include consents('.consents');
