// Declare skins here

%at-offer-content-skin-padded {
  // Default skin here
  padding-right: 6rem;
}

%at-offer-content-skin-edito2 {
  // Default skin here
  @extend %at-offer-content-skin-padded;
  display: table-cell;
}

@mixin offer-content($selector, $skin: default) {
  #{$selector} {
    @extend %at-offer-content-skin-#{$skin} !optional;

    font-family: $font-regular;
    font-size: $font-size-normal;
    color: color-palette(tone-primary-dark);
    padding-top: 3rem;

    @include gridle_state(mobile) {
      padding: 3rem 0 0;
      font-size: 1.5rem;
      margin-bottom: 3rem;
      display: block;
    }
  }
}

// Include component here
@include offer-content('.offer__content');
@include offer-content('.offer__content--padded', 'padded');
@include offer-content('.offer__content--table', 'table');
@include offer-content('.offer__content--edito2', 'edito2');
