@import '../2_atoms/pagetitle';
@import '../5_templates/_appointment';
@import '../0_utilities/defaults';

%little-text {
    color: #666666;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
}

%item-clickable {
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.06);
    }
    margin: 0 -1rem;
    padding: 0.5rem 1rem;
}

@mixin --mobile($selector, $modifier: '') {
    @at-root #{$selector}#{$modifier} {
        flex-direction: column;
        @if $modifier == --tooltip {
            &:hover {
                outline: none;
            }
            box-shadow: none;
            width: 27rem;
            @include gridle_state(mobile) {
                width: unset;
            }
        }
        #{$selector} {
            // mobile or modifier default
            &__noappointmentlabel {
                margin: 0 2.4rem;
            }
            &__right-part {
                width: 100%;
                padding: 0;
                > .grid-12 {
                    padding: 0;
                }
                @if $modifier == --tooltip {
                    margin-bottom: 1rem;
                }
                margin-top: 1rem;
                &__appointment-date {
                    margin-bottom: 0;
                    &:nth-child(n + 2) {
                        // pas le premier
                        border-left: 1px solid #ececec;
                    }

                    &__time {
                        &:nth-child(2n) {
                            display: none;
                        }
                    }
                    &__date {
                        display: none;
                        &--little {
                            font-weight: bold;
                        }
                    }
                }
            }

            &__left-part {
                width: 100%;
                padding: 1rem;
                border-right: 0;
                border-bottom: 1px solid #ececec;
                &__header {
                    margin-bottom: 0;
                    @if $modifier == --tooltip {
                        padding-right: 2rem;
                        @include gridle_state(mobile) {
                            margin-right: 2rem;
                        }
                        &__distance {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.appointment-planner-page {
    overflow: unset; // fix sticky map
    @include gridle_state(mobile) {
        padding-bottom: 10rem;
        overflow: hidden;
    }
}

@mixin appointment-planner($selector) {
    #{$selector} {
        @include appointment-header(#{$selector});
        &__search {
            display: flex;
            justify-content: space-evenly;
            &__instruction {
                @extend %important-text;

                @include gridle_state(mobile) {
                    text-align: center;
                    margin-bottom: 2rem;
                    padding: 2rem;
                }
            }
            &__input-container {
                position: relative;
                &__input {
                    &.textfield {
                        font-size: 12px;
                        line-height: 14px;
                        color: currentColor;
                        height: 4rem;
                    }
                    @include gridle_state(mobile) {
                        margin-bottom: 4rem;
                        padding: 1.7rem 1.5rem;
                    }
                }
                &__suggest {
                    position: absolute;
                    border: 1px solid #ececec;
                    background-color: #ffffff;
                    box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
                    z-index: 1;
                    top: 4rem;
                    width: calc(100% - 2rem - 2px);
                    &__item {
                        color: #4c4c4c;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 18px;
                        padding: 1rem;
                        &--clickable {
                            @extend %item-clickable;
                        }
                        &:nth-child(n + 2) {
                            border-top: 1px solid #ececec;
                        }
                        img {
                            max-width: 20px;
                            margin-right: 0.5rem;
                        }
                        &__title {
                            display: flex;
                            align-items: center;
                        }
                        &__addresses {
                            &__address {
                                @extend %item-clickable;
                                @extend %little-text;
                            }
                        }
                    }
                }
                &__localisation {
                    position: absolute;
                    margin: 1rem;
                    background: #ececec;
                    width: 70%;
                    display: flex;
                    cursor: text;
                }
            }
            &__button {
                height: 4rem;
                padding: 0;
            }
            @include gridle_state(mobile) {
                flex-direction: column;
                align-items: center;
            }
        }

        &__body {
            margin-top: 2rem;
            @include gridle_state(mobile) {
                margin-bottom: 1rem;
            }
            &__address {
                background: #d8edb6;
                color: #074624;
                padding: 1.5rem;
                display: flex;
                &__left {
                    border-right: 1px solid #074624;
                    @include gridle_state(mobile) {
                        text-align: center;
                        padding-bottom: 1rem;
                        border-right: none;
                    }
                }
                &__separator {
                    display: none;
                    @include gridle_state(mobile) {
                        display: block;
                        height: 2px;
                        background-color: #074624;
                        width: 20%;
                        margin: auto;
                    }
                }
                &__right {
                    margin-left: 2rem;
                    display: flex;
                    align-items: center;
                    @include gridle_state(mobile) {
                        text-align: center;
                        margin-left: 0;
                        margin-top: 1rem;
                    }
                }
                @include gridle_state(mobile) {
                    box-shadow: 0px 6px 14px -7px #111;
                    flex-direction: column;
                }
            }
            &__planner-result {
                margin-top: 2rem;

                &__nocenter {
                    color: #4c4c4c;
                    text-transform: initial;
                    padding: 3rem;
                    border: 2px solid #fc0081;
                    text-align: center;
                    &__contact {
                        margin-top: 1rem;
                    }
                }
                &__filter {
                    display: flex;
                    &__label {
                        color: #979797;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 15px;
                        @include gridle_state(mobile) {
                            display: none;
                        }
                    }
                    &__choices {
                        display: contents;
                        @include gridle_state(mobile) {
                            display: flex;
                            justify-content: space-around;
                            align-items: flex-start;
                        }
                        button {
                            &:focus {
                                outline: none;
                            }
                            padding: 0;
                            background: none;
                            border: none;
                            margin: 0 1rem;
                            &.selected {
                                border-bottom: 3px solid #85d001;
                            }
                        }

                        &__online-checkbox {
                            display: flex;
                            justify-content: flex-end;
                        }
                    }
                    &__map-button {
                        visibility: hidden;
                        @include gridle_state(mobile) {
                            visibility: visible;
                        }
                    }
                }
                &__appointments {
                    padding-top: 1rem;
                    max-height: 70vh;
                    @include gridle_state(tablet mobile) {
                        max-height: none;
                    }
                    overflow: auto;
                    &__item {
                        &:nth-child(n + 2) {
                            margin: 2rem 0;
                        }
                        min-height: 14.8rem;
                        box-shadow: 0 2px 4px 0 rgba(70, 119, 162, 0.28);
                        display: flex;
                        flex-direction: row;
                        &:hover,
                        &--selected {
                            outline: 1px solid #84d000;
                        }
                        &__noappointmentlabel {
                            text-align: center;
                            margin-bottom: 1rem;
                            font-size: 12px;
                            line-height: 16px;
                        }

                        &__link {
                            color: #074624;
                            font-size: 12px;
                            line-height: 15px;
                            text-align: center;
                            text-decoration: underline;
                        }
                        &__call-button {
                            min-width: auto;
                            width: 80%;
                            font-size: small;
                            border-width: 1.5px;

                            @include gridle_state(mobile) {
                                margin: 1rem 0;
                            }
                        }
                        &__right-part {
                            width: 50%;
                            padding: 2rem 0.5rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            &__appointment-date {
                                @include gridle(4);
                                margin-bottom: 1rem;
                                text-align: center;
                                @extend %little-text;
                                &__time {
                                    color: #074624;
                                    cursor: pointer;
                                    font-size: 12px;
                                    font-weight: 600;
                                    line-height: 24px;
                                }
                                &__date {
                                    text-transform: capitalize;
                                    font-weight: bold;
                                    &--little {
                                        font-weight: normal;
                                    }
                                }
                            }
                        }
                        &__left-part {
                            width: 50%;
                            border-right: 1px solid #ececec;
                            height: auto;
                            padding: 2rem;
                            &__header {
                                &__title {
                                    @extend %important-text;
                                }
                                &__distance {
                                    @extend %little-text;
                                    padding-left: 1rem;
                                }
                                margin-bottom: 2rem;
                                display: flex;
                                justify-content: space-between;
                            }
                            &__address {
                                @extend %little-text;
                                line-height: 15px;
                            }
                        }
                    }

                    @include --mobile(#{&}__item, --tooltip);
                    @include gridle_state(mobile) {
                        @include --mobile(#{&}__item);
                    }
                }
            }
            &__map {
                position: sticky;
                top: 20%;
                @include gridle_state(mobile) {
                    position: relative;
                    top: unset;
                    margin-top: 3rem;
                }
            }
        }
        &__separator {
            @include gridle_state(mobile) {
                border: 0;
                border-top: 1px solid #ececec;
                display: block;
                height: 1px;
                margin: 1rem -1rem;
                padding: 0;
            }
        }
    }
}

@include appointment-planner('.appointment-planner');
