// Declare skins here

%at-faq-skin-default {
  // Default skin here
  min-height: 35rem;
  padding: 4.2rem 0 4.2rem 0;
}

@mixin faq($selector, $skin: default) {
  #{$selector} {
    @extend %at-faq-skin-#{$skin} !optional;

    ul {
      list-style: none;
    }

    &__list{
      padding: 0;
      margin: 5rem 0 2rem 0;
    }
  }
}

// Include component here
@include faq('.faq');
