@import '../default/3_molecules/subanswer';
%at-sub-answer-skin-default {
  .sub-answer__label {
    label:after {
      color: color-palette(tone-error);
    }
  }
  .sub-answer__remove {
    padding: 3.2rem 0;

    @include gridle_state(mobile){
      padding :0;
      text-align: left;
      margin-top:0;
    }
  }
}