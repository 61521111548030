%at-inline-boxes-skin-default {
  // Default skin here

  list-style: none;
  margin: 0;
  padding-left: 0;
  display: table;
  width: 100%;
}

@mixin inline-boxes($selector, $skin: default) {
  #{$selector} {
    @extend %at-inline-boxes-skin-#{$skin} !optional;

    &__container {
      margin-right: -5.5rem; /* cf item padding */
    }

    &__item {
      display: table-cell;
      border-right: solid color-palette(neutral) 5.5rem;
      width: 33.33%;

      @include gridle_state(mobile) {
        display: block;
        width: 100%;
      }
    }
  }
}

// Include component here
@include inline-boxes('.inline-boxes');
