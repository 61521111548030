@font-face {
  font-family: "CandIcons";
  src: url('/assets/fonts/CandIcons.eot');
  src: url('/assets/fonts/CandIcons.eot?#iefix') format('eot'),
  url('/assets/fonts/CandIcons.woff') format('woff'),
  url('/assets/fonts/CandIcons.ttf') format('truetype'),
  url('/assets/fonts/CandIcons.svg#CandIcons') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Bugfix for Chrome Windows rendering: http://stackoverflow.com/a/14345363
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "CandIcons", sans-serif;
    src: url('/assets/fonts/CandIcons.svg#CandIcons') format('svg');
  }
}

@mixin iconGenerator() {
  font-family: "CandIcons", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include iconGenerator();
}
