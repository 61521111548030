// Declare skins here

%at-textfield-skin-default {
  border: 0;
  border-bottom: 1px solid color-palette(dolphin);
  min-width: 2rem;
  padding: 0 0 .5rem;
  background: none;
  color: color-palette(neutral-grey);
  border-radius: 0;
  font-size: $font-size-normal;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: color-palette(tone-valid);
    // Hack to remove -webkit-autofill yellow background (http://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete)
    -webkit-box-shadow: 0 0 0 1000px white inset;
  }

  &.js-valid, &.ng-valid {
    color: color-palette(tone-valid);

    & ~ .textfield-rich__post {
      color: color-palette(tone-valid);
    }
  }

  &.js-invalid,
  &.ng-invalid,
  &.angucomplete-input--required:not(.angucomplete-input-not-empty) {
    color: color-palette(tone-error);
    border-color: color-palette(tone-error);

    & ~ .textfield-rich__post {
      color: color-palette(tone-error);
    }
  }

  &.js-invalid.js-dirty,
  &.ng-invalid.ng-dirty,
  &.angucomplete-input--required.ng-dirty:not(.angucomplete-input-not-empty) {
    background-color: color-palette(tone-error-mistyrose);
    &::-webkit-input-placeholder {
      color: color-palette(tone-error);
    }

    &:-moz-placeholder { /* Firefox 18- */
      color: color-palette(tone-error);
    }

    &::-moz-placeholder {  /* Firefox 19+ */
      color: color-palette(tone-error);
    }

    &:-ms-input-placeholder {
      color: color-palette(tone-error);
    }
  }

  &.js-pristine, &.ng-pristine.ng-invalid {
    color: color-palette(neutral-grey-dark);
    border-color: color-palette(neutral-grey-dark);
    background-color: color-palette(neutral-grey);

    & ~ .textfield-rich__post {
      color: color-palette(neutral-grey-dark);
    }
  }
}

%at-textfield-skin-readonly {
  border: none;
  color: inherit;
}

%at-textfield-skin-neutral {
  color: color-palette(neutral);
  border-color: color-palette(neutral);

  &.js-valid, &.ng-valid {
    color: color-palette(neutral);

    & ~ .textfield-rich__post {
      color: color-palette(neutral);
    }
  }

  &.js-invalid, &.ng-invalid {
    color: color-palette(tone-error);
    border-color: color-palette(tone-error);

    & ~ .textfield-rich__post {
      color: color-palette(tone-error);
    }
  }
}

%at-textfield-skin-primary {
  color: color-palette(tone-primary);
  border-color: color-palette(tone-primary);

  &.js-valid, &.ng-valid {
    color: color-palette(tone-primary);

    & ~ .textfield-rich__post {
      color: color-palette(tone-primary);
    }
  }

  &.js-invalid, &.ng-invalid {
    color: color-palette(tone-error);
    border-color: color-palette(tone-error);

    & ~ .textfield-rich__post {
      color: color-palette(tone-error);
    }
  }
}

%at-textfield-skin-secondary {
  border-bottom: 1px solid color-palette(tone-secondary);
  line-height: 3rem;

  &:focus {
    outline: none;
  }

  &.js-valid, &.ng-valid {
    color: color-palette(tone-primary);

    & ~ .textfield-rich__post {
      color: color-palette(tone-pirmary);
    }
  }

  &.js-pristine, &.ng-pristine.ng-invalid-required {
    border-color: color-palette(neutral-grey-dark);
    background-color: color-palette(neutral-grey-darken);
  }
}

%at-textfield-skin-tiny {
  max-width: 3.5rem;
  text-align: center;
}

%at-textfield-skin-small {
  max-width: 5rem;
  text-align: center;
}

%at-textfield-skin-rate {
  max-width: 5.8rem;
}

%at-textfield-skin-eight-digits {
  max-width: 9rem;
}

%at-textfield-skin-simulation-step {
  margin-right: 0;
  padding-right: 0;
}

%at-textfield-skin-simulation-step-price {
  max-width: 5.5rem;
}

%at-textfield-skin-simulation-step-month {
  max-width: 2.8rem;
}

%at-textfield-skin-simulation-step-price:disabled, %at-textfield-skin-simulation-step-month:disabled {
  color: color-palette(neutral-grey-dark);
  border-color: color-palette(neutral-grey-dark);
  background-color: color-palette(neutral-grey);

  & ~ .textfield-rich__post {
    color: color-palette(neutral-grey-dark);
  }
}

%at-textfield-skin-medium {
  max-width: 11rem;
}

%at-textfield-skin-full {
  width: 100%;
}

%at-textfield-skin-valid {
  color: color-palette(tone-secondary);
  border-color: color-palette(tone-secondary);
}

%at-textfield-skin-iban {
  width: 36rem;
  text-transform: uppercase;
  text-align: center;

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    opacity: 0.3;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    opacity: 0.3;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    opacity: 0.3;
  }
  &:-moz-placeholder { /* Firefox 18- */
    opacity: 0.3;
  }


  @include gridle_state(mobile) {
    width: 100%;
  }
}

%at-textfield-skin-bank {
  height: 4.3rem;
}

%at-textfield-skin-no-border {
  border-width: 0;
}

%at-textfield-skin-disabled {
  color: color-palette(tone-primary-dark);
  background-color: color-palette(neutral);

  &.js-valid, &.ng-valid {
    color: color-palette(tone-primary-dark);
  }
}

%at-textfield-skin-neutral-grey-dark {
  color: color-palette(neutral-grey-dark);
}

%at-textfield-skin-neutral-grey-darkest {
  &.js-pristine,
  &.ng-pristine.ng-invalid,
  &.js-valid,
  &.ng-valid {
    color: color-palette(neutral-grey-darkest);
    border-bottom: solid .2rem color-palette(neutral-grey-darkest);
    background-color: color-palette(neutral);

    & ~ .textfield-rich__post {
      color: color-palette(neutral-grey-darkest);
    }
  }
}

@mixin textfield($selector, $skin: default) {
  #{$selector} {
    @extend %at-textfield-skin-#{$skin} !optional;

    @if $skin == default {
      // Hide number selector on Chrome and Firefox
      &::-webkit-inner-spin-button {
        display: none;
      }

      &::-webkit-date-and-time-value {
        margin: 0;
      }

      -moz-appearance: textfield;
      -webkit-appearance: textfield;

      &[data-cand-date-picker], &[cand-date-picker] {
        width: 12rem;
        text-align: center;
      }

      &[type=date] {
        min-width: 10rem;
        display: inline-block;
        min-height: 2.6rem;
        vertical-align: middle;
      }

      &--not-required{

        &:-moz-placeholder { /* Firefox 18- */
          color: color-palette(neutral-grey-dark);
        }

        &::-moz-placeholder {  /* Firefox 19+ */
          color: color-palette(neutral-grey-darkest);
        }

        &::-webkit-input-placeholder {
          color: color-palette(neutral-grey-dark);
        }

        &:-ms-input-placeholder {
          color: color-palette(neutral-grey-dark);
        }

      }
    }
  }
}

// Include component here
@include textfield('.textfield');
@include textfield('.textfield[readonly]', 'readonly');
@include textfield('.textfield--neutral', 'neutral');
@include textfield('.textfield--primary', 'primary');
@include textfield('.textfield--secondary', 'secondary');
@include textfield('.textfield--tiny', 'tiny');
@include textfield('.textfield--eight-digits', 'eight-digits');
@include textfield('.textfield--simulation-step', 'simulation-step');
@include textfield('.textfield--simulation-step-price', 'simulation-step-price');
@include textfield('.textfield--simulation-step-month', 'simulation-step-month');
@include textfield('.textfield--medium', 'medium');
@include textfield('.textfield--full', 'full');
@include textfield('.textfield--valid', 'valid');
@include textfield('.textfield--iban', 'iban');
@include textfield('.textfield--bank', 'bank');
@include textfield('.textfield--no-border', 'no-border');
@include textfield('.textfield--disabled', 'disabled');
@include textfield('.textfield--small', 'small');
@include textfield('.textfield--rate', 'rate');
@include textfield('.textfield--neutral-grey-dark', 'neutral-grey-dark');
@include textfield('.textfield--neutral-grey-darkest', 'neutral-grey-darkest');
