$vertical-gutter: 2rem;

%at-contract-files-skin-default {
  // Default skin here
}

%at-contract-files-skin-item-active {
  //.contractFiles--active

  .contractFiles__clickable {
    pointer-events: none;

    .contractFiles__icon {
      transition-delay: 1s;
    }

    &:hover {
      .contractFiles__icon {
        transform: none;
      }
    }
  }

  .contractFiles__label {
    font-family: $font-medium;
  }
}

@mixin contract-files($selector, $skin: default) {
  #{$selector} {
    @extend %at-contract-files-skin-#{$skin} !optional;

    @if $skin == default {

      list-style: none;
      padding-left: 0;
      margin: 0;

      @include gridle_state(mobile) {
        margin-top: -$vertical-gutter;
      }

      &__item {
        display: inline-block;
        width: 25%;
        margin-top: $vertical-gutter;
        vertical-align: top;

        @include gridle_state(mobile) {
          width: 50%;
        }
      }

      &__clickable {
        display: inline-block;
        cursor: pointer;

        &:hover {
          .contractFiles__icon {
            transform: translate3d(0, -.7rem, 0);
          }
        }
      }

      &__label {
        color: color-palette(tone-primary);
        font-size: $font-size-normal;
        line-height: 3rem;
      }

      &__icon {
        transition: transform 400ms ease;
      }
    }
  }
}

// Include component here
@include contract-files('.contractFiles');
@include contract-files('.contractFiles__item--active', 'item-active');
