// Declare skins here

%at-dashboard-skin-default {
  padding-bottom: 2rem;
  min-height: 50rem;

  .slider {
    margin: 5rem 0;

    @include gridle_state(mobile) {
      padding: inherit;
    }
  }

  .dashboard__projectHeader {
    padding: 0 4rem;
    padding-top: 2rem;

    @include gridle_state(mobile) {
      padding: 0;
    }
  }

  .dashboard__projectDetail {
    color: color-palette(tone-grey-black-dark);
    font-family: $font-regular;
    font-size: 2rem;
    line-height: 1.3;
    text-align: center;
    padding: 1rem;
    display: block;
  }

  .dashboard__projectMessage {
    padding-top: 0.5rem;
    text-align: center;
  }

  .dashboard__projectReset {
    display: inline-block !important;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
    clear: both !important;
  }

  .dashboard-testimony {
    margin: 7.5rem 0;
  }

  .dashboard-users {
    padding: 0 4rem;

    @include gridle_state(mobile) {
      padding: 0;
    }

    &__action {
      text-align: center;
      margin: 4rem 0;

      @include gridle_state(mobile) {
        margin-top: 0;
        margin-bottom: 4rem;
      }
    }
  }
}

@mixin dashboard($selector, $skin: default) {
  #{$selector} {
    @extend %at-dashboard-skin-#{$skin} !optional;

    &__projectStatus {
      font-family: $font-medium !important;
    }
    &__projectPrice {
      color: color-palette(tone-secondary);
    }
  }
}

// Include component here
@include dashboard('.dashboard');
