%at-welcome-modal-skin-default {

}

%at-welcome-modal-skin-list {
  display: inline-block;
  width: 100%;
  text-align: center;
}

@mixin welcome-modal($selector, $skin: default) {
  #{$selector} {
    @extend %at-welcome-modal-skin-#{$skin} !optional;

    &--curretnIndex{
      color: color-palette(tone-secondary);
    }

    &__item{
      display: inline-block;
      padding-left: 0 !important;
      padding-right: 0 !important;

    }

    &--item-wrapper{
      padding: 3.7rem 0rem;
      @include gridle_state(mobile) {
        display: table;
        padding: 1rem;
        width: 100%;
      }
    }

    &__item__icon-info{
      vertical-align: middle;
      display: none;
      color: color-palette(tone-secondary);
      text-align: right;
      font-size: 1.2rem;
      @include gridle_state(mobile) {
        display: table-cell;
      }

      .icon--ic_less{
        font-size: 4px;
      }

      .icon--ic_more{
        font-size: 1.5rem;
      }


    }

    &__item--focused{
      background-color: color-palette(grey-black);
      border-radius: 5px;
    }

    &__item--done{
      opacity: 0.4;
    }
    &__item__text-wrapper{
      padding-top: 3rem;
      color: color-palette(neutral-grey-black);
      font-family: $font-regular;
      font-size: 1.4rem;
      @include gridle_state(mobile) {
        display: table-cell;
        padding: 0;
        font-size: 1.2rem;
        vertical-align: middle;
        text-align: left;
      }
    }

    &__button-wrapper{
      padding-top: 3rem;
      text-align: center;
    }

    &__item__image-wrapper{
      min-height: 10.2rem;
      vertical-align: middle;
      display: flex;
      text-align: center;
      position: relative;
      @include gridle_state(mobile) {
        display: table-cell;
        min-height: 0;
        width: 7rem;
        text-align: left;

      }
    }

    &__item__image-wrapper--icon{
      margin: 0 auto;
      text-align: center;
      height: 9rem;
      width: 9rem;
      display: block;
      @include gridle_state(mobile) {
        width: 5rem;
        height: 6rem;
      }
    }

    &__item__image-wrapper--checked{
      position: absolute;
      top: 7rem;
      z-index: 999;
      right: 4rem;
    }
    &__item__image-wrapper--checked-1{
      right: 5rem;
    }

    .download-block{
      width: 100%;
      padding: 2.5rem 3rem;
      background-color: color-palette(neutral-grey-light);
      color: color-palette(neutral-grey-darker);
      margin-top: 4.4rem;
      @include gridle_state(mobile) {
        padding: 1.5rem 1.5rem;
      }
      a {
        text-decoration: none;
      }
    }

    .download-block--text{
      display: block;
      vertical-align: middle;
      padding-left: 7rem;
      @include gridle_state(mobile) {
        padding-left: 6rem;
      }
    }

    .download-block--info{
      background: transparent url("/assets/images/warning.svg") 0 5% no-repeat;
      display: table;
      min-height: 5.5rem;
      margin-bottom: 0;
      @include gridle_state(mobile) {
        background: transparent url("/assets/images/warning.svg") 0 50% no-repeat;
      }

    }
  }
}

// Include component here
@include welcome-modal('.welcome-modal');
@include welcome-modal('.welcome-modal--list','list');
