%at-modification-skin-default {
  // Default skin here
}

@mixin client-modification($selector, $skin: default) {
  #{$selector} {
    @extend %at-modification-skin-#{$skin} !optional;

    &__choiceGroup {
      margin: 1.2rem 0 5rem;

      .warn-field-changed__handler {
        margin-bottom: 2rem;
      }
    }

    .simulation__toggleContent {
      margin-top: 0;
    }
  }
}

// Include component here
@include client-modification('.client-modification');
