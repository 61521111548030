// Declare skins here
%at-panel-skin-default {
  padding : 0;
  margin: 2rem 0;
  background-color:white;
  border : 1px solid color-palette(neutral-grey-light);

}

@mixin panel($selector, $skin: default) {
  #{$selector} {
    @extend %at-panel-skin-#{$skin} !optional;

  }
}

// Include component here
@include panel('.panel');
