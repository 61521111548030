// Declare skins here

%at-proposition-skin-default {
  // Default skin here
}

%at-proposition-skin-rigth-padding {
  padding-right:6rem !important;
  @include gridle_state(tablet,mobile) {
    padding-right:1rem !important;
  }
}

@mixin proposition($selector, $skin: default) {
  #{$selector} {
    @extend %at-proposition-skin-#{$skin} !optional;

    &__pdfViewer {
      height: 45rem;
    }
  }
}

// Include component here
@include proposition('.proposition');
@include proposition('.proposition--rigth-padding','rigth-padding');
