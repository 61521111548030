// Declare skins here

%at-footer__column-skin-default {
  // Default skin here
  display: inline-block;
  color: color-palette(neutral);
  padding-right: 5rem;
  vertical-align: top;
  width: 21%;

  @include gridle_state(mobile){
    font-size: 1.6rem;
  }
};

%at-footer__column-skin-first {
  width: 37%;
}

@mixin footer__column($selector, $skin: default) {
  #{$selector} {
    @extend %at-footer__column-skin-#{$skin} !optional;
    &_link {
      font-family: $font-bold;
      font-size: $font-size-big;
      text-decoration: none;
      color: color-palette(neutral);
      position:relative;
      display: table-cell;
      vertical-align: bottom;
      padding-bottom: 2rem;
      white-space: nowrap;
      height:9.3rem;

      &:hover {
        &::after {
          content: "";
          display: block;
          width: 8rem;
          position: absolute;
          bottom: 1rem;
          border-bottom: solid 0.4rem color-palette(neutral);
        }
      }

      @include gridle_state(mobile){
        height: auto;
        margin: 3rem 0 1rem 0;
        display: block;
        padding: 0;
        &:hover {
          &::after {
            display: none;
          }
        }
      }
    }

    &_subtitle {
      text-decoration: none;
      color: color-palette(neutral);
      display: block;
      margin-top: 3rem;
    }

    .footer__title-wrapper{
      height:9.3rem;
      @include gridle_state(mobile){
        height: auto;
      }
    }
  }
}

// Include component here
@include footer__column('.footer__column');
@include footer__column('.footer__column--first', 'first');
