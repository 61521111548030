.gm-style .gm-style-iw-c {
  background-color: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.52);
  border-radius: 0;
  padding: 0;
  .gm-style-iw-d {
    overflow: visible !important;
  }
  button.gm-ui-hover-effect {
    top: 0px !important;
    right: 0px !important;
  }
}

.pac-container {
  border: 1px solid #ececec;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
  border-top: 0;
  margin-top: 5rem;
  .pac-item {
    color: #666666;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    padding: 1rem;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
    &:nth-child(n + 2) {
      border-top: 1px solid #ececec;
    }
    .pac-icon {
      display: none;
    }
    .pac-item-query {
      display: flex;
      align-items: center;
      color: #4c4c4c;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      white-space: pre;
      .pac-matched:not(:last-child)::after {
        content: "\00a0"; /* this is &nbsp; */
      }
    }
  }
}
