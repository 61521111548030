%at-lists-skin-default {
}

%at-lists-skin-check {
  .lists__item {
    @include icon(checkmark);
    list-style-type: none;

    &:before {
      margin: 0 0 0 -3rem;
      color: color-palette(tone-secondary);
    }
  }
}

@mixin lists($selector, $skin: default) {
  #{$selector} {
    @extend %at-lists-skin-#{$skin} !optional;

    &__item {
      padding-bottom: 2rem;
      font-size: 2.2rem;
      color: color-palette(tone-primary);
    }

    &__title {
      color: color-palette(tone-primary);
      font-family: $font-medium;
    }

    &__content {
      font-size: $font-size-normal;
      color: color-palette(tone-primary-dark);
    }
  }
}

// Include component here
@include lists('.lists');
@include lists('.lists--check', 'check');
