%at-arm-introduction-skin-default {
    @include gridle_state(mobile) {
        box-shadow: inset 0 7px 8px -8px rgba(0, 0, 0, 0.6);
        padding-bottom: 0;
        padding-top: 1rem;
    }

    & .simulation__buttons {
        margin: 0;

        @include gridle_state(mobile) {
            border-top: none;
            display: flex;
            justify-content: center;
        }

        & .button--primary {
            @include gridle_state(mobile) {
                min-width: 100%;
            }
        }
    }
}

%at-arm-introduction-skin-page {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/assets/images/background-3.jpg) no-repeat center center fixed;
    background-size: cover;
    @include gridle_state(mobile) {
        background: none !important;
        padding-bottom: 4.5rem;
    }

    & .adhesion-stepper {
        display: none;
    }
}
@mixin arm-introduction($selector, $skin: default) {
    #{$selector} {
        @extend %at-arm-introduction-skin-#{$skin} !optional;

        &__footer {
            display: flex;
            align-items: center;
            padding: 4rem 0;

            @include gridle_state(mobile) {
                padding: 2rem;
                flex-direction: column-reverse;
                text-align: center;
                align-items: stretch;
            }
            &__back-container {
                display: flex;
                align-items: center;
                flex: 2.5;
                justify-content: space-between;
                @include gridle_state(mobile) {
                    align-items: stretch;
                    flex-direction: column-reverse;
                }

                &__back {
                    margin-left: 3rem;
                    @include gridle_state(mobile) {
                        margin-top: 2rem;
                        margin-left: 0;
                    }
                }

                &__refuse-arm {
                    color: #666666;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 18px;
                    background-color: #ddd;
                    width: 34rem;
                    padding: 0.6rem 2rem;
                    margin-right: 1rem;

                    @include gridle_state(mobile) {
                        margin-right: 0;
                        width: 100%;
                    }
                    &:hover,
                    &:focus {
                        background-color: #ccc;
                        color: currentColor;
                    }
                }
            }
            &__rdv {
                flex: 1;
                margin-right: 3rem;
                @include gridle_state(mobile) {
                    margin-right: 0;
                    margin-bottom: 1rem;
                }
            }
        }

        &__content {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 2rem;
            &__header {
                display: flex;
                flex-direction: column;
            }
            &__body {
                display: flex;
                margin-top: 5rem;

                @include gridle_state(mobile) {
                    flex-direction: column;
                }
                &__right {
                    flex: 1;
                    padding: 1rem;

                    @include gridle_state(mobile) {
                        padding: 0;
                    }
                }
                &__left {
                    flex: 1;
                }
            }
        }

        &__image {
      background: url("/assets/images/arm-introduction.svg") no-repeat;
            background-size: contain;
            height: 80%;
            background-position: center;
            @include gridle_state(mobile) {
                height: 25rem;
            }
        }
        &__logo {
            display: flex;
            justify-content: space-around;
        }
        &__logo-arm {
            padding-top: 3rem;
            padding-bottom: 2rem;
        }
        &__title {
            font-size: 3rem;
            font-weight: 600;
            line-height: 3.7rem;
            text-align: center;

            @include first-letter-uppercase();

            @include gridle_state(mobile) {
                font-size: 2.5rem;
            }
        }
        &__description {
            @include first-letter-uppercase();

            @include gridle_state(mobile) {
                padding: 0;
                padding-top: 1rem;
                font-weight: 300;
                font-size: 1.4rem;
            }
        }
        &__icons {
            &-item {
                display: flex;
                height: 4rem;
                align-items: center;
                margin-right: 6rem;

                &_text {
                    font-weight: 600;
                    line-height: 1.9rem;
                    padding-left: 6rem;
                    padding-right: 11rem;
                }
            }
        }
        &__method {
            display: flex;
            justify-content: space-around;
            .arm-introduction__content__body__right & {
                flex-direction: column;
                padding-top: 1rem;
                @include gridle_state(mobile) {
                    flex-direction: row;
                }
            }
            &-title {
                color: #666666;
                font-size: 1.8rem;
                padding: 1rem 0;
                text-align: center;

                @include gridle_state(mobile) {
                    font-size: 1.6rem;
                }

                @include first-letter-uppercase();
            }
            &-item {
                display: flex;
                align-items: center;
                padding: 2.2rem 1rem;

                .arm-introduction__content__body__right & {
                    padding: 1rem 0;
                }
                &_icon {
                    .arm-introduction__content__body__right & {
                        width: 5rem;
                        height: 5rem;
                    }
                    width: 6rem;
                    height: 6rem;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    margin-right: 2rem;
                    @include gridle_state(mobile) {
                        margin-right: 0;
                        margin-bottom: 2rem;
                        width: 4.5rem;
                        height: 4.5rem;
                    }

                    &--currency {
            background-image: url("/assets/images/icon-gratuit.svg");
                    }
                    &--rdv {
            background-image: url("/assets/images/direct-send.svg");
                        background-size: 83%;
                    }
                    &--message {
            background-image: url("/assets/images/icon-rendezvous.svg");
                    }
                }

                &_text {
                    font-weight: 600;
                    font-size: 1.3rem;
                    max-width: 20rem;

                    .arm-introduction__content__body__right & {
                        max-width: 22rem;
                    }
                    @include first-letter-uppercase();
                    @include gridle_state(mobile) {
                        text-align: center;
                        font-size: 1.2rem;
                        line-height: 1.5rem;
                    }
                }

                @include gridle_state(mobile) {
                    flex-direction: column;
                }
            }
        }
        &__info {
            padding: 2rem;
        }
        &__infomobile {
            @include gridle_state(mobile) {
                padding: 0 3rem 4rem;
                margin: 0 1rem;
            }
        }
    }
}

@include arm-introduction(".arm-introduction");
@include arm-introduction(".arm-introduction-page", "page");
