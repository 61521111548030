$gutter: 2.5rem;

%at-offer-grid-skin-default {
  // Default skin here

  padding-left: 0;
  overflow: hidden;
}

@mixin offer-grid($selector, $skin: default) {
  #{$selector} {
    @extend %at-offer-grid-skin-#{$skin} !optional;

    &__wrapper {
      margin-right: -$gutter;
    }

    &__cell {
      @include gridle_state(tablet) {
        & + & {
          margin-top: 2rem;
        }
      }
    }

    /* Add specificity to override griddle padding */
    & > &__cell {
      padding-left: 0;
      padding-right: $gutter;
    }

    &__article {
      height: 100%;
      background-color: color-palette(tile-neutral);
      padding: 2rem 2rem 50rem 2rem;
      margin-bottom: -49rem;

      @include gridle_state(tablet) {
        padding: 2rem;
        margin-bottom: 0;
      }
    }
  }
}

// Include component here
@include offer-grid('.offer__grid');
