// Declare skins here

%at-simulation-table__cell-skin-default {
  &:first-child, &:nth-child(3) {
    width: 30%;
  }
}

%at-simulation-table__cell-skin-mobile {
  width: 50%;
}

@mixin simulation-table__cell($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation-table__cell-skin-#{$skin} !optional;

    & {
      border: .1rem solid color-palette(tone-primary-dark);
      padding: $font-size-normal 0;
      text-align: center;
      vertical-align: top;
    }
  }
}

// Include component here
@include simulation-table__cell('.simulation-table__cell');
@include simulation-table__cell('.simulation-table__cell--mobile', 'mobile');
