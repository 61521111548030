@import '../default/2_atoms/candtooltip';

%at-cand-tooltip-skin-default {
  &:after {
    font-weight: 300;
    text-transform: none;
    color: #333;
   // width: 4.56rem;
    min-height: 9.4rem;
    height: auto;
    background-color: color-palette(neutral-grey-light);
    border: 1px solid color-palette(neutral-grey-light);
    border-radius: 4px;
    margin-left: -19rem;
    top: 3.2rem;
    text-align: left;
    min-width:30rem;
    z-index: 10;

    @include gridle_state(mobile){
      margin-left: -13rem;
      min-width:29rem;
    }
  }

  &:hover, &:focus {

    &:before {
      content: '';
      display: none;
      width: 0;
      height: 0;
      position: absolute;
    }

    .tooltip_arrow {
      &:before {
        content: '';
        display: none;
        width: 0;
        height: 0;
        position: absolute;
      }
    }
  }
}

%at-cand-tooltip-skin-toparrow {
  white-space: normal;

  &:hover, &:focus {
    &:before {
      display: none;
      border: 1.2rem solid;
      border-color: transparent transparent color-palette(neutral-grey-light) transparent;
      left: 1rem;
      top: 1rem;
    }
    .tooltip_arrow {
      &:before {
        display: block;
        border: 10px solid;
        border-color: transparent transparent color-palette(neutral-grey-light) transparent;
        left: 0;
        top: 1.4rem;
        z-index: 9999;
      }
    }

    &.subguarantee {

      &:after {
        top: 2.5rem;
      }

      .tooltip_arrow {
        &:before {
          left: -1.3rem;
          top: 0.5rem;
        }
      }
    }
  }
}

%at-cand-tooltip-skin-pullright{
  &:after{
    margin-left: -4rem;
  }
}

%at-cand-tooltip-skin-left {
  &:after {
    margin-left: -34rem;
    @include gridle_state(mobile){
      margin-left: -25rem;
    }
  }
}


%at-cand-tooltip-skin-textright {
  &:after {
    margin-left: 0;
  }
}

%at-cand-tooltip-skin-textleft {
  &:after {
    margin-left: -26rem;
  }
}

%at-cand-tooltip-skin-right {
   float:right;
}

%at-cand-tooltip-skin-paddingleft {
  &:after {
    margin-left: -3rem;
  }
}

@include cand-tooltip('.tooltip--toparrow[data-cand-tooltip]:not([data-cand-tooltip=""])','toparrow');
@include cand-tooltip('.tooltip--padding[data-cand-tooltip]:not([data-cand-tooltip=""])','padding');
@include cand-tooltip('.tooltip--textright[data-cand-tooltip]:not([data-cand-tooltip=""])','textright');
@include cand-tooltip('.tooltip--right[data-cand-tooltip]:not([data-cand-tooltip=""])','right');
@include cand-tooltip('.tooltip--textleft[data-cand-tooltip]:not([data-cand-tooltip=""])','textleft');
@include cand-tooltip('.tooltip--pull[data-cand-tooltip]:not([data-cand-tooltip=""])','pull');
@include cand-tooltip('.tooltip--left[data-cand-tooltip]:not([data-cand-tooltip=""])','left');
@include cand-tooltip('.tooltip--pullright[data-cand-tooltip]:not([data-cand-tooltip=""])','pullright');
@include cand-tooltip('.tooltip--paddingleft[data-cand-tooltip]:not([data-cand-tooltip=""])','paddingleft');
