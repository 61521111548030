// Declare skins here

%at-tile-content-skin-default {
  // Default skin here
  font: 1.9rem $font-regular;
  color: color-palette(tone-primary-dark);
  margin: 0 0 2.5rem;
}

%at-tile-content-skin-neutral {
  @extend %at-tile-content-skin-default;
  color: color-palette(neutral);
}

%at-tile-content-skin-medium {
  @extend %at-tile-content-skin-default;
  max-width: 15rem;
}

@mixin tile-content($selector, $skin: default) {
  #{$selector} {
    @extend %at-tile-content-skin-#{$skin} !optional;
  }
}

// Include component here
@include tile-content('.tile-content');
@include tile-content('.tile-content--neutral','neutral');
@include tile-content('.tile-content--medium','medium');
