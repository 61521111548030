// Declare skins here

%at-dashboard-summary-skin-default {
  color: color-palette(tone-primary);
  font-size: $font-size-big;
  text-align: center;

  @include gridle_state(mobile) {
    font-size: $font-size-normal;
    margin-bottom: 4rem;
  }
}

@mixin dashboard-summary($selector, $skin: default) {
  #{$selector} {
    @extend %at-dashboard-summary-skin-#{$skin} !optional;

    &__title {
      font-family: $font-medium;
      text-transform: uppercase;
      font-size: (1.9/ 2.6) * 100%;
      display: inline-block;

      &:after {
        content: '';
        display: block;
        margin: 1.5rem auto 2rem;
        width: 4rem; height: .1rem;
        background-color: color-palette(tone-secondary);
      }
    }

    &__content {
      font-size: inherit;
      margin: 0;

      &.big-price {
        font-size: 150%;
        margin-top: -$font-size-normal;
      }
    }
  }
}

// Include component here
@include dashboard-summary('.dashboard-summary');
