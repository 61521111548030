// Declare skins here

%at-choice-box__label-skin-default {
  display: inline-block;
  font-size: $font-size-normal;
  line-height: 1.3;
  color: color-palette(tone-primary-dark);
  cursor: pointer;
  position: relative;
  transition: opacity .1s ease-out,background-color .1s ease-out;

  &:before {
    content: '';
    display: block;
    margin: 0 auto 1rem;
    width: 2rem;
    height: 2rem;
    border: .1rem solid color-palette(tone-primary-dark);
    vertical-align: top;
    background-color: color-palette(neutral);
    font-size: 1.6rem;
    padding: 0.2rem 0 0 0.2rem;
  }

  // For focus effect
  &:after {
    content: '';
    display: none;
    position: absolute;
    width: 4rem; height: 4rem;
    border-radius: 50%;
    z-index: -1;
    background-color: rgba(grey, .1);
    opacity: 0;
    transition: all .3s ease-out;
  }



}

%at-choice-box__label-skin-icon {


  &:before {
    content: '';
    display: block;
    width: 2rem;
    height: 2rem;
    vertical-align: top;
    background: transparent;
    font-size: 2rem;
    color: color-palette(checked-grey);
    cursor: pointer;
    visibility: visible;
    line-height: 1;
  }
  @include icon(choice-checked2);
}

%at-choice-box__label-skin-focus {
  opacity: 1;

  &:after {
    opacity: 1;
  }
}

%at-choice-box__label-skin-checked {
  opacity: 1;
  @include icon(choice-checked);

  &:after {
    background-color: rgba(color-palette(tone-secondary), .2);
  }

  &:before {
    border: 0;
    background-color: color-palette(tone-secondary);
    color: color-palette(neutral);
    //font-size: 2rem;
    //padding: .3rem 0 0 .3rem;
    line-height: 1;
  }
}

%at-choice-box__label-skin-iconChecked {
  &:before {
    color: color-palette(tone-secondary);
  }
}

%at-choice-box__label-skin-iconInvalid {
  &:before {
    color: color-palette(tone-error);
  }
}

%at-choice-box__label-skin-disabled {
  opacity: .4;
}

%at-choice-box__label-skin-disabled-checked {
  opacity: 1;
  @include icon(choice-checked);

  &:before {
    border: 0;
    //font-size: 2rem;
    //padding: .3rem 0 0 .3rem;
    line-height: 1;
    color: color-palette(tone-secondary);
  }
}
%at-choice-box__label-skin-disabled-refused {
  opacity: 1;
  @include icon(delete);

  &:before {
    border: 0;
    color: color-palette(tone-error);
    line-height: 1;
  }
}

%at-choice-box__label-skin-boxWarning-checked{
  width:auto;
  &:after {
    background-color: rgba(color-palette(tone-warning), .2);
  }
  &:before{
    background-color: color-palette(tone-warning);
  }
}

%at-choice-box__label-skin-boxWarning{
  width:auto;

  &:before{
    border: 0.1rem solid color-palette(tone-warning);
  }
}

%at-choice-box__label-skin-button {
  @extend %at-button-skin-default;
  //font-family: $font-medium;
  border: .1rem solid color-palette(tone-primary-dark);
  line-height: 1;
  padding: 0.3rem 1rem 0;
  min-width: 100%;
  cursor: pointer;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  max-width: 10rem;

  .choice-box__iconValid {
    visibility: hidden;
    float: right;
    margin-right: 10px;
  }


  &:before {
    display: none;
  }

  &:not([disabled]):hover {
    opacity: 1;
    border-color: color-palette(tone-primary);
    color: color-palette(tone-primary);
  }
  &.buttonBlocked{
    background-color: color-palette(neutral);
    border-color: color-palette(neutral-grey-lighten);
    color: color-palette(neutral-grey-lighten);
    opacity: 0.4;
    cursor: not-allowed;
    &:hover {
      opacity: 0.4;
      border-color: color-palette(neutral-grey-lighten);
      color: color-palette(neutral-grey-lighten);
    }
  }

  @include gridle_state(mobile) {
    font-size: 1.4rem;
  }
}

%at-choice-box__label-skin-primary {
    opacity: 1;
    color: color-palette(tone-primary);
    background-color: color-palette(neutral);
    border-color: color-palette(tone-primary);

    &:not([disabled]):hover {
      background-color: color-palette(tone-primary);
      border-color: color-palette(tone-primary);
      color: color-palette(neutral);
    }
}

%at-choice-box__label-skin-buttonChecked {
  opacity: 1;
  color: color-palette(neutral);
  background-color: color-palette(tone-secondary);
  border-color: color-palette(tone-secondary);
  font-family: $font-medium;

  &.choice-box__label--no-button{
    background-color: color-palette(neutral-grey-darkest);
    border-color: color-palette(neutral-grey-darkest);
  }
  .choice-box__iconValid {
    visibility: visible;
  }
}

%at-choice-box__label-skin-buttonFocus {
  box-shadow: 0 0 1rem color-palette(tone-primary);
}

%at-choice-box__label-skin-buttonWarning {
  &.choice-box__label--buttonChecked {
    opacity: 1;
    color: color-palette(tone-warning);
    background-color: color-palette(neutral);
    border-color: color-palette(tone-warning);
  }
}

%at-choice-box__label-skin-buttonInvalid {
  opacity: 1;
  color: color-palette(tone-error);
  background-color: color-palette(neutral);
  border-color: color-palette(tone-error);
}

%at-choice-box__label-skin-number {
 white-space: nowrap;
}

%at-choice-box__label-skin-buttonDownload {
  opacity: 1;
  color: color-palette(tone-primary);
  background-color: color-palette(neutral);
  border-color: color-palette(tone-primary);

  &:hover {
    background-color: color-palette(tone-primary);
    border-color: color-palette(tone-primary);
    color: color-palette(neutral);
  }

  &%at-choice-box__label-skin-buttonChecked {
    opacity: 1;
    color: color-palette(tone-secondary);
    background-color: color-palette(neutral);
    border-color: color-palette(tone-secondary);
  }
}

%at-choice-box__label-skin-confirmation {
  color: color-palette(tone-primary);
  opacity: 1;

  &:before {
    display: inline-block;
    float: left;
    border-color: color-palette(tone-primary);
  }
}

%at-choice-box__label-skin-invalid {
  opacity: 1;
  color: color-palette(tone-error);
  border-color: color-palette(tone-error);

  &:before {
    border-color: color-palette(tone-error);
    background-color: color-palette(neutral);
  }
}

%at-choice-box__label-skin-frame {
  border: 0.2rem solid color-palette(tone-secondary);
  padding: 1.7rem;
}

%at-choice-box__label-skin-loading {
  @include icon(loading);

  &:before {
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    border: none;
    background: none;
  }
}

@mixin choice-box__label($selector, $skin: default) {
  #{$selector} {
    @extend %at-choice-box__label-skin-#{$skin} !optional;

    @if $skin == default {
      &__text {
        &--small {
          font-size: 1.6rem;
        }
      }
    }
  }
}

// Native behaviours
@include choice-box__label('.choice-box__label');
@include choice-box__label('.choice-box__label--focus','focus');
@include choice-box__label('.choice-box__label--checked', 'checked');
@include choice-box__label('.choice-box__label--disabled','disabled');
@include choice-box__label('.choice-box__label--disabled-checked','disabled-checked');
@include choice-box__label('.choice-box__label--disabled-refused', 'disabled-refused');


// Custom behaviours
@include choice-box__label('.choice-box__label--button', 'button');
@include choice-box__label('.choice-box__label--number', 'number');
@include choice-box__label('.choice-box__label--primary', 'primary');
@include choice-box__label('.choice-box__label--buttonChecked', 'buttonChecked');
@include choice-box__label('.choice-box__label--buttonFocus', 'buttonFocus');
@include choice-box__label('.choice-box__label--buttonInvalid', 'buttonInvalid');
@include choice-box__label('.choice-box__label--buttonWarning', 'buttonWarning');
@include choice-box__label('.choice-box__label--buttonDownload', 'buttonDownload');
@include choice-box__label('.choice-box__label--confirmation', 'confirmation');
@include choice-box__label('.choice-box__label--invalid','invalid');
@include choice-box__label('.choice-box__label--frame', 'frame');
@include choice-box__label('.choice-box__label--loading', 'loading');
@include choice-box__label('.choice-box__label--boxWarning', 'boxWarning');
@include choice-box__label('.choice-box__label--boxWarning-checked', 'boxWarning-checked');
@include choice-box__label('.choice-box__label--icon', 'icon');
@include choice-box__label('.choice-box__label--iconChecked', 'iconChecked');
@include choice-box__label('.choice-box__label--iconInvalid', 'iconInvalid');
