// Declare skins here

%at-edito-table-skin-default {
    @include gridle_state(mobile){
        border: 0;
        display:block;
        margin:auto;
        width: 100%;
    }
}

@mixin edito-table($selector, $skin: default) {
  #{$selector} {
    @extend %at-edito-table-skin-#{$skin} !optional;
    @include gridle_state(mobile){
        & thead{
            display: none;
        }

      & tbody {
        display: block;
      }


        &--withoutHead {
          & tr:first-child {
            display: none !important;
          }
        }
        & tr {
            margin-bottom: 1rem;
            display: block;
            border-bottom: 0.1rem  solid color-palette(tone-primary-dark);
        }
        & td {
            display: block;
            border-bottom: none;
            width:100% !important;

            &:before {
                content: attr(data-label);
                text-transform: uppercase;
                font-size: 1.5rem;
                display:block;
                margin:auto;
                border-bottom: 0.1rem solid color-palette(tone-primary-dark);
                text-align:center;
            }
        }
    }
  }
}

// Include component here
@include edito-table('.edito-table');
