%at-push-to-left-skin-default {
  left:-80%;
}

@mixin push-to-left($selector, $skin: default) {
  #{$selector} {
    @extend %at-push-to-left-skin-#{$skin} !optional;
  }
}

// Include component here
@include push-to-left('.push-to-left');
