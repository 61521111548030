@import '../default/6_pages/outdated';

%at-outdated-skin-default {
  background-image: url(/assets/images/background-3.jpg);
  background-size: cover;
  background-repeat: no-repeat;


  .cand-outdated__content--pegase {
    text-align: center;

  }

  .cand-outdated__header {
    width: 100%;
    height: 3em;
    background-color: white;
    padding: 0 0 0.5em 7em;
    box-shadow: 0 0.3em 0.6em rgba(0, 0, 0, 0.2);
    @include gridle_state(mobile){
      padding : 0;
      margin: 0 auto;
      text-align: center ;
    }
  }
  .cand-outdated__logo {
    background-image: url('/assets/images/pegase-logo.png');
    background-position: center;
  }
  .cand-outdated__text {
    margin : 0 auto;
    max-width:50%;
    font-size: $font-size-medium;
  }

  .cand-outdated__title {
    font-size: $font-size-medium;
    margin: 1em 0;
    font-weight: bold;
  }

  .cand-outdated__button {
    margin-top: 3em;
    padding: 1em 6em;
    @include gridle_state(mobile) {
      margin-top: 0;
    }
  }

  .cand-outdated__alert-icon {
    width: 104px;
    height: 104px;
  }


  .cand-outdated__wrapper{
    width: 80%;
    margin : 8em auto auto;
    height:auto;

    @include gridle_state(mobile){
      width: 100%;
      margin: 5em 0;
    }
  }
}
