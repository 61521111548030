// Declare skins here

%at-arm-skin-default {
  margin: 5rem auto;
  font-size: $font-size-normal;

  @include gridle_state(tablet) {
    font-size: 1.7rem;
  }

  @include gridle_state(mobile) {
    font-size: 1.4rem;
  }
}

@mixin arm($selector, $skin: default) {
  #{$selector} {
    @extend %at-arm-skin-#{$skin} !optional;

    &__content {
      border: .1rem solid color-palette(tone-primary-light);
      padding: 2.5rem;
      margin-bottom: 1.5rem;
    }

    &__title {
      background-color: color-palette(tone-secondary);
      color: color-palette(neutral);
      text-align: center;
      font-family: $font-medium;
      text-transform: uppercase;
      font-size: inherit;
      padding: 1.5rem 0 1rem;
    }

    &__text {
      font-family: $font-medium;
      line-height: 1.6;

      & + & {
        margin-top: 1rem;
      }

      &--big {
        font-size: (2.6 / 1.9) * 100%; // 2.6rem relative in percent
      }

      &--small {
        font-family: $font-regular;
        font-size: (1.4 / 1.9) * 100%; // 1.4rem relative in percent
        line-height: 1.2;
      }
    }

    &__tel {
      color: color-palette(tone-secondary);
      font-size: (6 / 1.9) * 100%;
      text-decoration: none;
      display: block;
      margin: 2rem 0;

      @include gridle_state(mobile) {
        font-size: (5 / 1.9) * 100%;
      }
    }
  }
}

// Include component here
@include arm('.arm');
