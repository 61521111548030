%at-box-media-skin-default {
  // Default skin here

  margin: 0 -2rem;
  margin-bottom: 1rem;
}

@mixin box-media($selector, $skin: default) {
  #{$selector} {
    @extend %at-box-media-skin-#{$skin} !optional;

    &__img {
      width: 100%;
    }
  }
}

// Include component here
@include box-media('.box__media');
