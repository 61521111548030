%at-content-summary-skin-default {
  // Default skin here

  color: color-palette(tone-primary);
  font-size: $font-size-big;
  margin-bottom: 1rem;
}

@mixin content-summary($selector, $skin: default) {
  #{$selector} {
    @extend %at-content-summary-skin-#{$skin} !optional;
  }
}

// Include component here
@include content-summary('.content-summary');
