@keyframes warnFieldEnter {
  from {
    opacity: 0;
    transform: translate3d(0, -1rem, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

%at-warn-field-changed-skin-default {
  color: color-palette(tone-warning) !important;

  & ~ .angucomplete-wrapper input {
    color: color-palette(tone-warning) !important;
  }

  & ~ .warn-field-changed__choice .choice-box__label {
    @extend %at-choice-box__label-skin-buttonWarning;
  }

  &.choice ~ .choice-box__label{
    @extend %at-choice-box__label-skin-boxWarning;
  }
  &.choice:checked ~ .choice-box__label{
    @extend %at-choice-box__label-skin-boxWarning-checked;
  }

}

%at-warn-field-changed-skin-select {
  & ~ .warn-field-changed__handler {
    vertical-align: middle;
    z-index: 1;
  }

  & ~ .select-box__track {
    right: 3.5rem;
  }
}



%at-warn-field-changed__handler {
  @include icon(info);
  display: none;
  color: color-palette(tone-warning);
  font-size: $font-size-normal;
  vertical-align: middle;

  &[data-cand-tooltip] {
    display: none;
  }
  &.show-tooltip{
    display: inline-block;
    padding-bottom: 0.4rem;
  }
  &.show-tooltip-yesno{
    display: block !important;
    margin-bottom: 2rem;
  }
}

%at-warn-field-changed__handler--tiny {
  font-size: $font-size-normal;
}

%at-warn-field-changed__handler-active {
  display: inline-block;
  animation: warnFieldEnter .5s ease-in-out;

  &[data-cand-tooltip] {
    display: inline-block;
  }
}

@mixin warn-field-changed($selector, $skin: default) {
  #{$selector} {
    @extend %at-warn-field-changed-skin-#{$skin} !optional;

    @if $skin == default {
      &__handler {
        @extend %at-warn-field-changed__handler;

        &.icon--info:before {
          margin-right: .5rem;
        }

        &--tiny {
          @extend %at-warn-field-changed__handler--tiny;
        }
      }

      &:not(:disabled) ~ {
        .warn-field-changed__handler {
          @extend %at-warn-field-changed__handler-active;
        }

        .textfield-rich__post, .warn-field-changed__text {
          color: color-palette(tone-warning) !important;
        }
      }
    }
  }
}

// Include component here
@include warn-field-changed('.warn-field-changed');
@include warn-field-changed('select.warn-field-changed', 'select');
