%at-testimony-section-skin-default {
  // Default skin here
}

@mixin testimony-section($selector, $skin: default) {
  #{$selector} {
    @extend %at-testimony-section-skin-#{$skin} !optional;

    padding: 0 5rem;
    margin-bottom: 7.5rem;

    @include gridle_state(mobile) {
      padding: 0 5%;
    }
  }
}

// Include component here
@include testimony-section('.testimony__section');
@include testimony-section('.testimony__section--fullwidth', 'fullwidth');
