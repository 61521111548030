// Declare skins here

%at-user-status-bar-skin-default {
  // Default skin here
  position: relative;
  visibility:visible;
  text-align:center;

  animation: 0.5s cubic-bezier(0.2, 0.8, 0.14, 1.15) 0.8s slide-in;
  animation-fill-mode: backwards;

  @keyframes slide-in{
    from{
      transform:translate3d(-50%,0,0);
      opacity:0;
      visibility: hidden;
    }
    to{
      transform:translate3d(0,0,0);
    }
  }

  @include gridle_state(mobile) {
    margin-bottom: 0;
  }

}

@mixin user-status-bar($selector, $skin: default) {
  #{$selector} {
    @extend %at-user-status-bar-skin-#{$skin} !optional;
    &__wrapper {
      box-shadow: inset 0 -0.7rem 1rem rgba(128, 128, 128, 0.1);
    }
    &__text-wrapper{
      display: inline-block;
      text-align: left;
      vertical-align: middle;
      line-height: 2rem;
      height: 4rem;
    }
    &__title {
      position: absolute;
      margin: 2rem 0 0 0;
      width: 100%;
      color: color-palette(tone-primary);
      font-family: $font-medium;
    }
    &__list{
      display: inline-block;
      color: color-palette(tone-primary-dark);
      font-size: $font-size-normal;
      padding: 0;
      margin: 0;
    }
    &__item{
      padding-top: 3rem;
      margin: 0;
      &::before{
        font-size: 3.3rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.4rem;
        color: color-palette(tone-secondary);
      }
      @include gridle_state(mobile){
        text-align: left;
      }

    }
    &__status{
      color: inherit;
      text-decoration: none;
      font-size: 1.4rem;
      display: block;
      &--link:hover{
        text-decoration: underline;
      }
    }
    &__user-name{
      margin: 0;
      padding: 0;
      display: block;
      &--active {
        font-family: $font-medium;
        color:color-palette(tone-secondary);
      }
    }
  }
}

// Include component here
@include user-status-bar('.users-status-bar');
