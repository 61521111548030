%at-project-landing-skin-default {
  background-color: rgba(color-palette(tone-primary-lighter), 0.05);
}

@mixin project-landing($selector, $skin: default) {
  #{$selector} {
    @extend %at-project-landing-skin-#{$skin} !optional;

    &__content {
      padding: 6rem 9rem;

      @include gridle_state (mobile) {
        padding: 1rem 0;
        font-size: $font-size-small;
      }
    }

    .section-title {
      font-family: $font-hw-bold;
      font-size: 2.8rem;
      color: color-palette(tone-primary);

      @include gridle_state (mobile) {
        font-size: 1.6rem;
      }
    }

    &--gutter{
      padding: 0 1rem;
    }
  }
}

// Include component here
@include project-landing('.project-landing');
