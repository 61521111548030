%at-password-helper-skin-default {
  // Default skin here
    	position:relative;
    	font-size:1.4rem;
        padding-left: 0 !important;
        padding-right: 0 !important;
}

@mixin password-helper($selector, $skin: default) {
  #{$selector} {
    @extend %at-password-helper-skin-#{$skin} !optional;
    &Content{
        display:block;
        background-color: color-palette(neutral-grey-light);
        border: 1px solid color-palette(neutral-grey-light);
        box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.3);
        & ul{
            padding-left:1rem;
            & > li{
                list-style: none;
                line-height: 2.5rem;
            }
        }
        span{
            color: color-palette(tone-primary);
        }
    }
  }
}

// Include component here
@include password-helper('.password-helper');
