%at-responsive-table-skin-default {
  // Default skin here
  th, td {
    border-right: 2rem solid transparent;
  }
  th:last-child, td:last-child {
    border-right: none;
  }
}

%at-responsive-table-skin-secondary {
  // Default skin here

  td {
    color: color-palette(tone-secondary);
  }
}

@mixin responsive-table($selector, $skin: default) {
  #{$selector} {
    @extend %at-responsive-table-skin-#{$skin} !optional;

    @if $skin == default {
      @include gridle_state(mobile) {
        font-size: 1.5rem;

        th, td {
          border-right: none;
        }

        thead {
          display: none;
        }

        tr {
          display: block;
          margin-bottom: 2rem;
        }

        td {
          display: block;
          padding: 0;

          &:first-child {

          }

          &::before {
            content: attr(data-th);
            display: inline-block;
            color: color-palette(tone-primary-dark);
            font-family: $font-bold;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

// Include component here
@include responsive-table('.cand-responsive-table');
@include responsive-table('.cand-responsive-table--secondary', 'secondary');
