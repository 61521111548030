// Declare skins here

%at-page-404-title-skin-default {
   color: color-palette(neutral);
   font-family: $font-hw-bold;
   font-size: 5.6rem;
   padding-bottom: 1.5rem;

   @include gridle_state(mobile) {
     font-size: 3.2rem;
     padding-bottom: 1rem;
   }
}

@mixin page-404-title ($selector, $skin: default) {
  #{$selector} {
    @extend %at-page-404-title-skin-#{$skin} !optional;
  }
}

// Include component here
@include page-404-title('.page-404__title');
