%at-consents__figure-skin-default {
  font-size: $font-size-normal;
  margin-bottom: 4rem;

  @include gridle_state(mobile) {
    margin-bottom: 5rem;
  }
}

%at-consents__figure-skin-arm {
  font-size: $font-size-normal;
  margin-bottom: 4rem;
  background-color: color-palette(neutral-light);
  padding: 3rem 2rem;
  @include gridle_state(mobile) {
    display: block;
    width: 100%;
    padding: 1rem 0;
  }
}

%at-consents__figure-skin-arm-element {
  display: inline-block;
  position: relative;

  @include gridle_state(mobile) {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    border-bottom: 1px solid color-palette(neutral-grey-light);
    padding: 1rem 0;
    &:last-child{
      border-bottom: none;
    }
  }
  &:before{
    content: '';
    display: inline-block;
    width: .2rem;
    height: 100%;
    border-left: 1px solid color-palette(neutral-grey-light);
    position: absolute;
    left: 0rem;

    @include gridle_state(mobile) {
      display: none;
    }
  }

  &:first-child{
    &:before{
      display: none;
    }
  }
}

@mixin consents__figure($selector, $skin: default) {
  #{$selector} {
    @extend %at-consents__figure-skin-#{$skin} !optional;

    @if($skin == default) {
      display: table;
      padding-left: 1.4rem;

      @include gridle_state(mobile) {
        display: block;
        padding-left: 0;
      }

      &Caption {
        display: table-cell;
        padding-left: 4rem;
        vertical-align: middle;
        color: color-palette(neutral-grey-darker);

        @include gridle_state(mobile) {
          display: block;
          padding-left: 0;
          font-size: 1.2rem;
        }

        a {
          color: color-palette(tone-secondary);
        }

        p {
          margin-top: 1.5rem;
        }

        &:before {
          display: none;
        }
      }

      &Media {
        display: table-cell;
        width: 7rem; height: 7rem;
        background-repeat: no-repeat;
        background-size: 100% auto;

        @include gridle_state(mobile) {
          display: block;
          margin: 0 auto 2rem auto;
        }

        &--subscription {
          background-image: url('/assets/images/consents_1.svg');
        }

        &--declaration {
          background-image: url('/assets/images/consents_2.svg');
        }

        &--conduite {
          background-image: url('/assets/images/consents_3.svg');
        }
      }

      &Link {
        color: inherit;
        font-family: $font-medium;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    @if($skin == arm) {
      &Media {
        display: block;
        width: 8rem; height: 7rem;
        background-repeat: no-repeat;
        background-size: auto 100%;
        margin: auto;
        background-position: 50%;

        @include gridle_state(mobile) {
          display: inline-block;
          margin: 0 ;
          vertical-align: middle;
        }

        &--free {
          background-image: url('/assets/images/arm-gratuit.svg');
          width: 8rem; height: 7rem;
          @include gridle_state(mobile) {
            width: 7rem;
            height: 6rem;
          }
        }

        &--speed {
          background-image: url('/assets/images/arm-time.svg');
          width: 8rem; height: 7rem;
          @include gridle_state(mobile) {
            width: 7rem;
            height: 6rem;
          }
        }

        &--send {
          background-image: url('/assets/images/arm-send.svg');
          width: 12rem; height: 5rem;
          margin-top: 1rem;
          margin-bottom: 3rem;
          @include gridle_state(mobile) {
            margin: 0;
            width: 8rem;
            height: 3rem;
            margin-left: -1rem;
          }
        }
      }

      &Caption {
        text-align: center;
        margin-top: 2rem;
        @include gridle_state(mobile) {
          display: inline-block;
          vertical-align: middle;
          width: 70%;
          text-align: left;
          margin-top: 0;
        }
      }
    }

  }
}

// Include component here
@include consents__figure('.consents__figure');
@include consents__figure('.consents__figure--arm','arm');
@include consents__figure('.consents__figure--arm-element','arm-element');
