// Declare skins here

%at-password-box-skin-default {
  // Default skin here
  background-color: color-palette(neutral);
  width: 53rem;
  min-height: 46.2rem;
  border-top: solid 0.1rem color-palette(tone-primary);
  box-shadow: 0rem 0.8rem 1.5rem 0.4rem rgba(0,0,0,0.1);
  position: absolute;
  top: 0;
  right: 10rem;
  padding: 6.6rem 2.2rem;
  z-index: -1;
  transition: opacity .3s ease-out, z-index .3s ease-out;
  opacity: 0;

  .page-subtitle{
    text-align: center;
    margin-bottom: 6rem;
  }
  .page-label{
    text-align: center;
    font-size: 2rem;
  }

  .page-return{
    float: left;
    cursor: pointer;
  }

  .textfield{
    display: block;
    width: 100%;
    margin-bottom: 3rem;
    &[type='password']{
      margin-bottom: 1rem;
    }
  }

  .button {
    margin-top: 4rem;
  }

  @include gridle_state(mobile){
    position: fixed;
    width: 100%;
    right: 0;
    left: 0;
    top: 8rem;
    padding: 1rem;


    .page-subtitle--small{
      font-size: 2rem;
    }

    .textfield{
      margin-bottom: 1rem;
    }

    .button {
      margin-top: 2rem;
    }

    .popin__close-button{
      float: right;
      padding-top: 0;
      background: none;
      border: none;
      color: color-palette(popin-tone-secondary);
    }
  }
}

%at-password-box-skin-message {
  display: none;
}

%at-password-box-skin-form {
  display: none;
}

%at-password-box-skin-modal {
  position: relative;
  opacity: 1;
  right: 0;
  min-height: 10rem;
  width: 70%;
  max-width: 40rem;
  box-shadow: none;
  border: none;
  padding: 0;
  z-index: 0;
  text-align: center;
  margin: 0 auto;
  @include gridle_state(mobile){
    max-width: 100%;
  }
}

@mixin password-box($selector, $skin: default) {
  #{$selector} {
    @extend %at-password-box-skin-#{$skin} !optional;

    &.opened{
      z-index: 20;
      opacity: 1;
    }
    &.existed{
      display: block;
    }

    &-modal__link {
      margin-top: 2rem;
      font-size: 1.4rem;
      color: color-palette(tone-grey-black-dark);
      display: block;
      text-decoration: underline;
      cursor: pointer;
    }

  }
}

// Include component here
@include password-box('.password-box');
@include password-box('.password-box--form','form');
@include password-box('.password-box--message','message');
@include password-box('.password-box--modal','modal');
