%at-modal-fade-skin-default {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: color-palette(popin-tone-primary);
  opacity: .8;
  z-index: 90;
}

@mixin modal-fade($selector, $skin: default) {
  #{$selector} {
    @extend %at-modal-fade-skin-#{$skin} !optional;
  }
}

// Include component here
@include modal-fade('.cand-modal__fade');
