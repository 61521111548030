// Declare skins here
%at-pill__content-skin-default {
  padding : 1.7rem 1.5rem;
  background-color: color-palette(neutral-grey-darker);
  color: color-palette(neutral);
}




@mixin pill-content($selector, $skin: default) {
  #{$selector} {
    @extend %at-pill__content-skin-#{$skin} !optional;

    @if($skin == default) {

    }
  }
}

// Include component here
@include pill-content('.pill__content');