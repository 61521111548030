// Declare skins here

%at-proposition__table-skin-default {
  display: table;
  margin-bottom: 4rem;
}

@mixin proposition__table($selector, $skin: default) {
  #{$selector} {
    @extend %at-proposition__table-skin-#{$skin} !optional;
  }
}

// Include component here
@include proposition__table('.proposition__table');
