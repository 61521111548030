@import '../default/4_organisms/dashboardprojects';

%at-dashboardprojects-skin-default {
  color: color-palette(neutral-grey-black);

  .dashboard__projects__ahead {
    display: inline-block;
    padding: 2.1rem;
    background: color-palette(neutral);
    text-transform: uppercase;
  }

  .dashboard__projects__status {
    padding: 0;
    background: color-palette(neutral);

  }


  .dashboard__projects__toggle {
    clear: both;
    padding:3rem 3.4rem;
    background: color-palette(neutral-light);
    box-shadow: inset 0 0.3rem 0.4rem rgba(0, 0, 0, 0.2);
  }

  .dashboard__project {
    padding: 0 3.4rem 3.4rem;
    background: color-palette(neutral-light);

    @include gridle_state(mobile){
      padding : 0 1.5rem 1.5rem;
    }
  }

  .dashboard__project-total{
    background-color: color-palette(neutral-grey-darker);
    text-transform: capitalize;
    padding : 2.5rem 4rem;
    color : color-palette(neutral);
    margin-bottom: 2.5rem;

    @include gridle_state(mobile){
      padding : 2rem 3rem;
    }
  }

  .dashboard__person{
    .tab__content{
      padding: 0;
    }
    .tab__header{
      margin-top: 0.3rem;
      max-width: 100%;
      border-bottom: none;
      text-align: left;
    }
  }
  .dashboard__project-panel{
    min-height: 50rem;
    @include gridle_state(mobile){
      min-height: 0;
    }
  }
  .dashboard__person-label{
    font-weight: bold;
  }
  .dashboard__project-guarantees{
    margin-top : 3.2rem;
  }

  //Tiny tab headers
  .tab__header{
      height: auto;
      padding: 0.7rem 1rem 1.2rem;

    @include gridle_state(mobile){
      padding : 0.7rem 0;
    }
  }


}
