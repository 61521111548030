%at-switch-qs-skin-default {
  // Default skin here
  text-align: center;
  color: color-palette(tone-primary);
  font-size: $font-size-big;
}

@mixin switch-qs($selector, $skin: default) {
  #{$selector} {
    @extend %at-switch-qs-skin-#{$skin} !optional;

    &__title {
      font-size: inherit;
      margin: 6rem 0;
    }

    &__icon {
      display: inline-block;
      width: 16rem;
    }

    &__consent {
      margin-top: 3rem;
      padding-bottom: 0;
    }
  }
}

// Include component here
@include switch-qs('.switch-qs');
