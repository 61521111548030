%at-upload-warning-modal-skin-default {

  .cand-modal__overlay {
    border-radius: 2px;
  }

  .cand-modal__content {
    padding: 0;
  }

  .cand-modal__footer {

    @include gridle_state(mobile) {
      padding: 0 2rem 2rem;
    }

    button {
      min-width: 11.5rem;

      @include gridle_state(mobile) {
        width: 13.5rem;
      }
      @include gridle_state(mobile-xs) {
        width: 11.5rem;
        font-size: 1.1rem;
      }
    }
  }
}

@mixin upload-warning-modal($selector, $skin: default) {
  #{$selector} {
    @extend %at-upload-warning-modal-skin-#{$skin} !optional;

    &__content {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 2rem;
    }

    &__warning-icon {
      background: transparent url(/assets/images/warning.svg) 0 50% no-repeat;
      height: 4.5rem;
      width: 4.5rem;
    }

    /* stylelint-disable */
    &__message {
      color: #4c4c4c;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
    }

    &__message,
    &__description {
      font-size: 1.4rem;
      padding: 1rem;
      text-align: center;

      @include gridle_state(mobile-xs) {
        font-size: 1.2rem;
      }
    }
  }
}
@include upload-warning-modal('.upload-warning-modal')
