// Gridle settings
$desktop-config: (
  context: 12,
  gutter-width: 2rem,
  direction: ltr,
  name-multiplicator: 1,
  debug-show-class-names : false,
  debug: false
);

$desktop-small-config: (
  context: 12,
  gutter-width: 2rem,
  direction: ltr,
  max-width: $bp-desktop-small
);

$desktop-medium-config: (
  context: 12,
  gutter-width: 2rem,
  direction: ltr,
  max-width: $bp-desktop-medium
);

$header-mobile-config: (
  context: 4,
  gutter-width: 2rem,
  direction: ltr,
  max-width: $bp-header-mobile-max
);

$tablet-config: (
  context: 12,
  gutter-width: 2rem,
  direction: ltr,
  max-width: $bp-tablet-max
);

$mobile-config: (
  context: 4,
  gutter-width: 2rem,
  direction: ltr,
  max-width: $bp-mobile-max
);

$mobile-xs-config: (
  context: 4,
  gutter-width: 2rem,
  direction: ltr,
  max-width: $bp-mobile-xs-max
);

// Desktop
@include gridle_setup($desktop-config);
// Desktop small
@include gridle_register_state(desktop-small, $desktop-small-config);
// Desktop medium
@include gridle_register_state(desktop-medium, $desktop-medium-config);
// Tablet
@include gridle_register_state(tablet, $tablet-config);
// Mobile
@include gridle_register_state(mobile, $mobile-config);
@include gridle_register_state(mobile-xs, $mobile-xs-config);
// Header mobile specific
@include gridle_register_state(header-mobile, $header-mobile-config);

.grid-container {
  @include clearfix();
  width: 100%;
  max-width: $viewport-max-width;
  margin: 0 auto;

  &--section {
    padding: 0 7rem;

    @include gridle_state(tablet) {
      padding: 0 1rem;
    }
  }

  &--gutter {
    padding: 0 1rem;
  }

  &--table {
    display: table;
  }

  &--flex {
    display: flex;
  }
}

.grid-container-fluid {
  @include clearfix();

  &--table {
    display: table;
  }

  &--flex {
    display: flex;
  }
}
