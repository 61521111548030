@import "../../default/2_atoms/footer/footernavlink";

%at-footer-navLink-skin-default {
  display: inline;
  font-size: 1.4rem;
  padding: 0.3rem 0;
  &:hover {
    border-bottom: solid 0.1rem color-palette(neutral);
  }
  @include gridle_state(mobile) {
    overflow: hidden;
  }
}
