%at-modal-info-skin-default {
  // Default skin here
}

@mixin modal-info($selector, $skin: default) {
  #{$selector} {
    @extend %modal-info#{$skin} !optional;
    
    &--red {
      &.cand-modal .cand-modal__overlay .cand-modal__title {
        border-left-color: red;
      }
    }

    &--content{
      display : table;

      p {
        padding: 0 0.5rem;
      }
    }

    &__iconblock {
      display: table-cell;
      vertical-align: middle;
    }

    &__message {
      display: table-cell;
      vertical-align: middle;
      padding-left: 1rem;
    }

    &__next-button {
      float : right;
    }
    &__cancel-button {
      float : left;
    }

    &__inline {
      display : inline-block;
    }
  }
}

// Include component here
@include modal-info('.modal-info');
