%at-documents-toggle-skin-default {
  border-bottom: .1rem solid color-palette(neutral-grey-light);
}

%at-documents-toggle-skin-content {

}

%at-documents-toggle-skin-header {
  width: 100%;
  background-color: color-palette(neutral-light);
  color: color-palette(neutral-grey-black);
  padding: 4rem;
  display: table-cell;
  vertical-align: middle;
  box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 1.5rem;
}

%at-documents-toggle-skin-header-text {
 width: 75%;
  display: table-cell;
  vertical-align: middle;

  a{
    font-size: 1.6rem;
    font-weight: 400;
    color: color-palette(tone-secondary);

  }
}

%at-documents-toggle-skin-header-chevron {
  display: table-cell;
  vertical-align: middle;
}

%at-documents-toggle-skin-header-icon {
  display: block;
  vertical-align: middle;
  border: 1px solid color-palette(neutral-grey-light);
  background-color: color-palette(neutral-grey-light);
  border-radius: 50%;
  width: 4.2rem;
  height: 4.2rem;
  text-align: center;
  font-size: 2rem;
  padding-top: .7rem;
  float: right;
}

@mixin documents-toggle($selector, $skin: default) {
  #{$selector} {
    @extend %at-documents-toggle-skin-#{$skin} !optional;

    &--activeToggle{
      margin: 2rem -5rem;
      background-color: color-palette(neutral-light);
    }

    &--activeContent{
      padding: 4rem;
    }

  }
}

// Include component here
@include documents-toggle('.documents-toggle');
@include documents-toggle('.documents-toggle--header','header');
@include documents-toggle('.documents-toggle--header-text','header-text');
@include documents-toggle('.documents-toggle--header-icon','header-icon');
@include documents-toggle('.documents-toggle--header-chevron','header-chevron');
@include documents-toggle('.documents-toggle--content','content');
