// Declare skins here

%at-testimony-image-skin-default {
  // Default skin here
  border-radius: 50%;
  width: 8rem;
  margin: 4rem 0 0 0;

  @include gridle_state (  mobile ) {
    display: block;
    margin: 0 auto;
  }
}

@mixin testimony-image($selector, $skin: default) {
  #{$selector} {
    @extend %at-testimony-image-skin-#{$skin} !optional;
  }
}

// Include component here
@include testimony-image('.testimony__image');
