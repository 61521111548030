%at-fastquote-summary-item-skin-default {
  // Default skin here
  position: relative;
  line-height: 1;
}

%at-fastquote-summary-item-skin-nopadding {
  padding-bottom: 0rem;
}

%at-fastquote-summary-item-skin-center {
  text-align: center;
}



@mixin fastquote-summary-item($selector, $skin: default) {
  #{$selector} {
    @extend %at-fastquote-summary-item-skin-#{$skin} !optional;

    @if $skin == default {
      &__bundle {
        display: block;
        //position: absolute;
        font-size: 1.5rem;
        max-width: 21rem;
        margin: 1rem auto 0;

        @include gridle_state(mobile) {
          font-size: 1.2rem;
          top: 3.1rem;
          margin:0 auto;
          width:60%;
          position: initial;

        }
      }
      &__offer {
        display: block;
        font-family: $font-medium;
        font-size: 1.3rem;
        padding-bottom: 1rem;
        margin-top: 1rem;
        top: 5.5rem;
        min-width: 15rem;

        @include gridle_state(mobile) {
          font-size: 1.2rem;
          padding-bottom: 1rem;
          top: 3.1rem;
        }
      }
    }
  }
}

// Include component here
@include fastquote-summary-item('.fastquote-summary__item');
@include fastquote-summary-item('.fastquote-summary__item--nopadding','nopadding');
@include fastquote-summary-item('.fastquote-summary__item--center','center');
