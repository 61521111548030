// Declare skins here

%at-selection-list-skin-default {
  list-style: none;
  width: 49%;
  margin: 0;
  padding: 0;
  display: inline-block;
  @include gridle_state(mobile) {
    width: 100%;
  }
}

%at-selection-list-skin-separator {
  border-right: .1rem solid color-palette(neutral-grey-light);
  @include gridle_state(mobile) {
    border-right: none;
  }
}

%at-selection-list-skin-last {
  border-right: none;
  padding-left: 4rem;
  vertical-align: top;
  @include gridle_state(mobile) {
    padding-left: 0;
  }
}


%at-selection-list-skin-item {
  position: relative;
  margin-bottom: 1rem;
&:before{
  content: '•';
  color: color-palette(neutral-grey-darkest);
  position: absolute;
  font-size: 2rem;
  line-height: 0.6;
}
}

%at-selection-list-skin-text {
  display: block;
  margin-left: 2rem;
  font-weight: 700;
}


@mixin selection-list($selector, $skin: default) {
  #{$selector} {
    @extend %at-selection-list-skin-#{$skin} !optional;

    &-status{
      font-size: 1.2rem;
      font-weight: 400;
    }

    &-sent{
      color: color-palette(tone-secondary);

    }

    &-provide{
      color: color-palette(tone-warning);
    }

    &-error{
      color: color-palette(tone-error);
    }
  }
}

// Include component here
@include selection-list('.selection-list');
@include selection-list('.selection-list--item', 'item');
@include selection-list('.selection-list--text', 'text');
@include selection-list('.selection-list--last', 'last');
@include selection-list('.selection-list--separator', 'separator');
