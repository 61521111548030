@import '../default/2_atoms/pagetitle';

%at-page-title-skin-default {
  margin: 1.7rem 4rem;
  padding: 1.9rem 2.1rem 1.8rem 2.1rem;
  font-family: $font-regular;
  font-size: $font-size-big;
  color: color-palette(neutral-grey-black);
  text-transform: uppercase;
  border-left: 4px solid color-palette(tone-secondary-dark);

  &:after {
    display: none;
  }

  @include gridle_state(mobile) {
    margin: 1rem 1.5rem;
    padding: 1rem 1.6rem 1rem 1.6rem;
    font-size: $font-size-normal;
  }
}
