@import '../default/4_organisms/simulationgroup';

%at-simulation__group-skin-default {
  color: color-palette(neutral-grey-black);
  background: color-palette(neutral);
  text-align: left;
  line-height: 1.4;

  .qss__legal--notice, .qs__legal--notice {
    border-radius: 4px;
    background-color: color-palette(neutral-grey-light);
    margin: 2rem 6rem;
    font-family: $font-regular;
    color: color-palette(neutral-grey-black-bold);
    font-weight: 200;
    font-size: 1.2rem;
    padding: .6rem;
  }

  .simulation__content {
    padding: 3rem 4rem;
    box-shadow: inset 0 8px 8px -8px rgba(0, 0, 0, 0.6);

    @include gridle_state(mobile) {
      padding: 2rem;
    }
    &--no-shadow {
      padding-top: 0;
      box-shadow: none;
    }

    &--no-padding{
      padding : 3rem 0;

    }

  }

  .simulation__buttons {
    @include clearfix();
    padding: 3rem 4rem;
    background: color-palette(neutral-light);

    @include gridle_state(mobile) {
      padding: 1.4rem 2rem;
    }

    &--light {
      background: none;
      border-top: 1px solid color-palette(neutral-grey-lighten);
    }
  }
}

%at-simulation__group-skin-qs-summary {
  padding-left: 0;

  @include gridle_state(mobile) {
    padding-left: 0;
  }

  &.qs-summary-wrapper {
    @include gridle_state(mobile) {
      margin-top: .3rem;
    }
  }

  .section-title {
    margin-bottom: 2rem;
    text-transform: uppercase;

    @include gridle_state(mobile) {
      padding-left:2rem;
      margin-bottom: 1rem;
      font-weight: 400;
    }

    &__title {

      @include gridle_state(mobile) {
        font-size: 1.2rem;
        padding-left: 2rem;
      }
    }
  }
}

%at-simulation__group-skin-qs {
  .button--save {
    display: block;

    @include gridle_state(mobile) {
      float: left !important;
    }
  }

  .button--previous {
    @include gridle_state(mobile) {
      margin-top: .6rem;
      border-color: color-palette(neutral);
    }
  }

  .acceptance__option {
    margin: 0;
    padding-left: 6rem;

    @include gridle_state(mobile) {
      padding-left: 0;
    }
  }

  .qss__legal--notice, .qs__legal--notice {
    border-radius: 4px;
    background-color: color-palette(neutral-grey-light);
    margin: 2rem 6rem;
    font-family: $font-regular;
    color: color-palette(neutral-grey-black-bold);
    font-weight: 200;
    font-size: 1.2rem;
    padding: .6rem;
  }

  .acceptance {
    .choice-box__label {
      padding-bottom: 2rem;
      color: color-palette(neutral-grey-black);
      text-align: left;

      @include gridle_state(mobile) {
        padding: .9rem 3rem .9rem 2rem;
      }

      &:before {
        border-color: color-palette(tone-secondary);

        @include gridle_state(mobile) {
          position: absolute;
          transform: translate3d(0, -50%, 0);
          top: 50%;
          margin: 0;
        }
      }

      a {
        color: color-palette(tone-secondary);
      }
    }
    
    .acceptance-box__label {
      span {
        @include gridle_state(mobile) {
          display: block;
          padding-left: 3rem;
          line-height: 1.8rem;
          font-size: 1.2rem;
          text-align: left;
        }
      }
    }
  }
}

%at-simulation__group-skin-nomargin {
  margin-bottom: 0;
}

%at-simulation__group-skin-qs-no-title{
  .section-title {
    font-weight: normal;
    text-transform: none;
  }

  @include gridle_state(mobile){
    .height-weight-question {
      .section-title{
        text-transform: uppercase;
      }
    }
  }
}

@include simulation__group('.simulation__group--no-margin', 'nomargin');
@include simulation__group('.simulation__group--qs-no-title', 'qs-no-title');
