.recap {
  &__header {
    padding: 6rem 4rem 4rem 4rem;
    &--title {
      text-transform: uppercase;
      font-size: 3.3rem;
      padding: 0;
      @include gridle_state(mobile) {
        font-size: 2.3rem;
      }
    }
    &--informations {
      padding-top: 2rem;
      &__label{
        font-weight: 100;
      }
      &__doc-container {
        background-color: #f7f7f7;
        ul {
          list-style-type: none;
          padding: 2rem 2rem;
          li {
            padding: 0.5rem 0rem;
            &::before {
              content: "•";
              color: #85d001;
              font-size: 4rem;
              padding-right: 1rem;
              line-height: 0;
              vertical-align: sub;
            }
          }
        }
      }
    }
  }
  &__arm-introduction {
    background-color: #f7f7f7;
    box-shadow: inset 0 3px 4px 0 rgba(0, 0, 0, 0.2);
  }
}
