@import '../../default/2_atoms/footer/footermenulink';

%at-footer__menuLink-skin-default {
  display: inline;
  margin: 0 7.9rem 0 0;
  padding: 0;

@include gridle_state(mobile){
	margin : 0 1rem 0 0; 

}
}

