@mixin appointment-header($selector) {
  #{$selector}__header {
    &-back__button {
      height: 35px;
      width: 35px;
      transform: rotate(0deg);
      padding: 0;
      background: none;
      border: none;
      @include gridle_state(mobile) {
        padding-bottom: 15rem;
      }
    }
    @extend %at-page-title-skin-default;
    border-left: 0;
    display: flex;
    align-items: center;
    margin: 0;
    &-title {
      align-items: center;
      display: flex;
      font-weight: bold;
      justify-content: center;
      padding: 1rem;
      @include gridle_state(mobile) {
        flex-direction: column-reverse;
        text-align: center;
        padding: 3rem;
        padding-bottom: 0rem;
      }
      &__label {
        @include gridle_state(mobile) {
          font-size: 20px;
          padding: 2rem;
          padding-bottom: 0rem;
          width: 31rem;
        }
      }
    }
    &-title-style {
      @include gridle_state(mobile) {
        padding-bottom: 0rem;
        padding-top: 0rem;
      }
    }
  }
}

%important-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.appointment {
  @include gridle_state(mobile) {
    padding: 0;
    background: white;
    margin-bottom: -3rem;
  }
}
