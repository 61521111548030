// Files list organism

%at-files-list-skin-default {
  @extend .grid-container;

  font-family: $font-regular;
  color: color-palette(tone-primary-dark);
  font-size: $font-size-big;
  text-align: center;
  margin: 0 auto 5rem;
}

@mixin files-list($selector, $skin: default) {
  #{$selector} {
    @extend %at-files-list-skin-#{$skin} !optional;

    &__title {
      color: color-palette(tone-primary);
      font-size: $font-size-normal;
      font-family: $font-medium;
      margin-bottom: 2.5rem;
    }

    &__title--valid {
       color: color-palette(tone-secondary);

       @include icon(choice-checked, after);
       &:after {
         float: right;
       }
    }

    &__title--invalid {
       color: color-palette(tone-error);
       margin-bottom: 1rem;

       @include icon(close, after);
       &:after {
         float: right;
       }
    }

    &__invalid-upload {
      color: color-palette(tone-warning);
      font-size: 1.5rem;
      padding-bottom: 2.5rem;
    }

    &__container {
      padding: 0;
      margin: 0;
    }

    &__action {
      font-size: 2rem;
      padding: 1rem;
      text-align: left;
      text-indent: 1rem;
      cursor: pointer;
    }

    &__file {
      display: block;
      line-height: 1;

      & + & {
        margin-top: 1rem;
      }
    }
  }
}

// Include component here
@include files-list('.files-list');
