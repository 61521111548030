// Declare skins here

%at-footer-navLink-skin-default {
  text-decoration: none;
  text-transform: none;
  color: color-palette(neutral);
  font-size: inherit;
}

%at-footer-navLink-skin-noButton {
  &:hover {
    border-bottom: .3rem solid color-palette(neutral);
  }
}

%at-footer-navLink-skin-button {
  @extend %at-button-skin-default;

  padding: .5rem 1rem;
  margin: -.5rem -1rem;
}

@mixin footer-navLink($selector, $skin: default) {
  #{$selector} {
    @extend %at-footer-navLink-skin-#{$skin} !optional;
  }
}

// Include component here
@include footer-navLink('.footer-navLink');
@include footer-navLink('.footer-navLink--noButton', 'noButton');
@include footer-navLink('.footer-navLink--button', 'button');
