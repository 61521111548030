@import "../default/0_utilities/defaults";

$font-size-normal: 1.4rem;
$font-size-medium: 1.6rem;
$font-size-big: 2.2rem;

.hidden {
  display: none;
}

.phone {
  font-weight: 600;
  &--green {
    color: color-palette(tone-secondary);
  }
}

.text-underline-success {
  text-decoration: underline;
  text-decoration-color: color-palette(tone-secondary-black);
  color: color-palette(tone-secondary-black);
}

.m-5 {
  margin: 5px;
}

.mt-15 {
  margin-top: 15px;
}

.bloc {
  display: block;
}
