%at-status-body-skin-default{
  border: 1px solid color-palette(neutral-grey-light);
  padding: 2.7rem 3.5rem;
  margin : 1.7rem 3.3rem 3.3rem;
  font-size: $font-size-medium;
  font-weight: 300;
  line-height: 1.9rem;
  display: flex;  
  display: -webkit-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap !important;


  @include gridle_state(mobile){
    justify-content: center;
    text-align: center;
    padding: 2.1rem 2.5rem;
    display: block;
  }

}

@mixin status-body($selector, $skin: default) {
  #{$selector} {
    @extend %at-status-body-skin-#{$skin} !optional;
    @if($skin==default){
      &__text{
        line-height: 2;
        display: flex;
        flex-grow: 1;
        margin-right: 2rem;
        @include gridle_state(tablet,mobile){
          margin-right: auto;
        }
      }

      &__button{
        display: flex;
        overflow-wrap: normal;
        align-self: center;
        @include gridle_state(mobile){
          display: block;
        }
      }
    }
  }
}

// Include component here
@include status-body('.dashboard-status-body');
