// Declare skins here

%at-faq-answer-skin-default {
  // Default skin here
  font-family: $font-regular;
  font-size: $font-size-normal;
  color: color-palette(tone-primary-dark);
  padding-left: 3rem;
  overflow: hidden;
  display:block;
  position: relative;
  padding-top: 1.3rem;
}

@mixin faq-answer($selector, $skin: default) {
  #{$selector} {
    @extend %at-faq-answer-skin-#{$skin} !optional;

    li {
      padding-left: 1em;
      text-indent: -.7em;
    }

    li:before {
      content: "• ";
      color:color-palette(tone-secondary);
    }
  }
}

// Include component here
@include faq-answer('.faq__answer');
