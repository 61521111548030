/* Clearfix
===========

Mixin to apply a clearfix

*/

@mixin clearfix {
  &:before, &:after { content: " "; display: table; }
  &:after { clear: both; }
}