// Declare skins here

%at-offer-show__price-skin-default {
  display: inline-block;
  line-height: 5rem;
  margin: 2rem 0;
}

%at-offer-show__price-skin-left {
  float: left;
}
%at-offer-show__price-skin-right {
  float: right;
}

@mixin offer-show__price($selector, $skin: default) {
  #{$selector} {
    @extend %at-offer-show__price-skin-#{$skin} !optional;

    &Big {
      font-size: 4rem;
      display: inline-block;
      vertical-align: bottom;
      font-family: $font-hw-bold;

      @include gridle_state(mobile) {
        font-size: 3rem;
      }

      &--secondary {
        color: color-palette(tone-secondary);
      }


    }

    &Medium {
      font-size: 1.5rem;
      display: inline-block;
      line-height: 1;
      margin: 0 1rem;
      vertical-align: baseline;
      max-width: 20rem;
      white-space: pre-line;

      @include gridle_state(mobile) {
        font-size: 1.2rem;
        display: block;
        max-width: none;
        margin: 1rem 0;
      }
    }
  }
}

// Include component here
@include offer-show__price('.offer-show__price');
@include offer-show__price('.offer-show__price--left','left');
@include offer-show__price('.offer-show__price--right','right');
