/* First Letter Uppercase
===========

Mixin to apply a text transform

*/

@mixin first-letter-uppercase {
  &::first-letter {
    text-transform: capitalize;
  }
  &:first-letter {
    text-transform: capitalize;
  }
}
