%at-waiting-page-skin-default {
  min-height: 30rem;
  padding: 6rem 4rem;
  box-shadow: inset 0 8px 8px -8px rgba(0, 0, 0, 0.6);
  @include gridle_state (mobile) {
    min-height: 0;
    padding: 2rem 1rem;
  }
}



@mixin waiting-page($selector, $skin: default) {
  #{$selector} {
    @extend %at-waiting-page-skin-#{$skin} !optional;

    &__iconblock--waiting {
      color : white;
    }

    &__message{
      padding : 2rem;
      @include gridle_state (mobile) {
        padding-right: 0;
      }
    }

    &__modal-content{
      display : table;

      p {
        display: table-cell;
      }
    }

    &--button{
      margin-bottom: 2rem;
    }

  }
}

// Include component here
@include waiting-page('.waiting-page');

