@mixin has-float-label{
  position: absolute;
  left: 0;
  bottom: 0.2rem;
  cursor: text;
  opacity: 1;
  transition: all .2s;
  @content;
}

%at-float-label-skin-default {
  @include has-float-label();

  label, span{
    cursor: text;
  }
}
%at-float-label-skin-span{
  span{
    @include has-float-label;
  }
}

%at-float-label-skin-pushed {
  bottom:2.4rem;
  cursor: default;

  label, span{
    cursor: default;
  }

}



@mixin float-label($selector, $skin: default) {
  #{$selector} {
    @extend %at-float-label-skin-#{$skin} !optional;
  }
}

// Include component here
@include float-label('.float-label');
@include float-label('.float-label--pushed', 'pushed');
@include float-label('.float-label--span', 'span');