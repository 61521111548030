%at-good-to-know-container-skin-default {
  // Default skin here
}

@mixin good-to-know-container($selector, $skin: default) {
  #{$selector} {
    @extend %at-good-to-know-container-skin-#{$skin} !optional;

    &__content {
      padding: 6rem 9rem;

      @include gridle_state (mobile) {
        padding: 1rem 0;
      }
    }

    &__bulb {
      display: block;
      float: left;
      margin-top: -6rem;
      margin-left: -1rem;
      width: 8.7rem;
      height: 20.2rem;
      background: url('/assets/images/bulb.png') 0 0 no-repeat;
    }
  }
}

// Include component here
@include good-to-know-container('.good-to-know-container');
