%at-contact-caption-skin-default {
  background-color: color-palette(neutral-light);
  display: table;
  margin: 0 3.3rem 4rem;
  padding: 2rem;

  @include gridle_state(mobile) {
    padding: 0 3rem 4rem;
    margin: 0 -1rem;
  }
}
@mixin contact-caption($selector, $skin: default) {
  #{$selector} {
    @extend %at-contact-caption-skin-#{$skin} !optional;

    &--image {
      display: table-cell;
      padding: 1rem 3rem;
      vertical-align: middle;
      width: 20%;

      @include gridle_state(mobile) {
        align-items: center;
        display: flex;
        height: 9rem;
        justify-content: center;
        width: 100%;
      }

      img {
        height: auto;
        min-width: 7rem;
        width: 9rem;
      }
    }

    &--caption {
      display: table-cell;
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 2.5rem;
      text-align: left;
      vertical-align: middle;
      width: 80%;

      @include gridle_state(mobile) {
        display: table-row;
        font-size: 1.4rem;
        height: 10rem;
        line-height: 2rem;
        text-align: center;
        width: 100%;
      }

      p {
        font-weight: 300;

        @include gridle_state(mobile) {
          padding: 1rem 0;
        }
      }

      span {
        text-transform: uppercase;
      }
    }
  }
}
@include contact-caption('.contact-caption');
