// Declare skins here

%at-dashboardprojects-skin-default {
  font-family: $font-regular;
  font-size: $font-size-normal;
  color: color-palette(tone-primary-dark);
  padding-bottom: 5rem;

}

@mixin dashboardprojects($selector, $skin: default) {
  #{$selector} {
    @extend %at-dashboardprojects-skin-#{$skin} !optional;

    @if $skin == default{

      &__title {
        color: color-palette(tone-primary);
        font-family: $font-medium;
        margin: 4rem 0 2rem 0;

        .custom-icon {
          display: inline-block;
          vertical-align: middle;
          margin-right: 1rem;
        }
      }

      &__document-icon {
        @include icon(document);
        font-size: 3rem;
        margin-right: 1rem;
        vertical-align: middle;

         @include gridle_state(mobile){
            margin-right: 0.5rem;
          }
      }
  }

  }
}

// Include component here
@include dashboardprojects('.dashboard__projects');
