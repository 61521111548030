// Declare skins here

%at-footer-skin-default {
  // Default skin here
  font-family: $font-regular;
  font-size: $font-size-normal;
  background-color: color-palette(tone-primary);
  padding-top:7.6rem;
}

@mixin footer($selector, $skin: default) {
  #{$selector} {
    @extend %at-footer-skin-#{$skin} !optional;

    &__content {
      @include clearfix();
      padding-bottom: 10rem;
    }

    @include gridle_state(mobile) {
      padding: 1rem;
      height: auto;

      &__content {
        padding-bottom: 1rem;
        text-align: center;

        &:after {
          display: none;
        }
      }
    }
  }
}

// Include component here
@include footer('.footer');
