// Declare skins here

%at-search-button-skin-default {
  border: 0;
  background: 0;
  outline: 0;
  font-size: 2.3rem;
  color: color-palette(tone-primary);

  @include gridle_state(header-mobile mobile){
    line-height: 6rem;
    padding: 0.5rem;
    float: right;
  }
}

%at-search-button-skin-neutral{
  color: color-palette(neutral);
}

@mixin search-button($selector, $skin: default) {
  #{$selector} {
    @extend %at-search-button-skin-#{$skin} !optional;
  }
}

// Include component here
@include search-button('.search-button');
@include search-button('.search-button--neutral', 'neutral');
