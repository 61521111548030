%at-next-step-introduction-skin-default {
  padding: 2rem 3.3rem;

  @include gridle_state(mobile) {
    padding: 0 1.3rem;
  }

  & .download-block {
    background-color: color-palette(neutral-light);
    margin: 0;
    padding-left: 4.8rem;
    padding-right: 3.8rem;

    @include gridle_state(mobile) {
      margin: 0 -3rem;
      width: auto;
    }

    &--info {
      @include gridle_state(mobile) {
        left: -0.6rem;
        margin: 0 -3rem 0rem;
        position: relative;
        width: auto;
      }
    }

    &--text {
      @include first-letter-uppercase();

      color: color-palette(neutral-grey-black);
      font-size: 1.8rem;
      line-height: 2.7rem;

      @include gridle_state(mobile) {
        font-size: 1.4rem;
        line-height: 2rem;
        padding: 0 3rem 0 5rem;
      }
      @include gridle_state(mobile-xs) {
        padding: 0 2rem 0 4rem;
      }
    }

    &--button {
      button {
        max-width: 30rem;
        position: relative;
        width: 100%;

        @include gridle_state(mobile) {
          left: -1rem;
          max-width: 27rem;
        }
        @include gridle_state(mobile-xs) {
          left: 0;
          min-width: auto;
        }
      }
    }
  }
  & .contact-caption {
    margin: 1rem 0 3.5rem;

    @include gridle_state(mobile-xs) {
      margin-bottom: 2rem;
    }

    &--image {
      & img {
        width: 9rem;
      }
    }
  }
}

%at-next-step-introduction-skin-page {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/assets/images/background-3.jpg) no-repeat center center fixed;
  background-size: cover;
  @include gridle_state(mobile) {
    background: none !important;
    padding-bottom: 4.5rem;
  }

  & .adhesion-stepper {
    display: none;
  }
}

@mixin next-step-introduction($selector, $skin: default) {
  #{$selector} {
    @extend %at-next-step-introduction-skin-#{$skin} !optional;
    @if $skin == default {
      &__title {
        font-size: 3rem;
        font-weight: 600;
        margin: 1rem;
        text-transform: uppercase;
        text-align: center;
  
        @include gridle_state(mobile) {
          font-size: 2rem;
          padding: 0 3.3rem;
          text-align: center;
        }
        @include gridle_state(mobile-xs) {
          padding: 0;
        }
      }
      &__descriptions {
        &__description {
          &__icon {
            margin-right: 1.5rem;
            height: 3.9rem;
            min-width: 3.9rem;
            background-color: #f7f7f7;
            box-shadow: 0 1px 0 0 rgba(167, 167, 167, 0.5);
            border-radius: 50%;
            padding: 0.9rem;
            &--heart {
              padding: 0 0.5rem;
              padding-top: 0.4rem;
            }
          }
          display: flex;
          align-items: center;
          font-weight: 600;
          margin: 2rem 0;
          width: 88%;
  
          @include gridle_state(mobile) {
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 2rem;
            padding: 0 2rem;
            width: 100%;
          }
          @include gridle_state(mobile-xs) {
            padding: 0;
          }
  
          @include first-letter-uppercase();
        }
      }
      &__method {
        padding: 3.2rem 1rem 1rem;
  
        @include gridle_state(mobile) {
          margin: 0 -1rem;
          width: auto;
        }
  
        &-title {
          color: #666666;
          font-size: 1.8rem;
          padding: 1rem 0;
          text-align: center;
  
          @include gridle_state(mobile) {
            font-size: 1.6rem;
          }
  
          @include first-letter-uppercase();
        }
        &-item {
          align-items: center;
          display: flex;
          flex-direction: column;
          padding: 2.2rem 1rem;
  
          @include gridle_state(mobile) {
            padding-left: 0;
            padding-right: 0;
          }
  
          &_icon {
            width: 5rem;
            height: 5rem;
            margin-bottom: 2rem;
  
            &--monitor {
              background: url("/assets/images/icon-monitor.svg") no-repeat;
              background-size: 5rem;
            }
            &--phone {
              background: url("/assets/images/icon-shake-phone.svg") no-repeat;
              background-size: 4.5rem;
            }
            &--message {
              background: url("/assets/images/mail-icon-black.svg") no-repeat;
              background-size: 5rem;
              background-size: 7rem;
              width: 8rem;
            }
          }
  
          &_label {
            font-weight: 600;
            text-align: center;
  
            @include gridle_state(mobile) {
              font-size: 1.2rem;
              font-weight: 400;
              min-width: 10rem;
              text-align: center;
            }
            @include gridle_state(mobile-xs) {
              min-width: 8rem;
            }
  
            @include first-letter-uppercase();
          }
        }
      }
  
      &__back-button {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
  
        button {
          min-width: 33rem;
  
          @include gridle_state(mobile) {
            min-width: 100%;
          }
        }
      }
    }
  }
}

@include next-step-introduction(".next-step-introduction");
@include next-step-introduction(".next-step-introduction-page", "page");
