@font-face {
  font-family: "CandIcons", sans-serif;
  src: url('/assets/fonts/CandIcons.eot');
  src: url('/assets/fonts/CandIcons.eot?#iefix') format('eot'),
  url('/assets/fonts/CandIcons.woff') format('woff'),
  url('/assets/fonts/CandIcons.ttf') format('truetype'),
  url('/assets/fonts/CandIcons.svg#CandIcons') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Bugfix for Chrome Windows rendering: http://stackoverflow.com/a/14345363
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "CandIcons", sans-serif;
    src: url('/assets/fonts/CandIcons.svg#CandIcons') format('svg');
  }
}

@mixin iconGenerator() {
  font-family: "CandIcons", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include iconGenerator();
}

@function icon-char($filename) {
  $char: "";

@if $filename == add {
$char:"\f101";
} 
@if $filename == archive {
$char:"\f102";
} 
@if $filename == arrow-down {
$char:"\f103";
} 
@if $filename == arrow-up {
$char:"\f104";
} 
@if $filename == assurance {
$char:"\f105";
} 
@if $filename == bibliotheque {
$char:"\f106";
} 
@if $filename == bibliotheque2 {
$char:"\f107";
} 
@if $filename == burger {
$char:"\f108";
} 
@if $filename == checkmark {
$char:"\f109";
} 
@if $filename == chevron-down {
$char:"\f10a";
} 
@if $filename == chevron-up {
$char:"\f10b";
} 
@if $filename == choice-checked {
$char:"\f10c";
} 
@if $filename == close {
$char:"\f10d";
} 
@if $filename == delete {
$char:"\f10e";
} 
@if $filename == doc-upload {
$char:"\f10f";
} 
@if $filename == donnee-bancaire {
$char:"\f110";
} 
@if $filename == download {
$char:"\f111";
} 
@if $filename == edit {
$char:"\f112";
} 
@if $filename == facebook {
$char:"\f113";
} 
@if $filename == feedback {
$char:"\f114";
} 
@if $filename == fullscreen {
$char:"\f115";
} 
@if $filename == ic_less {
$char:"\f116";
} 
@if $filename == ic_more {
$char:"\f117";
} 
@if $filename == loading {
$char:"\f118";
} 
@if $filename == mail {
$char:"\f119";
} 
@if $filename == pin-rose {
$char:"\f11a";
} 
@if $filename == pin-vert {
$char:"\f11b";
} 
@if $filename == recherche {
$char:"\f11c";
} 
@if $filename == save {
$char:"\f11d";
} 
@if $filename == select-track-left {
$char:"\f11e";
} 
@if $filename == select-track-right {
$char:"\f11f";
} 
@if $filename == select-track-up {
$char:"\f120";
} 
@if $filename == select-track {
$char:"\f121";
} 
@if $filename == sinistre {
$char:"\f122";
} 
@if $filename == slide_bas {
$char:"\f123";
} 
@if $filename == slide_haut {
$char:"\f124";
} 
@if $filename == suivre {
$char:"\f125";
} 
@if $filename == tableau-banque {
$char:"\f126";
} 
@if $filename == tableau-bords {
$char:"\f127";
} 
@if $filename == testimony {
$char:"\f128";
} 
@if $filename == toggle-faq {
$char:"\f129";
} 
@if $filename == twitter {
$char:"\f12a";
} 
@if $filename == arrow-down-border {
$char:"\f12b";
} 
@if $filename == arrow-right {
$char:"\f12c";
} 
@if $filename == arrow-top {
$char:"\f12d";
} 
@if $filename == certif {
$char:"\f12e";
} 
@if $filename == circle-arrow {
$char:"\f12f";
} 
@if $filename == download-line {
$char:"\f130";
} 
@if $filename == exit {
$char:"\f131";
} 
@if $filename == info-carre {
$char:"\f132";
} 
@if $filename == info {
$char:"\f133";
} 
@if $filename == notification {
$char:"\f134";
} 
@if $filename == people {
$char:"\f135";
} 
@if $filename == person {
$char:"\f136";
} 
@return $char;
}

@mixin icon($filename, $insert: before) {
  &:#{$insert} {
    @extend %icon;
    content: icon-char($filename);
    font-family: "CandIcons", sans-serif;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
  }
}

.icon--add {
  @include icon(add);
}
.icon--archive {
  @include icon(archive);
}
.icon--arrow-down {
  @include icon(arrow-down);
}
.icon--arrow-up {
  @include icon(arrow-up);
}
.icon--assurance {
  @include icon(assurance);
}
.icon--bibliotheque {
  @include icon(bibliotheque);
}
.icon--bibliotheque2 {
  @include icon(bibliotheque2);
}
.icon--burger {
  @include icon(burger);
}
.icon--checkmark {
  @include icon(checkmark);
}
.icon--chevron-down {
  @include icon(chevron-down);
}
.icon--chevron-up {
  @include icon(chevron-up);
}
.icon--choice-checked {
  @include icon(choice-checked);
}
.icon--close {
  @include icon(close);
}
.icon--delete {
  @include icon(delete);
}
.icon--doc-upload {
  @include icon(doc-upload);
}
.icon--donnee-bancaire {
  @include icon(donnee-bancaire);
}
.icon--download {
  @include icon(download);
}
.icon--edit {
  @include icon(edit);
}
.icon--facebook {
  @include icon(facebook);
}
.icon--feedback {
  @include icon(feedback);
}
.icon--fullscreen {
  @include icon(fullscreen);
}
.icon--ic_less {
  @include icon(ic_less);
}
.icon--ic_more {
  @include icon(ic_more);
}
.icon--loading {
  @include icon(loading);
}
.icon--mail {
  @include icon(mail);
}
.icon--pin-rose {
  @include icon(pin-rose);
}
.icon--pin-vert {
  @include icon(pin-vert);
}
.icon--recherche {
  @include icon(recherche);
}
.icon--save {
  @include icon(save);
}
.icon--select-track-left {
  @include icon(select-track-left);
}
.icon--select-track-right {
  @include icon(select-track-right);
}
.icon--select-track-up {
  @include icon(select-track-up);
}
.icon--select-track {
  @include icon(select-track);
}
.icon--sinistre {
  @include icon(sinistre);
}
.icon--slide_bas {
  @include icon(slide_bas);
}
.icon--slide_haut {
  @include icon(slide_haut);
}
.icon--suivre {
  @include icon(suivre);
}
.icon--tableau-banque {
  @include icon(tableau-banque);
}
.icon--tableau-bords {
  @include icon(tableau-bords);
}
.icon--testimony {
  @include icon(testimony);
}
.icon--toggle-faq {
  @include icon(toggle-faq);
}
.icon--twitter {
  @include icon(twitter);
}
.icon--arrow-down-border {
  @include icon(arrow-down-border);
}
.icon--arrow-right {
  @include icon(arrow-right);
}
.icon--arrow-top {
  @include icon(arrow-top);
}
.icon--certif {
  @include icon(certif);
}
.icon--circle-arrow {
  @include icon(circle-arrow);
}
.icon--download-line {
  @include icon(download-line);
}
.icon--exit {
  @include icon(exit);
}
.icon--info-carre {
  @include icon(info-carre);
}
.icon--info {
  @include icon(info);
}
.icon--notification {
  @include icon(notification);
}
.icon--people {
  @include icon(people);
}
.icon--person {
  @include icon(person);
}

