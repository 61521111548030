%at-form-block-anim-skin-default {
  &.ng-enter {
    transition: opacity linear 0.5s;
    opacity: 0;
  }

  &.ng-enter.ng-enter-active {
    opacity: 1;
  }
}

@mixin form-block-anim($selector, $skin: default) {
  #{$selector} {
    @extend %at-form-block-anim-skin-#{$skin} !optional;
  }
}

// Include component here
@include form-block-anim('.form-block-anim');
