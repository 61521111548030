// Declare skins here

%at-fade-top-skin-default {
  &.ng-enter {
    opacity: 0;
    transform: translate3d(0, -1.5rem, 0);
    transition: all .5s ease-in-out;

    &-active {
      opacity: 1;
      transform: none;
    }
  }
}

@mixin fade-top($selector, $skin: default) {
  #{$selector} {
    @extend %at-fade-top-skin-#{$skin} !optional;
  }
}

// Include component here
@include fade-top('.fade-top');
