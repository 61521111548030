@import '../default/3_molecules/dashboardrecap';

%at-dashboard-recap-skin-default {
}
  .dashboard-recap__list {
    text-align: center;
    text-transform: uppercase;
  }

  .dashboard-recap__item {
    color: inherit;
    text-align: center;
    & + & {
      margin-top: 0;
    }
    margin-bottom: 1.3rem;
  }

  .dashboard-recap__title {
    font-weight: 700;
    padding: 0.8rem 1.4rem;
    margin-bottom: 0;
    margin-top: 3.2rem;
    color: inherit;
    font-size: $font-size-normal;
    text-align: left;

    @include gridle_state(mobile){
      text-align:center;
      margin-top: 1.2rem;
    }
  }

  .dashboard-recap__value {
    color: color-palette(tone-secondary);
    font-weight: bold;
      &--breakline::before{
        @include gridle_state(mobile){
        content:'\A';
        white-space:pre;
      }
    }

  }
.dashboard-recap__action{
  margin-top: 0;
  padding: 1rem;
}


