// Declare skins here

%at-projects-skin-default {
  // Default skin here
  color: color-palette(tone-secondary-dark);
  font-size: 1.6rem;
  font-family: $font-medium;
}

@mixin projects($selector, $skin: default) {
  #{$selector} {
    @extend %at-projects-skin-#{$skin} !optional;
    @if($skin == default){
      &__action{
        text-align: left;
        margin-top: 2rem;
      }
    }
  }
}

// Include component here
@include projects('.projects');
