// Declare skins here

%at-footer-navigation-skin-default {
  // Default skin here
  background-color: transparent;
  width: 63%;

  @include gridle_state(mobile) {
    position: inherit;
  }
}


@mixin footer-navigation($selector, $skin: default) {
  #{$selector} {
    @extend %at-footer-navigation-skin-#{$skin} !optional;

    &_list {
      list-style-type: none;
      white-space: nowrap;
    }

    &_share {
      margin-top:8rem;
      @include gridle_state(mobile) {
        margin: 3rem 0;
      }
    }

    &_item {
      display: inline-block;
      margin: 0 2rem;
      font-size: $font-size-normal;

      @include gridle_state(desktop-small) {
        font-size: 1.5rem;
        margin: 0 1rem;
      }

      @include gridle_state(mobile){
        margin: 1rem 0;
        padding: 0;
      }

      &--no-margin{
        margin: 0;
      }

      &:first-child{
        margin-left: 0;
      }
    }

    @include gridle_state(mobile) {
      padding: 1rem;
      height: auto;
    }
  }
}

// Include component here
@include footer-navigation('.footer__navigation');
