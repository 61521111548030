// Declare skins here

%at-faq-bullet-skin-default {
  // Default skin here
  width:2rem;
  height:2rem;
  border: solid 0.1rem color-palette(tone-primary-dark);
  border-radius: 50%;
  color:color-palette(tone-primary-dark);
  background-color: color-palette(neutral);
  @extend .icon--select-track;
  margin-right: 0.8rem;
  font-size: 1.2rem;
  display: inline-block;
  position: relative;
  top: -0.4rem;

  &:before{
    position : relative;
    left:0.3rem;
    top:0.2rem;
  }


}

%at-faq-bullet-skin-selected {
  // Default skin here
  border-color: color-palette(tone-secondary);
  color:color-palette(neutral);
  background-color: color-palette(tone-secondary);
  transform: scaleY(-1);

}

@mixin faq-bullet($selector, $skin: default) {
  #{$selector} {
    @extend %at-faq-bullet-skin-#{$skin} !optional;
  }
}

// Include component here
@include faq-bullet('.faq-bullet');
@include faq-bullet('.faq-bullet--selected', 'selected');
