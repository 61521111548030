// Declare skins here

%at-choice-box-skin-default {
    display: inline-block;
    text-align: center;
    position: relative;

    .choice-box__label {
        &:after {
            top: 0;
            left: 50%;
            transform: translate3d(-50%, -25%, 0) scale(0.5);
        }
    }

    .choice:focus ~ .choice-box__label:after {
        transform: translate3d(-50%, -25%, 0);
    }
}

%at-choice-box-skin-button {
    display: table;
    height: 5.5rem;
}

%at-choice-box-skin-buttons {
    display: flex;
    margin-right: 4rem;
    justify-content: space-around;
    @media screen and (max-width: 768px) {
        padding-top: 1.5rem;

    }
}

%at-choice-box-skin-buttonFull {
    @extend %at-choice-box-skin-button;
    width: 100%;
}

%at-choice-box-skin-multiline {
    margin-bottom: 2rem;
}

%at-choice-box-skin-table {
    .choice-box__label {
        display: table-row;
    }

    span {
        display: table-cell;
        vertical-align: bottom;
    }
}

%at-choice-box-skin-inline {
    .choice-box__label {
        width: 100%;

        &::before {
            float: left;
            vertical-align: top;
            overflow: hidden;
        }

        &:after {
            top: 0;
            left: 0;
            transform: translate3d(-25%, -25%, 0) scale(0.5);
        }

        span {
            // Media Object tricks
            display: inline-block;
            overflow: hidden;
        }
    }

    .choice-box__label.choice-box__label--frame {
        &:after {
            transform: translate3d(8%, 8%, 0) scale(0.5);
        }
    }

    .choice:focus ~ .choice-box__label:after {
        transform: translate3d(-25%, -25%, 0);
    }

    .choice:focus ~ .choice-box__label.choice-box__label--frame:after {
        transform: translate3d(8%, 8%, 0);
    }
}

%at-choice-box-skin-alone {
    margin: 3rem 0 0 0;
}

%at-choice-box-skin-inline-left {
    @extend %at-choice-box-skin-inline;

    .choice-box__label {
        text-align: justify;
        line-height: 2.9rem;

        &:before {
            margin: 0.3rem 1rem 0 0;
        }

        &:after {
            margin-top: 0.3rem;
        }
    }
}
%at-choice-box-skin-table-left {
    //solution to avoid the label to break after the checkbox when text is long
    // Using table display was moving the "after" effect around the checkbox
    @extend %at-choice-box-skin-inline-left;
    .choice-box__label {
        span {
            max-width: 95%;
            @include gridle_state(mobile) {
                max-width: 85%;
            }
        }
    }
}

%at-choice-box-skin-int {
    width: 6rem;

    & + & {
        margin-left: 2rem;
    }
}

%at-choice-box-skin-fitContent {
    .choice-box__label {
        padding: 0 2.5rem;
    }

    & + & {
        margin-left: 2rem;
    }
}

%at-choice-box-skin-full {
    display: block;
}

%at-choice-box-skin-frame {
    margin-left: -1.7rem;

    .choice-box__label {
        &:after {
            margin: 0.7rem 0 0 0.3rem;
        }
    }

    @include gridle_state(mobile) {
        margin-left: 0;
    }
}

@mixin choice-box($selector, $skin: default) {
    #{$selector} {
        @extend %at-choice-box-skin-#{$skin} !optional;
    }
}

// Include component here
@include choice-box('.choice-box');
@include choice-box('.choice-box--button', 'button');
@include choice-box('.choice-box--buttons', 'buttons');
@include choice-box('.choice-box--buttonFull', 'buttonFull');
@include choice-box('.choice-box--multiline', 'multiline');
@include choice-box('.choice-box--inline', 'inline');
@include choice-box('.choice-box--table', 'table');
@include choice-box('.choice-box--inline-left', 'inline-left');
@include choice-box('.choice-box--table-left', 'table-left');
@include choice-box('.choice-box--alone', 'alone');
@include choice-box('.choice-box--int', 'int');
@include choice-box('.choice-box--fitContent', 'fitContent');
@include choice-box('.choice-box--full', 'full');
@include choice-box('.choice-box--frame', 'frame');
