%stepper-separator {
  z-index: 0;
  content: "";
  position: absolute;
  top: 1.6rem;
  width: 50%;
  height: 0.4rem;
  background-color: color-palette(tone-secondary-black);

  @include gridle_state(mobile) {
    top: 1.4rem;
  }
}

%at-stepper-skin-default {
  position: relative;
  flex-basis: 25%;

  &:before {
    @extend %stepper-separator;
    left: 0;
  }

  &:after {
    @extend %stepper-separator;
    right: 0;
  }
}

%at-stepper-skin-active {
  .stepper__index {
    color: color-palette(tone-secondary-black);
    border-color: color-palette(neutral);
    background-color: color-palette(neutral);
  }

  .stepper__name {
    color: color-palette(neutral);
  }
}

%at-stepper-skin-full {
  flex-basis: 20%;
}

@mixin stepper($selector, $skin: default) {
  #{$selector} {
    @extend %at-stepper-skin-#{$skin} !optional;

    @if ($skin == default) {
      &__index {
        position: relative;
        z-index: 1;
        display: inline-block;
        width: 3.8rem;
        height: 3.8rem;
        border: 0.4rem solid color-palette(tone-secondary-black);
        background-color: color-palette(tone-secondary-black);
        font-size: 1.8rem;
        border-radius: 50%;
        line-height: 1.6;
        color: color-palette(tone-secondary);

        @include gridle_state(mobile) {
          width: 3rem;
          height: 3rem;
          font-size: $font-size-medium;
          line-height: 1.4;
        }
      }

      &__name {
        font-size: $font-size-normal;
        color: color-palette(tone-secondary-black);

        @include gridle_state(mobile) {
          display: none;
        }

        &Mobile {
          display: none;
          @include gridle_state(mobile) {
            display: block;
            margin: 0 0 2rem 0;
            padding-bottom: 1.2rem;
            background-color: rgba(color-palette(tone-secondary), 0.9);
            font-size: $font-size-normal;
            color: color-palette(neutral);
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

// Include component here
@include stepper(".stepper");
@include stepper(".stepper--active", "active");
@include stepper(".stepper--full", "full");
