@import '../../default/2_atoms/modal/modal-overlay';

%at-modal-overlay-skin-default {
	padding : 0;
	border-radius : 6px;
	top : 45%;
	transform : translateY(-50%);
  max-height: 72%;
}

%at-modal-overlay-skin-welcome {
  max-height: 72%;
  min-height: 20rem;
  top: 50%;
  @include gridle_state(mobile) {
    max-height: 92%;
    min-height: 0;
  }
}

%at-modal-overlay-skin-small {
	padding : 0;
	border-radius : 6px;
	top : 50%;
	transform : translateY(-50%);
  width: 60rem;
  @include gridle_state(mobile) {
    width: 98%;
  }
}

@mixin modal-overlay($selector, $skin: default) {
  #{$selector} {
    @extend %at-modal-overlay-skin-#{$skin} !optional;
  }
}

// Include component here
@include modal-overlay('.cand-modal__overlay--welcome','welcome');
@include modal-overlay('.cand-modal__overlay--small','small');
