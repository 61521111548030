@mixin map() {
  [id*="map_"] {
    height: 455px;
    width: 100%;
  }
  .map-legend {
    box-sizing: border-box;
    height: 28px;
    width: 180px;
    border: 0.5px solid #e2e0e0;
    background-color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 8px;
    &__label {
      display: inline-flex;
      align-items: center;
      color: #000000;
      font-size: 9px;
      line-height: 11px;
    }
    &__icon {
      margin-right: 0.5rem;
      height: 10px;
      width: 10px;
      display: inline-flex;
      border-radius: 50%;
      &.pink {
        background-color: #fc0081;
      }
      &.green {
        background-color: #85d001;
      }
    }
  }
}
@include map();
