%at-modal-overlay-skin-default {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 4%;
  background-color: color-palette(popin-background);
  width: 90rem;
  z-index: 100;
  max-height: 85%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @include gridle_state(tablet) {
    width: 88%;
    padding: 2rem 2%;
  }
}

%at-modal-overlay-skin-centered {
  text-align: center;
}

%at-modal-overlay-skin-loader {
  background-color: #ffffff;
  border-radius: 2.4rem !important;
  box-shadow: 0 1px 72px rgba(0, 0, 0, 0.5);
  height: auto;
  width: 18rem;

  .cand-modal__content {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 2rem !important;
    &--success {
      background-color: #84d000;
      p {
        color: white;
      }
    }
    p {
      color: #666666;
      font-size: 1.2rem;
      font-weight: 400;
      text-align: center !important;
    }

    .loader {
      height: 3rem;
      width: 3rem;
      &--success {
        height: 5rem;
        width: 5rem;
        background-image: url("/assets/images/check-circle-white.png");
        animation: none;
      }
    }
  }
}

@mixin modal-overlay($selector, $skin: default) {
  #{$selector} {
    @extend %at-modal-overlay-skin-#{$skin} !optional;

    @if $skin == default {
      &__close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        color: color-palette(popin-tone-secondary);
        cursor: pointer;
      }
    }
  }
}

// Include component here
@include modal-overlay(".cand-modal__overlay");
@include modal-overlay(".cand-modal__overlay--centered", "centered");
@include modal-overlay(".cand-modal__overlay--loader", "loader");
