// Declare skins here
%at-simulation__paragraph-skin-default {
  @include clearfix();
  font-family: $font-regular;
  color: color-palette(tone-primary-dark);
  font-size: 1.6rem;
  text-align: center;
  line-height: 2.2rem;
  list-style-type: none;
  padding: 0 0 4.3rem;
  margin: 0;
}

%at-simulation__paragraph-skin-question {
  font-size: $font-size-big;
  line-height: 3.4rem;
  color: color-palette(tone-primary);
  padding-bottom: 2.2rem;
}

// Parcours médical - Consentements médicaux et de sélection complémentaire : Mise en page des paragraphes.
%at-simulation__paragraph-skin-consents,
%at-simulation__paragraph-skin-additional-selection {
  font-size: $font-size-normal;
  line-height: 2.9rem;
  padding-bottom: 2.8rem;
}

%at-simulation__paragraph-skin-footer {
  padding: 4.3rem 0 0 0;
}

%at-simulation__paragraph-skin-notice {
  font-size: 1.5rem;
  margin-top: -4rem;
  margin-right: 10%;
}

%at-simulation__paragraph-skin-article {
  margin-top: 2rem;
}

%at-simulation__paragraph-skin-question-preambule {
  text-align: left;
  padding: 0 0 2.3rem 0;
  font-size: $font-size-normal;
  font-weight: bold;

  @include gridle_state(mobile){
    text-align: center;
  }
}

%at-simulation__paragraph-skin-documentary-space {
  font-size: $font-size-normal;
  line-height: 2.9rem;
  color: color-palette(tone-primary);
  font-family: $font-medium;
}

%at-simulation__paragraph-skin-left {
  text-align:left;
}

@mixin simulation__paragraph($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__paragraph-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__paragraph('.simulation__paragraph');
@include simulation__paragraph('.simulation__paragraph--question','question');
@include simulation__paragraph('.simulation__paragraph--consents','consents');
@include simulation__paragraph('.simulation__paragraph--footer','footer');
@include simulation__paragraph('.simulation__paragraph--additional-selection','additional-selection');
@include simulation__paragraph('.simulation__paragraph--documentary-space','documentary-space');
@include simulation__paragraph('.simulation__paragraph--question-preambule','question-preambule');
@include simulation__paragraph('.simulation__paragraph--notice','notice');
@include simulation__paragraph('.simulation__paragraph--article','article');
@include simulation__paragraph('.simulation__paragraph--left','left');
