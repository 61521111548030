// Declare skins here

%at-user-menu-skin-default {
  // Default skin here
  background-color: color-palette(neutral);
  width: 41rem;
  border-top: solid 0.1rem color-palette(tone-primary);
  box-shadow: 0rem 0.8rem 1.5rem 0.4rem rgba(0,0,0,0.1);
  position: absolute;
  right: 10rem;
  padding: 4.5rem 5rem;
  z-index: 100;
  transition: opacity 0.3s, visibility 0.3s;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-100%);

  .page-subtitle{
    text-align: center;
    margin-bottom: 4.5rem;

    &--small{
      font-size: 2rem;
    }
  }

  .button {
    margin-top: 6rem;
  }


 @include gridle_state(mobile){
   position: fixed;
    width: 100%;
    right: 0;
    left: 0;
    top: 8rem;
    padding: 2rem 1rem;

    .page-subtitle{
      margin-bottom: 3rem;
    }

    .button {
      margin-top: 2rem;
    }
  }

  @include gridle_state(tablet, mobile) {
    transform: translateX(0);
  }
}

@mixin user-menu($selector, $skin: default) {
  #{$selector} {
    @extend %at-user-menu-skin-#{$skin} !optional;

    &.opened{
      opacity: 1;
      visibility: visible;
    }

    &__listItem {
      font-size: $font-size-normal;
      color: color-palette(tone-primary);
      height: 5.1rem;
      padding: 1rem 2rem;

      &:hover{
        background-color: color-palette(tone-primary);
        color: color-palette(neutral);
      }

      i{
        margin-right: 2.5rem;
        font-size: 2.5rem;
      }

      a {
        text-decoration: none;
        color: inherit;
        & > * {
          display: inline-block;
          vertical-align: middle;
        }
      }

      @include gridle_state(header-mobile,mobile){
        height: 4rem;
        padding-left:1rem;
        margin:0;
        text-align:left;
        i{
          margin-right:0;
        }
      }
    }
    &__username{
      text-align:left;
      padding-left:1rem;
      padding-top:2rem;
      color: color-palette(tone-primary);
      font-size: 1.8rem;
      font-family: $font-medium;

      nav{
        padding:2rem 0;
      }

      i{
        color: color-palette(tone-secondary);
      }
      span{
        position:relative;
        &:after{
            content: "";
            display: block;
            height: 2px;
            width: 100%;
            background-color: #000a8b;
            position: absolute;
            bottom: -1rem;
            right:0;
        }
      }
    }

    &__connexion{
      padding: 2rem;
      &--logout{
        padding:1rem;
      }
    }
  }
}

// Include component here
@include user-menu('.usermenu-box');
