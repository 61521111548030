%at-action-modal-skin-default{

}
@mixin action-modal($selector, $skin: default) {
  #{$selector} {
    @extend %at-action-modal-skin-#{$skin} !optional;
    &__iconblock {
      color : white;
    }
    &__message{
      padding : 2rem;
    }
    &__content{
      display : table;
    }

    &__iconblock, &__message {
      display: table-cell;
      vertical-align: middle;
    }
  }
}
@include action-modal('.action-modal')