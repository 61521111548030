// Declare skins here

%at-footer__menuLink-skin-default {
  color: rgba(color-palette(neutral), 1);
  text-decoration: none;

  &:hover {
    color: rgba(color-palette(neutral), 0.6);
  }
}

@mixin footer__menuLink($selector, $skin: default) {
  #{$selector} {
    @extend %at-footer__menuLink-skin-#{$skin} !optional;
  }
}

// Include component here
@include footer__menuLink('.footer__menuLink');
