@import '../default/3_molecules/choicebox';

%at-choice-box-skin-button {
  height: 3.8rem;
}

%at-choice-box-skin-inline {
  .choice-box__label {
    span {
      display: inline;
    }
  }
}
