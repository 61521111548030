@import '../../default/2_atoms/simulation/simulationlistitem';

%at-simulation__listItem-skin-qs-summary {
  margin-bottom: 3.5rem;

  &:last-child {
    margin-bottom: 2rem;
  }

  div.list-digit--sub {
    position: relative;

    &:before {
      content: '\2022';
      display: block;
      position: absolute;
      left: 10rem;
      top: 0;
      margin-top: -18px;
      color: color-palette(neutral-grey-darkest);
      font-size: 35px;
      @include gridle_state(mobile){
        left:2rem;
        margin-top: 0;
      }
    }

    .list-digit__target {
      padding : 0 6.6rem 3rem 10rem;

      .question-title {
        padding-left: 2.1rem;
        @include gridle_state(mobile){
          padding-left: 2rem;
        }
      }

      @include gridle_state(mobile){
        padding: 0 2rem 2rem;
      }
    }
  }

  .sub-questions {
    margin-top: 0;
    padding-left: 0;
    color: color-palette(neutral-grey-darker);
    font-size: $font-size-normal;
    font-weight: 700;
  }

  .complex-question{
    padding : 0rem 6.6rem 3rem 10rem;
    background-color: color-palette(neutral-light);
    box-shadow: inset 1px 1px 3px rgba(color-palette(neutral-grey), 0.5), inset -1px -1px 3px rgba(color-palette(neutral-grey), 0.5);
    @include gridle_state(mobile){
      padding: 0 2rem 2rem;
    }

    .complex-question__content{

      &:not(:first-child){
        padding-top:0;
      }
    }
    .complex-question__intro{
      text-align: right;
      margin-bottom: 0;
      &:after{
        color : color-palette(tone-error);
      }
      @include gridle_state(mobile){
        text-align:left;
        margin-top:2rem;
      }
    }
    &::before{
      content: '';
      display: table;
      position: relative;
      top: -1rem;
      border-left: 2rem solid white;
      border-bottom: 2rem solid white;
      background: white;
      transform: rotate(-45deg);
      box-shadow: -2px 2px 3px -1px rgba(204, 204, 204, 0.5);

      @include gridle-state(mobile){
        margin:0 auto;
      }

    }

}
  @include gridle_state (mobile) {
    margin-bottom: 2.5rem;

    div {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }

    .buttons{
      .yesNo-button {
        width: 10rem;
      }
    }
  }
}
