@import '../default/4_organisms/dashboardheader';

%at-dashboard__header-skin-default {
  text-align: left;
  color: color-palette(neutral);
  margin-bottom: 6.6rem;

  @include gridle_state(mobile){
    margin-bottom: 3.1rem;
  }

  .dashboard__header_title {
    font-family: $font-hw-regular;
    font-size: 4rem;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }

  .dashboard__header_text {
    font-size: $font-size-medium;
  }
}
