%at-password-meter-skin-default {
  // Default skin here

  font-size: 0;
  list-style: none;
  margin: 0.5rem 0 0 0;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
}

@mixin password-meter($selector, $skin: default) {
  #{$selector} {
    @extend %at-password-meter-skin-#{$skin} !optional;
  }
}

// Include component here
@include password-meter('.password-meter');
