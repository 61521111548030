@import '../../default/2_atoms/password/passwordmeter-item';

%at-password-meter-item-skin-default {
  display: inline-block;
  width: 23%;
  height: .4rem;
  & + & {
    margin-left: .5rem;
  }
}

// Include component here
@include password-meter-item('.password-meter__item');
@include password-meter-item('.password-meter__item--low', 'low');
@include password-meter-item('.password-meter__item--medium', 'medium');
@include password-meter-item('.password-meter__item--strong', 'strong');
