%at-pdfViewer-skin-default {
  // Default skin here
}

%at-pdfViewer-skin-fullscreen {
  // Default skin here
  z-index: 9999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@mixin pdfViewer($selector, $skin: default) {
  #{$selector} {
    @extend %at-pdfViewer-skin-#{$skin} !optional;

    @if $skin == default {
      // Default component specific
      position: relative;
      height: 100%;

      &__viewer {
        width: 100%;
        height: 100%;
      }

      &__button {
        position: absolute;
        bottom: 2rem;
        right: 3rem;

        height: 4rem;
        width: 4rem;
        background: color-palette(neutral-light);

        box-shadow: 0 .2rem .2rem 0 rgba(0, 0, 0, 0.14), 0 .1rem .5rem 0 rgba(0, 0, 0, 0.12), 0 .3rem .1rem -.2rem rgba(0, 0, 0, 0.2);
        border: none;
        border-radius: 50%;
        outline: none;

        font-size: 1.5rem;
        color: color-palette(tone-primary-dark);
      }
    }
  }
}

// Include component here
@include pdfViewer('.pdfViewer');
@include pdfViewer('.pdfViewer.pdfViewer--fullscreen', 'fullscreen');
