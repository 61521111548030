// Declare skins here

%at-consents__block-skin-default {
  display:flex;
  justify-content:space-between;
  text-align:left;
  margin:2rem 0 2rem 0;

  @include gridle_state(mobile) {
    width:100%;
    flex-direction:column;
    justify-content:space-around;
  }
}

@mixin consents__block($selector, $skin: default) {
  #{$selector} {
    @extend %at-consents__block-skin-#{$skin} !optional;
    & > div{
      background-color: color-palette(neutral-grey);
      width:48%;
      padding:2%;
      @include gridle_state(mobile) {
        width:98%;
        margin-bottom:2rem;
      }
    }
    &Title {
      font-family: $font-medium;
      font-size: inherit;
      padding: 2rem;
      &:before{
        content:'\2022';
        display: inline-block;
        width: 1rem;
        margin-left: -2rem
      }
    }
    &Telephone {
      text-align: center;
      font-size:2.8rem;
      padding: 2rem;
    }
    &Notice{
      text-align: justify;
      font-size:1.5rem;
      line-height:1;
    }
    &Paragraph{
      line-height: 2.2rem;
      &--underline{
         text-decoration: underline;
      }
    }
  }
}

// Include component here
@include consents__block('.consents__block');
