// Declare skins here

%at-testimony-content-skin-default {
  // Default skin here
}

%at-testimony-content-skin-edito {
  // Default skin here
  color: color-palette(neutral);
  font-size: 1.8rem;
  line-height: 2.4rem;
  p {
    margin-top:0;
  }
}

%at-testimony-content-skin-edito1 {
  @extend %at-testimony-content-skin-edito;

  color: color-palette(neutral);
  font-size: 2.5rem;
  margin-top: 0;
}

%at-testimony-content-skin-edito2 {
  @extend %at-testimony-content-skin-edito;

  font-size: 1.8rem;
  color: color-palette(tone-primary-dark);
}

@mixin testimony-content($selector, $skin: default) {
  #{$selector} {
    @extend %at-testimony-content-skin-#{$skin} !optional;
  }
}

// Include component here
@include testimony-content('.testimony__content');
@include testimony-content('.testimony__content--edito1', 'edito1');
@include testimony-content('.testimony__content--edito2', 'edito2');

