/* override angucomplete-alt */

.angucomplete--full {
  & > .angucomplete-holder {
    width: 100%;
  }
}

.angucomplete-holder {
  position: relative;
  width: 60%;
  display: inline-block;

  & > input {
    width: 100%;
  }
}

.angucomplete-dropdown {
  box-shadow: 0 3px 5px 0 rgba(0,0,0,.3);
  overflow-y: scroll;
  background: color-palette(neutral-grey);
  position: absolute;
  transform: translateZ(0px);
  z-index: 100;
  text-align: left;
  top: 4.3rem;
  max-height: 23rem;
  left: 0;
  right: 0;
}

.angucomplete-row {
  padding: .8rem;
  line-height: 1.5;
  cursor: pointer;

  &:hover {
    background: color-palette(neutral);
    color: color-palette(tone-secondary);
  }
}

.angucomplete-wrapper {
  // default skins
}

.angucomplete-wrapper--inline {
  display: inline-block;

  .angucomplete-holder {
    width: 100%;
  }
}
