// Declare skins here

%at-offer__guarantees-skin-default {
  list-style-type: none;
  margin: 0 0 1.5rem;
  padding: 3rem 3rem 0 3rem;
  @include gridle_state(tablet,mobile){
    padding : 0 0 0 .5rem;
  }
}

@mixin offer__guarantees($selector, $skin: default) {
  #{$selector} {
    @extend %at-offer__guarantees-skin-#{$skin} !optional;

    @if $skin == default {
      &Item {
        text-align: left;
        margin-bottom: .8rem;
        font-size: $font-size-normal;
      }
      &Item--changed{
        .select, .textfield{
          color : color-palette(tone-warning);
        }
      }

      .select {
        border: 1px solid #e5e5e5;
        padding: 1rem;
        padding-right: 3rem;
      }
      .custom-select {
        .select-box__track {
          vertical-align: middle;
          top: 1rem;
        }
      }

      &Title {
        text-transform: uppercase;
        margin: 2.5rem 0 2rem;
        font-size: $font-size-normal;
      }

      &ListContainer{
        padding: 0;
        font-size: 1.4rem;
        &Title{
          color:color-palette(tone-primary);
          font-size: 1.6rem;
          margin-bottom: 1.5rem;
        }
      }

      &List{
        display: flex;
        justify-content: space-between;
        list-style: none;
        text-align: left;
        color: color-palette(dolphin);
        & > div:nth-child(2) {
          flex: 1;
          border-top: 0.1rem solid color-palette(neutral-grey-darken);
          margin: 1.5rem .7rem;
        }
        & .icon--info2{
          margin-right: .5rem;
          color: color-palette(neutral-grey-darkest);
          font-size: 1.8rem;
          vertical-align: middle;
        }
        & > div.--valid{
          color :color-palette(tone-valid);
        }
        & > div.--refused{
          color :color-palette(neutral-grey-darkest);
        }
        & .icon--info2{
          margin-right: .5rem;
          color: color-palette(neutral-grey-darkest);
        }
        &Price{
          color: color-palette(tone-secondary);
          font-family: $font-hw-bold;
        }
      }
      & .guarantee__group{
          margin-top: 0;
          margin-bottom: 2.5rem;
        > p{
          text-align: left;
          margin-top:0;
          margin-bottom: 0;
          display: block;
          &:before {
            content:'.';
            color:color-palette(tone-valid);
            font-size: 5rem;
          }
        }
      }
    }
  }
}

// Include component here
@include offer__guarantees('.offer__guarantees');
