// Declare skins here
%at-guarantees-skin-default {

  table {
    width: 100%;
    text-transform: uppercase;
    border-collapse: separate;
    border-spacing: 1.5rem;
    border-top: 2px solid color-palette(neutral-grey-light);

  }

}

@mixin guarantees($selector, $skin: default) {
  #{$selector} {
    @extend %at-guarantees-skin-#{$skin} !optional;

    .hide-mobile{
      @include gridle_state (mobile) {
        display: none;
      }
    }

    .show-mobile{
      display: none;
      @include gridle_state (mobile) {
        display: table;
        border-color: color-palette(tone-secondary);
        border-width: 1px;
        border-style: solid;
        border-radius: 0.5rem;
      }
    }

    .guarantee-divisor {
      // outline: thin solid gray;
    }

    &__header-icon{
      font-size: 2rem;
      &:before{
        top: .2rem;
        position: relative;
      }
    }
    &__item_col {
      vertical-align: middle;
      position: relative;

      &--right{
        text-align: right;
      }
      &--bordered{
        border-bottom: 2px solid color-palette(neutral-grey-light);
        width:95%;
        @include gridle_state(mobile){
          border-bottom: none;
          width:95%;
        }
      }
      &--bordered__nobottom {
        width:50%;
        @include gridle_state(mobile){
          border-bottom: none;
          width:50%;
        }
      }

      &--bordered__sup-info {
        color: #fc0081;
        @include gridle_state(mobile){
          border-bottom: none;
        }
      }
    }
    &__item_text{
      background-color: white;
      top: 1.4rem;
      position: relative;
      padding-right: 0.7rem;

      @include gridle_state(mobile){
        position: inherit;
      }
    }

    &__item_text--pegase{
      background-color: white;
      padding-right: 0.7rem;

      @include gridle_state(mobile){
        position: inherit;
      }
    }

    &__item-mobile-text {
      color: color-palette(tone-secondary);
    }

    .guarantee-quotity{
      font-weight: 700;
      color : color-palette(tone-secondary);
      position: relative;
      top: 1rem;
    }

    .guarantee-quotity-notop{
      font-weight: 700;
      color : color-palette(tone-secondary);
    }

    .icon--info-guarantee:before{
      content:'';
      position: absolute;
      background: url("/assets/images/guarantee-info.svg") no-repeat;
      height: 1.5rem;
      width: 1.5rem;
      left: -1rem;
    }

    .arrow--info-guarantee {
      background: url(/assets/images/up-arrow.svg) no-repeat;
      background-size: 1.5rem;
      height: 1.5rem;
      position: absolute;
      right: 1.5rem;
      top: 5px;
      width: 1.5rem;
    }

    .child-text {
      padding-left: 1.5rem;
    }

    &__item_icon{
      font-size: 2rem;
      position: relative;
      // top: 1rem;

      .icon-info:before {
        top: .2rem;
        position: relative;
      }

      .guarantee-refused{
        color  : color-palette(tone-error);
      }
      .guarantee-accepted{
        color : color-palette(tone-secondary);
      }
    }
    &__refused{

      padding: 1.5rem;
    }

    &__item_fixed{
      white-space: nowrap;
    }

    &__item_fixed--mobile {
      white-space: nowrap;
      min-width: 1.7rem;
      position: relative;
      width: 2rem;
    }

    .guarantee-arrow {
      height: 1.5rem;
      transition: transform .2s linear;
      &__reverse {
        transform: rotate(180deg);
      }
    }

    .guarantee-info-icon {
      max-width: none;
    }

    .tooltip-column {
      width: 2rem;
    }

    .guarantees__subitem_text {
      margin-left: 1.5rem;
    }
  }
}

// Include component here
@include guarantees('.guarantees');
