// Declare skins here

%at-projects-details-skin-default {
  margin: 5.5rem 0;
  @include gridle_state(mobile) {
    text-align: center;
    margin: 0;    
  }
}

@mixin projects-details ($selector, $skin: default) {
  #{$selector} {
    @extend %at-projects-details-skin-#{$skin} !optional;

    @if($skin == default){
      &__title{
        font-size: $font-size-big;
        color: color-palette(tone-primary);
        margin-bottom: 6.1rem;
        @include gridle_state(mobile) {
          margin-bottom: 3rem;          
        }
      }
    }
  }
}

// Include component here
@include projects-details('.projects-details');
