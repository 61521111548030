%at-input-with-status-skin-default {
  // Default skin here
  margin-bottom: 1rem;
}

@mixin input-with-status($selector, $skin: default) {
  #{$selector} {
    @extend %at-input-with-status-skin-#{$skin} !optional;

    &--inline{
      display: inline-block;
      width: 50%;
      margin-bottom: 0;
      position: relative;
      @include gridle_state(mobile) {
        width: 100%;
      }
    }
  }
}

// Include component here
@include input-with-status('.input-with-status');
