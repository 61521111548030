// Declare skins here

%at-select-box-skin-default {
  position: relative;
  padding-bottom: .5rem;
  margin-right: .5rem;
  display: inline-block;

  @include gridle_state(mobile) { // Prevent horizontal scroll due to too big select
    max-width: 100%;

    .select {
      max-width: 100%;
    }
  }
}

%at-select-box-track-skin-default {
  display: inline-block;
  color: color-palette(tone-secondary);
  @include icon('select-track');

  &::before {
    vertical-align: middle;
  }
}

%at-select-box-skin-full {
  margin-right: 0;
  width: 100%;

  .select {
    width: 100%;
  }

  .select-box__track {
    position: absolute;
    margin-left: 0;
    right: .5rem; top: .4rem;
  }
}

@mixin select-box($selector, $skin: default) {
  #{$selector} {
    @extend %at-select-box-skin-#{$skin} !optional;

    @if $skin == default {
      .select {
        padding-right: 3rem;
      }
    }

    &__track {
      position: absolute;
      top: 0;
      bottom: 0.5rem;
      right: 0.5rem;
      pointer-events: none;
      line-height: 1.3;
      @extend %at-select-box-track-skin-#{$skin} !optional;
    }

    &__post {
      @extend %at-select-box-post-skin-#{$skin} !optional;
    }
  }
}

// Include component here
@include select-box('.select-box');
@include select-box('.select-box--full', 'full');
@include select-box('.select-box--navigation', 'navigation');
