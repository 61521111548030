%at-offer-row-skin-default {
  // Default skin here

  list-style: none;
  padding-left: 0;
  margin: 0;
  overflow: hidden;
}

@mixin offer-row($selector, $skin: default) {
  #{$selector} {
    @extend %at-offer-row-skin-#{$skin} !optional;

    @media (min-width: $bp-tablet-max + 1) {
      // &__cell specifity: 2
      & > &__cell {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      &__cell {
        padding-bottom: 100%;
        margin-bottom: -100%;

        & + & {
          border-left: .1rem solid color-palette(tone-primary-dark);
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    &__title {
      font-family: $font-medium;
      font-size: 2rem;
      line-height: 1.3;
    }

    @include gridle_state(tablet) {
      &__cell {
        & + & {
          margin-top: 2rem;
        }
      }

      &__content {
        margin-top: .5rem;
      }
    }

  }
}

// Include component here
@include offer-row('.offer__row');
