// Declare skins here

%at-login-box-skin-default {
  // Default skin here
  background-color: color-palette(neutral);
  width: 40rem;
  min-height: 40rem;
  border-top: solid 0.1rem color-palette(tone-primary);
  box-shadow: 0rem 0.8rem 1.5rem 0.4rem rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  padding: 1rem 4rem;
  z-index: -1;
  transition: opacity .3s ease-out, z-index .3s ease-out;
  opacity: 0;
  transform: translateX(-100%);

  .page-subtitle{
    text-align: center;
    margin-bottom: 6rem;
  }

  .textfield{
    display: block;
    width: 100%;
    margin-bottom: 3rem;
      &[type='password']{
        margin-bottom: 1rem;
      }
  }

  .button {
    margin-top: 3rem;
  }

  @include gridle_state(header-mobile){
    top: 8rem;
    transform: none;
    right: 2rem;
  }
  @include gridle_state(mobile){
    position: fixed;
    width: 100%;
    right: 0;
    left: 0;
    top: 7rem;
    min-height: initial;
    transform: none;
    .page-subtitle{
      margin-bottom: 2rem;
    }

    .page-subtitle--small{
      font-size: 2rem;
    }

    .textfield{
      margin-bottom: 1rem;
    }

    .button {
      margin-top: 2rem;
    }

    .popin__close-button{
      float:left;
      padding-top: 0;
      background: none;
      border: none;
      color: color-palette(popin-tone-secondary);
    }
  }
}

@mixin login-box($selector, $skin: default) {
  #{$selector} {
    @extend %at-login-box-skin-#{$skin} !optional;

    &.opened{
      z-index: 20;
      opacity: 1;
    }
    &Footer{
      display: flex;
      justify-content: center;
    }
    &-modal__link {
      font-size: 1.4rem;
      color: color-palette(tone-grey-black-dark);
      display: block;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

// Include component here
@include login-box('.login-box');
