// Declare skins here

%at-guarantee-skin-default {
  text-align: left;
  margin-left:3rem;
  @include gridle_state(mobile) {
    margin: 0.5rem 0 .5rem 3rem
  }
}

%at-guarantee-skin-disabled {
  opacity: .4;
  pointer-events: none;
}

%at-guarantee-skin-left {
  text-align: left;
  margin: 0 1.5rem;
  display: block;
}

@mixin guarantee($selector, $skin: default) {
  #{$selector} {
    @extend %at-guarantee-skin-#{$skin} !optional;

    @if $skin == default {

      &__sub {
        font-size: 1.6rem;
        line-height: 1.4rem;
        @include gridle_state(mobile) {
          display: inline;
          font-size: inherit;
        }
      }

      .select {
        font-size: $font-size-normal;
        padding-bottom: .3rem;
        margin-bottom: .7rem;
      }

      &__group {
        font-size: 1.6rem;
        font-weight: bold;        
        & + & {
          margin-top: .7rem;
        }
        & .select-box{
          margin-right:0;
        }
      }
    }

  }
}

// Include component here
@include guarantee('.guarantee');
@include guarantee('.guarantee--disabled', 'disabled');
@include guarantee('.guarantee--left', 'left');
