%at-modal-skin-default {
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh; // Fix for iPad jumps when focus a field

  &.ng-enter, &.ng-leave {
    transition: opacity .4s ease-out;

    & > .cand-modal__overlay {
      transition: transform .4s ease-out;
    }
  }
  &.ng-enter.ng-enter-active, &.ng-leave {
    opacity: 1;

    & > .cand-modal__overlay {
      transform: translate3d(0, -50%, 0);
    }
  }
  &.ng-enter, &.ng-leave.ng-leave-active {
    opacity: 0;

    & > .cand-modal__overlay {
      transform: translate3d(0, -70%, 0);
    }
  }
}

@mixin modal($selector, $skin: default) {
  #{$selector} {
    @extend %at-modal-skin-#{$skin} !optional;
  }
}

// Include component here
@include modal('.cand-modal');
