%at-sub-answer-form-skin-default {
  .sub-answer__group{
    margin-right: 2rem;
    margin-bottom: 3rem;
    @include gridle_state(mobile){
      margin-right:0;
      margin-bottom:1rem;
    }
  }


}

@mixin sub-answer-form($selector, $skin: default) {
  #{$selector} {
    @extend %at-sub-answer-form-skin-#{$skin} !optional;

  }
}

// Include component here
@include sub-answer-form('.sub-answer-form');