// Declare skins here

%at-testimony-signature-skin-default {
  // Default skin here
  color: color-palette(tone-primary);
  font-family: $font-bold;
  text-align: center;
  margin: 3.3rem 0 0 0;
}

@mixin testimony-signature($selector, $skin: default) {
  #{$selector} {
    @extend %at-testimony-signature-skin-#{$skin} !optional;
  }
}

// Include component here
@include testimony-signature('.testimony__signature');
