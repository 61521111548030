// Declare skins here

%at-tile-skin-default {
  // Default skin here
  padding: 2.5rem 2.5rem 9rem;
  background-color: color-palette(tile-neutral);
  max-width: 100%;
  display: block;
  position: relative;
  white-space: normal;
  min-height: 40rem;
}

%at-tile-skin-secondary {
  // Default skin here
  background-color: color-palette(tone-secondary);
}

%at-tile-skin-image {
  // Default skin here
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: space-between;
}

@mixin tile($selector, $skin: default) {
  #{$selector} {
    @extend %at-tile-skin-#{$skin} !optional;

    /* TODO: calc function is not in the compability scope */
    .button{
      position: absolute;
      bottom: 1.5rem;
      width: calc(100% - 5rem);
    }
  }
}

// Include component here
@include tile('.tile');
@include tile('.tile--secondary','secondary');
@include tile('.tile--image','image');
