// Declare skins here

%at-fast-quote__title-skin-default {
  font-size: 3rem;
  margin-bottom: 3rem;

  @include gridle_state(tablet) {
    font-size: 2.5rem;
  }

  @include gridle_state(mobile) {
    font-size: 2rem;
  }

  @include first-letter-uppercase();
}

@mixin fast-quote__title($selector, $skin: default) {
  #{$selector} {
    @extend %at-fast-quote__title-skin-#{$skin} !optional;
  }
}

// Include component here
@include fast-quote__title('.fast-quote__title');
