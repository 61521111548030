// Declare skins here

%at-glossary-menu-skin-default {
  display: block;
  margin: 4rem 0;
}

@mixin glossary-menu ($selector, $skin: default) {
  #{$selector} {
    @extend %at-glossary-menu-skin-#{$skin} !optional;
  }
}

// Include component here
@include glossary-menu('.glossary-menu');
