// Declare skins here

%at-simulation-offreIsotope-skin-default {
  background-color: color-palette(tone-secondary);
  min-height: 30rem;
  margin: 6.6rem 0;
}

@mixin simulation-offreIsotope($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation-offreIsotope-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation-offreIsotope('.simulation__offreIsotope');
