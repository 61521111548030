// Declare skins here

%at-fast-quote__text-skin-default {
  display: inline-block;
  margin: .5rem 0;

  @include gridle_state(tablet) {
    display: inline;
  }
}

%at-fast-quote__text-skin-edito2 {
  @extend %at-fast-quote__text-skin-default;
}

@mixin fast-quote__text($selector, $skin: default) {
  #{$selector} {
    @extend %at-fast-quote__text-skin-#{$skin} !optional;
  }
}

// Include component here
@include fast-quote__text('.fast-quote__text');
@include fast-quote__text('.fast-quote__text--edito2', 'edito2');
