// Declare skins here

%at-outdated-skin-default {
  display: none;
  position: fixed;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: #ffffff;
}

@mixin outdated($selector, $skin: default) {
  #{$selector} {
    @extend %at-outdated-skin-#{$skin} !optional;

    &__wrapper {
      display: table;
      height: 100%;
      width: 100%;
      position: relative;
    }

    &__header {
      position: absolute;
      top: 0;
      left: 0;
      padding: 3em 0 0 7em;
      @include gridle_state(mobile) {
        padding: 3em 0 0 3em;
      }
    }

    &__logo {
      display: inline-block;
      background-image: url('/assets/images/cand-logo-resized.png');
      background-repeat: no-repeat;
      height: 53px;
      width: 220px;
    }

    &__alert-icon {
      display: inline-block;
      background-image: url('/assets/images/alerte_navigator.png');
      background-repeat: no-repeat;
      height: 104px;
      width: 104px;
    }

    &__shadow-icon {
      display: inline-block;
      background-image: url('/assets/images/consents_shadow.png');
      background-repeat: no-repeat;
      height: 6px;
      width: 62px;
    }

    &__content {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      width: 100%;
      font-size: 1.3em;
      line-height: 1.5;
      position: fixed;
      top: 8em;
    }

    & &__text {
      margin: 1em 0;
    }

    & &__button {
      margin-top: 1em;
      line-height: normal;
      min-width: 0;
      padding: 1em;
    }
  }
}

// Include component here
@include outdated('.cand-outdated');
