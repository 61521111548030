@import '../default/6_pages/simulation';

%at-simulation-skin-default {
  margin-top: 4.5rem;

  @include gridle_state(mobile) {
    margin-top: 2rem;
  }
}

.simulation__step {
  margin: 0;
}
