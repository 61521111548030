@import '../default/4_organisms/header';

%at-header-skin-default {
  padding: 0;
  height: 5rem;
  background: color-palette(neutral);
  @include gridle_state(mobile) {
    transition: all 1s ease;
    left: 0;
    top: 0;
  }
}
%at-header-skin-left-transition {
  -webkit-transition: left 1s ease;
  -moz-transition: left 1s ease;
  transition: left 1s ease;
  left: 0;
}

@include header('.header--left-transition', 'left-transition');
