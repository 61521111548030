// Declare skins here

%at-glossary-menu-list-skin-default {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-bottom: 0.2rem solid color-palette(tone-secondary);
}

@mixin glossary-menu-list ($selector, $skin: default) {
  #{$selector} {
    @extend %at-glossary-menu-list-skin-#{$skin} !optional;
  }
}

// Include component here
@include glossary-menu-list('.glossary-menu__list');
