%at-otp-skin-default {
  margin: 0.2rem 0 0;
  padding: 0 1rem;
  text-align: center;
}

%at-otp-skin-page {
  background: #FFFFFF !important;

  & .header {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

    & .header-navLink.header-navLink--documents,
    & .header-navLink.header-navLink--burger {
      display: none;
    }
  }
}

@mixin otp($selector, $skin: default) {
  #{$selector} {
    @extend %at-otp-skin-#{$skin} !optional;

    @if $skin == default {

      .js-valid.textfield, .ng-valid.textfield {
        border-color: color-palette(tone-secondary);
      }

      &__header {
        padding-top: 3.5rem;
        padding-bottom: 9rem;

        /* stylelint-disable */
        &-title {
          color: color-palette(tone-primary);
          font-family: 'Montserrat', sans-serif;
          font-size: $font-size-small;
          font-weight: 600;
          text-transform: uppercase;
        }
      }

      &__loader {
        position: relative;
        top: 4rem;
      }

      &__form {

        &-title {
          font-size: 1.8rem;

          &--green {
            color: color-palette(tone-secondary);
            font-weight: 600;
          }
        }

        &-group {
          padding-top: 3.6rem;

          .grid-12 {
            display: flex;
            justify-content: center;
            padding: 0;
          }

          input {
            background: #ececec;
            border: 0.1rem solid lightgrey;
            border-radius: .4rem;
            box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.4);
            height: 4rem;
            line-height: .2rem;
            padding: 1.3rem 1rem;
            text-align: center;
            width: 4rem;

            &:disabled {
              border-color: lightgrey !important;
            }

            &:not(:first-child) {
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }
}

@include otp('.otp');
@include otp('.otp-page', 'page');
