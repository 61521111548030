// Declare skins here

%at-info-grid-skin-default {
  color: color-palette(tone-secondary-dark);
  font-size: 1.6rem;
  font-family: $font-medium;
}

@mixin info-grid($selector, $skin: default) {
  #{$selector} {
    @extend %at-info-grid-skin-#{$skin} !optional;
    @if($skin == default){
      .info-block .info-block--speaker .info-block__title{
        margin-left: 2rem;
      }

      .js_slides{
        padding: 0;
      }
    }

    @media screen and (min-width: ($bp-mobile-max + 1)){
      .slider .js_slide{
        float: left !important;
      }
    }

    .slider .js_slide {
      @include gridle_state(mobile) {
        padding: 0 3rem;
      }
    }

  }
}

// Include component here
@include info-grid('.info-grid');
