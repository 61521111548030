// Declare skins here

%at-page-404-skin-default {
   background: url('/assets/images/rouleau-404.svg') 10% bottom no-repeat color-palette(tone-secondary);
   background-size: auto 82%;
   display: table;
   min-height: 60vh;

   @include gridle_state(tablet) {
     background-size: auto 70%;
     background-position: center bottom;
     min-height: 65vh;
     padding-top: 2rem;
   }

   @include gridle_state(mobile) {
     background-size: auto 60%;
     min-height: 90vh;
     padding-top: 2rem;
   };
}

@mixin page-404 ($selector, $skin: default) {
  #{$selector} {
    @extend %at-page-404-skin-#{$skin} !optional;
  }
}

// Include component here
@include page-404('.page-404');
