// Declare skins here

%at-edito-text-skin-default {
  font-family: $font-regular;
  font-size: 2rem;
}

%at-edito-text-skin-primary {
  color: color-palette(tone-primary);
}

%at-edito-text-skin-hand {
  font-family: $font-hw-bold;
}

@mixin edito-text($selector, $skin: default) {
  #{$selector} {
    @extend %at-edito-text-skin-#{$skin} !optional;
  }
}

// Include component here
@include edito-text('.edito-text');
@include edito-text('.edito-text--primary', 'primary');
@include edito-text('.edito-text--hand', 'hand');
