// Declare skins here

%at-page-404-subtitle-link-skin-default {
   color: color-palette(neutral);
}

@mixin page-404-subtitle-link ($selector, $skin: default) {
  #{$selector} {
    @extend %at-page-404-subtitle-link-skin-#{$skin} !optional;
  }
}

// Include component here
@include page-404-subtitle-link('.page-404__subtitleLink');
