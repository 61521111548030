// Declare skins here

%at-info-block__list-item-skin-default {
}

%at-info-block__list-item-skin-secondary {
  color: color-palette(tone-secondary);
}

@mixin info-block__list-item($selector, $skin: default) {
  #{$selector} {
    @extend %at-info-block__list-item-skin-#{$skin} !optional;

    @if($skin == default){
    }
  }
}

// Include component here
@include info-block__list-item('.info-block__list-item');
@include info-block__list-item('.info-block__list-item--secondary','secondary');

