%at-testimony-action-skin-default {
  // Default skin here

  position: absolute;
  width: 100%;
  bottom: 1.5rem;
  left: 0;
}

%at-testimony-action-skin-center {
  // Default skin here

  @extend %at-testimony-action-skin-default;
  text-align: center;
}

@mixin testimony-action($selector, $skin: default) {
  #{$selector} {
    @extend %at-testimony-action-skin-#{$skin} !optional;

    &__button {
      width: 90%;
    }
  }
}

// Include component here
@include testimony-action('.testimony__action');
@include testimony-action('.testimony__action--center', 'center');
