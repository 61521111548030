// Declare skins here

%at-simulation__tarifCellContent-skin-default {
  font-size: 2.5rem;
  color:color-palette(tone-secondary);
  font-family: $font-medium;
  text-align: center;
  display: block;
  &::first-line {
    font-size: 4.5rem;
    font-family: $font-regular;
  }

  @include gridle_state(mobile) {
    font-size: 1.5rem;
    &::first-line {
      font-size: 3.5rem;
    }
  }
}

@mixin simulation__tarifCellContent($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__tarifCellContent-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__tarifCellContent('.simulation__tarifCellContent');

