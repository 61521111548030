// Declare skins here

%at-page-subtitle-skin-default {
  color: color-palette(tone-secondary);
  font-family: $font-medium;
  font-size: 3.8rem;
}

%at-page-title-skin-hw {
  font-family: $font-hw-bold;
  color: color-palette(tone-primary);
}

%at-page-subtitle-skin-primary {
  color: color-palette(tone-primary);

  .page-subtitle--hw {
    color: color-palette(tone-secondary);
  }
}

%at-page-subtitle-skin-icon {
  &:before{
    float:right;
  }
}

%at-page-subtitle-skin-small {
  font-size: $font-size-big;
}

@mixin page-subtitle($selector, $skin: default) {
  #{$selector} {
    @extend %at-page-subtitle-skin-#{$skin} !optional;
  }
}

// Include component here
@include page-subtitle('.page-subtitle');
@include page-subtitle('.page-subtitle--hw', 'hw');
@include page-subtitle('.page-subtitle--primary', 'primary');
@include page-subtitle('.page-subtitle--icon', 'icon');
@include page-subtitle('.page-subtitle--small', 'small');
