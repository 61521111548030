%at-documents-nav-list-skin-default {
  display: inline-block;
  list-style: none;
  padding: 0;
  @include gridle_state (mobile) {
    width: 100%;
  }
}

%at-documents-nav-list-skin-item {
  display: inline-block;
  border-bottom: solid 0.4rem color-palette(neutral-grey-light);
  height: 7.6rem;
  padding: 1.6rem 1.4rem 2.2rem;
  background-color: white;
  width: auto;
  color: color-palette(neutral-grey-darkest);
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
  padding-left: 0;

  .icon{
    position: relative;
    &:before{
      position: absolute;
    }
  }


  @include gridle_state (mobile) {
    width: 40%;
    max-width: 17rem;
    font-size: 1.5rem;
  }

  &:first-child{
    margin-right: 1rem;
    @include gridle_state (mobile) {
      width: 40%;
    }
  }
}

%at-documents-nav-list-skin-text {
  display: block;
  margin-left: 2rem;
}

@mixin documents-nav-list($selector, $skin: default) {
  #{$selector} {
    @extend %at-documents-nav-list-skin-#{$skin} !optional;

    &--active{
      border-bottom: solid 0.7rem color-palette(tone-primary);
      color: color-palette(tone-primary);
    }

  }
}

// Include component here
@include documents-nav-list('.documents-nav-list');
@include documents-nav-list('.documents-nav-list--item','item');
@include documents-nav-list('.documents-nav-list--text','text');
