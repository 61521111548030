%at-document-selection-list-skin-page {
    @include gridle_state(mobile) {
        padding-bottom: 0 !important;
    }

    & .document-selection .simulation__group {
        @include gridle_state(mobile) {
            margin-bottom: 0;
        }
    }
}
%at-document-selection-list-skin-default {
    @include gridle_state(mobile) {
        padding-bottom: 4rem;
    }

    & .simulation__buttons {
        border: none;
        margin: 0;

        @include gridle_state(mobile) {
            display: flex;
            flex-direction: column-reverse;
        }

        .button {
            @include gridle_state(mobile) {
                margin: 0.5rem 0 2rem;
                width: 100%;
            }
        }
    }
    & .contact-caption {
        margin: 0 3.3rem 6.7rem;
        margin-top: -1rem;

        @include gridle_state(mobile) {
            margin: 2rem -2rem;
        }
        @include gridle_state(mobile-xs) {
            margin: 0rem -2.5rem 2rem;
            padding: 2rem 0.5rem;
        }
        &--position {
            margin: -1rem -1rem 0rem;
            margin-top: 2rem;
            @include gridle_state(mobile) {
                margin-bottom: 3.7rem;
            }
            @include gridle_state(mobile-xs) {
                margin: 1rem 1.5rem 3rem;
                padding: 2rem 0.5rem;
            }
        }
    }

    & .contact-caption--caption p {
        @include gridle_state(mobile) {
            font-size: 1.6rem;
            font-weight: 400;
            padding: 0 0.5rem 1rem;
        }
        @include gridle_state(mobile-xs) {
            font-size: 1.3rem;
        }
    }
    & .contact-caption--caption span {
        @include gridle_state(mobile) {
            padding: 0 4rem;
        }
        @include gridle_state(mobile-xs) {
            padding: 0 3rem;
        }
    }
}

@mixin document-selection-list($selector, $skin: default) {
    #{$selector} {
        @extend %at-document-selection-list-skin-#{$skin} !optional;

        &__header {
            padding: 5.2rem 4.3rem 3.5rem;

            @include gridle_state(mobile) {
                box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.2);
                padding: 3rem 2rem;
            }

            &-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                margin-bottom: 2rem;
                &--label {
                    font-size: 26px;
                    font-weight: 600;
                    line-height: 22px;
                    
                    @include gridle_state(mobile) {
                        font-size: 1.9rem;
                    }
                    @include gridle_state(mobile-xs) {
                        font-size: 1.4rem;
                    }
                }
                &--show {
                    margin-bottom: 0;
                }
                &__icon--show {
                    height: 3px;
                    width: 20px;
                    background-color: #84d000;
                }
            }
            &__subtitle {
                overflow: hidden;
                transition: max-height 0.2s ease-out;
                max-height: 0px;
                &--show {
                    max-height: 35rem;
                }
                &-subtitle {
                    @include first-letter-uppercase();
                    padding: 3.5rem 0 2.2rem;

                    @include gridle_state(mobile) {
                        padding: 2rem 0;
                        line-height: 1.7rem;
                    }
                    @include gridle_state(mobile-xs) {
                        font-size: 1.4rem;
                    }
                }
                &-link {
                    cursor: pointer;
                }
                &-info {
                    display: flex;
                    height: 4rem;
                    align-items: center;
                    margin-bottom: 2.6rem;

                    @include gridle_state(mobile) {
                        height: auto;
                    }

                    &_icon {
                        position: relative;
                        top: -2rem;

                        &::before {
                            background-position: center !important;
                            background-color: color-palette(
                                neutral-light
                            ) !important;
                            border-radius: 50%;
                            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
                            content: '';
                            height: 4rem;
                            position: absolute;
                            width: 4rem;
                        }

                        &--heart-health {
                            &::before {
                                background: url('/assets/images/icon-heart-health.svg')
                                    no-repeat;
                            }
                        }

                        &--sent {
                            &::before {
                                background: url('/assets/images/icon-sent.svg')
                                    no-repeat;
                            }
                        }
                        &--info {
                            &::before {
                                background: url('/assets/images/icon-information.svg')
                                    no-repeat;
                            }
                        }
                    }
                    &_text {
                        font-weight: 600;
                        line-height: 2rem;
                        padding-left: 6rem;
                        padding-right: 11rem;

                        @include gridle_state(mobile) {
                            font-size: 1.4rem;
                            padding-right: 0;
                        }
                        @include gridle_state(mobile-xs) {
                            font-size: 1.2rem;
                        }

                        a {
                            color: color-palette(tone-primary);
                            text-decoration: none;
                        }

                        @include first-letter-uppercase();
                    }
                }
            }
        }

        &__box {
            background-color: color-palette(neutral-light);
            box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.2); // inset 0 -3px 4px rgba(0, 0, 0, 0.2);
            margin: 0;
            padding: 2rem 4.3rem;

            @include gridle_state(mobile) {
                padding: 1.5rem 1.5rem;
            }
            &--ptm {
                padding-top: 4rem;
                @include gridle_state(mobile) {
                    padding-top: 3rem;
                }
            }
            &--pbm {
                padding-bottom: 4rem;
                @include gridle_state(mobile) {
                    padding-bottom: 3rem;
                }
            }
            &--no-shadow {
                box-shadow: none;
            }
            &--layout {
                background-color: rgba(214, 226, 221, 0.5);
                box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
                padding: 1.8rem 5.8rem 2rem;

                @include gridle_state(mobile) {
                    padding: 0 1rem 1.5rem;
                }
            }
            &-header {
                padding: 2.6rem 0;

                @include gridle_state(mobile) {
                    padding-bottom: 1rem;
                }

                &_text {
                    align-content: center;
                    align-items: center;
                    color: #4677a2;
                    display: flex;
                    font-size: 1.8rem;
                    font-weight: 600;
                    justify-content: space-between;
                    text-transform: uppercase;

                    @include gridle_state(mobile) {
                        font-size: 1.6rem;
                    }
                }
                &_icon {
                    cursor: pointer;
                    left: -3rem;
                    margin-left: 2rem;
                    position: relative;
                    top: -1.5rem;

                    @include gridle_state(mobile) {
                        margin-left: 4rem;
                    }

                    &::before {
                        background: url('/assets/images/icon_question.svg')
                            no-repeat;
                        background-position: center !important;
                        border-radius: 50%;
                        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
                        content: '';
                        height: 3rem;
                        position: absolute;
                        width: 3rem;
                    }
                }
            }
            &-footer {
                align-items: stretch;
                display: flex;
                justify-content: center;
                padding: 2rem 0 1rem;

                @include gridle_state(mobile) {
                    align-items: center;
                    flex-direction: column-reverse;
                    padding: 2rem 1rem 1rem;
                }

                &_link {
                    display: flex;
                    align-items: center;
                    color: #666666;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 18px;
                    background-color: #ddd;
                    width: 34rem;
                    padding: 0.6rem 2rem;
                    margin-right: 1rem;
                    text-decoration: none;
                    text-transform: uppercase;
                    text-align: center;

                    @include gridle_state(mobile) {
                        margin-right: 0;
                        width: 100%;
                    }
                    &:hover,
                    &:focus {
                        background-color: #ccc;
                        color: currentColor;
                    }
                }
                &_rdv {
                    font-size: 1.4rem;
                    font-weight: 600;
                    margin-left: 5rem;
                    max-width: 40rem;

                    @include gridle_state(mobile) {
                        font-size: 1.4rem;
                        padding: 1rem 2rem;
                        margin: 0 0 2rem;
                    }
                }
            }
        }
    }
}

@include document-selection-list('.document-selection-list');
@include document-selection-list('.document-selection-list-page', 'page');
