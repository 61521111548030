%at-finalisation-skin-default {
  // Default skin here
}

@mixin finalisation($selector, $skin: default) {
  #{$selector} {
    @extend %at-finalisation-skin-#{$skin} !optional;

    &__stepTitle {
      display: inline-block;
      position: relative;

      &__checkmark {
        position: absolute;
        font-size: $font-size-normal;
        color: color-palette(tone-secondary);
        line-height: normal;
        left: -3rem;
        top: 0;
      }
    }

    &__stepDescription {
      font-size: 2rem;
    }

    &__pdfViewer {
      margin-top: 5rem;
      width: 100%;
      height: 45rem;
    }

    &__signatureIframe {
      height: 100rem;
      -webkit-overflow-scrolling: touch;

      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
    }

    &__signatureIframeLoading span {
      font-size: 2rem;

      &:before {
        display: block;
      }
    }

  }
}

// Include component here
@include finalisation('.finalisation');
