@import '../default/3_molecules/footermenu';

%at-footer__menu-skin-default {
  display: inline;
  margin: 0;
  padding: 0;

  @include gridle_state(mobile){
		text-align: center;
  }
}

