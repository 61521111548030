// Declare skins here

%at-offer-testimony-skin-default {
  // Default skin here
}

@mixin offer-testimony($selector, $skin: default) {
  #{$selector} {
    @extend %at-offer-testimony-skin-#{$skin} !optional;

    &__action {
      margin-top: 5.5rem;
      @include gridle_state(mobile){
        margin-top: 2rem;
      }
    }
  }
}

// Include component here
@include offer-testimony('.offer-testimony');
