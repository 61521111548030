// Declare skins here

%at-tile-title-skin-default {
  // Default skin here
  color: color-palette(tone-primary-dark);
  font-family: $font-medium;
  font-size: 2.2rem;
  margin-bottom: 2.5rem;
}

%at-tile-title-skin-primary{
  @extend %at-tile-title-skin-default;
  color: color-palette(tone-primary);
}

%at-tile-title-skin-neutral{
  @extend %at-tile-title-skin-default;
  color: color-palette(neutral);
}

@mixin tile-title($selector, $skin: default) {
  #{$selector} {
    @extend %at-tile-title-skin-#{$skin} !optional;

    // Reset link style for SEO (we can have a link inside .tile-title)
    a {
      text-decoration: none;
      color: inherit;
    }
  }
}

// Include component here
@include tile-title('.tile-title');
@include tile-title('.tile-title--primary','primary');
@include tile-title('.tile-title--neutral','neutral');
