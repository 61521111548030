%at-additional-selection-skin-default {

}

%at-additional-selection-skin-content {
  margin: 4rem -5rem;
  padding-top: 3.5rem;
  background-color: color-palette(neutral-light);
  box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.2);
  .documents-toggle--content{
    padding: 0 4rem;
    margin: 3.5rem 0;
  }
}

%at-additional-selection-skin-text {
  background-color: color-palette(neutral-light);
  padding: 0 4rem;
}

@mixin additional-selection($selector, $skin: default) {
  #{$selector} {
    @extend %at-additional-selection-skin-#{$skin} !optional;

    .simulation__buttons--light{
      border-top: none;
    }

    .button--download{
      min-width: 15rem;
    }

    .waiting-page__modal-content{
      margin-top: 3rem;
      border: .2rem solid color-palette(tone-warning);
      width: 100%;
    }

    .waiting-page__message{
      padding: 0 1rem;
      vertical-align: middle;
      display: table-cell;

      p{
        display: block;
      }
    }

    .document-selection--tooltip{
      .document-selection--text{
        padding-left: 1rem;
      }
      margin-bottom: 0;
      margin-top: 2rem;
    }
  }
}

// Include component here
@include additional-selection('.additional-selection');
@include additional-selection('.additional-selection--content','content');
@include additional-selection('.additional-selection--text','text');
