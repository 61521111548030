@mixin document-recapcontainer($selector) {
  #{$selector} {
    background-color: #d8edb6;
    box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
    padding: 2rem;
    color: #074624;
    font-family: "montserrat", sans-serif;
    &__header {
      &__title {
        &--webcallback {
          margin-bottom: 2rem;
          @include gridle_state(mobile) {
            margin-bottom: 0;
          }
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 600;
        line-height: 22px;
        @include gridle_state(mobile) {
          flex-direction: column;
        }
        &--left {
          display: flex;
          align-items: center;
          flex-direction: row;
        }
        &__arm-image {
          height: 3.5rem;
          @include gridle_state(mobile) {
            margin-bottom: 1rem;
          }
        }
        &__icon-calendar {
          height: 24px;
          width: 23px;
          margin-right: 1rem;
        }
      }
    }
    &__body {
      line-height: 22px;
      padding-left: 4rem;
      &__appointment {
        font-size: 16px;
        font-weight: 600;
      }
      &__details {
        color: #074624;
        font-size: 14px;
        @include gridle_state(mobile) {
          text-align: center;
        }
      }
      @include gridle_state(mobile) {
        text-align: center;
        padding-left: 0;
      }
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      padding-left: 4rem;
      &__buttons {
        display: flex;
        align-items: center;
        button.button.button--grey {
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          min-width: 19rem;
          border-width: 1px;
          padding: 1rem;
          @include gridle_state(mobile) {
            margin-bottom: 1rem;
          }
        }
        &__cancel-rdv {
          font-size: 14px;
          font-weight: 500;
          min-width: 19rem;
          padding: 1rem;
          line-height: 18px;
          border: 1px solid #fc0081;
          background-color: #e1d4c7;
          border-radius: 2px;
          margin-left: 1rem;
          color: #fc0081;
          @include gridle_state(mobile) {
            margin-left: 0;
            margin-bottom: 1rem;
          }
        }

        @include gridle_state(mobile) {
          flex-direction: column;
        }
      }
      @include gridle_state(mobile) {
        padding-left: 0;
        flex-direction: column-reverse;
        align-items: stretch;
      }
    }
  }
}

@include document-recapcontainer(".recap-container");
