// Declare skins here
%at-tab__header-skin-default {
	cursor:pointer;
	height: 7.6rem;
	background-color : color-palette(tone-secondary-dark);
	margin : 0;
	padding : 1.6rem 2.4rem 2.2rem ;
	align-self: flex-end;

	&:not(:last-child){
		margin-right: 1rem;
	}
}

%at-tab__header-skin-active {
	cursor: default;
	height: 8.6rem;
	padding-top : 2.6rem;
	background-color : color-palette(neutral);
	align-self: flex-start;

}

%at-tab__header-skin-inline {
	height: 8.6rem;
	padding-top : 2.6rem;
	background-color : color-palette(neutral);
	text-align: center;
	align-self: flex-start;
	&:not(:last-child){
		margin-right: 0;
	}

}

%at-tab__header-skin-button{
	color: color-palette(tone-primary);
	font-size: 1.2rem;
	@include gridle_state(mobile){
		&:not(:last-child){
			border-right: 0.1rem solid color-palette(tone-primary);
		}
	}

}

%at-tab__header-skin-no-padding{
	@include gridle_state(tablet){
			padding: 0;		
	}
}

@mixin tab-header($selector, $skin: default) {
	#{$selector} {
		@extend %at-tab__header-skin-#{$skin} !optional;

		@if($skin == default) {
			&__title{
				text-transform: uppercase;

			}
			&__subtitle::before{
					content:"\a";
					white-space: pre;
				
			}
		}

		@if($skin == 'inline'){
			&.tab__header--active{
				border-bottom : solid 0.7rem color-palette(tone-primary);
				color : color-palette(tone-primary); 
			}
		}
		@if($skin == 'button'){
			&.tab__header--active {
				@include gridle_state(mobile){
					border-bottom: none;
					background-color: color-palette(tone-primary);
					color: color-palette(neutral);
			}
			}
		}
	} 
}

// Include component here
@include tab-header('.tab__header');
@include tab-header('.tab__header--active', 'active');
@include tab-header('.tab__header--inline', 'inline');
@include tab-header('.tab__header--button', 'button');
@include tab-header('.tab__header--no-padding', 'no-padding');