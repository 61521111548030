// Declare skins here

%at-simulation__list-skin-default {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

%at-simulation__list-skin-consents {
  display: table;
  position: relative;
  margin: 0 auto;
  list-style-type: disc;
  text-align: left;
  
  @include gridle_state (mobile) {
    margin-left: 2rem;
  }

}

%at-simulation__list-skin-palier-recap {
  display: table;
  margin-top: 5rem;
}

%at-simulation__list-skin-garantie-recap {
  text-align: left;
}

@mixin simulation__list($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__list-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__list('.simulation__list');
@include simulation__list('.simulation__list--consents','consents');
@include simulation__list('.simulation__list--palier-recap','palier-recap');
@include simulation__list('.simulation__list--garantie-recap','garantie-recap');
