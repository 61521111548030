// Declare skins here

%at-info-block-skin-default {
  // Default skin here
  min-height: 25.5rem;
  margin-bottom: 1.7rem;
  width: 100%;
  display: inline-block;

  @include gridle_state (  mobile  ){
    background: none;
    text-align: center;
    min-height: 40rem;
    margin: 0;
  }
}

%at-info-block-skin-title {
  font-size: $font-size-normal;
  color: color-palette(tone-primary);
}

%at-info-block-skin-primary {
  background-color: color-palette(tone-primary);
}

%at-info-block-skin-secondary {
  background-color: color-palette(tone-secondary);
}

%at-info-block-skin-hand {
  background-image: url('/assets/images/info-hand.svg');
  background-repeat: no-repeat;
  background-position: 4rem 100%;
  background-size: auto 18rem;

  @include gridle_state (  tablet  ){
background-size: auto 11rem;
  }

  @include gridle_state (  mobile  ){
    background-size: auto 10rem;
    .info-block__text{
      margin-top: 6rem;
    }
  }

}

%at-info-block-skin-speaker {
  background-image: url('/assets/images/Megaphone.svg');
  background-repeat: no-repeat;
  background-position: 4rem 1rem;
  background-size: 13rem 100%;

  @include gridle_state (  tablet  ){
    background-size: auto 10rem;
    background-position: 2rem 8rem;
    background-image: none;

    ul{
      list-style-type: none;
      padding: 0;
    }

  }

  @include gridle_state (  mobile  ){
    background-position: center -8rem;
    background-size: 10rem 100%;
    background-image: url('/assets/images/Megaphone.svg');
    ul{
      list-style-type: circle;
      padding: 0;
    }
  }

  .info-block__content-wrapper{
    padding-top: 0;

    @include gridle_state (  mobile  ){
      margin-top: 12rem;
    }
  }
}


@mixin info-block($selector, $skin: default) {
  #{$selector} {
    @extend %at-info-block-skin-#{$skin} !optional;
    @if($skin == default){

      &__content-wrapper{
        text-align: left;
        width: 62%;
        float: right;
        padding-top: 5rem;

        @include gridle_state (  tablet  ){
          width: 100%;
          text-align: center;
          padding: 0 3rem;
        }
      }

      &--primary &--hw{
        color: color-palette(neutral);
      }
    }
  }
}

// Include component here
@include info-block('.info-block');
@include info-block('.info-block--primary', 'primary');
@include info-block('.info-block--secondary', 'secondary');
@include info-block('.info-block--hand', 'hand');
@include info-block('.info-block--speaker', 'speaker');
@include info-block('.info-block__title', 'title');
