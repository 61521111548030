// Declare skins here

%at-footer-subtitle-skin-default {
  // Default skin here
  color: color-palette(tone-secondary);
  font-size: 1.5rem;
  margin: 0;
  font-family: $font-bold;
}

%at-footer-subtitle-skin-hw {
  font-family: $font-hw-bold;
}

@mixin footer-subtitle($selector, $skin: default) {
  #{$selector} {
    @extend %at-footer-subtitle-skin-#{$skin} !optional;
  }
}

// Include component here
@include footer-subtitle('.footer__subtitle');
@include footer-subtitle('.footer__subtitle--hw','hw');
