// Declare skins here

%at-redirect-form-skin-default {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

%at-redirect-form-skin-button {
  margin-top: 4rem;
}

@mixin redirect-form($selector, $skin: default) {
  #{$selector} {
    @extend %at-redirect-form-skin-#{$skin} !optional;


    &--loginText {
      margin: 2rem 0;
      color: color-palette(tone-primary-dark);
    }

    &--locality-link, &--login-link {
      color: color-palette(tone-primary);
      text-decoration: underline;
      cursor: pointer;
    }

  }
}

// Include component here
@include redirect-form('.redirect-form');
@include redirect-form('.redirect-form--button', 'button');
