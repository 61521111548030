%at-content-open-nav-skin-default {
  @include gridle_state(mobile) {
    height: 100%;
    overflow: hidden;
    //pointer-events: none !important;
  }
}

@mixin content-open-nav($selector, $skin: default) {
  #{$selector} {
    @extend %at-content-open-nav-skin-#{$skin} !optional;
  }
}

// Include component here
@include content-open-nav('.content-open-nav');
