@keyframes iconLoading {
  from { transform: rotateZ(0deg); }
  to { transform: rotateZ(360deg); }
}
.icon--loading:before {
  display: inline-block;
  animation: iconLoading 1s infinite linear;
}

.loader {
  display: inline-block;
  animation: iconLoading 1s infinite linear;
  background-image: url('/assets/images/loader.png');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 5.5rem;
  height: 5.5rem;
}
