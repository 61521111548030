/* Document rules (*, body, html)
=================================

Force "border-box" for all elements, reset "font-size" for a "base 10" REM compatibility

*/

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

//* {
//  -webkit-touch-callout: none;
//  -webkit-user-select: none; /* Disable selection/copy in UIWebView */
//}

html {
  min-height: 100%;
  font-size: 62.5%; // Set baseline font size to 10px, This is used as a baseline for rem (root ems) values
  overflow-x: hidden;
}

body {
  position: relative;
  height: 100%;
  font-family: $font-regular;
  font-size: 1.6em; // Bump font-size back up to 16px
  line-height: 1.4;
  overflow-x: hidden;
  overflow-y: hidden;

  .brand{
    font-family: $font-bold;
    &--hw{
      font-family: $font-hw-regular;
    }
  }
}

input {
  -webkit-user-select: auto;
}

h1,h2,h3,h4,h5,h6 {
  line-height: normal;
  margin:0;
  padding:0;
  font-weight: normal;
}

h1 {
  font-family: $font-medium;
  font-size: 7.6rem;

  @include gridle_state(tablet) {
    font-size: 6rem;
  }
}

h2 {
  font-family: $font-medium;
  font-size: 3.8rem;
}

h3 {
  font-size: 2.3rem;
}
