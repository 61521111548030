// Declare skins here

%at-slider-menu-skin-default {
  margin-top: 0 !important;
  position: relative;
  padding: 0 1rem
}

%at-slider-menu-skin-title {
  width: 15rem;
  margin: 0 auto;
  text-align: center;
  display: block;
  border-bottom: .5rem solid color-palette(tone-primary);
  padding-bottom: 1.2rem;
}

%at-slider-menu-skin-arrow {
  display: block;
  font-size: 2.2rem;
  color: color-palette(neutral-grey-black);
  background: none;
  outline: none;
  border: none;
  position: absolute;
  top: 1rem;
  z-index: 10;
}



@mixin slider-menu($selector, $skin: default) {
  #{$selector} {
    @extend %at-slider-menu-skin-#{$skin} !optional;


    .slider__frame {

      width: 100%;

      position: relative;
      overflow: hidden;
      white-space: nowrap;
    }

    .slides {
      display: inline-block;
    }

    li {
      position: relative;
      display: inline-block;
      width: 100%;
      text-align: center;
    }

    .prev, .next {
      position: absolute;
      top: 0;
      display: block;
      cursor: pointer;
    }

    .next {
      right: 0;
    }

    .prev {
      left: 0;
    }

  }
}

// Include component here
@include slider-menu('.slider-menu');
@include slider-menu('.slider-menu--title','title');
@include slider-menu('.slider-menu--arrow','arrow');
