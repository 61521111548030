%at-offer-aside-skin-default {
  // Default skin here

  display: table-cell;
}

@mixin offer-aside($selector, $skin: default) {
  #{$selector} {
    @extend %at-offer-aside-skin-#{$skin} !optional;

    &__item {
      margin-bottom: 4rem;
    }

    &__item:last-child {
      margin-bottom: 0;
    }
  }
}

// Include component here
@include offer-aside('.offer__aside');
