@import "../circlearrowicon";

// Declare skins here
%at-pill__header-skin-default {
  padding : 1.5rem;
  width:90%;
  color : color-palette(neutral-grey-black);

}

%at-pill__header-skin-link {
  @include icon(arrow-right, after);
  &:after{
    position:absolute;
    right:0;
    margin-right:1.7rem;

  }
}
%at-pill__header-skin-link-content {
  @extend %at-circle-arrow-icon-skin-default;
  &:after{
    transform: rotate(90deg);
    position:absolute;
    right:0;
    margin-right:1.7rem;

  }
}

@mixin pill-header($selector, $skin: default) {
  #{$selector} {
    @extend %at-pill__header-skin-#{$skin} !optional;

    @if($skin == default) {
      &__title{
        text-transform: uppercase;

      }
      &__subtitle{
        color : color-palette(tone-secondary);
        &::before{
          content:"\a";
          white-space: pre;
        }
      }

    }

    /*@if($skin == 'inline'){
      &.pill__header--active{
        border-bottom : solid 0.7rem color-palette(tone-primary);
        color : color-palette(tone-primary);
      }
    }*/
  }
}

// Include component here
@include pill-header('.pill__header');
@include pill-header('.pill__header--link', 'link');
@include pill-header('.pill__header--link-content', 'link-content');
@include pill-header('.pill__header--active', 'active');