// Declare skins here

%at-documentary-space-skin-default {
  // Default skin here
}

@mixin documentary-space($selector, $skin: default) {
  #{$selector} {
    @extend %at-documentary-space-skin-#{$skin} !optional;

    &__legend {
      font-family: $font-medium;
      font-size: 1.4rem;
      color: color-palette(neutral);
      background-color: color-palette(tone-primary);
      border-radius: 2rem;
      text-transform: capitalize;
      padding: 0.5rem 1.5rem;
      margin-right: 1.5rem;
      &--grey{
        background-color: color-palette(tone-primary-dark);
      }
      &--green{
        background-color: color-palette(tone-secondary);
      }
      &--blue{
        background-color: color-palette(tone-primary);
      }
      &--cyan{
        background-color: color-palette(tone-tertiary);
      }
    }

    &__legendSimple{
      text-transform: none;
    }

    &__search {
      @include icon(recherche);
      text-align: left;
      display: inline;

      &::before{
        margin-right: 1rem;
        font-size: 2rem;
        display: inline;
        position: absolute;
        bottom: 0.4rem;
        color:color-palette(tone-secondary);
      }

      .angucomplete-holder {
        width: 100%;
        padding: 0 3rem;
        color: color-palette(tone-primary-dark);
      }

      &-wrapper{
        position: relative;
        padding-bottom: 0.3rem;
        margin-bottom: 1rem;
        border-bottom: 0.1rem solid color-palette(tone-secondary-dark);
      }
    }

    &__document-icon{
      @include icon(document);
      font-size: 3rem;
      margin-right: 1rem;
      vertical-align: middle;

      @include gridle_state(mobile){
        margin-right: 0.5rem;
      }
    }

    &__button-clear{
      border: none;
      background: none;
      display: inline-block;
      position: absolute;
      right: 0;
      bottom: 0.7rem;
      transition: color 0.2s ease-out;
      &:focus{
        outline: none;
      }
      &:hover{
        color: color-palette(tone-error);
      }
    }

    &__button-download {
      @include icon(download);
      border: none;
      background: none;
      position: relative;
      font-size: 2.5rem;
      color: color-palette(tone-primary);
      &:focus{
        outline: none;
      }

      &--secure {
        &::after {
          display: block;
          content: '';
          width: 1.5rem;
          height: 1.5rem;
          position: absolute;
          bottom: 0.3rem;
          right: 0;
          background: url("/assets/images/download-secure.svg") no-repeat center center;
        }
      }
    }

    &__project-header {
      font-family: $font-medium;
      font-size: 2.4rem;
      margin-bottom: 4rem;
      color: color-palette(tone-primary);
    }

    &__documents-header {
      height: 5rem;
      font-size: $font-size-normal;
      font-family: $font-bold;
      text-transform: uppercase;
      color: color-palette(neutral);
      background-color: color-palette(tone-primary);
      text-align: left;
      padding: 1rem 0 0 2rem;
    }
    &__documents-button {
      margin-top: 0;
      height: 6rem;
      text-align: center;
      padding-bottom: 1rem;
      border : 0.1rem solid color-palette(tone-primary);
      border-top: none;
      margin-bottom: 3rem;
    }

    &__table {
      border: solid 0.1rem color-palette(tone-primary);
      font-family: $font-regular;
      font-size: $font-size-normal;
      color: color-palette(tone-primary);
      width: 100%;
      margin-bottom: 3rem;

      tr {
        height: 5rem;
      }

      th {
        font-weight: normal;
      }

      &-cell {
        text-align: center;
        &--left {
          text-align: left !important;
          padding-left: 2rem;
          width: 60%;
        }
        &--right {
          text-align: right !important;
        }
        &--link{
          cursor: pointer;
        }
        &--bordered{
          padding: 1rem;
          border-top: 0.1rem solid color-palette(thead-grey);
        }

      }

      &-header {
        background-color: color-palette(thead-grey);
      }

    }

    &__table--noborder{
      border-bottom: none;
      margin-bottom:0;
    }

    &__files-list{
      .filefield--empty{
        padding-bottom: 2rem;
        max-width: 30rem;
        &:before {
          left: 50%;
        }
      }

      .files-list__file{
        max-width: 30rem;
        width: 100%;
        margin: 0 auto;
      }

    }

    @include gridle_state(mobile){
      &__table {
        table-layout: fixed;
        &-cell--link{
          text-decoration: underline;
        }
        &-row--disabled{
          opacity: 0.5;
        }

      }
    }
  }
}

// Include component here
@include documentary-space('.documentary-space');
