%at-document-selection-type-skin-default {
  @include gridle_state(mobile) {
    background: #fff;
    border-top: #cdccccdb solid 1px;
    position: relative;
    top: -2rem;
  }
}

%at-document-selection-type-skin-page {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/assets/images/background-3.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  & .adhesion-stepper {
    display: none;
  }

  & .cand-modal__message {
    padding: 1rem 1rem 0;
  }

  @include gridle_state(mobile) {
    background: none !important;

    & .footer-top-margin {
      margin-top: 0;
    }
  }
}

@mixin document-selection-type($selector, $skin: default) {
  #{$selector} {
    @extend %at-document-selection-type-skin-#{$skin} !optional;

    @include gridle_state(mobile) {
      margin-bottom: -3.5rem;
    }

    @if $skin == default {
      &__header {
        padding: 6.6rem 3rem 3rem;
        text-align: center;
        text-transform: uppercase;

        @include gridle_state(mobile) {
          padding: 4.7rem 3rem 1.3rem;
        }

        &-title {
          font-size: 3rem;

          @include gridle_state(mobile) {
            font-size: 1.8rem;
          }
        }
        &-subtitle {
          color: #666666;
          font-weight: 300;
          font-size: 1.6rem;
          padding-top: 2rem;
          padding-bottom: 2rem;
          text-transform: initial;
        }
        &-descriptiontitle {
          color: #666666;
          font-weight: 300;
          font-size: 1.6rem;
          margin-right: 15rem;
          margin-left: 15rem;
          padding-bottom: 2rem;
          text-transform: initial;
          @include gridle_state(mobile) {
            margin-right: 0rem;
            margin-left: 0rem;
          }
        }

        &-description {
          font-size: 1.8rem;
          font-weight: 300;
          line-height: 27px;
          margin-top: 4rem;
          &--details {
            font-size: initial;
            text-transform: initial;
          }
          @include gridle_state(mobile) {
            font-size: 1.6rem;
            margin-top: 2.9rem;
          }
        }
      }

      &__footer {
        margin-top: 4rem;
        padding: 3.5rem 2.5rem 8rem;

        @include gridle_state(mobile) {
          align-items: center;
          border-top: none;
          display: flex;
          flex-direction: column-reverse;
          justify-content: center;
          margin-top: 0;
          padding: 3.5rem 0 3rem;
        }

        .button--disabled {
          opacity: 0.3;
        }

        button {
          @include gridle_state(mobile) {
            width: 100%;
          }
        }

        .grid-mobile-4 {
          @include gridle_state(mobile) {
            padding: 0;
            width: 80%;
          }
          &:not(:last-child) {
            @include gridle_state(mobile) {
              margin-top: 1rem;
            }
          }
        }
      }

      &__content {
        align-items: center;
        display: flex;
        flex-direction: column;
      }

      &__buttons {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        @include gridle_state(mobile) {
          flex-direction: column;
          width: 80%;
        }
        &__file {
          &-library,
          &-photo {
            position: absolute;
            opacity: 0;
            width: 0;
            top: -100px;
          }
        }
        .button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          height: 139px;
          width: 32%;
          max-width: 32%;

          @include gridle_state(mobile) {
            align-items: center;
            display: flex;
            height: 85px;
            flex-direction: row;
            justify-content: center;
            max-width: 100%;
            padding: 0 2rem;
            width: 100%;
            position: relative;
          }

          &:not(:last-child) {
            margin-right: 2.6rem;

            @include gridle_state(mobile) {
              margin-right: 0;
              margin-bottom: 2rem;
            }
          }
        }
      }

      &__button {
        padding: 2.3rem 4rem 1.6rem;
        &-input {
          width: 27%;
          position: absolute;
          height: 13rem;
          opacity: 0;
          cursor: pointer;
          @include gridle_state(mobile tablet) {
            width: 0;
          }
          &--mobile-show {
            @include gridle_state(mobile) {
              width: 80%;
              height: 8.5rem;
            }
            @include gridle_state(tablet) {
              display: flex;
              justify-self: stretch;
              align-self: center;
              width: inherit;
            }
          }
          &--in-modal {
            bottom: 0;
            right: 0;
            @include gridle_state(tablet) {
              margin: 2.1rem;
              height: 15%;
              width: 20rem;
            }
            @include gridle_state(mobile) {
              margin: 1.1rem 0.7rem;
              height: 17%;
              width: 13.2rem;
            }
          }
        }
        &-image {
          width: 25%;

          &--left {
            background: url("/assets/images/icon-monitor.svg") no-repeat;
            background-size: 4.7rem !important;
            height: 4.2rem;

            @include gridle_state(mobile) {
              background-size: 4rem !important;
              background-image: url("/assets/images/icon-library.png");
              background-repeat: no-repeat;
              height: 5rem;
            }
          }

          &--right {
            background: url("/assets/images/icon-shake-phone.svg") no-repeat;
            background-size: 4.5rem !important;
            height: 5rem;

            @include gridle_state(mobile) {
              background-size: 4rem !important;
              background-image: url("/assets/images/icon-camera.png");
              background-repeat: no-repeat;
              height: 4.7rem;
            }
          }
        }

        &-label {
          padding: 1rem 2rem 0;
          & .button-text {
            color: #000000;
          }
          @include gridle_state(mobile) {
            font-size: 1.2rem;
            padding: 1rem 0;
            text-align: left;
            width: 75%;
          }
        }
      }

      &__files {
        margin-top: 4rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: #f7f7f7;
        width: 50%;
        @include gridle_state(mobile) {
          width: 80%;
          margin-top: 3rem;
        }
        &__count-label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.5rem 2rem;
          @include gridle_state(mobile) {
            padding: 0rem 0.5rem;
          }
          &__text {
            padding-left: 1rem;
            @include gridle_state(mobile) {
              font-size: 1.2rem;
              padding: 1.2rem 1rem;
            }
          }

          &__icon {
            display: flex;
            justify-content: flex-end;
            padding: 1rem;
          }
        }
        &__list {
          padding: 2rem 3rem;
          list-style-type: none;
          margin: 0;
          border-top: 1px solid #d3d3d3;
          li {
            &:not(:last-child) {
              padding-bottom: 1rem;
            }
            &::before {
              content: "\2022";
              color: #84d000;
              display: inline-block;
              width: 1.2rem;
              font-weight: 800;
            }
          }
        }
      }
    }
  }
}

@include document-selection-type(".document-selection-type");
@include document-selection-type(".document-selection-type-page", "page");
