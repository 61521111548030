%at-angucomplete-input-skin-default {
  // Default skin here

  color: color-palette(tone-secondary);
}

@mixin angucomplete-input($selector, $skin: default) {
  #{$selector} {
    @extend %at-angucomplete-input-skin-#{$skin} !optional;

    &.ng-pristine:not(.ng-valid) {
      color: color-palette(neutral-grey-dark);
      border-color: color-palette(neutral-grey-dark);
      background-color: color-palette(neutral-grey);
    }
    
    // Fix dropdown.
    &.textfield + .angucomplete-dropdown {
      top: 3.6rem;
    }
  }
}

// Include component here
@include angucomplete-input('.angucomplete-input');
