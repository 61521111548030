// Declare skins here

%at-slider-skin-default {
  // Default skin here
  position: relative;
  margin-top: 3rem;
  padding: 0 1rem;

  @include gridle_state(mobile) {
    margin-top: 0;
     }
  .js_slide {
    display: inline-block;
    float: none !important;
    vertical-align: top;
    white-space: normal;

  }

  .slides {
    margin: 0;
    padding : 0;
  }
}

@mixin slider($selector, $skin: default) {
  #{$selector} {
    @extend %at-slider-skin-#{$skin} !optional;
  }
}

// Include component here
@include slider('.slider');
