@import "../5_templates/_appointment";

.appointment-details-page {
  @include gridle_state(mobile) {
    padding-bottom: 0;
    .appointment {
      padding-bottom: 5rem;
    }
  }
}

@mixin appointment($selector) {
  #{$selector} {
    .simulation__buttons.simulation__buttons--light {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 0px;
      padding-right: 0px;
    }
    &__link {
      @extend %important-text;
      color: #074624;
      text-decoration: underline;
      cursor: pointer;
    }
    @include appointment-header(#{$selector});
    .appointment-details &__header {
      @include gridle_state(mobile) {
        &-back {
          margin-top: 2rem;
          z-index: 1;
        }
        &-icon {
          margin-bottom: 3rem;
        }
      }
      &-title {
        font-size: 30px;
        font-weight: 600;
        line-height: 37px;
        @include gridle_state(mobile) {
          padding: 0;
          font-size: 20px;
          font-weight: 600;
          line-height: 20px;
          left: 0;
          position: absolute;
          width: 100%;
        }
        &--light {
          color: #666666;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          &--city {
            display: inline-block;
          }
          @include gridle_state(mobile) {
            margin-top: 1rem;
            text-transform: capitalize;
            &--city {
              display: block;
            }
          }
        }
      }
    }
    &__body {
      text-align: center;
      &__changecenter {
        color: #4c4c4c;
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        background-color: rgba(132, 208, 0, 0.2);
        border: none;
        padding: 1.5rem 3rem;
        &:hover,
        &:focus {
          background-color: rgba(132, 208, 0, 0.2);
          color: #4c4c4c;
        }
        @include gridle_state(mobile) {
          padding: 0.8rem 3.4rem;
          border-width: 1px;
        }
      }
      &__duration-information {
        color: #4c4c4c;
        font-size: 14px;
        line-height: 18px;
        margin: 2rem 0;
        @include gridle_state(mobile) {
          width: 27rem;
          margin: 2rem auto;
        }
      }
      &__accordions {
        margin: 2rem 0;
        .pegase-accordion {
          &__body {
            &__hours {
              text-align: left;
              padding: 1.5rem;
              &__hour {
                display: inline-block;
                text-align: center;
                min-width: calc(100% / 8 - 1.2rem);
                @include gridle_state(mobile) {
                  min-width: calc(100% / 4 - 1.2rem);
                }
                cursor: pointer;
                color: #666666;
                font-size: 14px;
                font-weight: 500;
                line-height: 28px;
                margin: 0.6rem;
                border-radius: 3px;
                background-color: #ececec;
                &.active {
                  background-color: #074624;
                  color: white;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }

      .appointment-details__link--info {
        color: #4c4c4c;
        font-weight: 500;
        text-decoration: none;
        margin: 5rem 0 2.5rem 0;
        span {
            display: block;
        }
        u {
          font-weight: 600;
          cursor: pointer;
          display: block;
        }
      }
    }
    &__footer {
      @include gridle_state(mobile) {
        &.simulation__buttons {
          flex-direction: column-reverse;
          padding: 2rem !important;
          align-items: stretch !important;
        }
      }
      &__back {
        display: flex;
        flex: 1;
        align-items: center;
        text-align: center;
        margin-left: 3rem;
        @include gridle_state(mobile) {
          flex-direction: column;
          align-items: stretch;
          margin-left: 0;
        }

        .button.button--grey {
          @include gridle_state(tablet) {
            min-width: 21rem;
          }
          @include gridle_state(mobile) {
            margin-bottom: 1rem;
          }
        }
      }
      &__next {
        margin-right: 3rem;

        @include gridle_state(mobile) {
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

@include appointment(".appointment-details");
