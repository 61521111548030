// Declare skins here

%at-project-choice-skin-default {
  // Default skin here
}



@mixin project-choice($selector, $skin: default) {
  #{$selector} {
    @extend %at-project-choice-skin-#{$skin} !optional;

    @if $skin == default {
    &__title{
      color: color-palette(tone-primary);
      font-family: $font-medium;
      font-size: 2rem;
      line-height: 1.3;
      text-transform: uppercase;
      margin-bottom: 3.5rem;
      padding: 0 3rem;
      text-align: center;
    }

    &__list{
      margin: 0 0 2rem 0; padding: 0;
      list-style-type: none;
      height: 23rem;
    }

    &__data-block{
      margin: 2rem 0;
    }

    &__item {
      color: color-palette(tone-primary-dark);
      line-height: 1;
      padding: 0 3rem;

      & + & {
        margin-top: 1.4rem;
      }

      span {
        font-family: $font-medium;
      }
    }

      @include gridle_state(mobile) {
        &__list{
          height: auto;
        }
        &__data-block{
          text-align: center;
        }
      }

    }
  }
}

// Include component here
@include project-choice('.project-choice');
