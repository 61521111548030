%at-login-viewer-skin-default {
  // Default skin here
  display: block;
  text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 0;
  font-size: 0;
  color: color-palette(tone-secondary);
}

@mixin login-viewer($selector, $skin: default) {
  #{$selector} {
    @extend %at-login-viewer-skin-#{$skin} !optional;

    &__item {
      display: inline-block;
      border: .1rem solid color-palette(tone-primary);
      font-size: 3.5rem;
      width: 4rem;
      height: 5rem;
      line-height: 5rem;

      & + & {
        margin-left: .5rem;
      }
    }

    &__title + &__wrapper {
      margin-top: 2rem;
    }

    &__reset{
      margin-bottom: 2rem;
    }
  }
}

// Include component here
@include login-viewer('.login-viewer');
