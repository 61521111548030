%at-cand-breadcrumb-skin-default {
  // Default skin here

  list-style: none;
  font-size: 1.4rem;
  padding-left: 0;
}

@mixin cand-breadcrumb($selector, $skin: default) {
  #{$selector} {
    @extend %at-cand-breadcrumb-skin-#{$skin} !optional;

    &__item {
      display: inline-block;

      & + &::before {
        content: ' | ';
        color: color-palette(tone-primary-light);
      }

      &.active {
        color: color-palette(tone-primary);
      }
    }

    &__link {
      color: color-palette(tone-primary-light);
      text-decoration: none;

      &:hover {
        color: color-palette(tone-primary-dark);
      }
    }
  }
}

// Include component here
@include cand-breadcrumb('.cand-breadcrumb');
