// Declare skins here

%at-proposition__step-skin-default {
  padding: 0;
}

@mixin proposition__step($selector, $skin: default) {
  #{$selector} {
    @extend %at-proposition__step-skin-#{$skin} !optional;

    &__content {
      padding-top: 5rem;
    }
  }
}

// Include component here
@include proposition__step('.proposition__step');
