@import '../default/2_atoms/pagesubtitle';

%at-page-subtitle-skin-default {
  font-size: 1.6rem;
  padding-bottom: 3rem;
}
%at-page-subtitle-skin-uppercase {
  text-transform: uppercase;
}
%at-page-subtitle-skin-centered {
  text-align: center;
}
%at-page-subtitle-skin-no-padding {
  padding-bottom: 0;
}
@include page-subtitle('.page-subtitle--uppercase', 'uppercase');
@include page-subtitle('.page-subtitle--centered', 'centered');
@include page-subtitle('.page-subtitle--no-padding', 'no-padding');