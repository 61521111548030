// Declare skins here

%at-glossary-results-item-skin-default {
    padding-top: 1rem;
    padding-bottom: 1rem;

    &:nth-child(3n+1) {
      @include gridle_no_gutter(left);
      @include gridle_gutter(left right, mobile);
    }

    &:nth-child(3n+3) {
      @include gridle_no_gutter(right);
      @include gridle_gutter(left right, mobile);
    }
}

@mixin glossary-results-item ($selector, $skin: default) {
  #{$selector} {
    @extend %at-glossary-results-item-skin-#{$skin} !optional;
  }
}

// Include component here
@include glossary-results-item('.glossary-results__item');
