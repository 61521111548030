%at-differed-decision-introduction-skin-default {
  @include gridle_state(mobile) {
    box-shadow: inset 0 7px 8px -8px rgba(0, 0, 0, 0.6);
    padding-bottom: 7rem;
    padding-top: 1rem;
  }

  & .simulation__buttons {
    margin: 0;

    @include gridle_state(mobile) {
      border-top: none;
      display: flex;
      justify-content: center;
    }

    & .button--primary {
      @include gridle_state(mobile) {
        min-width: 100%;
      }
    }
  }
}

%at-differed-decision-introduction-skin-page {
  @include gridle_state(mobile) {
    padding-bottom: 0;

    & .simulation__group {
      margin-bottom: 0;
    }
  }
}
@mixin differed-decision-introduction($selector, $skin: default) {
  #{$selector} {
    @extend %at-differed-decision-introduction-skin-#{$skin} !optional;

    &__content {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 6rem 0 3.5rem;

      &-image {
        background: url("/assets/images/illustre.svg") no-repeat;
        background-size: 27rem;
        height: 27rem;
        width: 27rem;
      }
      &-title {
        font-size: 3rem;
        font-weight: 600;
        line-height: 3.7rem;

        @include first-letter-uppercase();

        @include gridle_state(mobile) {
          font-size: 2.5rem;
        }
      }
      &-description {
        padding: 4rem 23rem;
        text-align: center;

        @include first-letter-uppercase();

        @include gridle_state(mobile) {
          padding: 4rem 1rem 2rem;
        }
      }
      &__link {
        color: #666666;
        font-size: 1.4rem;
        line-height: 1.7rem;
        max-width: 30rem;
        text-align: center;
        background-color: #f6f6f6;
        padding: 1rem;
        border-radius: 2px;
        a {
          color: #666666;
        }
      }
    }
  }
}

@include differed-decision-introduction(".differed-decision-introduction");
@include differed-decision-introduction(".differed-decision-introduction-page", "page");
