$font-regular: 'montserrat';
$font-medium: $font-regular;
$font-bold: $font-regular;
$font-black: $font-regular;

$font-hw: null;
$font-hw-regular: 'lato';
$font-hw-bold: null;

@font-face {
    font-family: $font-regular;
    src: url('/assets/fonts/montserrat-ultralight-webfont.eot');
    src: url('/assets/fonts/montserrat-ultralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/montserrat-ultralight-webfont.woff') format('woff'),
         url('/assets/fonts/montserrat-ultralight-webfont.ttf') format('truetype'),
         url('/assets/fonts/montserrat-ultralight-webfont.svg#montserratultra_light') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: $font-regular;
    src: url('/assets/fonts/montserrat-light-webfont.eot');
    src: url('/assets/fonts/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/montserrat-light-webfont.woff') format('woff'),
         url('/assets/fonts/montserrat-light-webfont.ttf') format('truetype'),
         url('/assets/fonts/montserrat-light-webfont.svg#montserratlight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: $font-regular;
    src: url('/assets/fonts/montserrat-regular-webfont.eot');
    src: url('/assets/fonts/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/montserrat-regular-webfont.woff') format('woff'),
         url('/assets/fonts/montserrat-regular-webfont.ttf') format('truetype'),
         url('/assets/fonts/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: $font-regular;
    src: url('/assets/fonts/montserrat-semibold-webfont.eot');
    src: url('/assets/fonts/montserrat-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/montserrat-semibold-webfont.woff') format('woff'),
         url('/assets/fonts/montserrat-semibold-webfont.ttf') format('truetype'),
         url('/assets/fonts/montserrat-semibold-webfont.svg#montserratsemi_bold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: $font-regular;
    src: url('/assets/fonts/montserrat-bold-webfont.eot');
    src: url('/assets/fonts/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/montserrat-bold-webfont.woff') format('woff'),
         url('/assets/fonts/montserrat-bold-webfont.ttf') format('truetype'),
         url('/assets/fonts/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: $font-regular;
    src: url('/assets/fonts/montserrat-black-webfont.eot');
    src: url('/assets/fonts/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/montserrat-black-webfont.woff') format('woff'),
         url('/assets/fonts/montserrat-black-webfont.ttf') format('truetype'),
         url('/assets/fonts/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: $font-regular;
    src: url('/assets/fonts/montserrat-extrabold-webfont.eot');
    src: url('/assets/fonts/montserrat-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/montserrat-extrabold-webfont.woff') format('woff'),
         url('/assets/fonts/montserrat-extrabold-webfont.ttf') format('truetype'),
         url('/assets/fonts/montserrat-extrabold-webfont.svg#montserratextra_bold') format('svg');
    font-weight: 900;
    font-style: normal;
}

/*@font-face {
    font-family: 'montserrathairline';
    src: url('/assets/fonts/montserrat-hairline-webfont.eot');
    src: url('/assets/fonts/montserrat-hairline-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/montserrat-hairline-webfont.woff') format('woff'),
         url('/assets/fonts/montserrat-hairline-webfont.ttf') format('truetype'),
         url('/assets/fonts/montserrat-hairline-webfont.svg#montserrathairline') format('svg');
    font-weight: normal;
    font-style: normal;
}*/

@font-face {
    font-family: $font-hw-regular;
    src: url('/assets/fonts/lato-regular-webfont.eot');
    src: url('/assets/fonts/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/lato-regular-webfont.woff') format('woff'),
         url('/assets/fonts/lato-regular-webfont.ttf') format('truetype'),
         url('/assets/fonts/lato-regular-webfont.svg#latoregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

/*@font-face {
    font-family: 'latoblack';
    src: url('/assets/fonts/lato-black-webfont.eot');
    src: url('/assets/fonts/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/lato-black-webfont.woff') format('woff'),
         url('/assets/fonts/lato-black-webfont.ttf') format('truetype'),
         url('/assets/fonts/lato-black-webfont.svg#latoblack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoblack_italic';
    src: url('/assets/fonts/lato-blackitalic-webfont.eot');
    src: url('/assets/fonts/lato-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/lato-blackitalic-webfont.woff') format('woff'),
         url('/assets/fonts/lato-blackitalic-webfont.ttf') format('truetype'),
         url('/assets/fonts/lato-blackitalic-webfont.svg#latoblack_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latobold';
    src: url('/assets/fonts/lato-bold-webfont.eot');
    src: url('/assets/fonts/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/lato-bold-webfont.woff') format('woff'),
         url('/assets/fonts/lato-bold-webfont.ttf') format('truetype'),
         url('/assets/fonts/lato-bold-webfont.svg#latobold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latobold_italic';
    src: url('/assets/fonts/lato-bolditalic-webfont.eot');
    src: url('/assets/fonts/lato-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/lato-bolditalic-webfont.woff') format('woff'),
         url('/assets/fonts/lato-bolditalic-webfont.ttf') format('truetype'),
         url('/assets/fonts/lato-bolditalic-webfont.svg#latobold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latohairline';
    src: url('/assets/fonts/lato-hairline-webfont.eot');
    src: url('/assets/fonts/lato-hairline-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/lato-hairline-webfont.woff') format('woff'),
         url('/assets/fonts/lato-hairline-webfont.ttf') format('truetype'),
         url('/assets/fonts/lato-hairline-webfont.svg#latohairline') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latohairline_italic';
    src: url('/assets/fonts/lato-hairlineitalic-webfont.eot');
    src: url('/assets/fonts/lato-hairlineitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/lato-hairlineitalic-webfont.woff') format('woff'),
         url('/assets/fonts/lato-hairlineitalic-webfont.ttf') format('truetype'),
         url('/assets/fonts/lato-hairlineitalic-webfont.svg#latohairline_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoitalic';
    src: url('/assets/fonts/lato-italic-webfont.eot');
    src: url('/assets/fonts/lato-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/lato-italic-webfont.woff') format('woff'),
         url('/assets/fonts/lato-italic-webfont.ttf') format('truetype'),
         url('/assets/fonts/lato-italic-webfont.svg#latoitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latolight';
    src: url('/assets/fonts/lato-light-webfont.eot');
    src: url('/assets/fonts/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/lato-light-webfont.woff') format('woff'),
         url('/assets/fonts/lato-light-webfont.ttf') format('truetype'),
         url('/assets/fonts/lato-light-webfont.svg#latolight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latolight_italic';
    src: url('/assets/fonts/lato-lightitalic-webfont.eot');
    src: url('/assets/fonts/lato-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/lato-lightitalic-webfont.woff') format('woff'),
         url('/assets/fonts/lato-lightitalic-webfont.ttf') format('truetype'),
         url('/assets/fonts/lato-lightitalic-webfont.svg#latolight_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}*/
