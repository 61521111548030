// Declare skins here

%at-simulation__groupLink-skin-default {
  font-family: $font-regular;
  color: color-palette(neutral-grey-dark);
  font-size: 1.4rem;
  text-align: center;
  margin: 0 auto 5rem;
  line-height: 4.5rem;
  list-style-type: none;
  padding: 0;

  @include gridle_state(mobile) {
    margin-bottom: 2.5rem;
  }

  &:hover {
    text-decoration: none;
  }

}

%at-simulation__groupLink-skin-newline {
  &:before {
    content: '';
    display: block;
  }
}

%at-simulation__groupLink-skin-consents {
    font-size: 2rem;
    line-height: 2rem;
    margin: 0;
    cursor: pointer;
}

%at-simulation__groupLink-skin-bold{
  font-weight: bold;
  font-size: 1.8rem;
}
@mixin simulation__groupLink($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__groupLink-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__groupLink('.simulation__groupLink');
@include simulation__groupLink('.simulation__groupLink--newline','newline');
@include simulation__groupLink('.simulation__groupLink--bold','bold');
@include simulation__groupLink('.simulation__groupLink--consents','consents');
