// Declare skins here

%at-simulation__legals-skin-default {
  display: block;
  font-size: 1.4rem;
  font-family: $font-regular;
  margin-top: 1rem;
}


@mixin simulation__legals($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__legals-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__legals('.simulation__legals');
