@import "../0_utilities/defaults";

%at-appointment-type-skin-default {
  box-shadow: inset 0 8px 8px -8px rgba(0, 0, 0, 0.6);
  display: block;
  padding-top: 3rem;

  .button.button--primary {
    margin-top: 2rem;
    line-height: 1rem;
  }

  @include gridle_state(mobile) {
    padding-top: 0;
    padding-bottom: 9rem;
    margin-bottom: -4rem;

    .button--previous {
      width: 100%;
    }
    .simulation__buttons {
      border-top: none;
    }
  }

  .simulation__group {
    padding-bottom: 2.3rem;
    padding-top: 3.6rem;
  }
}

%at-appointment-type-skin-page {
  @include gridle_state(mobile) {
    padding-bottom: 2.5rem;
  }
}

@mixin appointment-type($selector, $skin: default) {
  #{$selector} {
    @extend %at-appointment-type-skin-#{$skin} !optional;

    &__header {
      @include gridle_state(mobile) {
        flex-direction: column-reverse;
        padding: 0;
      }

      align-items: center;
      padding: 0 5.3rem 4rem 5.3rem;
      justify-content: space-between;
      font-weight: bold;
      display: flex;
      &-title {
        font-size: 3rem;
        line-height: 2.7rem;
        text-transform: uppercase;

        @include gridle_state(mobile) {
          font-size: 2rem;
          font-weight: 600;
          padding: 0.5rem 0 2rem;
          text-align: center;
        }
        @include gridle_state(mobile-xs) {
          font-size: 1.8rem;
        }
      }

      &-icon {
        display: flex;

        img {
          height: 4.1rem;
          width: 10.5rem;
        }

        @include gridle_state(mobile) {
          padding: 0;
          width: 8rem;
        }
      }
    }

    &__buttons {
      display: flex;
      padding: 0 4.3rem;
      align-items: center;
      @include gridle_state(mobile) {
        padding: 0 1.5rem;
        flex-direction: column;
      }
      @include gridle_state(mobile-xs) {
        padding: 0 2rem;
      }
    }

    &__button {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 24.7rem;
      width: 100%;
      flex: 0.8;

      @include gridle_state(mobile) {
        flex-direction: row;
        min-height: 8rem;
        min-width: 100%;
        position: relative;
        border-width: 1px;
        &--phone__label-container {
          text-align: left;
        }
      }
      &--online {
        flex: 1;
        min-height: 29rem;
        margin: 0 4rem;
        border-color: #fc0081;

        @include gridle_state(mobile) {
          flex: 0.8;
          min-height: 12rem;
        }
      }

      &.button--border--grey {
        border-color: color-palette(neutral-grey-black) !important;
      }

      &.button--download {
        color: color-palette(neutral-grey-black);
        cursor: auto;
        &:hover,
        &:focus {
          background-color: #ffffff;
          @include gridle_state(mobile) {
            color: white !important;
            background-color: #fc0081;
          }
        }
      }

      &--middle {
        @include gridle_state(mobile) {
          margin: 1rem 0;
        }
      }

      &-image {
        height: 6.3rem;
        width: 6.4rem;
        margin-bottom: 4rem;

        @include gridle_state(mobile) {
          margin: 0 3rem;
        }
        @include gridle_state(mobile-xs) {
          background-size: 3rem !important;
          margin: 2rem 3rem 0;
        }

        &--cursor {
          background: url("/assets/images/icon-cursor.svg") no-repeat;
          @include gridle_state(mobile) {
            margin-top: 1rem;
          }
        }

        &--time {
          background: url("/assets/images/arm-time.svg") no-repeat;
          @include gridle_state(mobile) {
            background-size: 5.6rem;
          }
        }

        &--phone-ringing {
          background: url("/assets/images/icon-phone-ringing.svg") no-repeat;
          background-size: 3.5rem;
          margin-bottom: 1.3rem;
          margin-left: 3rem;

          @include gridle_state(mobile) {
            margin-left: 4rem;
            margin-right: 2rem;
            & .appointment-type__button-label {
              text-align: left;
            }
          }
        }
      }

      &-contact {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 1.7rem;
        text-align: center;
        &__number {
          &__label {
            position: absolute;
            background: white;
            font-size: small;
            margin-top: 0.8rem;
            margin-left: 0.5rem;
            color: #4c4c4c;
            @include gridle_state(mobile-xs) {
              margin-top: 0.6rem;
            }
          }
        }
        @include gridle_state(mobile) {
          align-items: flex-start;
          margin-top: 0;
        }

        &--text {
          background-color: color-palette(tone-primary);
          color: #ffffff;
          font-size: 0.6rem;
          font-weight: 600;
          height: 1.2rem;
          line-height: 1.2rem;
          margin-bottom: 1rem;
          overflow: hidden;
          text-align: center;
          text-overflow: ellipsis;
          width: 13rem;

          @include gridle_state(mobile) {
            .button--download:hover &,
            .button--download:focus & {
              background-color: white !important;
              color: color-palette(tone-primary) !important;
            }
          }
        }

        &--notice,
        &--notice2 {
          color: #666666;
          font-weight: 500;
          line-height: 1.3rem;
          font-size: 12px;
          text-transform: none;

          @include gridle_state(mobile) {
            text-align: left;
            .button--download:hover &,
            .button--download:focus & {
              color: white !important;
            }
          }
        }
      }

      &-label {
        font-weight: 600;
        line-height: 1.9rem;

        @include gridle_state(mobile) {
          padding-right: 3rem;
          text-align: left;
        }
        @include gridle_state(mobile-xs) {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.button--rdv {
  margin-top: 2rem;
  line-height: 1rem;
}

@include appointment-type(".appointment-type");
@include appointment-type(".appointment-type-page", "page");
