// Declare skins here

%at-glossary-menu-item-skin-default {

  display: table-cell;
  text-align: center;
  padding-bottom: 0.5rem;

  &:hover {
    border-bottom: 0.4rem solid color-palette(tone-secondary);
  }

  .glossary-menu__itemLink {
    font-size: $font-size-normal;
    text-decoration: none;
    color: color-palette(tone-secondary-dark);

    &:hover {
      color: color-palette(tone-primary-dark);
    }
  }
}

%at-glossary-menu-item-skin-selected {

  border-bottom: 0.4rem solid color-palette(tone-secondary);

  .glossary-menu__itemLink {
    font-family: $font-medium;
    color: color-palette(tone-primary-dark);
  }

}

@mixin glossary-menu-item ($selector, $skin: default) {
  #{$selector} {
    @extend %at-glossary-menu-item-skin-#{$skin} !optional;
  }
}

// Include component here
@include glossary-menu-item('.glossary-menu__item');
@include glossary-menu-item('.glossary-menu__item--selected', 'selected');
