@import '../default/2_atoms/textfield';

%at-textfield-skin-default {
  padding: 1rem 1.5rem;
  border: 1px solid color-palette(neutral-grey-lighter);
  border-radius: 4px;
  background: color-palette(neutral-grey-light);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.4);

  @include gridle_state(mobile) {
    padding: .7rem 1.5rem;
  }

  &.js-valid, &.ng-valid {
    color: color-palette(neutral-grey-darken);
  }

  &.js-pristine, &.ng-pristine.ng-invalid {
      color: color-palette(neutral-grey-darken);
      border-color: color-palette(neutral-grey-lighter);
      background-color: color-palette(neutral-grey-light);
    }
}

%at-textfield-skin-tiny {
  width: 8rem;
  max-width: 8rem;
}
