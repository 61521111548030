%at-loading-page-skin-default {
  text-align: center;
  display: block;
  min-height: 40rem;
  box-shadow: inset 0 8px 8px -8px rgba(0, 0, 0, 0.6);
  padding-top: 4rem;
  @include gridle_state (mobile) {
    min-height: 0;
  }

}



@mixin loading-page($selector, $skin: default) {
  #{$selector} {
    @extend %at-loading-page-skin-#{$skin} !optional;
    &--success {
      margin-left: 4rem;
      @include gridle_state (mobile) {
        margin-left: 2rem;
      }
    }
    &--icon{
      width: 100%;
      text-align: center;
      display: block;

      .icon{
        font-size: 6rem;
        color: color-palette(tone-secondary);
      }
    }

    &--text{
      width: 100%;
      text-align: center;
      display: block;
      margin-top: 2rem;
    }

    .simulation__buttons--light{
      margin: 0;
      margin-top: 6rem;
      padding-top: 2rem;

    }

    .button--previous {
      float: left;
    }
  }
}

// Include component here
@include loading-page('.loading-page');

