// Declare skins here

%at-fastquote-summary-skin-default {
  background-color: color-palette(neutral-grey);
  color: color-palette(tone-primary);
  font-size: $font-size-normal;
  text-align: center;
  padding: 5rem 0;

  @include gridle_state(mobile) {
    font-size: 1.4rem;
    padding: 5rem 1rem;
  }
}

%at-fastquote-summary-skin-neutral {
  background-color: color-palette(neutral);
  padding: 0;
  margin-bottom: 7rem;

  @include gridle_state(mobile) {
    margin-bottom: 2rem;
  }
}

%at-fastquote-summary-skin-primary {
  background-color: color-palette(tone-primary);
  color: color-palette(neutral);
  padding: 2rem 0;
}

@mixin fastquote-summary($selector, $skin: default) {
  #{$selector} {
    @extend %at-fastquote-summary-skin-#{$skin} !optional;

    &__title {
      margin: 0 auto;
      max-width: 80rem;
    }
  }
}

// Include component here
@include fastquote-summary('.fastquote-summary');
@include fastquote-summary('.fastquote-summary--neutral', 'neutral');
@include fastquote-summary('.fastquote-summary--primary', 'primary');
