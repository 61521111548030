// Declare skins here

%at-auto-grow-skin-default {
  position: relative;
  margin-right: .5rem;
}

@mixin auto-grow($selector, $skin: default) {
  #{$selector} {
    @extend %at-auto-grow-skin-#{$skin} !optional;

    &__editable {
      display: inline-block;
      line-height: 2.8rem;
      min-width: 3rem;
      outline: none;
      text-align: left;

      &.js-empty {
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
      }
    }

    &__placeholder {
      color: color-palette(neutral-grey-dark);
    }
  }
}

// Include component here
@include auto-grow('.auto-grow');
