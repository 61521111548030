%at-content-quote-skin-default {
  // Default skin here

  text-align: center;
  font-family: $font-hw-bold;
  margin: 0 auto;
  width: 85%;
  font-size: 3.2rem;
}

@mixin content-quote($selector, $skin: default) {
  #{$selector} {
    @extend %at-content-quote-skin-#{$skin} !optional;
  }
}

// Include component here
@include content-quote('.content-quote');
