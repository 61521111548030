// Declare skins here

%at-projects-title-skin-default {
  font-family: $font-medium;
  font-size: 1.4rem;
  color:color-palette(tone-primary-dark);
  text-align: center;
}

%at-projects-title-skin-hw {
  font-family: $font-hw-bold;
  font-size: 2.8rem;
  color:color-palette(tone-primary);
}

@mixin projects-title($selector, $skin: default) {
  #{$selector} {
    @extend %at-projects-title-skin-#{$skin} !optional;
  }
}

// Include component here
@include projects-title('.projects-title');
@include projects-title('.projects-title--hw','hw');
