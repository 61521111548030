%at-content-media-skin-default {
  // Default skin here

  text-align: center;
  margin: 5rem 0;
}

@mixin content-media($selector, $skin: default) {
  #{$selector} {
    @extend %at-content-media-skin-#{$skin} !optional;

    &__title {
      font-size: $font-size-normal;
      font-family: $font-medium;
      margin-bottom: 4rem;
    }
  }
}

// Include component here
@include content-media('.content-media');
