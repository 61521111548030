// Declare skins here

%at-simulation__banner-skin-default {
    display: flex;
    align-items: center;
    background: color-palette(hawkes-blue);
    padding: 2rem 3rem;
    position: relative;
    line-height: initial;
    width: 100%;
    @include gridle_state(tablet,mobile){
      flex-direction: column;
      padding: 1rem;
    }
}

%at-simulation__banner-skin-white {
  background-color: color-palette(neutral);
}

@mixin simulation__banner($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__banner-skin-#{$skin} !optional;
    &Content{
      text-align: left;
      margin:0rem 2rem;
      display: flex;
      flex-direction: column;
      &Title{
        font-size: 2.2rem;
        color: color-palette(tone-primary);
        font-family: $font-hw-bold;
        font-weight: 400;
        &--bold{
          font-family: $font-hw-bold;
          font-size: 2.4rem;
          color: color-palette(tone-primary);
          font-weight: 700;
        }
        &--big{
          font-size: 2.6rem;
        }
      }
      &Text{
        font-size: 1.4rem;
        line-height: 2rem;
        color: color-palette(dolphin);
      }
      @include gridle_state(tablet,mobile){
        width:initial;
        text-align: center;
        margin:0rem;
      }
      &--width{
        width:60%;
        @include gridle_state(tablet,mobile){
          width:inherit;
        }
      }
      &--grow{
        flex:1;
      }
    }
    &Icon{
      position: absolute;
      top:2rem;
      right:2rem;
      color: color-palette(tone-primary);
      font-size: 2rem;
      width: 3rem;
    }
    & > div:first-child{
      min-width: 7rem;
    }

    &--adjust-flex{
      flex: 1;
    }

    &--adjust-wrap{
      flex-wrap: wrap;
    }

    &--adjust-basis{
      flex-basis: 100%;
    }
  }
}

// Include component here
@include simulation__banner('.simulation__banner');
@include simulation__banner('.simulation__bannre--white','white');
