// Declare skins here

%at-info-block__list-skin-default {
  font-family: $font-regular;
  font-size: 1.6rem;
  color: color-palette(neutral);
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0 1rem 0 0;

  @include gridle_state (  tablet  ){
    margin-left: 12rem;
  }
  @include gridle_state (  mobile  ){
    padding: 0;
    margin-left: 0;
    text-align: center;
  }
  

  li:before{
    content: '';
    display: inline-block;
    border-radius: 50%;
    width: 0.7rem;
    height: 0.7rem;
    margin: 0 0.7rem 0 0;
    background-color: white;
  }
}

@mixin info-block__list($selector, $skin: default) {
  #{$selector} {
    @extend %at-info-block__list-skin-#{$skin} !optional;

    @if($skin == default){

    }
  }
}

// Include component here
@include info-block__list('.info-block__list');

