// Declare skins here

%at-simulation__quotiteListItem-skin-default {
  display: inline-block;
  font-size: $font-size-big;
  color: color-palette(tone-primary-dark);

  @include  gridle_state(mobile){
    font-size: 2.2rem;
  }
}

@mixin simulation__quotiteListItem($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__quotiteListItem-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__quotiteListItem('.simulation__quotiteListItem');
