%at-finalisation-skin-default {
  display: block;
  box-shadow: inset 0 8px 8px -8px rgba(0, 0, 0, 0.6);
  padding: 4rem;

  &.signed-with-no-certificate {
    padding-top: 0;
  }

  @include gridle_state (mobile) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@mixin finalisation($selector, $skin: default) { 
  #{$selector} {
    @extend %at-finalisation-skin-#{$skin} !optional;

    &--button{
      padding: 4rem 0;
      padding-bottom: 0;
      border-top: .1rem solid color-palette(neutral-grey-lighten);
      margin: 0 -4rem;
    }

    &--head{
      min-height: 5.5rem;
      margin-bottom: 1rem;
      @include gridle_state(mobile) {
        margin-bottom: 2rem;
      }

      .icon{
        color: color-palette(tone-secondary);
        font-size: 3rem;
        &:before{
          margin-top: 1rem;
          display: block;
        }
      }
    }

    &__stepDescription {
      border: 1px solid color-palette(grey-light);
      padding: 31px 0;

      p {
        padding: 0 50px;
      }
    }
 
    &__information--title {
      color: color-palette(grey-border);
      font-size: 17px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    &__information--content {
      color: color-palette(tone-primary-king);
      font-size: 17px;

      &__paraph {
        margin-bottom: 15px;
      }
    }

    &__information--correspondant-aeres {
      margin-bottom: 15px;
    }

    &__information--correspondant-aeres--table {
      margin: auto;
    }

    &__information--correspondant-aeres--table--td {
      padding: 1px 0px 1px 0px;
    }

    &__information--correspondant-aeres-vert--boxleft {
      border: 1px solid #D7D7D7;
      border-right: 0px;
      padding-left: 4px;
      padding-right: 3px;
      color: #71bf44 !important;
      font-family: ARIAL, sans-serif;
      font-weight: bold;
    } 

    &__information--correspondant-aeres-vert--boxmiddle { 
      background: url(/assets/images/cartouche-numeros-vert.png);
      background-repeat: no-repeat;
      background-size: 75px 27px;
      height: 27px;
      width: 75px;
    } 

    &__information--correspondant-aeres-vert--boxright {
      border: 1px solid #D7D7D7;
      border-left: 0px;
      height: 25px;
      width: 7px;
    }
 
    &__information--correspondant-aeres-gris--boxleft {
      border: 1px solid #91919b;
      border-right: 0px;
      padding-left: 4px;
      padding-right: 3px;
      color: #91919b !important;
      font-family: ARIAL, sans-serif;
      font-weight: bold;
    }
    
    &__information--correspondant-aeres-gris--boxmiddle { 
      background: url(/assets/images/cartouche-numeros-cristal.png);
      background-repeat: no-repeat;
      background-size: 75px 27px;
      height: 27px;
      width: 75px;
    } 
    &__information--correspondant-aeres-gris--boxright {
      border: 1px solid #91919b;
      border-left: 0px;
      height: 25px;
      width: 7px;
    } 

    &__description {
      color: color-palette(tone-primary-king);
      font-size: 17px;
      font-weight: 400;
      line-height: 19px;
    }

    &--head-text{
      vertical-align: middle;
      width: 80%;
      p {
        text-align: justify;
      }
      
      .text-green{
        color: color-palette(tone-secondary);
      }
    }

    &--head-icon{
      vertical-align: middle;
      height: 5rem;
      width: 4.6rem;
      float: left;
    }

    &--bottom{
      text-align: center;
      margin: 0 auto;
      width: 80%;
      padding: 3rem;
      @include gridle_state(mobile) {
        width: 100%;
        padding: 3rem 1rem;
      }
 
      &.has-no-certificate{
        padding: 0rem 3rem 3rem;
      }
    }


  }
}

// Include component here
@include finalisation('.finalisation');

