%at-circle-close-icon-skin-default {
	background : url('/assets/images/close-circle.svg') center no-repeat;
	width: 3rem;
	height: 3rem;
}

@mixin circle-close-icon($selector, $skin: default) {
  #{$selector} {
    @extend %at-circle-close-icon-skin-#{$skin} !optional;
  }
}

// Include component here
@include circle-close-icon('.circle-close-icon');

