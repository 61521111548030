// Declare skins here
%at-panel__header-skin-default {
  padding : 1.5rem;
  width:100%;
  color : color-palette(neutral);
  background-color : color-palette(tone-secondary);

}

%at-panel__header-skin-dark {
  background-color : color-palette(tone-secondary-black);
}


%at-panel__header-skin-grey {
  background-color : color-palette(neutral-grey-dark);
}

@mixin panel-header($selector, $skin: default) {
  #{$selector} {
    @extend %at-panel__header-skin-#{$skin} !optional;

    @if($skin == default) {
        text-transform: uppercase;
      .panel__header-title{
        font-size: $font-size-normal;
      }
      .panel__header-icon{
        margin-right: 1.5rem;
      }
    }

  }
}

// Include component here
@include panel-header('.panel__header');
@include panel-header('.panel__header--dark', 'dark');
@include panel-header('.panel__header--grey', 'grey');