@import '../default/5_templates/dashboard';

.dashboard__title{
  font-size: $font-size-medium;
  text-transform: uppercase;
  display: inline-block;
}


.dashboard__person-value{
  word-break: break-all;
}
