// Declare skins here

%at-simulation__header-skin-default {
  background-color: #f6f7f9;
  color: #000a8b;
  font-size: $font-size-normal;
  text-align: center;
  padding: 3rem 7rem;

}


@mixin simulation__header($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__header-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__header('.simulation__header');

