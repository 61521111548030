// Declare skins here

%at-recap-ecretement-skin-default {
  font-size: 1.6rem;
  color: color-palette(neutral-grey);
  font-family: $font-regular;
  border-left: solid 0.1rem rgba(color-palette(neutral-grey), 0.1);
  border-right: solid 0.1rem rgba(color-palette(neutral-grey), 0.1);
  border-bottom: solid 0.1rem rgba(color-palette(neutral-grey), 0.1);
  padding: 1rem 3rem;
  margin-bottom: 3rem;

  @include gridle_state(mobile){
    padding: 0;
    margin-bottom: 3rem;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
}

%at-recap-ecretement-skin-list {
  position: relative;
  list-style: none;
  padding: 0;
  margin-left: 2rem;
  margin-right: 2rem;
  li:before{
    content: "•";
    color: color-palette(tone-primary);
    position: absolute;
  }
}

%at-recap-ecretement-skin-text {
  @include gridle_state(mobile){
    margin-left: 0;
    margin-right: 2rem;
  }
}


@mixin recap-ecretement($selector, $skin: default) {
  #{$selector} {
    @extend %at-recap-ecretement-skin-#{$skin} !optional;
   &ItemText{
     color: color-palette(neutral-grey);
     display: block;
     margin-left: 2rem;
     a{
       color: color-palette(tone-primary);
       text-decoration: underline;
     }
   }
  }
}


// Include component here
@include recap-ecretement('.recap-ecretement');
@include recap-ecretement('.recap-ecretement--list','list');
@include recap-ecretement('.recap-ecretement--text','text');
