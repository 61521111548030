@import '../default/1_quarks/document';

/* Document rules (*, body, html)
=================================
*/

html {
    position: relative;
    min-height: 100%;
    overflow-y: scroll;
}

body {
    position: relative;
    padding-bottom: 7rem;
    color: color-palette(neutral-grey-black);
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        url('/assets/images/background-3.jpg') no-repeat center center fixed;
    background-size: cover;
    min-height: 100%;
    @include gridle_state(mobile) {
        padding-bottom: 9rem;
    }
}
