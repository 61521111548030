// Declare skins here

%at-slider__navigation-skin-default {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  margin-top: 1rem;
}


@mixin slider__navigation($selector, $skin: default) {
  #{$selector} {
    @extend %at-slider__navigation-skin-#{$skin} !optional;

    .slider__bullet{
      width: 1.4rem;
      height: 1.4rem;
      border-radius: 50%;
      display: inline-block;
      margin-right: 0.5rem;
      background-color: rgba(color-palette(neutral-grey-dark), 0.7);
      transition: background-color 0.3s ease-out;
      cursor: pointer;

      &--active{
        background-color: color-palette(tone-secondary);
      }
    }
  }
}

// Include component here
@include slider__navigation('.slider__navigation');
