%at-warning-skin-default {
  padding: 2.4rem 0 2.4rem 11rem;
  color: color-palette(neutral);
  background: color-palette(neutral-grey-darker) url('/assets/images/warning.svg') 4rem 50% no-repeat;

  @include gridle_state(mobile) {
    padding: 1.5rem 1rem;
    background-image: none;
    text-align: left;
    line-height: 1.4rem;
    letter-spacing: -0.2px;
    font-weight: 300;
  }
}

%at-warning-skin-light {
  background: color-palette(neutral-light) url('/assets/images/warning.svg') 4rem 50% no-repeat;
  color: color-palette(neutral-grey-blackest);

  @include gridle_state(mobile) {
    padding: 1.9rem 2rem 1.8rem 8rem;
    background-position: 2rem 50%;
    text-align: left;
    line-height: 1.5rem;
    letter-spacing: normal;
    font-weight: 400;
  }
}

@mixin warning($selector, $skin: default) {
  #{$selector} {
    @extend %at-warning-skin-#{$skin} !optional;
  }
}

// Include component here
@include warning('.warning');
@include warning('.warning--light', 'light');
