// Declare skins here

%at-simulation__tarifDescription-skin-default {
  font-size: $font-size-big;
  color:color-palette(tone-primary);
  font-family: $font-regular;
  text-align: center;
  line-height: 3.5rem;
  padding: 4rem 0;
  clear: both;

  @include gridle_state(mobile){
    padding: 2rem;
    font-size: 2rem;
    line-height: 2.5rem;
  }

}

@mixin simulation__tarifDescription($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__tarifDescription-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__tarifDescription('.simulation__tarifDescription');
