// Declare skins here

%at-footer-title-skin-default {
  // Default skin here
  margin: 0;
  font-family: $font-bold;
  font-size: 6rem;
  color: color-palette(neutral);
  line-height: 4rem;
}

%at-footer-title-skin-hw {
  font-family: $font-hw-bold;
}

@mixin footer-title($selector, $skin: default) {
  #{$selector} {
    @extend %at-footer-title-skin-#{$skin} !optional;
  }
}

// Include component here
@include footer-title('.footer__title');
@include footer-title('.footer__title--hw','hw');
