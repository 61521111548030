// Declare skins here

%at-header__menu-skin-default {
  background-color: color-palette(tone-secondary);
  color: white;
  width: 33rem;
  position: absolute;
  right: 0;
  padding: 2rem;
  z-index: 100;
  top: 3rem;
  visibility: hidden;
  opacity: 0;

  @include gridle_state(mobile){
    left: 100%;
    width: 90%;
    transition: all 1s ease;
    visibility: visible;
    opacity: 1;
    top: -2rem;
    padding: 0;
    background-color: color-palette(tone-secondary-light);
    display: block;
    margin-left: -1rem;
    min-height: 70rem;
  }
}

%at-header__menu-skin-right {
  @include gridle_state(mobile){
    left: -90%;
    width: 90% !important;
    transition: all 1s ease;
    position: fixed;
    visibility: visible;
    opacity: 1;
    top: 0;
    padding: 0 !important;
    background-color: color-palette(neutral-light);
    color: color-palette(neutral-grey-tundora);    
    display: block;
    margin: 0rem;
    float: none !important;
    min-height: 70rem;
  }
}



@mixin header__menu($selector, $skin: default) {
  #{$selector} {
    @extend %at-header__menu-skin-#{$skin} !optional;

    &--opened{
      opacity: 1;
      visibility: visible;
      left:0;
      @include gridle_state(mobile){
        display: block;
      }
    }

    &--push-right{
      @include gridle_state(mobile) {
        left: 90% !important;
        position: fixed;
      }
    }
    &Title{
      text-align: center;
      font-size: 1.5rem;
      padding: 2rem;
      background: white;
      display: flex;
      justify-content: space-between;
      align-items:center;
      box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);    
      position: relative;
      z-index:1;  
      span{
        margin-right: 3rem;
      }
      i:after{
        display: block;
        color: white;
        background-color: color-palette(tone-secondary);
        cursor:pointer;
      }
    }
    &Content{
      background: #f7f7f7;
    }
    ul{
      margin:0;
      padding: 0;
      & > li{
        list-style: none;
        padding: 2rem;
        margin: 0rem 2rem;
        padding-left: 0;
      }
      & > li:not(:last-child){
        border-bottom: 1px solid rgba(color-palette(neutral-grey-dark), 0.2);
      }
    }
  }
}

// Include component here
@include header__menu('.header__menu');
@include header__menu('.header__menu--right','right');
