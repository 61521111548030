@import "../2_atoms/floatlabel";
%at-float-label-group-skin-default {
  position: relative;
  width : 100%;
}


@mixin float-label-group($selector, $skin: default) {
  #{$selector} {
    @extend %at-float-label-group-skin-#{$skin} !optional;
      input:focus+span, input.ng-valid-required+span{
        @extend %at-float-label-skin-pushed;
        text-transform: uppercase;
        font-size: 1rem;
      }
  }
}

// Include component here
@include float-label-group('.float-label-group');
