@import '../../default/2_atoms/login/login-link';

%at-login-link-skin-default {
  // Default skin here
  font-family: $font-regular;
  font-size: 1.2rem;
  color: color-palette(tone-secondary);
  display: initial;
  text-decoration: underline;
  cursor: pointer;
}

%at-login-link-skin-right {
  @extend %at-login-link-skin-default;
  text-align:right;
  float:right;
  display:initial;
  margin-left:2rem;
  font-size: 1.2rem;
}

@mixin login-link($selector, $skin: default) {
  #{$selector} {
    @extend %at-login-link-skin-#{$skin} !optional;
  }
}

// Include component here
@include login-link('.login-box__link');
@include login-link('.login-box__link--right','right');
