// Declare skins here

%at-edito-title-skin-default {
  color: color-palette(tone-secondary);
  font-family: $font-medium;

  &:after{
    content: "";
    display: block;
    width: 8rem;
    border-bottom: solid 4px color-palette(tone-primary);
    margin-top: 1rem;
  }
}


%at-edito-title-skin-primary {
  color: color-palette(tone-primary);
}

%at-edito-title-skin-icon {
  cursor: pointer;

  &:before{
    float:right;
  }
}

@mixin edito-title($selector, $skin: default) {
  #{$selector} {
    @extend %at-edito-title-skin-#{$skin} !optional;
  }
}

// Include component here
@include edito-title('.edito-title');
@include edito-title('.edito-title--primary', 'primary');
@include edito-title('.edito-title--icon', 'icon');
