// Declare skins here

%at-choice-reset {
  appearance: none;

  // Input radio/checkbox reset (https://css-tricks.com/snippets/css/custom-radio-buttons/)
  position:absolute;
  clip: rect(0,0,0,0);
  clip: rect(0 0 0 0);
}

%at-choice-skin-default {
  display: block;
  @extend %at-choice-reset;

  &:focus ~ .choice-box__label {
    @extend .choice-box__label--focus;
  }

  &:checked:not([disabled]) ~ .choice-box__label {
    @extend .choice-box__label--checked;
  }

  &[disabled] ~ .choice-box__label {
    @extend .choice-box__label--disabled;
  }

  &[disabled]:checked ~ .choice-box__label {
    @extend .choice-box__label--disabled-checked;
  }
  &[disabled]:checked ~ .choice-box__label--refused {
    @extend .choice-box__label--disabled-refused;
  }

  &.js-dirty.js-invalid, &.ng-dirty.ng-invalid {
    & ~ .choice-box__label {
      @extend .choice-box__label--invalid;
    }
  }

}

%at-choice-skin-button {
  @extend %at-choice-reset;

  &:focus ~ .choice-box__label {
    @extend .choice-box__label--buttonFocus;
  }

  &:checked ~ .choice-box__label {
    @extend .choice-box__label--buttonChecked;
  }
  &:checked ~ .choice-box__label.buttonBlocked{
    background-color: color-palette(neutral-grey-lighten);
    border-color: color-palette(neutral-grey-lighten);
    color: color-palette(neutral);
    opacity: 0.4;
  }

  &.js-dirty.js-invalid, &.ng-dirty.ng-invalid {
    & ~ .choice-box__label {
      @extend .choice-box__label--buttonInvalid;
    }
  }

}

%at-choice-skin-icon {
  @extend .choice-box__label--icon;
  visibility: hidden;

  &:checked{
    @extend .choice-box__label--iconChecked;
  }
  .custom-table--input:checked + &:before {
    @extend .choice-box__label--iconChecked;
  }
  .custom-table--input.js-dirty.js-invalid + &:before,
  .custom-table--input.ng-dirty.ng-invalid + &:before {
    @extend .choice-box__label--iconInvalid;
  }
  &:focus{
    &:before{
      border: none;
    }
  }

  &.js-dirty.js-invalid, &.ng-dirty.ng-invalid {
    @extend .choice-box__label--iconInvalid;
  }
}

%at-choice-skin-buttonNeutral {
  @extend %at-choice-reset;

  &:checked ~ .choice-box__label {
    @extend .choice-box__label--buttonChecked;
  }

}

@mixin choice($selector, $skin: default) {
  #{$selector} {
    @extend %at-choice-skin-#{$skin} !optional;
  }
}

// Include component here
@include choice('.choice');
@include choice('.choice--button', 'button');
@include choice('.choice--buttonNeutral', 'buttonNeutral');
@include choice('.choice--icon', 'icon');
