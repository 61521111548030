// Declare skins here

%at-simulation-tarifTable-skin-default {
  background-color: color-palette(tone-primary);
  color: color-palette(neutral);
  margin-bottom: 2rem;
  padding: 2rem 0rem;

  @include gridle_state(mobile) {
    max-width: 100%;
  }
}

%at-simulation-tarifTable-skin-primary {
  background-color: color-palette(tone-secondary-black);
  color: white;
}

%at-simulation-tarifTable-skin-flex {
  display: flex;
  & > article{
    flex: 1;
  }
}

@mixin simulation-tarifTable($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation-tarifTable-skin-#{$skin} !optional;

    @if $skin == default {
      & > p {
         padding: 0rem 2rem;
      }
      &_title {
        font-family: $font-hw-bold;
        margin-top: 2rem;
        font-size: 2.4rem;
        font-weight: 400;

        &--separator {
          margin-top: 4rem;

          &:after {
            content: '';
            display: block;
            width: 8rem;
            margin: 0.5rem auto 4rem;
          }
        }
        &--bold{
          font-family: $font-hw-bold;
          font-size: 2.4rem;
          color: color-palette(neutral);
          font-weight: 700;
        }
      }

      &_text {
        line-height: 1.2;
        font-size: 1.4rem;
        &--separator1:after {
          content: '';
          display: block;
          width: 70%;
          margin: 2rem auto;
          height: 0.5rem;
          background: url('/assets/images/hr.svg') no-repeat 0 0;
          border: 0;
        }
        &--separator2:after {
          content: '';
          display: block;
          width: 70%;
          margin: 2rem auto;
          height: 0.5rem;
          background: url('/assets/images/hr.svg') no-repeat 0 0;
          border: 0;
        }
      }

      &_action {
        margin-top: 2rem;
        font-size: 1.8rem;
      }

      &_price {
        line-height: 1;
        margin: 0 0 1rem !important;
        font-size: 3.1rem;
        font-weight: 700;
      }
      &_icon{
        background-color: color-palette(neutral);
        border-radius: 50%;
        width: 10.5rem;
        height: 10.5rem;
        position: relative;
        margin:auto;
        &:after{
          content:'';
          display: block;
          background: url("/assets/images/pig.png") no-repeat;
          margin: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 6rem;
          height: 5rem;
          background-size: cover;
        }
      }
      @include gridle_state(tablet) {
        &_text, &_title {
          font-size: $font-size-normal;
        }
      }
    }
  }
}

// Include component here
@include simulation-tarifTable('.simulation__tarifTable');
@include simulation-tarifTable('.simulation__tarifTable--primary', 'primary');
@include simulation-tarifTable('.simulation__tarifTable--flex', 'flex');
