// Declare skins here

%at-site-logo-skin-default {
  $logoWidth : 18.3rem;
  $logoHeight : 2.4rem;

  margin-top: 1.3rem;
  text-decoration: none;
  display: block;

  background: url('/assets/images/pegase-logo.png') no-repeat;
  background-size: 100%;
  width: $logoWidth;
  height: $logoHeight;

  @include gridle_state(mobile) {
    margin: 1.5rem auto 0 auto;
    width: $logoWidth * 0.85;
    height: $logoHeight * 0.85;
  }
}

@mixin site-logo($selector, $skin: default) {
  #{$selector} {
    @extend %at-site-logo-skin-#{$skin} !optional;
  }
}

// Include component here
@include site-logo('.site-logo');
