// Declare skins here

%at-fast-quote-skin-default {
  color: color-palette(neutral);
  font-size: $font-size-normal;
  line-height: 2.3;
  margin-top: 6.2rem;

  .fast-quote__action {
    margin-top: 3rem;

    button {
      text-transform: uppercase;
    }
  }


  .select {
    border: .1rem solid color-palette(grey-select-border);
    background: none;
    height: 3.4rem;
    border-radius: .3rem;

    & ~ .select-box__track {
      color: color-palette(tone-primary-brightless);
      margin-top: .9rem;
      font-size: 1.4rem;
    }

    &.js-valid, &.ng-valid {
      color: color-palette(tone-primary-brightless);
      border-color: color-palette(tone-primary-brightless);

      & ~ .select-box__track {
        color: color-palette(tone-primary-brightless);
      }
    }

    &.js-invalid, &.ng-invalid {
      color: color-palette(tone-error);
      border-color: color-palette(tone-error);

      & ~ .select-box__track {
        color: color-palette(tone-error);
      }
    }
  }

  .select-box__track {
    color: color-palette(tone-primary-brightless);
  }

  .textfield {
    background: none;

    &.js-valid ~ .textfield-rich__post, &.ng-valid ~ .textfield-rich__post {
      color: color-palette(tone-primary-brightless);
    }

    &.js-pristine, &.ng-pristine {
      border-bottom: .2rem solid  color-palette(neutral-grey-darkest);
    }

    &.js-valid, &.ng-valid {
      color: color-palette(tone-primary-brightless);
      border-color: color-palette(tone-primary-brightless);
    }

    &.js-invalid, &.ng-invalid {
      color: color-palette(tone-error);
      border-color: color-palette(tone-error);
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px color-palette(neutral) inset;
    }

  }

  @include gridle_state(tablet) {
  }

  @include gridle_state (  mobile  ){
    font-size: 2rem;
    text-align: center;
    max-width: 60rem;
    margin: 2rem auto 0;
  }

  .textfield, .select {
    font-size: inherit;
  }

  .textfield--tiny {
    min-width: 4rem;
    max-width: 6rem;
  }

  .textfield--eight-digits {
    max-width: 15rem;
  }

  [data-cand-date-picker], [cand-date-picker] {
    width: 16rem;
    max-width: 16rem;
  }

  .custom-select__item {
    font-size: 2rem;
  }

  .fast-quote__form {
    position: relative;
    color: color-palette(tone-primary-king);
    padding: 3rem 1rem;
    max-width: 65rem;
    width: 100%;
    background-color: color-palette(neutral);
    margin: 0 auto;

    @include gridle_state(mobile) {
      position: initial;
    }
  }

  .fast-quote__error {
    display: none;

    p {
      display: inline-block;
      background: color-palette(neutral);
      color: color-palette(tone-error);
      font-size: $font-size-small;
      line-height: 2.6rem;
      padding: .5rem 1.5rem;
      border-radius: 2.5rem;
      border:2px solid;
    }
  }
}

%at-fast-quote-skin-primary-dark {
  color: color-palette(tone-primary-dark);
}

%at-fast-quote-skin-embed {
  text-align: center;
  padding: 5rem 2rem;
  line-height: 1.5;
  background-color: color-palette(neutral-grey);
  color: color-palette(tone-primary-dark);

  .select {
    border: 1px solid color-palette(grey-select-border);
  }
  .fast-quote__title {
    color: color-palette(tone-primary);
    font-size: $font-size-big;
    margin-left: auto;
    margin-right: auto;
    max-width: 76rem;
    width: 100%;
  }

  .fast-quote__form {
    position: static;
    margin-left: auto;
    margin-right: auto;
    max-width: 76rem;
    width: 100%;
  }

  .fast-quote__birthday {
    max-width: 17.5rem;
    width: 100%;

    @include gridle_state(mobile) {
      max-width: none;
      width: auto;
    }
  }

  .fast-quote__action {
    text-align: center;
    overflow: auto;
    margin-top: 3rem;

    button {
      clear: both;
      float: none;
      text-transform: uppercase;
    }
  }

  .fast-quote__error {
    p {
      background: none;
    }
  }

  .textfield {
    border-bottom-color: color-palette(neutral-grey-dark);
  }
}



@mixin fast-quote($selector, $skin: default) {
  #{$selector} {
    @extend %at-fast-quote-skin-#{$skin} !optional;

    &--edito{
      background-color: color-palette(tone-primary-lightest);
      border-radius: .6rem;
      border: 1px solid color-palette(tone-primary-lightest);
      margin-top: 0;

      .fast-quote__form {
        background-color: transparent;
      }

      .fast-quote__title {
        font-family: $font-regular;
        font-size: 2.4rem;
      }
    }

  }
}

// Include component here
@include fast-quote('.fast-quote');
@include fast-quote('.fast-quote--dark', 'primary-dark');
@include fast-quote('.fast-quote--embed', 'embed');
