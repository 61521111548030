// Declare skins here

%at-glossary-skin-default {
  margin-bottom: 2rem;

  .page-title {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .glossary-container {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  @include gridle_state(mobile) {
    .glossary-container {
      margin-left: 0;
      margin-right: 0;
    }
  }

}

@mixin glossary($selector, $skin: default) {
  #{$selector} {
    @extend %at-glossary-skin-#{$skin} !optional;
  }
}

// Include component here
@include glossary('.glossary');
