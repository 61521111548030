// Declare skins here

%at-simulation__ctaItem-skin-default {
  @include gridle_state(mobile){
    margin-bottom: 9rem;
  }
}

%at-simulation__ctaItem-skin-separator {
  border-right: solid 0.1rem color-palette(tone-primary-light);
  @include gridle_state(mobile){
    border: none;
  }
}

@mixin simulation__ctaItem($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__ctaItem-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__ctaItem('.simulation__ctaItem');
@include simulation__ctaItem('.simulation__ctaItem--separator','separator');
