// Declare skins here

%at-header-logo__title-skin-default {
  margin: 0;
  font-family: $font-bold;
  font-size: 6rem;
  color: color-palette(tone-primary);
  line-height: 6rem;

  @include gridle_state(header-mobile mobile) {
    font-size: 4rem;
    line-height: 4rem;
  }
}

%at-header-logo__title-skin-hw {
  font-family: $font-hw-bold;
}

%at-header-logo__title-skin-small {
  font-size: 5rem;
  line-height: 5rem;
}

%at-header-logo__title-skin-xsmall {
  font-size: 4rem;
  line-height: 4rem;
}

@mixin header-logo__title($selector, $skin: default) {
  #{$selector} {
    @extend %at-header-logo__title-skin-#{$skin} !optional;
  }
}

// Include component here
@include header-logo__title('.header-logo__title');
@include header-logo__title('.header-logo__title--hw', 'hw');
@include header-logo__title('.header-logo__title--small', 'small');
@include header-logo__title('.header-logo__title--xsmall', 'xsmall');
