@mixin sms-modal($selector, $skin: default) {
  #{$selector} {
    @extend %at-sms-modal-skin-#{$skin} !optional;
    .cand-modal__overlay{
      top: 49%;
      max-height: 85%;
    }
    &__iconblock {
      background-color: color-palette(tone-secondary);
      color: white;
      min-width: 7.6rem;
    }
    &__message {
      padding: 2rem;

      &--phone {
        color: color-palette(tone-secondary);
        font-weight: 700;
      }
    }
    &__content {
      display: table;
    }

    &__iconblock,
    &__message {
      display: table-cell;
      vertical-align: middle;
    }

    &__inputs {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      &__arrow {
        transform: rotate(-90deg);
        height: 2.3rem;
      }
      ul {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        padding: 0;
      }

      li {
        background-color: #d8edb6;
        border-radius: 4px;
        height: 52px;
        line-height: 5rem;
        text-align: center;
        width: 58px;

        span {
          font-size: 2rem;
          font-weight: 700;
        }

        &:not(:last-child) {
          margin-right: 1.9rem;
        }
      }
    }
  }
}
@include sms-modal(".sms-modal");
