
%at-error-page-skin-default {
  width: 80%;
}

@mixin error-page($selector, $skin: default) {
  #{$selector} {
    @extend %at-error-page-skin-#{$skin} !optional;

    &__logo{
      margin : 2.3rem auto;
    }

    &__title{
      font-size: $font-size-medium;
      margin: 1rem 0;
      font-weight: bold;
    }
    &__content {
      display: block;
      text-align: center;
      width: 100%;
      font-size: 1.3em;
      line-height: 1.5;
    }

    &__text {
      margin : 0 auto;
      text-align: center;
      font-size: $font-size-medium;
    }

    &__button {
      line-height: normal;
      min-width: 0;
      margin-top: 3rem;
      padding: 1rem 6rem;
      @include gridle_state(mobile) {
        margin-top: 1rem;
      }
    }
  }
}

@include error-page('.error-page');