@import '../default/2_atoms/listdigit';

%at-list-digit-skin-default {
  .list-digit__target, &.list-digit__target {
    padding : 0 6.6rem 3rem 10rem;

    @include gridle_state(mobile) {
      padding: 0 2rem 2rem;
    }

    .question-title{
      @include gridle_state(mobile) {
        padding-left: 2.1rem;
      }
    }
  }

  .list-digit__target:before, &.list-digit__target:before {
    width: auto;
    border: none;
    font-weight: normal;
    font-size: $font-size-big;
    line-height: 2rem;
    padding-left: 6rem;
    text-align: right;

    @include gridle_state(mobile) {
      width: 2rem;
      padding-left: 0;
      margin-left: 1rem;
      font-size: 2rem;
      line-height: 1.8rem;
    }
  }

  &.list-digit__target .list-digit__target {
    padding-left: 0;
    padding-right: 0;

    &:before {
      display: none;
    }
  }

  .question-title {
    &::before{
      top: 1.1rem;
    }

    @include gridle_state(mobile){
      &.question-title--no-top{
        &::before{
          top:0;
        }
      }
    }
  }

  .question-height {
    @include gridle_state(mobile) {
      padding-bottom: 1.6rem;
    }
  }

  .question-height,
  .question-weight {
    @include gridle_state(mobile) {
      display: table;
      width: 100%;
    }
  }

  .question-height__begin,
  .question-weight__begin {
    @include gridle_state(mobile) {
      display: table-cell;
      font-size: 1.3rem;
    }
  }

  .question-height__answers,
  .question-weight__answers {
    @include gridle_state(mobile) {
      display: table-cell;
      text-align: right;
    }
  }

  .question-height__end,
  .question-weight__end {
    @include gridle_state(mobile) {
      display: inline-block;
      width: 2.5rem;
      font-size: 1.3rem;
    }
  }
}

.list-digit__target--complex {
  padding-bottom: 2rem;
  min-height: 5rem;

  .question-title {
    @include gridle_state(mobile) {
      padding-left: 2.1rem;

    }
  }
}
