%at-sinister__prerequisite-skin-default {
  text-align: left;
  font-size: $font-size-normal;
}

@mixin sinister__prerequisite($selector, $skin: default) {
  #{$selector} {
    @extend %at-sinister__prerequisite-skin-#{$skin} !optional;

    &_title {
      color: color-palette(tone-primary);
      font-size: $font-size-big;
      font-family: $font-medium;
    }
  }
}

// Include component here
@include sinister__prerequisite('.sinister__prerequisite');
