@import '../default/2_atoms/headernavlink';

%at-header-navLink-skin-default {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 3.5rem;
  height: 5rem;
  color: color-palette(neutral);
  font-size: 1.3rem;
  line-height: 5rem;
}

%at-header-navLink-skin-documents {
  padding-left: 5.9rem;
  background: color-palette(tone-secondary);

  &:before {
    content: '';
    position: absolute;
    left: 2.4rem;
    top: 1.4rem;
    width: 1.9rem;
    height: 2.3rem;
    background-image: url('/assets/images/document.svg');
  }

  @include gridle_state(mobile) {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    width: 7.9rem;
    font-size: .8rem;

    &:before {
      top: .8rem;
      left: 3rem;
    }
  }
}

%at-header-navLink-skin-burger {
  padding-left: 2.1rem;
  padding-right: 5.6rem;
  background: color-palette(tone-primary);

  &:before {
    content: '';
    position: absolute;
    right: 2rem;
    top: 1.4rem;
    width: 3rem;
    height: 2rem;
    color: white;    
    background-image: url('/assets/images/burger.svg');
  }

  @include gridle_state(mobile) {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    width: 7.9rem;
    font-size: .8rem;

    &:before {
      left:0;
      right:0;
      margin:auto;
    }
  }
}

@include header-navLink('.header-navLink--documents', 'documents');
@include header-navLink('.header-navLink--burger', 'burger');
