// Declare skins here

%at-landing-container-skin-default {
  overflow: hidden;
}

@mixin landing-container($selector, $skin: default) {
  #{$selector} {
    @extend %at-landing-container-skin-#{$skin} !optional;

    .page-banner-container {
      min-height: 31.9rem;
      background-color: color-palette(tone-primary);
      background-size: cover;
      background-position: center;

      @include gridle_state(mobile) {
        min-height: 0;
        background: color-palette(tone-primary-brightless);
      }
    }

    .page-title {
      text-align: left;

      @include gridle_state(mobile) {
        font-size: 2rem;
      }
    }

    .page-title--hw {
      @include gridle_state(mobile) {
        font-size: 1.6rem;
      }
    }

    .projects-list {
      margin-top: 3.9rem;

      @include gridle_state(mobile) {
        margin-top: 2rem;
      }
    }
  }
}

// Include component here
@include landing-container('.landing-container');
