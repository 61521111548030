// Declare skins here

%at-header-skin-default {
  @include clearfix();
  padding: 1.6rem 0;
  background-color: color-palette(neutral);
  width: 100%;
  position: relative;
  z-index: 100;
  box-shadow: 0 0.2rem 0.3rem rgba(1, 1, 1, 0.45);

  @include gridle_state(header-mobile mobile){
    background-color: color-palette(neutral);
    position: fixed;
    padding: 1rem 0 0 0;
    box-shadow: none;
    z-index: 20;
  }
}

%at-header-skin-floatable {
 position: absolute;
}

%at-header-skin-no-shadow {
  box-shadow: none;
}

%at-header-skin-docked {
  position: fixed;
  top: 0; left: 0;
}

%at-header-skin-light {
  padding: 1.6rem 0;
}

@mixin header($selector, $skin: default) {
  #{$selector} {
    @extend %at-header-skin-#{$skin} !optional;
  }
}

// Include component here
@include header('.header');
@include header('.header--floatable', 'floatable');
@include header('.header--no-shadow', 'no-shadow');
@include header('.header--docked', 'docked');
@include header('.header--light', 'light');
