@import '../default/4_organisms/footernavigation';


%at-footer-navigation-skin-default {
  // Default skin here
  width: 100%;
  @include gridle_state(mobile) {
      padding: 0;
      height: auto;
      text-align: center;
    }

}
