// Declare skins here

%at-home-container-skin-default {
  margin-top: 7rem;
  @include gridle_state(mobile, tablet) {
    margin: 0;
  }
}

@mixin home-container($selector, $skin: default) {
  #{$selector} {
    @extend %at-home-container-skin-#{$skin} !optional;

    .section-title{
      font-family: $font-medium;
      font-size: 1.4rem;
      color:color-palette(tone-primary-dark);
      text-align: center;
      margin-bottom: 3rem;


      @include gridle_state(mobile) {
        margin-bottom: 1rem;
      }

      &--hw{
        font-family: $font-hw-bold;
        font-size: 2.8rem;
        color:color-palette(tone-primary);
        text-transform: uppercase;
      }
    }

    &__title {
      margin: 0 0 4rem 2.3rem;
    }

    &__tiles {
     // margin-bottom: 5rem;
    }

    &__scroll-button{
      color: color-palette(neutral-grey-dark);
      position: relative;
      left: 50%;
      transform: translate3d(-50%, -100%, 0);
      font-size: 5rem;
      border: none;
      background: none;
      outline: 0;
      &--up{
        position:absolute;
      }
    }
  }
}

// Include component here
@include home-container('.home-container');
