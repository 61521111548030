// Declare skins here

%at-simulation__stepper-skin-default {
  margin-bottom: 8rem;
  text-align: center;

  @include gridle_state(mobile) {
    margin-bottom: 3rem;
  }
}

%at-simulation__stepper-skin-large {
  margin-left: 3rem;

  @include gridle_state(mobile) {
    margin-bottom: 3rem;
    margin-left: 0;
  }
}

@mixin simulation__stepper($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__stepper-skin-#{$skin} !optional;

    &List {
      display: inline-block;
      padding: 0; margin: 0;
      list-style-type: none;
    }
  }
}

// Include component here
@include simulation__stepper('.simulation__stepper');
@include simulation__stepper('.simulation__stepper--large','large');
