// Declare skins here

%at-testimony-title-skin-default {
  // Default skin here
  font-family: $font-hw-bold;
  color: color-palette(neutral);
  font-size: 4rem;
  line-height: 1;
  padding: 2rem 0;
  margin:0;
}

%at-testimony-title-skin-grey {
  // Default skin here
  color: color-palette(tone-primary-dark);
}

@mixin testimony-title($selector, $skin: default) {
  #{$selector} {
    @extend %at-testimony-title-skin-#{$skin} !optional;
  }
}

// Include component here
@include testimony-title('.testimony__title');
@include testimony-title('.testimony__title--grey', 'grey');
