@import "../default/3_molecules/modal";
@import "../2_atoms/pagetitle";
%at-modal-skin-default {
  .cand-modal__title {
    @extend %at-page-title-skin-default;
    text-align: left;
    margin: 2rem;
    @include gridle_state(mobile) {
      padding: 0.7rem;
    }
  }
  .cand-modal__message {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    &Text {
      margin-left: 2rem;
      font-size: 1.6rem;
    }
  }
  .cand-modal__content {
    box-shadow: inset 0 8px 8px -8px rgba(0, 0, 0, 0.2);
    margin: 0;
    padding: 2rem;
    width: 100%;

    @include gridle_state(mobile) {
      padding: 0;
      padding-bottom: 1rem;
    }
  }
  .cand-modal__titlebt {
    color: #4c4c4c;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    @include gridle_state(mobile) {
      font-size: 2rem;
      padding-top: 1.7rem;
    }
  }
  .cand-modal__detailTitle {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    @include gridle_state(mobile) {
      font-size: 13px;
      font-weight: 700;
      margin-left: 2rem;
      padding-top: 1.7rem;
      text-align: left;
    }
  }
  .cand-modal__box {
    display: flex;
    justify-content: space-around;
    background-color: #f7f7f7;
    box-shadow: inset rgba(0, 0, 0, 0.2);
    width: 55%;
    margin: 2rem auto;
    padding: 1rem;
    font-size: 12px;
    @include gridle_state(mobile) {
      width: 85%;
      flex-direction: column;
      background: none;
      margin: 0;
      margin-left: 3rem;
      font-size: 12px;
      padding-bottom: 0;
    }
    @include gridle_state(tablet) {
      padding: 1rem 3rem;
    }
  }
  .cand-modal__senddocument {
    @extend .contact-caption;
    margin-bottom: 0 !important;
  }
  .cand-modal__buttonsize {
    border: 0px solid #fc0081;
    padding: 0.8rem 14rem;
    @include gridle_state(mobile) {
      padding: 1rem 10rem;
    }
  }
  .cand-modal__sendtext {
    font-weight: 300;
    line-height: 1.5rem;
    @include gridle_state(mobile) {
      font-weight: 300;
      line-height: 1.5rem;
      padding-top: 0rem;
      text-align: center;
    }
  }
  .cand-modal__content,
  .cand-modal__content p {
    color: color-palette(neutral-grey-darker);
  }
  .cand-modal__small-text {
    font-family: $font-regular;
    font-size: $font-size-normal;
    color: color-palette(popin-tone-primary);
  }
  .cand-modal__content-title {
    @extend .cand-modal__title;
    font-size: $font-size-big;
  }
}
%at-modal-skin-side-mobile {
  @include gridle_state(mobile) {
    box-shadow: inset 6px 0 8px rgba(0, 0, 0, 0.2);
    height: 100vh;
    left: 20%;
    width: 80%;

    .cand-modal__title {
      border: none;
      border-bottom: 4px solid color-palette(neutral-grey-blackest);
      text-align: center;
      font-size: 2.2rem;
    }

    &.ng-enter,
    &.ng-leave {
      box-shadow: none;
      transition: all 1s ease;

      & > .cand-modal__overlay {
        transition: left 1s ease;
      }
    }
    &.ng-enter.ng-enter-active,
    &.ng-leave {
      opacity: 1;

      & > .cand-modal__overlay {
        left: 0;
        transform: none;
      }
    }
    &.ng-enter,
    &.ng-leave.ng-leave-active {
      opacity: 0;

      & > .cand-modal__overlay {
        left: 100%;
        transform: none;
      }
    }

    .cand-modal__overlay {
      box-shadow: inset 10px 0 6px -8px rgba(0, 0, 0, 0.4);
      background-color: color-palette(neutral-light);
      border-radius: 0;
      max-height: 100%;
      margin: 0;
      min-height: 100%;
      overflow-y: scroll;
      top: 0;
      transform: none;
    }
    .cand-modal__fade {
      background-color: transparent;
      left: -30%;
      width: 30%;
    }
  }
}

%at-modal-skin-documentary {
  top: 5rem;
  z-index: 2;
}

@include modal(".cand-modal--side-mobile", "side-mobile");
@include modal(".cand-modal--documentary", "documentary");
