// Declare skins here

%at-fast-quote__action-skin-default {

}

%at-fast-quote__action-skin-edito2 {
  display: inline-block;
}

@mixin fast-quote__action($selector, $skin: default) {
  #{$selector} {
    @extend %at-fast-quote__action-skin-#{$skin} !optional;

    margin-top: 5rem;
    @include gridle_state(mobile){
      margin-top: 4rem;
    }
  }
}

// Include component here
@include fast-quote__action('.fast-quote__action');
@include fast-quote__action('.fast-quote__action--edito2', 'edito2');
