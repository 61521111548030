// Declare skins here
%at-simulation__groupTitle-skin-default {
  @include clearfix();
  font-family: $font-medium;
  color: color-palette(tone-primary);
  text-align: center;
  margin-bottom: 2.2rem;
  list-style-type: none;
  padding: 0;
  font-size: $font-size-normal;
  line-height: 1.4;

  @include gridle_state(mobile) {
    margin-bottom: 2.5rem;
  }
}

%at-simulation__groupTitle-skin-strong {
  font-family: $font-medium;
}

%at-simulation__groupTitle-skin-info {
  font-size: 1.7rem;
  line-height: 2rem;
  padding-bottom: 2rem;
  display: block;
}

%at-simulation__groupTitle-skin-sub-title {
  font-size: 2rem;
  text-transform: uppercase;
}

%at-simulation__groupTitle-skin-bigger {
  font-size: 2.5rem;
}

%at-simulation__groupTitle-skin-upper {
  text-transform: uppercase;
}

%at-simulation__groupTitle-skin-documentary-space {
  margin-top: 8rem;
}

%at-simulation__groupTitle-skin-tiny {
  font-size: 1.2rem;
}

%at-simulation__groupTitle-skin-no-mb {
  margin-bottom: 0rem;
}

@mixin simulation__groupTitle($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__groupTitle-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__groupTitle('.simulation__groupTitle');
@include simulation__groupTitle('.simulation__groupTitle--strong','strong');
@include simulation__groupTitle('.simulation__groupTitle--info','info');
@include simulation__groupTitle('.simulation__groupTitle--sub-title','sub-title');
@include simulation__groupTitle('.simulation__groupTitle--upper','upper');
@include simulation__groupTitle('.simulation__groupTitle--documentary-space','documentary-space');
@include simulation__groupTitle('.simulation__groupTitle--bigger','bigger');
@include simulation__groupTitle('.simulation__groupTitle--tiny','tiny');
@include simulation__groupTitle('.simulation__groupTitle--no-mb','no-mb');
