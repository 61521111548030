// Declare skins here

%at-customModal-skin-default {

}

%at-customModal-skin-content {
  min-height: 20rem;

  .textfield {
    background: none;

    &.js-valid ~ .textfield-rich__post, &.ng-valid ~ .textfield-rich__post {
      color: color-palette(tone-primary-brightless);
    }

    &.js-pristine, &.ng-pristine {
      border-bottom: .2rem solid color-palette(neutral-grey-darkest);
    }

    &.js-valid, &.ng-valid {
      color: color-palette(tone-primary-brightless);
      border-color: color-palette(tone-primary-brightless);
    }

    &.js-invalid, &.ng-invalid {
      color: color-palette(tone-error);
      border-color: color-palette(tone-error);
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px color-palette(neutral) inset;
    }
  }
}

@mixin customModal($selector, $skin: default) {
  #{$selector} {
    @extend %at-customModal-skin-#{$skin} !optional;

    .bank-form__label {
      color: color-palette(tone-primary-dark);
    }

    .custom-modal-text {
      color: color-palette(tone-grey-black-dark);
      margin-bottom: 2rem;
    }

  }
}

// Include component here
@include customModal('.customModal');
@include customModal('.customModal--content', 'content');
