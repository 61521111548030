// Declare skins here

%at-page-404-subtitle-skin-default {
  color: color-palette(neutral);
  font-family: $font-regular;
  font-size: 3.4rem;

  @include gridle_state(mobile) {
    font-size: 2.2rem;
  }
}

@mixin page-404-subtitle ($selector, $skin: default) {
  #{$selector} {
    @extend %at-page-404-subtitle-skin-#{$skin} !optional;
  }
}

// Include component here
@include page-404-subtitle('.page-404__subtitle');
