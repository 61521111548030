// Declare skins here

%at-info-block__text-skin-default {
  font-size: 2.4rem;
  line-height: normal;
}

%at-info-block__text-skin-neutral {
  color: color-palette(neutral);
}

%at-info-block__text-skin-primary {
  color: color-palette(tone-primary);
}

%at-info-block__text-skin-secondary {
  color: color-palette(tone-secondary);
}

%at-info-block__text-skin-hw {
  font-family: $font-hw-bold;
  font-size: 3.1rem;
}

%at-info-block__text-skin-header {
  margin-left: 1rem;
  @include gridle_state(tablet) {
    margin-left: 0;
    text-align: center;
  }
}

%at-info-block__text-skin-hw {
  font-size: $font-size-big;
}

@mixin info-block__text($selector, $skin: default) {
  #{$selector} {
    @extend %at-info-block__text-skin-#{$skin} !optional;
  }
}

// Include component here
@include info-block__text('.info-block__text');
@include info-block__text('.info-block__text--neutral', 'neutral');
@include info-block__text('.info-block__text--primary', 'primary');
@include info-block__text('.info-block__text--secondary', 'secondary');
@include info-block__text('.info-block__text--hw', 'hw');
@include info-block__text('.info-block__text--header', 'header');
@include info-block__text('.info-block__text--hw-small', 'hw-small');

