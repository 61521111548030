%at-fast-quote-landing-skin-default {
  position: relative;
  margin: -15rem 0 4rem 5rem;
  color: color-palette(tone-primary-dark);
  font-size: 1.6rem;
  box-shadow: 0 .9rem 0 0 white, 0 -.6rem 0 0 white, 1.1rem 0 0.5rem -1rem rgba(207, 207, 207, 1), -1.1rem 0 0.5rem -1rem rgba(207, 207, 207, 1);

  @include gridle_state(mobile) {
    margin: 0;
    box-shadow: none;
  }

  .select--tiny {
    max-width: 16.5rem;
  }

  .select-box__track {
    position: static;
  }
}

@mixin fast-quote-landing($selector, $skin: default) {
  #{$selector} {
    @extend %at-fast-quote-landing-skin-#{$skin} !optional;

    &__content {
      padding-top: .5rem;
      line-height: 4.7rem;

      @include gridle_state(mobile) {
        text-align: center;
      }
    }

    &__form {
      margin-top: -3.9rem;
      padding: 3rem;
      background: color-palette(neutral);

      @include gridle_state(mobile) {
        margin: 1rem;
        padding: 2rem 3rem;
        border: 2px solid #002689;
        border-radius: 6px;
      }
    }

    &__title {
      font-family: $font-bold;
      font-size: 2.4rem;
      color: color-palette(tone-primary);
      text-align: center;
    }

    &__subtitle {
      &:before,
      &:after {
        content: '';
        display: inline-block;
        border-style: solid;
      }

      &:before {
        margin-right: .9rem;
        border-width: .5rem 0 .5rem .87rem;
        border-color: transparent transparent transparent color-palette(tone-primary-cadet);
      }

      &:after {
        margin-left: .9rem;
        border-width: .5rem .87rem .5rem 0;
        border-color: transparent color-palette(tone-primary-cadet) transparent transparent;
      }

      @include gridle_state(mobile) {

        &:before, &:after {
          display: none;
        }
      }
    }

    &__unit {
      color: color-palette(neutral-grey-darker);
    }

    &__action {
      margin-top: 5rem;

      @include gridle_state(mobile) {
        margin-top: 2rem;
      }

      .button {
        text-transform: uppercase;
      }
    }

    &__error {
      display: none;

      p {
        display: inline-block;
        background: color-palette(neutral);
        color: color-palette(tone-error);
        font-size: $font-size-small;
        line-height: 2.6rem;
        padding: .5rem 1.5rem;
        border-radius: 2.5rem;
        border: .2rem solid;
      }
    }

    &__logo{
      padding-bottom: 4rem;
      img{
        max-width: 100%;
        width: auto;
        height: auto;
        text-align: center;
        margin: 0 auto;
      }
    }
    &--widget{
      max-width: 41rem;
      padding: 0;
      background-color: color-palette(neutral);
      color: color-palette(tone-primary-dark);
      border-top: 1px outset color-palette(neutral) ;
      border-top-left-radius: .4rem;
      border-top-right-radius: .4rem;
      @include gridle_state(mobile) {
        margin-top: 0;
        width: 100%;
        max-width: 100%;
      }
      &__title{
        font-size: 2rem;
        color: color-palette(tone-primary);
        font-weight: bold;
        text-align: center;
      }
      &__subtitle{
        color: color-palette(tone-secondary);
        font-family: $font-hw-bold;
        font-size: 2rem;
        text-align: center;
      }

      &__header{
        padding: 2rem;
        border-bottom: 1px solid color-palette(tone-primary);
        position: relative;
        background: color-palette(neutral);
        border-left: 1px outset color-palette(neutral) ;
        &:after, &:before {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(255, 255, 255, 0);
          border-top-color: #fff;
          border-width: 12px;
          margin-left: -12px;
        }
        &:before {
          border-color: rgba(114, 201, 231, 0);
          border-top-color: color-palette(tone-primary);
          border-width: 13px;
          margin-left: -13px;
        }
      }

      &__content{
        text-align: center;
        padding-top: .5rem;
        line-height: 4.7rem;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        border-bottom-right-radius: .4rem;
        border-bottom-left-radius: .4rem;
        @include gridle_state(tablet){
          padding: 1rem;
        }
      }

      &__button{
        @include gridle_state(tablet) {
          width: 100%;
          min-width: inherit;
        }
      }

      &__content-subtitle{
        font-size: 1.8rem;
        font-weight: bold;
        color: color-palette(grey-text-darker);
      }

      &__action {
        margin-top: 3rem;
        padding-bottom: 3rem;

        .button {
          text-transform: uppercase;
          font-size: 1.6rem;
        }
      }
      &__upper{
        text-transform: uppercase;
      }

      &__bold{
        font-weight: bold;
      }

      &__action {

        button {
          text-transform: uppercase;
        }
      }


      .select {
        border: .1rem solid color-palette(grey-select-border);
        background: none;
        height: 3.4rem;
        border-radius: .3rem;

        & ~ .select-box__track {
          color: color-palette(tone-primary-brightless);
          margin-top: .9rem;
          font-size: 1.4rem;
        }

        &.js-valid, &.ng-valid {
          color: color-palette(tone-primary-brightless);
          border-color: color-palette(tone-primary-brightless);

          & ~ .select-box__track {
            color: color-palette(tone-primary-brightless);
          }
        }

        &.js-invalid, &.ng-invalid {
          color: color-palette(tone-error);
          border-color: color-palette(tone-error);

          & ~ .select-box__track {
            color: color-palette(tone-error);
          }
        }
      }

      .select-box__track {
        color: color-palette(tone-primary-brightless);
      }

      .textfield {
        background: none;

        &.js-valid ~ .textfield-rich__post, &.ng-valid ~ .textfield-rich__post {
          color: color-palette(tone-primary-brightless);
        }

        &.js-pristine, &.ng-pristine {
          border-bottom: .2rem solid  color-palette(neutral-grey-darkest);
        }

        &.js-valid, &.ng-valid {
          color: color-palette(tone-primary-brightless);
          border-color: color-palette(tone-primary-brightless);
        }

        &.js-invalid, &.ng-invalid {
          color: color-palette(tone-error);
          border-color: color-palette(tone-error);
        }

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 1000px color-palette(neutral) inset;
        }

      }

    }

  }
}

// Include component here
@include fast-quote-landing('.fast-quote-landing');
