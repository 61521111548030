%at-sub-answer-skin-default {

  .sub-answer__questions{
    display: flex;
    flex-flow:row nowrap;
    align-items: flex-end;
    @include gridle_state(mobile){
      flex-wrap: wrap;
    }
  }
  .sub-answer__label {
    label:after {
      content: '\00a0*';
    }

    &.float-label{
      z-index:50;//allow to overlap input with non transparent bg
      line-height: 1;

    }

  }
    .sub-answer__remove {
      .sub-answer__button{
        cursor: pointer;
      }
      color: color-palette(tone-error);
      text-align: right;
      font-weight: bold;

      @include gridle_state(mobile) {
        text-align: center;
        padding: 0;
        margin:2rem 0;

      }
    }

  .sub-answer__input {
    input{
      width:100%;
    }

  }

}


@mixin sub-answer($selector, $skin: default) {
  #{$selector} {
    @extend %at-sub-answer-skin-#{$skin} !optional;

  }
}

// Include component here
@include sub-answer('.sub-answer');