// Declare skins here

%at-tri-tiles-skin-default {
  // Default skin here
  display:block;
  padding-bottom: 8rem;
}

@mixin tri-tiles($selector, $skin: default) {
  #{$selector} {
    @extend %at-tri-tiles-skin-#{$skin} !optional;

    &__tile-wrapper{
      display: inline-block;
      margin: 0;
      padding : 0;
    }
  }
}

// Include component here
@include tri-tiles('.tri-tiles');
