// Button defaults

@mixin buttonRelief { // Skin to have relief on the button
  border-width: 0 0 .4rem 0;

  &:active {
    border-width: .4rem 0 0 0;
    border-color: transparent;
  }
}

// Declare skins here

%at-button-skin-default {
  // Default skin here
  min-width: 22rem;
  text-align: center;
  border: 1px solid color-palette(neutral);
  background-color: transparent;
  padding: 1.5rem 2.2rem;
  color: color-palette(neutral);
  font: 1.9rem $font-medium;
  outline:none;
  display: inline-block;
  transition: opacity .3s ease-out, background-color .1s ease-out, color .1s ease-out;
  cursor: pointer;
  box-shadow: 0 0.1rem 0.2rem rgba(1, 1, 1, 0.42);
  border-radius: 2.5rem;

  // Reset for <a /> tag
  vertical-align: middle;
  text-decoration: none;

  &:hover, &:focus {
    background-color: color-palette(neutral);
    border-color: color-palette(neutral);
    color: color-palette(tone-secondary);
    //font-family: $font-medium;
  }

  &[disabled] {
    opacity: .5;
    pointer-events: none;
  }

  &.ng-invalid:not(.ng-pristine) {
    border-color: color-palette(tone-error);
    color: color-palette(tone-error);

    &:hover {
      background-color: color-palette(tone-error);
      color: color-palette(neutral);
    }
  }
}

%at-button-skin-primary {
  background-color: color-palette(tone-primary);
  border-color: color-palette(tone-primary);
  color: color-palette(neutral);

  &:hover, &:focus {
    color: color-palette(neutral);
    border-color: color-palette(tone-primary-king);
    background-color: color-palette(tone-primary-king);
  }
}

%at-button-skin-small {
  min-width: 0;
  font-size: 1.6rem;
  @include gridle_state(mobile) {
    padding: 1.5rem 1rem 1.2rem;
  }
}

%at-button-skin-chevron-down {
  position: relative;
  @include icon(chevron-down,after);

  &:after {
    font-weight: bold;
    position: absolute;
    right: 0rem;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

%at-button-skin-secondary {
  background-color: color-palette(tone-secondary);
  color: color-palette(neutral);
  border-color: color-palette(tone-secondary);

  &:hover, &:focus {
    color: color-palette(neutral);
    border-color: color-palette(tone-secondary);
    background-color: lighten(color-palette(tone-secondary), 2%);
  }
}

%at-button-skin-neutral {
  color: color-palette(neutral);
  border-color: color-palette(neutral);
}

%at-button-skin-primaryBright {
  background-color: color-palette(tone-primary-bright);
  border-color: lighten(color-palette(tone-primary-bright), 10%);

  &:hover {
    background-color: lighten(color-palette(tone-primary-bright), 2%);
    border-color: lighten(color-palette(tone-primary-bright), 12%);
    color: color-palette(neutral);
  }

  @include buttonRelief();
}

%at-button-skin-secondaryBlack {
  background-color: color-palette(tone-secondary-black);
  border-color: darken(color-palette(tone-secondary-black), 10%);

  &:hover {
    background-color: lighten(color-palette(tone-secondary-black), 2%);
    border-color: darken(color-palette(tone-secondary-black), 12%);
    color: color-palette(neutral);
  }

  @include buttonRelief();
}

%at-button-skin-grey {
  color: color-palette(neutral-grey-dark);
  border-color: color-palette(neutral-grey-dark);
  &:hover{
    border-color: color-palette(tone-primary);
    color: color-palette(tone-primary);
  }
}

%at-button-skin-flat-grey {
  font-family: $font-regular;
  padding: 1.5rem 5rem;
  color: color-palette(tone-primary);
  border: none;
  background-color: color-palette(neutral-light);
  &:hover, &:focus {
    background-color: color-palette(neutral-light);
    color: color-palette(tone-primary);
  }
}

%at-button-skin-cancel {
  color: color-palette(popin-tone-secondary);
  border-color: color-palette(popin-tone-secondary);
  min-width: 0;
  padding: 1rem 3rem;
  @include gridle_state(mobile) {
    padding: 1.5rem 1rem 1.2rem !important;
  }

  &:hover, &:focus {
    background-color: transparent;
    border-color: color-palette(popin-tone-secondary);
    color: color-palette(popin-tone-secondary);
  }
}


%at-button-skin-fullwidth{
  width: 100%;
  padding: 1rem;
}
%at-button-skin-fullwidth-mobile{
  @include gridle_state(mobile){
    width: 100%;
    padding: 1rem;
  }
}

%at-button-skin-transparent{
  color: color-palette(input-placeholder);
  text-align: left;
  border-bottom: .1rem solid color-palette(input-placeholder);
  padding: 0 0 1rem 0;
  text-transform: uppercase;
  opacity: .6;

  &:hover, &:focus {
    opacity: 1;
    color: color-palette(input-placeholder);
    border-bottom: .1rem solid color-palette(input-placeholder);
  }
}

%at-button-skin-downloaded {
  @include icon(checkmark);

  position: relative;

  padding: .8rem 3rem !important;

  &:before {
    position: absolute;
    right: .5rem;
    top: 50%;
    line-height: 0;
  }

}

%at-button-skin-active_primary {
  background-color: color-palette(tone-primary);
  border-color: color-palette(tone-primary);
  color: color-palette(neutral);
}

%at-button-skin-flat {
  @extend %at-button-skin-primary;
  border-radius: 0;
  background-color: color-palette(neutral);
  color: color-palette(tone-primary-dark);
  border-color: color-palette(tone-primary-dark);
  box-shadow: none;
}

%at-button-skin-flat-primary {
  @extend %at-button-skin-primary;
  border-radius: 0;
}

%at-button-skin-flat-primary-reversed {
  @extend %at-button-skin-primary;
  background: color-palette(neutral);
  color: color-palette(tone-primary);
  border-radius: 0;
  box-shadow: none;
}

%at-button-skin-primary-reversed {
  @extend %at-button-skin-primary;
  background: color-palette(neutral);
  color: color-palette(tone-primary);
}

%at-button-skin-secondary-reversed {
  @extend %at-button-skin-secondary;
  background: color-palette(neutral);
  color: color-palette(tone-secondary);
}

%at-button-skin-camera {
  background: #ffffff !important;
  border: 7px solid !important;
  border-color: color-palette(neutral-grey-darkest) !important;
  border-radius: 50% !important;
  height: 5.3rem;
  width: 5.3rem;
  max-width: 5.3rem;
  min-width: 5.3rem !important;
}

@mixin button($selector, $skin: default) {
  #{$selector} {
    @extend %at-button-skin-#{$skin} !optional;
  }
}

// Include component here
@include button('.button');
@include button('.button--primary','primary');
@include button('.button--rounded','rounded');
@include button('.button--primaryBright','primaryBright');
@include button('.button--secondary','secondary');
@include button('.button--neutral','neutral');
@include button('.button--secondaryBlack','secondaryBlack');
@include button('.button--grey','grey');
@include button('.button--cancel','cancel');
@include button('.button--border','border');
@include button('.button--flat-grey','flat-grey');
@include button('.button--flat-primary','flat-primary');
@include button('.button--flat','flat');
@include button('.button--flat-primary-reversed','flat-primary-reversed');
@include button('.button--primary-reversed','primary-reversed');
@include button('.button--secondary-reversed','secondary-reversed');

@include button('.button--fullwidth','fullwidth');
@include button('.button--fullwidth-mobile','fullwidth-mobile');
@include button('.button--downloaded','downloaded');
@include button('.button--transparent','transparent');
@include button('.button--active_primary','active_primary');
@include button('.button--small','small');
@include button('.button--chevron-down','chevron-down');
@include button('.button--camera','camera');
