%at-appointment-consent-skin-default {
  @include gridle_state(mobile) {
    box-shadow: inset 0 7px 8px -8px rgba(0, 0, 0, 0.6);
    padding-bottom: 0;
    padding-top: 0rem;
  }

  & .simulation__buttons {
    margin: 0;

    @include gridle_state(mobile) {
      border-top: none;
    }

    .button {
      @include gridle_state(mobile) {
        margin: 1rem 0px;
        min-width: 100%;
      }
    }
  }
}

%at-appointment-consent-skin-page {
  & .adhesion-stepper {
    display: block;
  }
}
@mixin appointment-consent($selector, $skin: default) {
  #{$selector} {
    @extend %at-appointment-consent-skin-#{$skin} !optional;
    @include gridle_state(mobile) {
      padding-bottom: 3rem;
    }
    &__content {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 3rem;
      @include gridle_state(mobile) {
        padding-bottom: 0rem;
        padding-top: 0rem;
        text-align: center;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 2rem;
        width: 100%;

        @include gridle_state(mobile) {
          justify-content: center;
          padding-top: 3rem;
          padding-bottom: 2rem;
        }
        &__title {
          font-size: 30px;
          font-weight: 600;
          line-height: 22px;
          text-transform: uppercase;
          @include gridle_state(mobile) {
            display: none;
          }
        }

        &__image {
          content: url("/assets/images/logo-ARM.svg");
          height: 4.5rem;
        }
      }
    }

    &__description {
      font-family: Montserrat;
      text-align: left;

      &::first-letter {
        text-transform: capitalize;
      }

      @include gridle_state(mobile) {
        padding: 1rem 1rem 2rem;
        text-align: center;
      }
      &--descriptionBackground {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #f7f7f7;
        padding: 2rem 5rem;
        white-space: pre-line;
        @include gridle_state(mobile) {
          background-color: transparent;
          padding: 0;
          text-align: left;
        }
      }
    }
    &__checkboxPos {
      border-color: #979797 !important;
      white-space: initial;
      font-weight: 600;
      &--checkboxLabel {
        @include gridle_state(mobile) {
          font-size: 17px;
        }
      }
    }

    .choice-box__label,
    .choice-box__container {
      color: color-palette(neutral-grey-black);
      text-align: left;

      @include gridle_state(mobile) {
        padding: 0;
      }
    }
    .choice-box__label {
      &:before {
        border-color: color-palette(tone-secondary);
      }
    }
  }
}

@include appointment-consent(".appointment-consent");
@include appointment-consent(".appointment-consent-page", "page");
