// Declare skins here

%at-testimony-slide-skin-default {

  font-size: 1.6rem;
  font-family: $font-regular;
  color: color-palette(tone-secondary-dark);

  background-color: color-palette(neutral);
  padding: 2rem;

  @include gridle_state (  mobile  ){

  }
}

@mixin testimony-slide($selector, $skin: default) {
  #{$selector} {
    @extend %at-testimony-slide-skin-#{$skin} !optional;

    @if($skin == default){

    }
  }
}

// Include component here
@include testimony-slide('.testimony__slide');

