// Declare skins here

%at-footer-description-skin-default {
  // Default skin here
  color: color-palette(neutral);
  margin: 0;
  padding: 0 2rem 0 0;
}

%at-footer-description-skin-light {
  @extend %at-footer-description-skin-default;
  opacity: 0.5;
}

@mixin footer-description($selector, $skin: default) {
  #{$selector} {
    @extend %at-footer-description-skin-#{$skin} !optional;
  }
}

// Include component here
@include footer-description('.footer__description');
@include footer-description('.footer__description--light', 'light');
