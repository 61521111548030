@import '../default/5_templates/documentary-space';
@import '../2_atoms/circlearrowicon';
%at-documentary-space-skin-default {

	.documentary-space__home{
		margin:1.7rem;
	}

	.documentary-space__documents-page{
		& > .cand-modal__content > *, .pill__content {
			box-shadow: inset 10px 0 6px -8px rgba(0, 0, 0, 0.4);
		}

		.page-subtitle{
			padding: 2.1rem 1.4rem;
		}
		.documentary-space__back{
			width:100%;
			background-color: white;
			padding: 2.2rem;
			position:relative;
			box-shadow: 6px 0 8px rgba(0, 0, 0, 0.2), inset 10px 0 6px -8px rgba(0, 0, 0, 0.4);
			&-button{
				display: table-cell;
				@extend %at-circle-arrow-icon-skin-default;
				&:after{
					background-color: color-palette(tone-secondary);
					transform: rotate(270deg);
					color:white;
					position:absolute;
					left:0;
					margin-left:1.7rem;

				}
			}

			&-label{
				display: table-cell;
				vertical-align: middle;
				width:80%
			}


		}
	}
	.documentary-space__introduction{
		border-left: 4px solid color-palette(tone-secondary-dark);
		padding : 0 2.3rem;
		margin : 0 4rem 4rem;

		@include gridle_state(mobile){
			border : none;
			padding: 0;
			margin:2rem 0;
			font-size: 12px;
			font-weight: 300;

		}
	}
  .cand-modal__content{
  	background-color : color-palette(neutral-grey-light);

	  @include gridle_state(mobile){
		  background-color : color-palette(neutral-light);
		  box-shadow: none;
		  padding: 0;
	  }
  }

  .cand-modal__overlay{
  	width: auto;
  	margin : 0 10rem;
	  @include gridle_state(mobile){
		  margin : 0;
	  }
  }
}




