%at-documentary-list-skin-default {
  align-items: center;
  display: flex;
  flex-direction: column;

  @include gridle_state(mobile) {
    align-items: flex-start;
  }

  & .choice-box__label:before {
    height: 1.6rem;
    width: 1.6rem;
    border: 0.1rem solid #979797;
    border-radius: 0.3rem;
    font-size: 1.2rem;
    min-width: 1.6rem;
  }

  & .choice-box__label {
    color: #666666;
    font-size: 1.4rem;
    line-height: 2.2rem;

    @include gridle_state(mobile) {
      align-items: center;
      display: flex;
      text-align: left;
    }

    span {
      @include gridle_state(mobile) {
        display: block;
      }
      @include gridle_state(mobile-xs) {
        font-size: 1.2rem;
      }
    }
  }
}

@mixin documentary-list($selector, $skin: default) {
  #{$selector} {
    @extend %at-documentary-list-skin-#{$skin} !optional;

    &--bottom {
      margin: 0 auto;
      &.button--deny {
        .choice-box__label:before {
          background-color: #ececec;
        }
      }
    }
    &--full-width {
      width: 100%;
    }
    &--no-padding {
      padding: 0;
      margin: 0;
    }
    &__item {
      box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
      background-color: #ffffff;
      list-style: none;
      list-style-type: none;
      margin: 1rem 0;
      &--method {
        display: flex;
        flex-direction: column;
        background-color: #f7f7f7;
        &__link {
          margin: 0 auto;
          padding-bottom: 1rem;
          color: #fc0081;
        }
      }
      &-download {
        @include first-letter-uppercase();

        @include gridle_state(mobile) {
          font-size: 1.4rem;
        }

        a {
          color: color-palette(tone-primary);
        }
      }
      &--top {
        padding: 2.3rem 3rem 2.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__right {
          display: flex;
          align-items: center;
          &__arm-image {
            height: 2.5rem;
          }
        }
        @include gridle_state(mobile) {
          display: flex;
          align-items: initial;
          flex-direction: column;
          padding-left: 2rem;
          padding-right: 2rem;
        }
      }
      &--detailexam {
        font-size: 1.3rem;
        color: #666666 !important;
      }
      &--bottom {
        display: flex;
        flex-direction: row-reverse;
        align-items: stretch;
        border-top: 1px solid color-palette(neutral-grey-light);
        padding: 0.8rem 3rem;
        &__info {
          max-width: 46rem;
          margin-right: auto;
          font-size: 1.4rem;
          color: #074624;
        }
        .button--deny {
          .choice-box__label:before {
            background-color: #ececec;
          }
        }
        @include gridle_state(mobile) {
          padding: 1rem 2rem;
          justify-content: center;
        }
      }

      &-title {
        font-size: $font-size-medium;
        font-weight: 600;
        text-transform: uppercase;

        @include gridle_state(mobile) {
          font-size: 1.4rem;
          text-align: left;
          width: 60%;
        }
      }

      &-status {
        font-size: $font-size-medium;
        font-weight: 600;

        @include first-letter-uppercase();

        @include gridle_state(mobile) {
          font-size: 1.2rem;
          height: 10%;
          line-height: 17px;
          width: 28%;
        }

        &--sent {
          color: color-palette(tone-secondary);
        }

        &--provide {
          color: color-palette(tone-warning);
        }

        &--error {
          color: color-palette(tone-error);
        }
      }

      &-checkbox {
        @include gridle_state(mobile) {
          padding: 0.5rem 1rem;
          align-items: center;
          justify-content: center;
          display: flex;
          text-align: center;
        }
      }
      &-button {
        margin-left: 1rem;
        margin-top: auto;
        margin-bottom: auto;
        @include gridle_state(mobile) {
          text-align: right;
        }
        &--byother {
          font-size: 1.5rem;
        }
        .button {
          border: none;
          background-color: white;
          color: color-palette(tone-primary);
          min-width: 18rem;
          padding-right: 0;
          padding-left: 0;
          position: relative;
          text-decoration: none;
          text-transform: none;
          @include noSelect();

          @include gridle_state(mobile) {
            min-width: 12rem;
            text-align: right;
          }

          .icon {
            border-bottom: 0.1rem solid color-palette(tone-primary);
            font-size: 1.3rem;
            padding: 0;
            margin: 0;
            position: initial;
            margin-right: 1rem;
            @include gridle_state(mobile) {
              font-size: 1.5rem;
            }
          }

          span {
            @include gridle_state(mobile-xs) {
              font-size: 1.2rem;
            }
          }
        }
        &.button--validate {
          padding-top: 0.8rem;
          padding-bottom: 0.8rem;
          @include gridle_state(mobile) {
            padding: 1rem;
          }
          @include gridle_state(mobile-xs) {
            padding-left: 1rem;
            padding-right: 1rem;
          }
          button.button {
            padding-bottom: 0;
            padding-top: 0;
            background-color: #fee5f2;
            &:focus,
            &:hover {
              background-color: #fee5f2;
            }
          }
        }
        &--float-left {
          text-align: left !important;
          .button {
            text-align: left !important;
            @include gridle_state(mobile) {
              text-align: center !important;
            }
          }
        }
      }
    }
  }
}

@include documentary-list(".documentary-list");
