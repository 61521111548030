// Declare skins here

%at-simulation__offre-skin-default {

  .simulation__panel{
    border-color: color-palette(neutral);
    min-height: 41.2rem;
    padding: 4.4rem 3rem;
  }

  .simulation__panelContent {
    //background-color: color-palette(neutral);
    padding: 2rem 1.5rem;
    margin: 1rem;
    text-align: left;
    font-size: $font-size-normal;

    .button{
      margin-top: 0;
      padding: .5rem 0;
    }

    @include gridle_state(mobile) {
      padding: 0;
    }
  }

  .simulation__loading-panel{
    position: absolute;
    top: 0;
    left : 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(255,255,255,0.7);

    @include gridle_state(tablet) {
      position: fixed;
    }
  }

  .simulation__loading-message{
    // background: url("/assets/images/loading-grey.svg") no-repeat center top;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0 1rem;
    transform: translate3d(-50%, -50%, 0);
    &::before {
      display: block;
      margin-bottom: 2rem;
    }
  }

  .simulation__panelContent_text{
    line-height: $font-size-normal;
    font-size: $font-size-normal;
  }

  .simulation__panelContent_title{
    font-family: $font-medium;
    color: color-palette(tone-primary-dark);
    margin-bottom: 2rem;
  }

  .contact{
    text-align: left;
  }

  .simulation__offreContent {
    padding: 0;
  }
}


@mixin simulation__offre($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__offre-skin-#{$skin} !optional;

    &_total {
      margin-bottom: 6.5rem;

      &Text {
        font-family: $font-medium;
        font-size: $font-size-normal;

        @include gridle_state(mobile) {
          text-align: left;
          //font-size: $font-size-normal;
        }
      }
    }

    &_quote {
      color: color-palette(tone-error);
      font-size: 1.4rem;
      text-align: left;
      line-height: 2.3rem;
      padding: 1rem 4rem;
      &--warning{
        background: color-palette(bridesmaidapprox);
      }
    }

    &_info {
      color: color-palette(tone-secondary-black);
      font-size: $font-size-normal;
      text-align: left;
      line-height: 2.3rem;
      padding-bottom: 2rem;
    }

    &_title {
      font-size: 1.8rem;
      color: color-palette(tone-primary);
      display: inline-block;
    }

    &_subtitle {
      font-size: 1.6rem;
      color: color-palette(dolphin);
      display:block;
      padding-left: 2.5rem;
    }

    &_bloc{
      text-align: left;
      padding:3rem;
      .icon--info2{
        font-size: 1.8rem;
        color: color-palette(neutral-grey-dark);
      }
    }
    &_silder{
      padding: 1rem 2rem;
      @include gridle_state(mobile) {
        padding: 2rem 0rem;
      }
    }
    & .rz-pointer.rz-pointer-min{
      border-radius:0.8rem;
      background-color: white;
      width: 1.5rem;
      height: 3rem;
      box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.4);
      &:after{
        display: none;
      }
    }
    & .rzslider {
      padding-bottom:4rem;
      & .rz-bar{
        background-color: color-palette(bar-grey);
        &.rz-selection{
          background-color: color-palette(tone-secondary);
        }
      }
      & .rz-bubble{
        top:.5rem;
        &.rz-limit{
          color:color-palette(tone-primary);
        }
        & .rz-center{
          font-family: $font-hw-bold;
          display: flex;
          position: absolute;
          top: 2rem;
          background-color: color-palette(tone-primary);
          transform: translate(-50%, 0%);
          border-radius: .8rem;
          color: white;
          width: 6.2rem;
          height: 4.4rem;
          &:before {
            content: '';
            width: 0;
            height: 0;
            border-left: 1rem solid transparent;
            border-right: 1rem solid transparent;
            border-bottom: 1rem solid color-palette(tone-primary);
            top: -4px;
            position: absolute;
            left: 35%;
          }
          b{
            margin:auto;
          }
        }
      }
    }
  }
}

// Include component here
@include simulation__offre('.simulation__offre');

