// Declare skins here

%at-header-subnav-skin-default {
	position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 0;
  text-align: left; /* for aligning the sub items */
  background-color: white;
  color: color-palette(neutral-grey-black);
  box-shadow: 0 .2rem .4rem rgba(0, 0, 0, 0.5);
}

@mixin header-subnav($selector, $skin: default) {
  #{$selector} {
    @extend %at-header-subnav-skin-#{$skin} !optional;
    ul{
      text-transform: none;
      list-style: none;
      padding: 0;
      & > li{
        padding: 1.8rem;
        white-space: normal;
        line-height: normal;
        i{
          float: right;
        }
      }
      & > li:not(:last-child){
        border-bottom: .2rem solid color-palette(neutral-grey-light);
      }
    }
    &Last{
       color: color-palette(tone-primary);
    }
  }
}

// Include component here
@include header-subnav('.header-subnav');
