// Declare skins here

%at-header-block-skin-default {
  padding: 3rem 4.9rem;
  background-color: color-palette(neutral-grey-light);
  color: color-palette(neutral-grey-darker);
  font-weight: 300;
  margin-bottom: 3rem;

  @include gridle_state(mobile) {
    padding: 1.3rem;
  }
}

%at-header-block-skin-title {
  font-size: 2.6rem;
  display: block;
  line-height: 3.2rem;
  padding-bottom: .8rem;

  @include gridle_state(mobile) {
    font-size: 1.4rem;
    padding-bottom: .3rem;
  }
}

%at-header-block-skin-text {
  font-size: 1.9rem;
  display: block;
  line-height: 2.3rem;

  @include gridle_state(mobile) {
    font-size: 1.2rem
  }
}


@mixin header-block($selector, $skin: default) {
  #{$selector} {
    @extend %at-header-block-skin-#{$skin} !optional;

  }
}

// Include component here
@include header-block('.header-block');
@include header-block('.header-block--title', 'title');
@include header-block('.header-block--text', 'text');
