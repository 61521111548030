%at-circle-arrow-icon-skin-default {
  @include icon(chevron-up,after);
   &:after {
     width: 3rem;
     height: 3rem;
     padding: 0.4rem;
     border-radius: 50%;
     background-color: color-palette(neutral-grey-light);
     text-align: center;
     color: #6f6f6f;
   }
}
%at-circle-arrow-icon-skin-secondary{
  &:after{
    background-color: color-palette(tone-secondary);
    color:white;
  }
}
%at-circle-arrow-icon-skin-big{
  &:after{
    width: 4rem;
    height: 4rem;
    padding: 1rem;
  }
}

%at-circle-arrow-icon-skin-down {
  &:after {
    transform: rotateZ(180deg);
  }
}

%at-circle-arrow-icon-skin-right {
  &:after {
    transform: rotateZ(90deg);
  }
}

@mixin circle-arrow-icon($selector, $skin: default) {
  #{$selector} {
    @extend %at-circle-arrow-icon-skin-#{$skin} !optional;
  }
}

// Include component here
@include circle-arrow-icon('.circle-arrow-icon');
@include circle-arrow-icon('.circle-arrow-icon--secondary', 'secondary');
@include circle-arrow-icon('.circle-arrow-icon--big', 'big');
@include circle-arrow-icon('.circle-arrow-icon--down', 'down');
@include circle-arrow-icon('.circle-arrow-icon--right', 'right');
