// Declare skins here

%at-tile-media-skin-default {
  // Default skin here
  background-size: contain;
  background-repeat: no-repeat;
  flex-grow: 2;
  margin: 2.5rem 0 3.5rem 0;
}

@mixin tile-media($selector, $skin: default) {
  #{$selector} {
    @extend %at-tile-media-skin-#{$skin} !optional;
  }
}

// Include component here
@include tile-media('.tile-media');
