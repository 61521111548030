@import '../default/6_pages/main';

%at-main-skin-default {
  min-height: calc(100vh - 16.5rem);

  @include gridle_state(header-mobile tablet) {
    min-height: calc(100vh - 7rem);
    padding-top: 5rem;
    &.push-to-left{
      left:-80%;
    }
  }
}
