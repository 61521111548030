// Declare skins here
// TODO: A refactoriser. Utiliser deux markup HTML lors de l'utilisation des modifiers.

%at-simulation__toggle-skin-default {
  position: relative;
  font-size: $font-size-big;
  text-align: center;
  text-transform: uppercase;
  color: color-palette(tone-primary-light);
  margin-bottom: 0;
  cursor: pointer;

  &.js-opened {
    color: color-palette(tone-primary-dark);
  }
}

%at-simulation__toggle-skin-fixed {
  cursor: text;
}

%at-simulation__toggle-skin-neutral {
  background-color: color-palette(neutral-grey);
  margin-bottom: .2rem;
  padding: 4rem 0;
  text-align: center;
  transition: background-color .3s ease-out;

  &.js-opened {
    background-color: color-palette(neutral);
  }
}

%at-simulation__toggle-skin-primary {
  background-color: color-palette(neutral-grey);
  color: color-palette(tone-primary);
  text-align: left;
  transition: background-color .3s ease-out;
  text-transform: none;
  padding: 1rem 1.5rem;
  margin-bottom: .5rem;
  //font-family: $font-medium;
  font-size: $font-size-normal;

  .simulation__toggle_text {
    font-size: inherit;
    padding: 1rem 0;
  }

  .simulation__toggle_icon {
    margin-left: 2.5rem;
  }

  .simulation__toggle_info {
    margin-right: 5rem;

    @include gridle_state(mobile) {
      margin-right: 0;
    }
  }
  .simulation__toggle_info:before {
    margin-right: 1rem;
  }

  //.textfield {
  //  font-family: $font-regular;
  //}

  &.js-opened {
    background-color: color-palette(tone-primary);
    color: color-palette(neutral);
  }

  @include gridle_state(mobile) {
    font-size: $font-size-normal;
  }
}

%at-simulation__toggle-skin-secondary {
  @extend %at-simulation__toggle-skin-primary;
  text-align: center;
  color: color-palette(tone-primary);
  background-color: color-palette(neutral-light);

  .simulation__toggle_text {
    display: table;
    font-family: $font-regular;

    @include gridle_state(mobile) {
          font-size: $font-size-small;
        }
  }


  .simulation__toggle_type,
  .simulation__toggle_date {
    display: table-cell;
    text-align: left;
  }

  .simulation__toggle_type--middle{
    vertical-align: middle;
  }

  .simulation__toggle_date {
    text-align: right;
  }

  &.js-opened {
    background-color: color-palette(tone-secondary);

    .simulation__toggle_text {
      font-family: $font-medium;
    }
  }
}

%at-simulation__toggle-skin-documents-proposition {
  line-height: 4rem;
  &.js-opened {
    margin-bottom: 4rem;
  }
}

%at-simulation__toggle-skin-flex {
 display: flex;
 align-items: center;
 & > h3{
   flex: 1;
 }
}

%at-simulation__toggle-skin-chevron {
 display: flex;
 align-items: center;
 &.js-opened > h3{
   	@include icon(chevron-up,before);
 }
 & > h3{
   	@include icon(chevron-down,before);
 }
}
@mixin simulation__toggle($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__toggle-skin-#{$skin} !optional;

    @if $skin == default {
      &Content {
        @include clearfix();
        margin-top: 2rem;
      }

      &_text {
        display: inline-block;
        //font-family: $font-medium;
        font-size: $font-size-normal;

        &--uppercase {
          text-transform: uppercase;
        }
      }

      &_icon {
        position: absolute;
        right: 2.5rem;
        top: 50%;
        line-height: normal;
        display: inline-block;
        transition: transform .3s ease-out;
        transform: translate3d(0, -50%, 0);

        @include gridle_state(mobile) {
          right: 1rem;
        }
      }

      &.js-opened {
        .simulation__toggle_icon {
          transform: translate3d(0, -50%, 0) rotateZ(180deg);
        }
      }
      &_title{
        padding: 1rem 0;
      }
    }
  }
}

// Include component here
@include simulation__toggle('.simulation__toggle');
@include simulation__toggle('.simulation__toggle--neutral', 'neutral');
@include simulation__toggle('.simulation__toggle--primary', 'primary');
@include simulation__toggle('.simulation__toggle--secondary', 'secondary');
@include simulation__toggle('.simulation__toggle--documents-proposition', 'documents-proposition');
@include simulation__toggle('.simulation__toggle--fixed', 'fixed');
@include simulation__toggle('.simulation__toggle--flex', 'flex');
@include simulation__toggle('.simulation__toggle--chevron', 'chevron');
