%at-alert-skin-default{
  width: 100%;
  min-height: 4.5rem;
  border : 1px solid color-palette(tone-error);
  color : color-palette(tone-error);
  padding : 1rem 1rem 1rem 6rem;
  position : relative;



  @include icon(notification);

  &::before{
    position : absolute;
    left:0;
    top:0;
    width : 4.5rem;
    padding: 0.5rem 0;
    height:100%;
    background-color: color-palette(tone-error);
    text-align: center;
    color: color-palette(neutral);
    font-size: $font-size-big;
  }

}



@mixin alert($selector, $skin: default) {
  #{$selector} {
    @extend %at-alert-skin-#{$skin} !optional;

  }
}

@include alert('.alert');

