// Declare skins here

%at-contact-skin-default {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: color-palette(neutral-light);
}

%at-contact-skin-folded{
  height: 12rem;

  .contact__column {
    display: none;
  }
}

@mixin contact($selector, $skin: default) {
  #{$selector} {
    @extend %at-contact-skin-#{$skin} !optional;

    &__column {
      padding: 0; margin: 0;

      & + & {
        padding-left: 10rem;
        margin-top: 6rem;
      }

      .page-title { // TODO: refactor as Atom
        margin-bottom: 2rem;
      }
    }

    &__group {
      margin-bottom: 5rem;
      list-style-type: none;

      &:before {
        font-size: 2.5rem;
        color: color-palette(tone-secondary);
        float: left;
        line-height: 1;
        margin-right: 2rem;
      }

      &Content {
        overflow: hidden;
        display: inline-block;
      }
    }

    &__link {
      color: color-palette(tone-primary-dark);
      text-decoration: none;

      &--icon {
        font-size: 2rem;

        & + & {
          margin-left: 1.5rem;
        }
      }
    }
  }
}

// Include component here
@include contact('.contact');
@include contact('.contact--folded', 'folded');
