// Declare skins here

%at-glossary-menu-itemLink-skin-default {
  font-size: $font-size-normal;
  text-decoration: none;
  color: color-palette(tone-secondary-dark);

  &:hover {
    color: color-palette(tone-primary-dark);
  }
}

@mixin glossary-menu-item ($selector, $skin: default) {
  #{$selector} {
    @extend %at-glossary-menu-itemLink-skin-#{$skin} !optional;
  }
}

// Include component here
@include glossary-menu-item('.glossary-menu__itemLink');
