// Declare skins here

%at-main-skin-default {
  @include gridle_state(header-mobile) {
    padding-top: 8rem;
  }
  @include gridle_state(header-mobile mobile){
  	left:0;
  	position: relative;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;
  }
}

@mixin main($selector, $skin: default) {
  #{$selector} {
    @extend %at-main-skin-#{$skin} !optional;

    &.megamenu-pushtoright{
  		@include gridle_state(header-mobile mobile){
  			left: 90%;
  		}
    }
    &.main-landing {
      @include gridle_state(header-mobile) {
        padding-top: 7.3rem;
      }
    }
  }
}

// Include component here
@include main('#main');
