%at-download-block-skin-default {
    width: 100%;
    padding: 2.5rem 3rem;
    color: color-palette(neutral-grey-darker);
    margin-top: 4.4rem;
    @include gridle_state(mobile) {
        padding: 1.5rem 1.5rem;
    }
}

@mixin download-block($selector, $skin: default) {
    #{$selector} {
        @extend %at-download-block-skin-#{$skin} !optional;

        &--text {
            display: table-cell;
            vertical-align: middle;
            padding-left: 7rem;

            a {
                color: color-palette(tone-secondary);
                text-decoration: underline !important;
                cursor: pointer;
            }
            @include gridle_state(mobile) {
                font-size: 1.2rem;
            }
        }

        &--text__mobile {
            a {
                color: color-palette(tone-secondary);
                text-decoration: underline !important;
                cursor: pointer;
            }
            @include gridle_state(mobile) {
                display: block;
                vertical-align: middle;
                padding-left: 0;
                font-size: 1.2rem;
            }
        }

        &--link {
            cursor: pointer;
        }

        &--info {
            background: transparent url('/assets/images/warning.svg') 0 50%
                no-repeat;
            display: table;
            min-height: 5.5rem;
            margin-bottom: 1rem;
            @include gridle_state(mobile) {
                margin-bottom: 2rem;
            }
        }

        .center {
            margin: 0 auto;
            float: none;
            text-align: center;
        }

        .button--download {
            margin: 1rem auto;
            display: block;
            width: 55rem;
            @include gridle_state(mobile) {
                width: 100%;
            }
        }

        &__mobile {
            background-color: color-palette(neutral-grey-light);
            box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
            width: auto !important;
            margin: 1.5rem -1rem;
            text-align: left;
            padding: 1rem 0;
        }

        &--gray {
            @extend %at-download-block-skin-#{$skin} !optional;
            display: flex;
            justify-content: flex-start;
            margin-top: 0;
            margin-left: 1.5rem;
            align-items: center;
            @include gridle_state(mobile) {
                margin-left: -2rem;
                margin-right: -2rem;
                width: unset;
                padding: 2rem 4rem;
            }
            & .button--download {
                width: unset;
                margin: 0;
                border: 2px solid #4c4c4c;
                color: #666666;
                &:hover,
                &:focus {
                    background: #4c4c4c;
                    color: white;
                }
                @include gridle_state(mobile) {
                    width: 100%;
                    max-width: unset;
                }
            }
            &--details {
                width: 100%;
                font-size: 18px;
                line-height: 27px;

                a {
                    color: color-palette(tone-secondary-black);
                    text-decoration: underline;
                }

                @include gridle_state(mobile) {
                    font-size: 1.4rem;
                    line-height: 2.5rem;
                    margin-bottom: 1rem;
                }
            }
            &--details--icon {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 1rem;
                background: #ececec;
                border-radius: 53%;
                width: 3rem;
                height: 4rem;
                padding: 0;

                @media screen and (max-width: 768px) {
                    margin-bottom: 2rem;
                }
            }
        }
    }
}

// Include component here
@include download-block('.download-block');
