// Declare skins here

%at-slider-frame-skin-default {
  // Default skin here
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}

@mixin slider-frame($selector, $skin: default) {
  #{$selector} {
    @extend %at-slider-frame-skin-#{$skin} !optional;
  }
}

// Include component here
@include slider-frame('.slider__frame');
