%at-sinister-skin-default {
  padding-bottom: 5rem;
  text-align: center;
  color: color-palette(tone-primary-dark);
  font-size: 1.9rem;

  .simulation__stepper {
    margin-bottom: 4rem;
  }

  .simulation__stepperItem {
    cursor: default;
  }

  .sinister__buttons .button {
    width: 100%;
    min-width: 0;
  }

  .lists,
  .lists--check {
    margin-left: 3.5rem;

    @include gridle_state(mobile) {
      margin-left: 0;
    }
  }

  .custom-icon--document2 {
    width: 3.2rem;
    height: 3.8rem;
    vertical-align: middle;
  }

  .icon--download {
    margin-right: .5rem;
    font-size: 2.5rem;
  }

  .icon--loading:before {
    margin-right: 2rem;
  }

  .button__text {
    text-decoration: underline;
  }

  .button--start {
    display: block;
    margin: 4.7rem auto 0 auto;
    min-width: 31rem;

    @include gridle_state(mobile) {
      min-width: 1rem;
    }
  }
}

@mixin sinister($selector, $skin: default) {
  #{$selector} {
    @extend %at-sinister-skin-#{$skin} !optional;

    &__title {
      color: color-palette(tone-primary);
      font-family: $font-medium;
    }

    &__phone {
      padding-top: 4rem;
    }

    &__details {
      padding-top: 4rem;
      padding-bottom: 3rem;
    }

    &__description {
      .button--flat {
        padding: 0;

        &.js-active {
          background: color-palette(tone-primary);
          color: color-palette(neutral);
        }
      }
    }

    &__label {
      display: block;
      padding: 1.4rem 0;
      cursor: pointer;
    }

    &__radio {
      position: absolute;
      left: -9999rem;
    }

    &__textarea {
      resize: none;
      width: 26rem;
      height: 2.7rem;
    }

    &__dateLabel {
      margin-bottom: 1rem;
    }

    &__dateInput {
      padding: 1.5rem 3rem .7rem 0;
      width: 18rem !important;
      background-image: url('/assets/images/calendar.svg');
      background-repeat: no-repeat;
      background-size: 16%;
      background-position: 95% 50%;
    }

    &__dateInput[type=date] {
      min-height: 4rem;
    }

    &__dataForm {
      margin-bottom: 6rem;
      line-height: 2;

      .button {
        margin-top: 2rem;
      }
    }

    &__upload {
      margin-bottom: 3rem;

      .files-list__title {
        font-size: 1.6rem;
      }

      .filefield {
        padding: 10px;
        width: 60%;
      }

      .filefield--empty {
        width: 60%;
      }

      .files-list__action {
        background-color: #e6e9ef;
      }
    }

    &__upload-item {
      margin-bottom: 3rem;
    }

    &__upload-itemFirst {
      clear: left;
    }
  }
}

// Include component here
@include sinister('.sinister');
