// Declare skins here

%at-simulation__password-requirements-skin-default {
  display: block;
  font-size: 60%;
}

%at-simulation__password-requirements-skin-warning {
  color: color-palette(tone-warning);
}


@mixin simulation__password-requirements($selector, $skin: default) {
  #{$selector} {
    @extend %at-simulation__password-requirements-skin-#{$skin} !optional;
  }
}

// Include component here
@include simulation__password-requirements('.simulation__password-requirements');
@include simulation__password-requirements('.simulation__password-requirements--warning', 'warning');
