// Declare skins here

%at-youtube-video-skin-default {
  // Default skin here
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}

@mixin youtube-video($selector, $skin: default) {
  #{$selector} {
    @extend %at-youtube-video-skin-#{$skin} !optional;
    & > iframe{
	    position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
    }
  }
}

// Include component here
@include youtube-video('[data-youtube-video]');
