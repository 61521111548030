// Declare skins here

%at-client-navigation-skin-default {
  margin: 4rem 0;
}

@mixin client-navigation($selector, $skin: default) {
  #{$selector} {
    @extend %at-client-navigation-skin-#{$skin} !optional;

    &__list {
      margin: 0; padding: 0;
      font-size: $font-size-normal;
      list-style-type: none;
    }

    &__item {
      & + & {
        margin-top: .2rem;
      }

      &.js-active {
        .client-navigation__itemLink {
          color: color-palette(tone-primary);

          .client-navigation__title {
            border-bottom: .2rem solid color-palette(tone-secondary);
          }
        }
      }

      &Link {
        color: rgba(color-palette(tone-primary-dark), .4);
        text-decoration: none;

        &:hover, &.js-active {
          color: color-palette(tone-primary);

          .client-navigation__title {
            border-bottom: .2rem solid color-palette(tone-secondary);
          }
        }
      }
    }

    &__title {
      padding-bottom: .5rem;
    }

    &__icon {
      font-size: 2.5rem;
      margin-right: .7rem;
    }
  }
}

// Include component here
@include client-navigation('.client-navigation');
