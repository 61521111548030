// Declare skins here

%at-documentslist-skin-default {
  margin: 0;
}

%at-documentslist-skin-info {
  text-align: right;
  font-weight: 700;
  vertical-align: middle;
  display: block;
  margin-top: -4rem;
  padding-bottom: 2rem;
  .icon{
    color: color-palette(tone-primary) !important;
    vertical-align: middle;
    margin-left: 1rem !important;
    font-size: 2rem !important;
  }
}

%at-documentslist-skin-content {
  margin: 0;
  padding: 0 3.5rem;
  border : .1rem solid color-palette(neutral-grey-light);
  background-color: color-palette(neutral);
  @include gridle_state (mobile) {
    padding: 0;
  }
}

%at-documentslist-skin-header {
  background-color: color-palette(neutral-grey-darker);
  padding: 2.4rem 3.8rem;
  color: color-palette(neutral);
  font-weight: 400;
  font-size: 1.6rem;
  @include gridle_state (mobile) {
    padding: 1.5rem 1.5rem;
    font-size: $font-size-normal;

  }
}


@mixin documents-list($selector, $skin: default) {
  #{$selector} {
    @extend %at-documentslist-skin-#{$skin} !optional;

    @if($skin == default) {
      &__item{
        display: table;
        list-style-type : none;
        padding : 3.5rem 0;
        min-height: 11rem;
        width: 100%;
        @include gridle_state (mobile) {
          min-height: 5rem;
        }
          &:not(:last-child){
            border-bottom: .1rem solid color-palette(neutral-grey-light);

          }

        .icon--checkmark{
          display: table-cell;
          vertical-align: middle;
          color: color-palette(tone-secondary);
          @include gridle_state (mobile) {
            padding-top: 1rem;
            vertical-align: top;
            text-align: center;
          }
        }

        &_col{
          display: table-cell;
          vertical-align: middle;
          text-align: left;
          @include gridle_state (mobile) {
            display: block;
            padding-left: 0;
            margin: 1rem 0;
          }
          &:first-child{
            width: 38%;
            text-align: left;
            padding-left: 3%;
            @include gridle_state (tablet) {
              width: 25%;
            }
            @include gridle_state (mobile) {
              width: 100%;
              padding-left: 2rem;
            }
          }

          &--title{
            display: inline-block;
            text-align: left;
          }

          &--button{
            width: 23rem;
            margin: 0 auto;
            text-align: right;
            @include gridle_state (mobile) {
              margin: 0;
              text-align: center;
              width: 100%;
            }

            .button{
              @include gridle_state (mobile) {
                width: 90%;
                max-width: 30rem;
              }
            }


          }

          &--empty{
            display: block;
            min-width: 10rem;
          }

          &--upload{
            width: 35%;
            text-align: left;
            @include gridle_state (mobile) {
              width: 100%;
              margin: 2rem 0;
              padding-left: 2rem;
            }
          }
        }
        @include gridle_state(mobile){
          padding : 0;
        }
      }

      .files-list__file{
        position: relative;
        &:not(.files-list__file--empty){
          padding: 1rem;
          background-color: color-palette(neutral-light);
          color: color-palette(neutral-grey-black);
          font-size: $font-size-normal;
          font-weight: 400;
          @include gridle_state (mobile) {
            max-width: 40rem;
          }

        }

        .icon--close{
          position: absolute;
          padding: 0;
          right: .5rem;
          top: 1.3rem;
          &:before{
            float: right;
            font-size: $font-size-normal;
          }
        }

      }

      .files-list__container{
        text-align: left;
      }

      .files-list{
        margin: 0 auto;
        width: 95%;
        @include gridle_state (mobile) {
          margin: 0;
          text-align: left;
        }
      }
      &__item-header{
        @include gridle_state(mobile){
          display:table-cell;
          vertical-align: middle;
          width:70%;
        }
      }
      &__item-title{
        text-transform: uppercase;
      }
      &__item-info{
        &::before{
          content:"\a";
          white-space: pre;
        }
        @include gridle_state(mobile){
          color:color-palette(tone-secondary);
        }

      }
      &__item-button{
        float : right;
        @include gridle_state(mobile){
          display:table-cell;
          vertical-align: middle;
          width:20%;
          float:none;
          .button{
            min-width: 0;
            width: 4rem;
          }
        }
      }


      .filefield {
        padding: 0;
        width: 95%;
        background: none;
        font-size: $font-size-medium;
        color: color-palette(neutral-grey);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:not(.filefield--empty){
          color: color-palette(neutral-grey-black);
          font-size: $font-size-normal;
          font-weight: 400;
          max-width: 50rem;
          @include gridle_state (tablet) {
            max-width: 20rem;
            font-size: 1.2rem;
          }
          @include gridle_state (mobile) {
            max-width: 40rem;
          }
        }

        @include gridle_state (mobile) {
          max-width: 85%;
          white-space: normal;
          text-align: left;
        }

      }

      .filefield--empty {
        position: relative;
        padding: 0;
        width: 3.4rem;
        height: 3.4rem;
        min-width: 1rem;
        max-width: 100%;
        font-size: 2rem;
        border: 0;
        border-radius: 50%;
        background: color-palette(tone-primary);
        transition: none;
        font-weight: bold;
        vertical-align: middle;

        &:before {
          position: absolute;
          top: 50%;
          left: 49%;
          margin: -.7rem 0 0 -.7rem;
          color: color-palette(neutral);
          font-size: 1.5rem;
          font-weight: bolder;
        }
        @include gridle_state (mobile) {
          text-align: left;
        }
      }

      .filefield--invalid,
      .filefield--error {
        border: none;
        color: color-palette(tone-error);
      }

      .fileButton--label{
        font-size: $font-size-normal;
        display: inline-block;
        vertical-align: middle;
      }

      .selection-list--text{
        margin-left: 0;
      }

      &--notice{
        display: block;
        text-align: right;
        font-size: 1rem;
        font-weight: 200;
        padding-bottom: .5rem;
        @include gridle_state (mobile) {
          padding-right: .5rem;
        }
      }

      &--items{
        margin-left: -4rem;
      }



    }


  }
}

// Include component here
@include documents-list('.documents-list');
@include documents-list('.documents-list--content','content');
@include documents-list('.documents-list--header','header');
@include documents-list('.documents-list--info','info');
