// Declare skins here

// TODO: position modifiers around element: centered, align left, align right...

%at-cand-tooltip-skin-default {
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;

  &:after {
    content: attr(data-cand-tooltip);
    pointer-events: none;
    text-align: center;
    background-color: color-palette(neutral-grey);
    color: color-palette(tone-primary);
    padding: 1.5rem 2rem;
    font-size: 1.2rem;
    line-height: 1;
    //white-space: nowrap;
    min-width: 40rem;
    max-width: 50rem;
    position: absolute;
    top: 4rem;
    left: 0;
    z-index: 4;
    transform: translate3d(0, -1rem, 0);
    opacity: 0;

    transition: opacity .2s ease-out,
    transform .3s ease-out;
    transition-delay: 0s;

    @include gridle_state(mobile) {
      white-space: normal;
      min-width: 32rem;
    }
  }

  &:hover {
    &:after {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition-delay: 0s;
    }
  }

}
%at-cand-tooltip-skin-left-mobile{
  @include gridle_state(mobile){
    &:after{
      min-width: 30rem;
      margin-left: -25rem;
    }
  }
}

%at-cand-tooltip-skin-pull-left-mobile{
  @include gridle_state(mobile){
  &:after{
      min-width: 30rem;
      margin-left: -15rem;
    }
  }
}

%at-cand-tooltip-skin-pushed-left-mobile{
  @include gridle_state(mobile){
    &:after{
      min-width: 28rem;
      margin-left: -2rem;
    }
  }
}

%at-cand-tooltip-skin-align-left-mobile{
  @include gridle_state(mobile){
    &:after{
      min-width: 28rem;
    }
  }
}

%at-cand-tooltip-skin-pull-left{
  &:after{
    margin-left: -20rem;
    @include gridle_state(mobile){
      min-width: 30rem;
      margin-left: -15rem;
    }
  }
}

%at-cand-tooltip-skin-push-right{
  &:after{
    margin-left: 0;
    left: initial;
    right: -6rem;
    z-index: 99;
    min-width: 28rem;
    white-space: normal;
  }
}

@mixin cand-tooltip($selector, $skin: default) {
  #{$selector} {
    @extend %at-cand-tooltip-skin-#{$skin} !optional;
  }
}

// Include component here
@include cand-tooltip('[data-cand-tooltip]:not([data-cand-tooltip=""])');
@include cand-tooltip('.tooltip--pull-left[data-cand-tooltip]:not([data-cand-tooltip=""])', 'pull-left');
@include cand-tooltip('.tooltip--pull-left-mobile[data-cand-tooltip]:not([data-cand-tooltip=""])', 'pull-left-mobile');
@include cand-tooltip('.tooltip--left-mobile[data-cand-tooltip]:not([data-cand-tooltip=""])', 'left-mobile');
@include cand-tooltip('.tooltip--align-left-mobile[data-cand-tooltip]:not([data-cand-tooltip=""])', 'align-left-mobile');
@include cand-tooltip('.tooltip--pushed-left-mobile[data-cand-tooltip]:not([data-cand-tooltip=""])', 'pushed-left-mobile');
@include cand-tooltip('.tooltip--push-right[data-cand-tooltip]:not([data-cand-tooltip=""])', 'push-right');
