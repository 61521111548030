%at-testimony-summary-skin-default {
  // Default skin here

  font-family: $font-medium;
  font-size: 2rem;
  padding-top: 1.3rem;
}

@mixin testimony-summary($selector, $skin: default) {
  #{$selector} {
    @extend %at-testimony-summary-skin-#{$skin} !optional;
  }
}

// Include component here
@include testimony-summary('.testimony__summary');
