@import '../default/4_organisms/simulationstepper';

%at-simulation__stepper-skin-default {
  margin-bottom: 0;

  .simulation__stepperList {
    padding: 3.6rem 6rem 3.2rem 6rem;
    background-color: rgba(color-palette(tone-secondary), .9);
    display: flex;

    @include gridle_state(mobile) {
      padding: 1.3rem 2.1rem 1.5rem 2.1rem;
      flex-direction: row;
    }
  }
}
