// Declare skins here

%at-page-404-container-skin-default {
   display: table-cell;
   vertical-align: middle;
   padding-left: 35%;

   @include gridle_state(tablet) {
     vertical-align: top;
     text-align: center;
     padding-left: 0;
   }
}

@mixin page-404-container ($selector, $skin: default) {
  #{$selector} {
    @extend %at-page-404-container-skin-#{$skin} !optional;
  }
}

// Include component here
@include page-404-container('.page-404__container');
