// Declare skins here

%at-tabs-skin-default {
  margin: 0;
  padding: 0;
}

%at-tabs-skin-bordered {
  border-bottom : 0.1rem solid color-palette(neutral-grey-light);
}

%at-tabs-skin-button-mobile{
  @include gridle_state(mobile){
    border: 0.1rem solid color-palette(tone-primary);
    border-radius: 0.4rem;
  }
}

@mixin tabs($selector, $skin: default) {
  #{$selector} {
    @extend %at-tabs-skin-#{$skin} !optional;

    @if($skin == default) {
    	display: flex;
        flex-flow: row wrap;
		& li{
			flex : 1;
            // Unique item won't expand to fullwidth
            max-width: 50%;
			list-style-type: none;

		}
    }
    

  }
}

// Include component here
@include tabs('.tabs');
@include tabs('.tabs--bordered', 'bordered');
@include tabs('.tabs--button-mobile', 'button-mobile');
