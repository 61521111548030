// Declare skins here
$document-size: 7rem;
$document-ratio: 1;

$shadow-height: 0.8rem;
$shadow-ratio: 9.6;

$alert-height: 10rem;
$alert-ratio: 1;

$dashboard-refused-size: 4.3rem;

%at-custom-icon-skin-default {
  display: inline-block;
  background-size: contain;
}

%at-custom-icon-skin-document {
  background-image: url('/assets/images/document.svg');
  background-repeat: no-repeat;
  height: $document-size;
  width: $document-size * $document-ratio;
}

%at-custom-icon-skin-downloadable-document {
  background-image: url('/assets/images/downloadable-document.svg');
  background-repeat: no-repeat;
  height: $document-size;
  width: $document-size * $document-ratio;
}

%at-custom-icon-skin-shadow {
  background-image: url('/assets/images/consents_shadow.svg');
  background-repeat: no-repeat;
  height: $shadow-height;
  width: $shadow-height * $shadow-ratio;
}

%at-custom-icon-skin-alert-navigator {
  background-image: url('/assets/images/alerte_navigator.svg');
  background-repeat: no-repeat;
  height: $alert-height;
  width: $alert-height * $alert-ratio;
}

%at-custom-icon-skin-document2 {
  background-image: url('/assets/images/document2.svg');
  background-repeat: no-repeat;
  height: $dashboard-refused-size;
  width: $dashboard-refused-size;
}

%at-custom-icon-skin-documents {
  background-image: url('/assets/images/documents.svg');
  background-repeat: no-repeat;
  height: $dashboard-refused-size;
  width: $dashboard-refused-size;
}

%at-custom-icon-skin-info {
  background-image: url('/assets/images/info.svg');
  background-repeat: no-repeat;
  height: $dashboard-refused-size;
  width: $dashboard-refused-size;
}


  @mixin custom-icon($selector, $skin: default) {
    #{$selector} {
      @extend %at-custom-icon-skin-#{$skin} !optional;
    }
  }

// Include component here
  @include custom-icon('.custom-icon');
  @include custom-icon('.custom-icon--document', 'document');
  @include custom-icon('.custom-icon--document2', 'document2');
  @include custom-icon('.custom-icon--documents', 'documents');
  @include custom-icon('.custom-icon--info', 'info');
  @include custom-icon('.custom-icon--downloadable-document', 'downloadable-document');
  @include custom-icon('.custom-icon--shadow', 'shadow');
  @include custom-icon('.custom-icon--alert-navigator', 'alert-navigator');
